import React, { useMemo } from 'react';
import { bool, string, arrayOf, exact, object, number, func } from 'prop-types';
import DOMPurify from 'dompurify';

import { Icon } from '@atoms';
import { Coupon, Map } from '@molecules';
import { Main } from '@templates';
import { Typography, Flex } from '@mixins';
import { useViewport } from '@hooks';
import { getCloudinaryImageLinkBySize } from '@helpers/common';
import { theme } from '@styles';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { BackButton } from '../EditUserProfile/styles';
import { Contact } from './styles';

const BenefitDetails = ({
  couponDescription,
  isLoading,
  image,
  title,
  discountText,
  description,
  contactDetails,
  coupon,
  longitude,
  latitude,
  place,
  address,
  onBackClick,
}) => {
  const onMapClick = () => {
    window.open(`https://www.google.com/maps/place/${address}/@${latitude},${longitude},${18}z`);
  };

  const contactDetailsBlock = contactDetails.reduce((acc, { icon, content, link }) => {
    if (content) {
      acc.push(
        <Flex key={link} mb={[20, 40]} alignItems="center">
          <Icon mr={[10, 20]} SVG={icon} width={[20, 30]} />
          <Contact
            target="_blank"
            as="a"
            href={link}
            variant="proximaNova-400"
            fontSize={[12, 14, 16]}
            maxWidth={[250, 400]}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {content}
          </Contact>
        </Flex>,
      );
    }

    return acc;
  }, []);

  const { width } = useViewport();

  const IMAGE_WIDTH = useMemo(() => (width > 1024 ? 660 : 360), [width]);

  return (
    <Main isLoading={isLoading}>
      <Flex alignItems="start" width="100%" flexDirection="column">
        <Flex
          width="fit-content"
          alignItems="center"
          mb={[20, 25, 30]}
          onClick={onBackClick}
          pointer
        >
          <BackButton mr={['9px', 11, 13]} SVG={Dropdown} color={theme.color.gray[500]} />
          <Typography variant="proximaNova-400">
            Benefits
          </Typography>
        </Flex>
        <Flex mb={[30, 40, 50, 60]} width="100%" justifyContent="space-between">
          <Flex flexDirection="column" maxWidth={[230, 280, 365, 500]}>
            <Typography lineHeight={1.2} mb={15} variant="garamond-500" fontSize={[30, 34]}>
              {title}
            </Typography>
            <Typography mb={20} variant="proximaNova-600" fontSize={[16, 18, 20]}>
              {discountText}
            </Typography>
            <Flex
              mb={[0, 0, 0, 60]}
              display="block"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
            />
            {width > 1023 && (coupon || couponDescription) && (
              <Coupon couponDescription={couponDescription} link={contactDetails[0].link} coupon={coupon} />
            )}
          </Flex>
          <Flex
            width="50%"
            height={[200, 280, 330, 500]}
            background={`url(${getCloudinaryImageLinkBySize(
              image, IMAGE_WIDTH,
            )}) no-repeat center`}
            backgroundSize="cover"
          />
        </Flex>
        {width < 1024 && (coupon || couponDescription) && (
          <Coupon couponDescription={couponDescription} link={contactDetails[0].link} coupon={coupon} />
        )}
        {!!contactDetailsBlock.length && (
          <Flex
            mt={width < 1024 ? [30, 50, 70, 0] : 0}
            width="fit-content"
            flexDirection="column"
          >
            <Typography mb={[15, 30]} variant="garamond-500" fontSize={[24, 30, 36]}>
              Contact details
            </Typography>
            {contactDetailsBlock}
          </Flex>
        )}
        {!!latitude && !!longitude && address && (
          <>
            <Typography mb={[14, 20]} variant="proximaNova-600" fontSize={[16, 18, 20]}>
              Navigate
            </Typography>
            <Flex
              pointer
              onClick={onMapClick}
              border="1px solid #EFECE6"
              flexDirection="column"
              width={460}
            >
              <Map position={{ lat: latitude, lng: longitude }} width="100%" height="270px" zoom={17} />
              {(address || place) && (
                <Flex
                  width="100%"
                  p={26}
                  flexDirection="column"
                >
                  {place && (
                    <Typography fontSize={[16, 18, 20]} variant="proximaNova-600" mb={address ? 10 : 0}>
                      {place}
                    </Typography>
                  )}
                  {address && (
                    <Typography fontSize={[12, 14, 16]} variant="proximaNova-400">
                      {address}
                    </Typography>
                  )}
                </Flex>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </Main>
  );
};

BenefitDetails.defaultProps = {
  couponDescription: '',
  image: '',
  title: '',
  discountText: '',
  description: '',
  contactDetails: [],
  coupon: '',
  latitude: 0,
  longitude: 0,
  place: '',
  address: '',
};

BenefitDetails.propTypes = {
  couponDescription: string,
  onBackClick: func.isRequired,
  coupon: string,
  isLoading: bool.isRequired,
  image: string,
  title: string,
  discountText: string,
  description: string,
  contactDetails: arrayOf(
    exact({
      link: string,
      content: string,
      icon: object,
    }),
  ),
  latitude: number,
  longitude: number,
  place: string,
  address: string,
};

export default BenefitDetails;
