import styled from 'styled-components';
import { color, typography } from 'styled-system';

export const DataTableEllipsisCell = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${color}
  ${typography}
`;

export default DataTableEllipsisCell;
