import React, { Fragment, useCallback } from 'react';
import { string, bool, exact, arrayOf, number, func, object } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Icon } from '@atoms';
import { Socials, CompanyTeam } from '@molecules';
import { PhotoChanger } from '@organisms';
import { Main } from '@templates';
import { theme } from '@styles';
import { routes } from '@constants';
import { Typography, Flex } from '@mixins';

import { ReactComponent as Edit } from '@assets/svgs/Edit.svg';
import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { EditButton } from './styles';
import { BackButton } from '../EditUserProfile/styles';

const getColor = (isWebsite, isEmpty) => {
  switch (true) {
    case isEmpty:
      return theme.color.gray[200];
    case isWebsite:
      return theme.color.darkGreen;
    default:
      return theme.color.gray[500];
  }
};

const CompanyProfile = ({
  error,
  isPhotoLoading,
  isEditable,
  companyId,
  onRemovePhoto,
  onSavePhoto,
  isLoading,
  aboutUs,
  companyName,
  industry,
  logo,
  socials,
  website,
  onTeamLoad,
  team,
  teamAmount,
  from,
}) => {
  const history = useHistory();

  const companyDetails = [
    {
      title: 'About Us',
      placeholder: 'Share your elevator pitch',
      value: aboutUs,
    },
    {
      title: 'Industry',
      placeholder: 'Where you make it happen',
      value: industry,
    },
    {
      title: 'Website',
      placeholder: 'Where are you on the grid?',
      value: website,
    },
  ];

  const handleWebsiteClick = url => () => {
    const link = url.includes('https://') ? url : `https://${url}`;
    window.open(link);
  };

  const companyDetailsBlock = companyDetails.reduce((acc, { title, placeholder, value }) => {
    const isWebsite = value && title === 'Website';
    if (value || isEditable) {
      acc.push(
        <Fragment key={title}>
          <Typography mb={[14, 16, 20]} variant="garamond-500" fontSize={[22, 26, 32]}>
            {title}
          </Typography>
          <Typography
            width="fit-content"
            {...(isWebsite && { pointer: true })}
            {...(isWebsite && { textDecoration: 'underline' })}
            {...(isWebsite && { onClick: handleWebsiteClick(value) })}
            fontSize={[10, 14, 16]}
            mb={[25, 35, 45]}
            variant="proximaNova-400"
            color={getColor(title === 'Website', !value)}
          >
            {value || placeholder}
          </Typography>
        </Fragment>,
      );
    }
    return acc;
  }, []);

  const hasSocials = socials.find(social => !!social.link);

  const onEdit = useCallback(() => {
    history.push({
      pathname: `${routes.companyProfile}/${companyId}/edit`,
      ...(from && {
        state: {
          from,
        },
      }),
    });
  }, [from, companyId]);

  const handleMeetYourNeighbordClick = useCallback(() => {
    history.push(routes.knowYourNeighbors);
  }, []);

  return (
    <Main isLoading={isLoading} error={error}>
      <Flex mb={[20, 25, 30]} width="100%" alignItems="center" justifyContent="space-between">
        {from === routes.knowYourNeighbors
          ? (
            <Flex pointer onClick={handleMeetYourNeighbordClick} alignItems="center">
              <BackButton mr={['9px', 11, 13]} SVG={Dropdown} color={theme.color.gray[500]} />
              <Typography variant="proximaNova-400" fontSize={[12, 14, 16]}>
                Back
              </Typography>
            </Flex>
          )
          : (
            <Typography variant="garamond-500" fontSize={[30, 34]}>
              Company Profile
            </Typography>
          )}
        {isEditable && (
          <EditButton
            pointer
            onClick={onEdit}
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            width={[25, 35, 48]}
            height={[25, 35, 48]}
          >
            <Icon onClick={onEdit} SVG={Edit} width={[14, 18, 22]} height={[14, 18, 22]} />
          </EditButton>
        )}
      </Flex>
      <Flex width="100%">
        <Flex
          mr={['6px', '8px', 10]}
          minWidth={[150, 200, 250, 280]}
          width={[150, 200, 250, 280]}
          flexDirection="column"
        >
          <Flex height={[150, 200, 250, 280]}>
            <PhotoChanger
              isLoading={isPhotoLoading}
              isEditable={isEditable}
              avatar={logo}
              isCompany
              onSavePhoto={onSavePhoto}
              onRemovePhoto={onRemovePhoto}
            />
          </Flex>
          <Flex
            width="100%"
            py={[20, 34, 50]}
            mt="6px"
            justifyContent="center"
            alignItems="center"
            backgroundColor={theme.color.darkGreen}
          >
            <Typography
              color={theme.color.white}
              variant="garamond-500"
              fontSize={[18, 25, 30, 34]}
              p={[10, 15]}
            >
              {companyName.replace(/\s/g, ' ')}
            </Typography>
          </Flex>
        </Flex>
        <Flex
          py={[20, 30, 30, 40]}
          border={`1px solid ${theme.color.gray[100]}`}
          width="100%"
          flexDirection="column"
          pl={[25, 40, 40, 55]}
        >
          <Flex
            pr={[25, 40, 40, 55]}
            width="100%"
            flexDirection="column"
            maxWidth={[220, 340, 460]}
            mb={[10, 14, 20]}
          >
            {companyDetailsBlock}
            {(hasSocials || isEditable) && (
              <>
                <Typography
                  mb={isEditable ? [20, 24, 30] : 10}
                  variant="garamond-500"
                  fontSize={[22, 26, 32]}
                >
                  Don&apos;t Be a Stranger
                </Typography>
                <Socials isEditable={isEditable} isCompany socials={socials} />
              </>
            )}
          </Flex>
          <CompanyTeam
            isEditable={isEditable}
            from={from}
            team={team}
            amount={teamAmount}
            onLoadMore={onTeamLoad}
          />
        </Flex>
      </Flex>
    </Main>
  );
};

CompanyProfile.defaultProps = {
  error: {},
  companyId: 1,
  team: [],
  teamAmount: 0,
  companyName: '',
  aboutUs: '',
  logo: '',
  industry: '',
  socials: [],
  website: '',
  from: '',
};

CompanyProfile.propTypes = {
  error: exact({
    status: number,
    message: string,
  }),
  isPhotoLoading: bool.isRequired,
  from: string,
  companyId: number,
  onTeamLoad: func.isRequired,
  teamAmount: number,
  team: arrayOf(
    exact({
      id: number,
      firstName: string,
      lastName: string,
      imageUrl: string,
      jobTitle: string,
      role: string,
    }),
  ),
  website: string,
  onRemovePhoto: func.isRequired,
  onSavePhoto: func.isRequired,
  industry: string,
  socials: arrayOf(
    exact({
      name: string,
      link: string,
      icon: object,
    }),
  ),
  companyName: string,
  logo: string,
  aboutUs: string,
  isLoading: bool.isRequired,
  isEditable: bool.isRequired,
};

export default CompanyProfile;
