import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { EventDetails as View } from '@views';
import { LOADING, SUCCESS } from '@constants/requestPhase';
import {
  getEventById, bookEvent, resetPhases, getEventBookingInfo, getEventBookings, cancelBookingEvent,
  getEventAvailableDates,
} from '@store/event/duck';
import { FAILURE } from 'src/constants/requestPhase';
import { trackEvent } from '@utils/mixpanel';
import { routes } from '@constants';

const EventDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const loc = useLocation();

  const {
    getEventByIdPhase, bookEventPhase, getBookingInfoPhase, getBookingsPhase, cancelBookingEventPhase, bookEventError,
    getEventAvailableDatesPhase, eventById: {
      details: {
        isRecurring, hasSlots, name, imageUrl, upcomingId, upcomingDate, description, locationDescription,
        location, startTime, endTime,
      },
      bookingInfo, bookings,
    },
  } = useSelector(store => store.eventStore);

  const [selectedDateId, setSelectedDateId] = useState(upcomingId);
  const [selectedService, setSelectedService] = useState();
  const [error, setError] = useState({});

  const bookingId = useMemo(() => bookingInfo.bookingIds?.find(booking => (
    bookings?.find(userBooking => booking.id === userBooking.bookingId)
  ))?.bookingId, [bookingInfo, bookings]);

  const startDate = upcomingDate
    ? `${upcomingDate}T${startTime}.000Z` : new Date().toISOString();

  const endDate = upcomingDate
    ? `${upcomingDate}T${endTime}.000Z` : new Date().toISOString();

  const handleSelectedDateChange = useCallback(date => {
    setSelectedDateId(date);
  }, []);

  const handleBookEvent = useCallback(() => {
    dispatch(bookEvent(id, upcomingId));
  }, [id, upcomingId]);

  const handleUnbookEvent = useCallback(() => {
    dispatch(cancelBookingEvent(id, upcomingId, {
      bookingId,
    }));
  }, [bookingId, id, upcomingId]);

  const handleSelectedServiceChange = useCallback(service => {
    setSelectedService(service);
  }, []);

  useEffect(() => {
    if (name) {
      const pageTitle = `Mindspace: Event ${name}`;
      document.title = pageTitle;

      window.gtag('event', 'page_view', {
        page_title: pageTitle,
      });

      trackEvent('Page Viewed', {
        Page: 'Event',
        'Event Name': name,
      });
    }
  }, [name]);

  useEffect(() => {
    if (id !== parseInt(id, 10)) {
      dispatch(getEventById(id));
      dispatch(getEventAvailableDates(id));
    }
  }, []);

  useEffect(() => {
    if (getEventByIdPhase === SUCCESS) {
      dispatch(resetPhases());
      if (hasSlots) {
        dispatch(getEventAvailableDates(id));
      }
    }
    if (getEventByIdPhase === FAILURE || getEventAvailableDatesPhase === FAILURE) {
      history.push(routes.home);
    }
  }, [getEventByIdPhase, getEventAvailableDatesPhase]);

  useEffect(() => {
    if (bookEventPhase === SUCCESS || cancelBookingEventPhase === SUCCESS || getEventByIdPhase === SUCCESS) {
      dispatch(resetPhases());
      dispatch(getEventBookings(id));
      if (!isRecurring && !hasSlots) {
        dispatch(getEventBookingInfo(id, upcomingId));
      }
    }
    if (bookEventPhase === FAILURE) {
      if (!isRecurring && !hasSlots) {
        setError({
          status: bookEventError.data.error.status,
          message: 'Yikes, this event is already fully booked',
          buttonLabel: 'Close',
        });
        dispatch(getEventById(id));
        dispatch(getEventAvailableDates(id));
        dispatch(resetPhases());
      }
    }
  }, [bookEventPhase, cancelBookingEventPhase, getEventByIdPhase]);

  return (
    <View
      error={error}
      status={bookingInfo.status}
      id={parseInt(id, 10)}
      shouldOpenModal={loc.state?.shouldOpenModal}
      onUnbookEvent={handleUnbookEvent}
      onBookEvent={handleBookEvent}
      onSelectedDateChange={handleSelectedDateChange}
      onSelectedServiceChange={handleSelectedServiceChange}
      selectedDateId={selectedDateId}
      selectedService={selectedService}
      hasSlots={hasSlots}
      name={name}
      isAttending={!!bookingId}
      description={description}
      locationDescription={locationDescription}
      location={location}
      startDate={startDate}
      endDate={endDate}
      imageUrl={imageUrl}
      isLoading={getEventByIdPhase === LOADING || (bookEventPhase === LOADING && !isRecurring && !hasSlots)
      || getBookingsPhase === LOADING || getBookingInfoPhase === LOADING
      || cancelBookingEventPhase === LOADING}
    />
  );
};

export default EventDetails;

