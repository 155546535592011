import React, { useMemo } from 'react';
import { Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { dayjs } from '@utils';
import {
  PRIVACY_POLICY_URL,
  TERMS_SERVICE_URL,
  APP_STORE_URL,
  PLAY_STORE_URL,
} from '@constants/links';

import { ReactComponent as Logo } from '@assets/svgs/Logo.svg';
import { ReactComponent as AppStore } from '@assets/svgs/AppStore.svg';
import { ReactComponent as PlayStore } from '@assets/svgs/PlayStore.svg';

import socials from './socials';

const Footer = () => {
  const onPlayStoreClick = () => {
    window.open(PLAY_STORE_URL);
  };

  const onAppStoreClick = () => {
    window.open(APP_STORE_URL);
  };

  const socialsBlock = useMemo(() => socials.map(({ id, icon, handler }, index) => (
    <Icon
      mr={index === socials.length - 1 ? 0 : [15, 30]}
      SVG={icon}
      key={id}
      onClick={handler}
      height={[12, 16]}
      pointer
    />
  )), []);

  return (
    <Flex
      backgroundColor={theme.color.white}
      position="absolute"
      bottom={0}
      right={0}
      left={0}
      zIndex={2}
      flexDirection="column"
      px={[20, 60]}
    >
      <Flex alignItems="center" py={[20, 45]} justifyContent="space-between">
        <Icon SVG={Logo} width={[100, 173]} height={[15, 22]} />
        <Flex>
          {socialsBlock}
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        py={[20, 45]}
        borderTop={`1px solid ${theme.color.gray[100]}`}
        justifyContent="space-between"
      >
        <Flex flexDirection={['column', 'column', 'row']}>
          <Flex
            onClick={onAppStoreClick}
            pointer
            mr={[0, 0, 10]}
            mb={[10, 10, 0]}
            width={[90, 147]}
          >
            <Icon SVG={AppStore} />
          </Flex>
          <Flex onClick={onPlayStoreClick} pointer width={[90, 147]}>
            <Icon SVG={PlayStore} />
          </Flex>
        </Flex>
        <Flex>
          <Flex flexDirection="column">
            <Flex justifyContent="end" mb={[2, 12]}>
              <Typography
                as="a"
                href={TERMS_SERVICE_URL}
                target="_blank"
                fontSize={['10px', 2]}
                mr={[13, 26]}
                color={theme.color.gray[500]}
                variant="proximaNova-600"
              >
                Terms of Service
              </Typography>
              <Typography
                as="a"
                href={PRIVACY_POLICY_URL}
                target="_blank"
                fontSize={['10px', 2]}
                color={theme.color.gray[500]}
                variant="proximaNova-600"
              >
                Privacy Policy
              </Typography>
            </Flex>
            <Flex>
              <Typography fontSize={['10px', 1]} color={theme.color.gray[400]} variant="proximaNova-400">
                {`Copyright © ${dayjs().format('YYYY')} Mindspace Inc. All rights reserved`}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
