import React from 'react';
import { node, func, string, bool, arrayOf, number } from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Flex } from '@mixins';
import { Error } from '@molecules/WelcomeForm/styles';
import { theme } from '@styles';
import Input from '../styles';

const ControlledInput = ({
  onChange,
  children,
  hasError,
  isRound,
  name,
  error,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Flex flexDirection="column">
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="start"
        {...(isRound && {
          border: `1px solid ${hasError ? theme.color.red : '#D9D9D9'}`, pl: '10px', borderRadius: '6px',
        })}
        {...(!isRound && { borderBottom: `1px solid ${hasError ? theme.color.red : theme.color.gray[200]}` })}
      >
        {children}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              onChange={(...args) => {
                field.onChange(...args);
                if (onChange) {
                  onChange(...args);
                }
              }}
              {...props}
              width="100%"
              border="none"
              py={['3px', '3px', '5px', '7px']}
              fontWeight={400}
              color={error ? theme.color.red : theme.color.gray[500]}
            />
          )}
        />
      </Flex>
      <Error hasError={error} fontSize={[10, 12]} variant="proximaNova-400" color={theme.color.red}>
        {error}
      </Error>
    </Flex>
  );
};

ControlledInput.defaultProps = {
  error: null,
  name: '',
  isRound: false,
  children: <Flex />,
  hasError: false,
  hideCursor: false,
  onBlur: () => {},
  onChange: () => {},
  fontSize: [12, 12, 14, 16],
};

ControlledInput.propTypes = {
  error: string,
  name: string,
  fontSize: arrayOf(number),
  isRound: bool,
  onBlur: func,
  onChange: func,
  placeholder: string.isRequired,
  children: node,
  hasError: bool,
  hideCursor: bool,
};

export default ControlledInput;
