import styled, { css } from 'styled-components';

import { Flex } from '@mixins';

const FlexWithNoGradientBackground = styled(Flex)`
${({ imageUrl }) => css`
  background: url(${imageUrl}) no-repeat center top;
  background-size: cover;
`}`;

export default FlexWithNoGradientBackground;
