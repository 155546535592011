import AfghanistanFlagIcon from '@assets/flags/afghanistan.svg';
import AlbaniaFlagIcon from '@assets/flags/albania.svg';
import AlgeriaFlagIcon from '@assets/flags/algeria.svg';
import AmericanSamoaFlagIcon from '@assets/flags/american-samoa.svg';
import AndorraFlagIcon from '@assets/flags/andorra.svg';
import AngolaFlagIcon from '@assets/flags/angola.svg';
import AnguillaFlagIcon from '@assets/flags/anguilla.svg';
import AntiguaAndBarbudaFlagIcon from '@assets/flags/antigua-and-barbuda.svg';
import ArgentinaFlagIcon from '@assets/flags/argentina.svg';
import ArmeniaFlagIcon from '@assets/flags/armenia.svg';
import ArubaFlagIcon from '@assets/flags/aruba.svg';
import AustraliaFlagIcon from '@assets/flags/australia.svg';
import AustriaFlagIcon from '@assets/flags/austria.svg';
import AzerbaijanFlagIcon from '@assets/flags/azerbaijan.svg';
import BahamasFlagIcon from '@assets/flags/bahamas.svg';
import BahrainFlagIcon from '@assets/flags/bahrain.svg';
import BangladeshFlagIcon from '@assets/flags/bangladesh.svg';
import BarbadosFlagIcon from '@assets/flags/barbados.svg';
import BelarusFlagIcon from '@assets/flags/belarus.svg';
import BelgiumFlagIcon from '@assets/flags/belgium.svg';
import BelizeFlagIcon from '@assets/flags/belize.svg';
import BeninFlagIcon from '@assets/flags/benin.svg';
import BermudaFlagIcon from '@assets/flags/bermuda.svg';
import BhutanFlagIcon from '@assets/flags/bhutan.svg';
import BoliviaFlagIcon from '@assets/flags/bolivia.svg';
import BosniaAndHerzegovinaFlagIcon from '@assets/flags/bosnia-and-herzegovina.svg';
import BotswanaFlagIcon from '@assets/flags/botswana.svg';
import BrazilFlagIcon from '@assets/flags/brazil.svg';
import BritishVirginIslandsFlagIcon from '@assets/flags/british-virgin-islands.svg';
import BruneiDarussalamFlagIcon from '@assets/flags/brunei-darussalam.svg';
import BulgariaFlagIcon from '@assets/flags/bulgaria.svg';
import BurkinaFasoFlagIcon from '@assets/flags/burkina-faso.svg';
import BurundiFlagIcon from '@assets/flags/burundi.svg';
import CambodiaFlagIcon from '@assets/flags/cambodia.svg';
import CameroonFlagIcon from '@assets/flags/cameroon.svg';
import CanadaFlagIcon from '@assets/flags/canada.svg';
import CapeVerdeFlagIcon from '@assets/flags/cape-verde.svg';
import CaymanIslandsFlagIcon from '@assets/flags/cayman-islands.svg';
import CentralAfricanRepublicFlagIcon from '@assets/flags/central-african-republic.svg';
import ChadFlagIcon from '@assets/flags/chad.svg';
import ChileFlagIcon from '@assets/flags/chile.svg';
import ChinaFlagIcon from '@assets/flags/china.svg';
import ChristmasIslandFlagIcon from '@assets/flags/christmas-island.svg';
import CocosIslandsFlagIcon from '@assets/flags/cocos-islands.svg';
import ColombiaFlagIcon from '@assets/flags/colombia.svg';
import ComorosFlagIcon from '@assets/flags/comoros.svg';
import CongoRepublicFlagIcon from '@assets/flags/congo-republic.svg';
import CongoFlagIcon from '@assets/flags/congo.svg';
import CookIslandsFlagIcon from '@assets/flags/cook-islands.svg';
import CostaRicaFlagIcon from '@assets/flags/costa-rica.svg';
import CoteDivoireFlagIcon from '@assets/flags/cote-divoire.svg';
import CroatiaFlagIcon from '@assets/flags/croatia.svg';
import CubaFlagIcon from '@assets/flags/cuba.svg';
import CyprusFlagIcon from '@assets/flags/cyprus.svg';
import CzechRepublicFlagIcon from '@assets/flags/czech-republic.svg';
import DenmarkFlagIcon from '@assets/flags/denmark.svg';
import DjiboutiFlagIcon from '@assets/flags/djibouti.svg';
import DominicaFlagIcon from '@assets/flags/dominica.svg';
import DominicanRepublicFlagIcon from '@assets/flags/dominican-republic.svg';
import EcuadorFlagIcon from '@assets/flags/ecuador.svg';
import EgyptFlagIcon from '@assets/flags/egypt.svg';
import ElSalvadorFlagIcon from '@assets/flags/el-salvador.svg';
import EquatorialGuineaFlagIcon from '@assets/flags/equatorial-guinea.svg';
import EritreaFlagIcon from '@assets/flags/eritrea.svg';
import EstoniaFlagIcon from '@assets/flags/estonia.svg';
import EthiopiaFlagIcon from '@assets/flags/ethiopia.svg';
import FalklandIslandsFlagIcon from '@assets/flags/falkland-islands.svg';
import FaroeIslandsFlagIcon from '@assets/flags/faroe-islands.svg';
import FijiFlagIcon from '@assets/flags/fiji.svg';
import FinlandFlagIcon from '@assets/flags/finland.svg';
import FranceFlagIcon from '@assets/flags/france.svg';
import FrenchPolynesiaFlagIcon from '@assets/flags/french-polynesia.svg';
import GabonFlagIcon from '@assets/flags/gabon.svg';
import GambiaFlagIcon from '@assets/flags/gambia.svg';
import GeorgiaFlagIcon from '@assets/flags/georgia.svg';
import GermanyFlagIcon from '@assets/flags/germany.svg';
import GhanaFlagIcon from '@assets/flags/ghana.svg';
import GibraltarFlagIcon from '@assets/flags/gibraltar.svg';
import GreeceFlagIcon from '@assets/flags/greece.svg';
import GreenlandFlagIcon from '@assets/flags/greenland.svg';
import GrenadaFlagIcon from '@assets/flags/grenada.svg';
import GuamFlagIcon from '@assets/flags/guam.svg';
import GuatemalaFlagIcon from '@assets/flags/guatemala.svg';
import GuernseyFlagIcon from '@assets/flags/guernsey.svg';
import GuineaBissauFlagIcon from '@assets/flags/guinea-bissau.svg';
import GuineaFlagIcon from '@assets/flags/guinea.svg';
import GuyanaFlagIcon from '@assets/flags/guyana.svg';
import HaitiFlagIcon from '@assets/flags/haiti.svg';
import HolySeeFlagIcon from '@assets/flags/holy-see.svg';
import HondurasFlagIcon from '@assets/flags/honduras.svg';
import HongKongFlagIcon from '@assets/flags/hong-kong.svg';
import HungaryFlagIcon from '@assets/flags/hungary.svg';
import IcelandFlagIcon from '@assets/flags/iceland.svg';
import IndiaFlagIcon from '@assets/flags/india.svg';
import IndonesiaFlagIcon from '@assets/flags/indonesia.svg';
import IranFlagIcon from '@assets/flags/iran.svg';
import IraqFlagIcon from '@assets/flags/iraq.svg';
import IrelandFlagIcon from '@assets/flags/ireland.svg';
import IsleOfManFlagIcon from '@assets/flags/isle-of-man.svg';
import IsraelFlagIcon from '@assets/flags/israel.svg';
import ItalyFlagIcon from '@assets/flags/italy.svg';
import JamaicaFlagIcon from '@assets/flags/jamaica.svg';
import JapanFlagIcon from '@assets/flags/japan.svg';
import JerseyFlagIcon from '@assets/flags/jersey.svg';
import JordanFlagIcon from '@assets/flags/jordan.svg';
import KazakhstanFlagIcon from '@assets/flags/kazakhstan.svg';
import KenyaFlagIcon from '@assets/flags/kenya.svg';
import KiribatiFlagIcon from '@assets/flags/kiribati.svg';
import KuwaitFlagIcon from '@assets/flags/kuwait.svg';
import KyrgyzstanFlagIcon from '@assets/flags/kyrgyzstan.svg';
import LaosFlagIcon from '@assets/flags/laos.svg';
import LatviaFlagIcon from '@assets/flags/latvia.svg';
import LebanonFlagIcon from '@assets/flags/lebanon.svg';
import LesothoFlagIcon from '@assets/flags/lesotho.svg';
import LiberiaFlagIcon from '@assets/flags/liberia.svg';
import LibyanArabJamahiriyaFlagIcon from '@assets/flags/libyan-arab-jamahiriya.svg';
import LiechtensteinFlagIcon from '@assets/flags/liechtenstein.svg';
import LithuaniaFlagIcon from '@assets/flags/lithuania.svg';
import LuxembourgFlagIcon from '@assets/flags/luxembourg.svg';
import MacaoFlagIcon from '@assets/flags/macao.svg';
import MacedoniaFlagIcon from '@assets/flags/macedonia.svg';
import MadagaskarFlagIcon from '@assets/flags/madagascar.svg';
import MalawiFlagIcon from '@assets/flags/malawi.svg';
import MalaysiaFlagIcon from '@assets/flags/malaysia.svg';
import MaldivesFlagIcon from '@assets/flags/maldives.svg';
import MaliFlagIcon from '@assets/flags/mali.svg';
import MaltaFlagIcon from '@assets/flags/malta.svg';
import MarshallIslandsFlagIcon from '@assets/flags/marshall-islands.svg';
import MartiniqueFlagIcon from '@assets/flags/martinique.svg';
import MauritaniaFlagIcon from '@assets/flags/mauritania.svg';
import MauritiusFlagIcon from '@assets/flags/mauritius.svg';
import MexicoFlagIcon from '@assets/flags/mexico.svg';
import MicronesiaFlagIcon from '@assets/flags/micronesia.svg';
import MoldovaFlagIcon from '@assets/flags/moldova.svg';
import MonacoFlagIcon from '@assets/flags/monaco.svg';
import MongoliaFlagIcon from '@assets/flags/mongolia.svg';
import MontenegroFlagIcon from '@assets/flags/montenegro.svg';
import MontserratFlagIcon from '@assets/flags/montserrat.svg';
import MoroccoFlagIcon from '@assets/flags/morocco.svg';
import MozambiqueFlagIcon from '@assets/flags/mozambique.svg';
import MyanmarFlagIcon from '@assets/flags/myanmar.svg';
import NamibiaFlagIcon from '@assets/flags/namibia.svg';
import NauruFlagIcon from '@assets/flags/nauru.svg';
import NepalFlagIcon from '@assets/flags/nepal.svg';
import NetherlandsFlagIcon from '@assets/flags/netherlands.svg';
import NewZealandFlagIcon from '@assets/flags/new-zealand.svg';
import NicaraguaFlagIcon from '@assets/flags/nicaragua.svg';
import NigerFlagIcon from '@assets/flags/niger.svg';
import NigeriaFlagIcon from '@assets/flags/nigeria.svg';
import NiueFlagIcon from '@assets/flags/niue.svg';
import NorfolkIslandFlagIcon from '@assets/flags/norfolk-island.svg';
import NorthKoreaFlagIcon from '@assets/flags/north-korea.svg';
import NorthernMarianaIslandsFlagIcon from '@assets/flags/northern-mariana-islands.svg';
import NorwayFlagIcon from '@assets/flags/norway.svg';
import OmanFlagIcon from '@assets/flags/oman.svg';
import PakistanFlagIcon from '@assets/flags/pakistan.svg';
import PalauFlagIcon from '@assets/flags/palau.svg';
import PanamaFlagIcon from '@assets/flags/panama.svg';
import PapuaNewGuineaFlagIcon from '@assets/flags/papua-new-guinea.svg';
import ParaguayFlagIcon from '@assets/flags/paraguay.svg';
import PeruFlagIcon from '@assets/flags/peru.svg';
import PhilippinesFlagIcon from '@assets/flags/philippines.svg';
import PolandFlagIcon from '@assets/flags/poland.svg';
import PortugalFlagIcon from '@assets/flags/portugal.svg';
import PuertoRicoFlagIcon from '@assets/flags/puerto-rico.svg';
import QatarFlagIcon from '@assets/flags/qatar.svg';
import RomaniaFlagIcon from '@assets/flags/romania.svg';
import RussiaFlagIcon from '@assets/flags/russia.svg';
import RwandaFlagIcon from '@assets/flags/rwanda.svg';
import SaintBarthelemyFlagIcon from '@assets/flags/saint-barthelemy.svg';
import SaintKittsAndNevisFlagIcon from '@assets/flags/saint-kitts-and-nevis.svg';
import SaintLuciaFlagIcon from '@assets/flags/saint-lucia.svg';
import SaintVincentAndTheGrenadinesFlagIcon from '@assets/flags/saint-vincent-and-the-grenadines.svg';
import SamoaFlagIcon from '@assets/flags/samoa.svg';
import SanMarinoFlagIcon from '@assets/flags/san-marino.svg';
import SaoTomeAndPrincipeFlagIcon from '@assets/flags/sao-tome-and-principe.svg';
import SaudiArabiaFlagIcon from '@assets/flags/saudi-arabia.svg';
import SenegalFlagIcon from '@assets/flags/senegal.svg';
import SerbiaFlagIcon from '@assets/flags/serbia.svg';
import SeychellesFlagIcon from '@assets/flags/seychelles.svg';
import SierraLeoneFlagIcon from '@assets/flags/sierra-leone.svg';
import SingaporeFlagIcon from '@assets/flags/singapore.svg';
import SlovakiaFlagIcon from '@assets/flags/slovakia.svg';
import SloveniaFlagIcon from '@assets/flags/slovenia.svg';
import SolomonIslandsFlagIcon from '@assets/flags/solomon-islands.svg';
import SomaliaFlagIcon from '@assets/flags/somalia.svg';
import SouthAfricaFlagIcon from '@assets/flags/south-africa.svg';
import SouthKoreaFlagIcon from '@assets/flags/south-korea.svg';
import SpainFlagIcon from '@assets/flags/spain.svg';
import SriLankaFlagIcon from '@assets/flags/sri-lanka.svg';
import SudanFlagIcon from '@assets/flags/sudan.svg';
import SurinameFlagIcon from '@assets/flags/suriname.svg';
import SwazilandFlagIcon from '@assets/flags/swaziland.svg';
import SwedenFlagIcon from '@assets/flags/sweden.svg';
import SwitzerlandFlagIcon from '@assets/flags/switzerland.svg';
import SyrianArabRepublicFlagIcon from '@assets/flags/syrian-arab-republic.svg';
import TaiwanFlagIcon from '@assets/flags/taiwan.svg';
import TajikistanFlagIcon from '@assets/flags/tajikistan.svg';
import TanzaniaFlagIcon from '@assets/flags/tanzania.svg';
import ThailandFlagIcon from '@assets/flags/thailand.svg';
import TimorLesteFlagIcon from '@assets/flags/timor-leste.svg';
import TogoFlagIcon from '@assets/flags/togo.svg';
import TokelauFlagIcon from '@assets/flags/tokelau.svg';
import TongaFlagIcon from '@assets/flags/tonga.svg';
import TrinidadAndTobagoFlagIcon from '@assets/flags/trinidad-and-tobago.svg';
import TunisiaFlagIcon from '@assets/flags/tunisia.svg';
import TurkeyFlagIcon from '@assets/flags/turkey.svg';
import TurkmenistanFlagIcon from '@assets/flags/turkmenistan.svg';
import TurksAndCaicosIslandsFlagIcon from '@assets/flags/turks-and-caicos-islands.svg';
import TuvaluFlagIcon from '@assets/flags/tuvalu.svg';
import UgandaFlagIcon from '@assets/flags/uganda.svg';
import UkraineFlagIcon from '@assets/flags/ukraine.svg';
import UnitedArabEmiratesFlagIcon from '@assets/flags/united-arab-emirates.svg';
import UnitedKingdomFlagIcon from '@assets/flags/united-kingdom.svg';
import UnitedStatesVirginIslandsFlagIcon from '@assets/flags/united-states-virgin-islands.svg';
import UnitedStatesFlagIcon from '@assets/flags/united-states.svg';
import UruguayFlagIcon from '@assets/flags/uruguay.svg';
import UzbekistanFlagIcon from '@assets/flags/uzbekistan.svg';
import VanuatuFlagIcon from '@assets/flags/vanuatu.svg';
import VenezuelaFlagIcon from '@assets/flags/venezuela.svg';
import VietnamFlagIcon from '@assets/flags/vietnam.svg';
import YemenFlagIcon from '@assets/flags/yemen.svg';
import ZambiaFlagIcon from '@assets/flags/zambia.svg';
import ZimbabweFlagIcon from '@assets/flags/zimbabwe.svg';

const COUNTRIES = {
  AF: {
    alphaCode: 'AF',
    dialCode: '+93',
    flag: AfghanistanFlagIcon,
    name: 'Afghanistan',
  },
  AL: {
    alphaCode: 'AL',
    dialCode: '+355',
    flag: AlbaniaFlagIcon,
    name: 'Albania',
  },
  DZ: {
    alphaCode: 'DZ',
    dialCode: '+213',
    flag: AlgeriaFlagIcon,
    name: 'Algeria',
  },
  AS: {
    alphaCode: 'AS',
    dialCode: '+1 684',
    flag: AmericanSamoaFlagIcon,
    name: 'American Samoa',
  },
  AD: {
    alphaCode: 'AD',
    dialCode: '+376',
    flag: AndorraFlagIcon,
    name: 'Andorra',
  },
  AO: {
    alphaCode: 'AO',
    dialCode: '+244',
    flag: AngolaFlagIcon,
    name: 'Angola',
  },
  AI: {
    alphaCode: 'AI',
    dialCode: '+1 264',
    flag: AnguillaFlagIcon,
    name: 'Anguilla',
  },

  AG: {
    alphaCode: 'AG',
    dialCode: '+1 268',
    flag: AntiguaAndBarbudaFlagIcon,
    name: 'Antigua and Barbuda',
  },
  AR: {
    alphaCode: 'AR',
    dialCode: '+54',
    flag: ArgentinaFlagIcon,
    name: 'Argentina',
  },
  AM: {
    alphaCode: 'AM',
    dialCode: '+374',
    flag: ArmeniaFlagIcon,
    name: 'Armenia',
  },
  AW: {
    alphaCode: 'AW',
    dialCode: '+297',
    flag: ArubaFlagIcon,
    name: 'Aruba',
  },
  AU: {
    alphaCode: 'AU',
    dialCode: '+61',
    flag: AustraliaFlagIcon,
    name: 'Australia',
  },
  AT: {
    alphaCode: 'AT',
    dialCode: '+43',
    flag: AustriaFlagIcon,
    name: 'Austria',
  },
  AZ: {
    alphaCode: 'AZ',
    dialCode: '+994',
    flag: AzerbaijanFlagIcon,
    name: 'Azerbaijan',
  },
  BS: {
    alphaCode: 'BS',
    dialCode: '+1 242',
    flag: BahamasFlagIcon,
    name: 'Bahamas',
  },
  BH: {
    alphaCode: 'BH',
    dialCode: '+973',
    flag: BahrainFlagIcon,
    name: 'Bahrain',
  },
  BD: {
    alphaCode: 'BD',
    dialCode: '+880',
    flag: BangladeshFlagIcon,
    name: 'Bangladesh',
  },
  BB: {
    alphaCode: 'BB',
    dialCode: '+1 246',
    flag: BarbadosFlagIcon,
    name: 'Barbados',
  },
  BY: {
    alphaCode: 'BY',
    dialCode: '+375',
    flag: BelarusFlagIcon,
    name: 'Belarus',
  },
  BE: {
    alphaCode: 'BE',
    dialCode: '+32',
    flag: BelgiumFlagIcon,
    name: 'Belgium',
  },
  BZ: {
    alphaCode: 'BZ',
    dialCode: '+501',
    flag: BelizeFlagIcon,
    name: 'Belize',
  },
  BJ: {
    alphaCode: 'BJ',
    dialCode: '+229',
    flag: BeninFlagIcon,
    name: 'Benin',
  },
  BM: {
    alphaCode: 'BM',
    dialCode: '+1441',
    flag: BermudaFlagIcon,
    name: 'Bermuda',
  },
  BT: {
    alphaCode: 'BT',
    dialCode: '+975',
    flag: BhutanFlagIcon,
    name: 'Bhutan',
  },
  BO: {
    alphaCode: 'BO',
    dialCode: '+591',
    flag: BoliviaFlagIcon,
    name: 'Bolivia',
  },
  BA: {
    alphaCode: 'BA',
    dialCode: '+387',
    flag: BosniaAndHerzegovinaFlagIcon,
    name: 'Bosnia and Herzegovina',
  },
  BW: {
    alphaCode: 'BW',
    dialCode: '+267',
    flag: BotswanaFlagIcon,
    name: 'Botswana',
  },
  BR: {
    alphaCode: 'BR',
    dialCode: '+55',
    flag: BrazilFlagIcon,
    name: 'Brazil',
  },

  BN: {
    alphaCode: 'BN',
    dialCode: '+673',
    flag: BruneiDarussalamFlagIcon,
    name: 'Brunei Darussalam',
  },
  BG: {
    alphaCode: 'BG',
    dialCode: '+359',
    flag: BulgariaFlagIcon,
    name: 'Bulgaria',
  },
  BF: {
    alphaCode: 'BF',
    dialCode: '+226',
    flag: BurkinaFasoFlagIcon,
    name: 'Burkina Faso',
  },
  BI: {
    alphaCode: 'BI',
    dialCode: '+257',
    flag: BurundiFlagIcon,
    name: 'Burundi',
  },
  KH: {
    alphaCode: 'KH',
    dialCode: '+855',
    flag: CambodiaFlagIcon,
    name: 'Cambodia',
  },
  CM: {
    alphaCode: 'CM',
    dialCode: '+237',
    flag: CameroonFlagIcon,
    name: 'Cameroon',
  },
  CA: {
    alphaCode: 'CA',
    dialCode: '+1',
    flag: CanadaFlagIcon,
    name: 'Canada',
  },
  CV: {
    alphaCode: 'CV',
    dialCode: '+238',
    flag: CapeVerdeFlagIcon,
    name: 'Cape Verde',
  },
  KY: {
    alphaCode: 'KY',
    dialCode: '+1 345',
    flag: CaymanIslandsFlagIcon,
    name: 'Cayman Islands',
  },
  CF: {
    alphaCode: 'CF',
    dialCode: '+236',
    flag: CentralAfricanRepublicFlagIcon,
    name: 'Central African Republic',
  },
  TD: {
    alphaCode: 'TD',
    dialCode: '+235',
    flag: ChadFlagIcon,
    name: 'Chad',
  },
  CL: {
    alphaCode: 'CL',
    dialCode: '+56',
    flag: ChileFlagIcon,
    name: 'Chile',
  },
  CN: {
    alphaCode: 'CN',
    dialCode: '+86',
    flag: ChinaFlagIcon,
    name: 'China',
  },
  CX: {
    alphaCode: 'CX',
    dialCode: '+61',
    flag: ChristmasIslandFlagIcon,
    name: 'Christmas Island',
  },
  CC: {
    alphaCode: 'CC',
    dialCode: '+61',
    flag: CocosIslandsFlagIcon,
    name: 'Cocos (Keeling) Islands',
  },
  CO: {
    alphaCode: 'CO',
    dialCode: '+57',
    flag: ColombiaFlagIcon,
    name: 'Colombia',
  },
  KM: {
    alphaCode: 'KM',
    dialCode: '+269',
    flag: ComorosFlagIcon,
    name: 'Comoros',
  },
  CG: {
    alphaCode: 'CG',
    dialCode: '+242',
    flag: CongoFlagIcon,
    name: 'Congo',
  },
  CD: {
    alphaCode: 'CD',
    dialCode: '+243',
    flag: CongoRepublicFlagIcon,
    name: 'Congo, The Democratic Republic of the',
  },
  CK: {
    alphaCode: 'CK',
    dialCode: '+682',
    flag: CookIslandsFlagIcon,
    name: 'Cook Islands',
  },
  CR: {
    alphaCode: 'CR',
    dialCode: '+506',
    flag: CostaRicaFlagIcon,
    name: 'Costa Rica',
  },
  CI: {
    alphaCode: 'CI',
    dialCode: '+225',
    flag: CoteDivoireFlagIcon,
    name: "Cote d'Ivoire",
  },
  HR: {
    alphaCode: 'HR',
    dialCode: '+385',
    flag: CroatiaFlagIcon,
    name: 'Croatia',
  },
  CU: {
    alphaCode: 'CU',
    dialCode: '+53',
    flag: CubaFlagIcon,
    name: 'Cuba',
  },
  CY: {
    alphaCode: 'CY',
    dialCode: '+537',
    flag: CyprusFlagIcon,
    name: 'Cyprus',
  },
  CZ: {
    alphaCode: 'CZ',
    dialCode: '+420',
    flag: CzechRepublicFlagIcon,
    name: 'Czech Republic',
  },
  DK: {
    alphaCode: 'DK',
    dialCode: '+45',
    flag: DenmarkFlagIcon,
    name: 'Denmark',
  },
  DJ: {
    alphaCode: 'DJ',
    dialCode: '+253',
    flag: DjiboutiFlagIcon,
    name: 'Djibouti',
  },
  DM: {
    alphaCode: 'DM',
    dialCode: '+1 767',
    flag: DominicaFlagIcon,
    name: 'Dominica',
  },
  DO: {
    alphaCode: 'DO',
    dialCode: '+1 849',
    flag: DominicanRepublicFlagIcon,
    name: 'Dominican Republic',
  },
  EC: {
    alphaCode: 'EC',
    dialCode: '+593',
    flag: EcuadorFlagIcon,
    name: 'Ecuador',
  },
  EG: {
    alphaCode: 'EG',
    dialCode: '+20',
    flag: EgyptFlagIcon,
    name: 'Egypt',
  },
  SV: {
    alphaCode: 'SV',
    dialCode: '+503',
    flag: ElSalvadorFlagIcon,
    name: 'El Salvador',
  },
  GQ: {
    alphaCode: 'GQ',
    dialCode: '+240',
    flag: EquatorialGuineaFlagIcon,
    name: 'Equatorial Guinea',
  },
  ER: {
    alphaCode: 'ER',
    dialCode: '+291',
    flag: EritreaFlagIcon,
    name: 'Eritrea',
  },
  EE: {
    alphaCode: 'EE',
    dialCode: '+372',
    flag: EstoniaFlagIcon,
    name: 'Estonia',
  },
  ET: {
    alphaCode: 'ET',
    dialCode: '+251',
    flag: EthiopiaFlagIcon,
    name: 'Ethiopia',
  },
  FK: {
    alphaCode: 'FK',
    dialCode: '+500',
    flag: FalklandIslandsFlagIcon,
    name: 'Falkland Islands (Malvinas)',
  },
  FO: {
    alphaCode: 'FO',
    dialCode: '+298',
    flag: FaroeIslandsFlagIcon,
    name: 'Faroe Islands',
  },
  FJ: {
    alphaCode: 'FJ',
    dialCode: '+679',
    flag: FijiFlagIcon,
    name: 'Fiji',
  },
  FI: {
    alphaCode: 'FI',
    dialCode: '+358',
    flag: FinlandFlagIcon,
    name: 'Finland',
  },
  FR: {
    alphaCode: 'FR',
    dialCode: '+33',
    flag: FranceFlagIcon,
    name: 'France',
  },

  PF: {
    alphaCode: 'PF',
    dialCode: '+689',
    flag: FrenchPolynesiaFlagIcon,
    name: 'French Polynesia',
  },
  GA: {
    alphaCode: 'GA',
    dialCode: '+241',
    flag: GabonFlagIcon,
    name: 'Gabon',
  },
  GM: {
    alphaCode: 'GM',
    dialCode: '+220',
    flag: GambiaFlagIcon,
    name: 'Gambia',
  },
  GE: {
    alphaCode: 'GE',
    dialCode: '+995',
    flag: GeorgiaFlagIcon,
    name: 'Georgia',
  },
  DE: {
    alphaCode: 'DE',
    dialCode: '+49',
    flag: GermanyFlagIcon,
    name: 'Germany',
  },
  GH: {
    alphaCode: 'GH',
    dialCode: '+233',
    flag: GhanaFlagIcon,
    name: 'Ghana',
  },
  GI: {
    alphaCode: 'GI',
    dialCode: '+350',
    flag: GibraltarFlagIcon,
    name: 'Gibraltar',
  },
  GR: {
    alphaCode: 'GR',
    dialCode: '+30',
    flag: GreeceFlagIcon,
    name: 'Greece',
  },
  GL: {
    alphaCode: 'GL',
    dialCode: '+299',
    flag: GreenlandFlagIcon,
    name: 'Greenland',
  },
  GD: {
    alphaCode: 'GD',
    dialCode: '+1 473',
    flag: GrenadaFlagIcon,
    name: 'Grenada',
  },

  GU: {
    alphaCode: 'GU',
    dialCode: '+1 671',
    flag: GuamFlagIcon,
    name: 'Guam',
  },
  GT: {
    alphaCode: 'GT',
    dialCode: '+502',
    flag: GuatemalaFlagIcon,
    name: 'Guatemala',
  },
  GG: {
    alphaCode: 'GG',
    dialCode: '+44',
    flag: GuernseyFlagIcon,
    name: 'Guernsey',
  },
  GN: {
    alphaCode: 'GN',
    dialCode: '+224',
    flag: GuineaFlagIcon,
    name: 'Guinea',
  },
  GW: {
    alphaCode: 'GW',
    dialCode: '+245',
    flag: GuineaBissauFlagIcon,
    name: 'Guinea-Bissau',
  },
  GY: {
    alphaCode: 'GY',
    dialCode: '+595',
    flag: GuyanaFlagIcon,
    name: 'Guyana',
  },
  HT: {
    alphaCode: 'HT',
    dialCode: '+509',
    flag: HaitiFlagIcon,
    name: 'Haiti',
  },
  VA: {
    alphaCode: 'VA',
    dialCode: '+379',
    flag: HolySeeFlagIcon,
    name: 'Holy See (Vatican City State)',
  },
  HN: {
    alphaCode: 'HN',
    dialCode: '+504',
    flag: HondurasFlagIcon,
    name: 'Honduras',
  },
  HK: {
    alphaCode: 'HK',
    dialCode: '+852',
    flag: HongKongFlagIcon,
    name: 'Hong Kong',
  },
  HU: {
    alphaCode: 'HU',
    dialCode: '+36',
    flag: HungaryFlagIcon,
    name: 'Hungary',
  },
  IS: {
    alphaCode: 'IS',
    dialCode: '+354',
    flag: IcelandFlagIcon,
    name: 'Iceland',
  },
  IN: {
    alphaCode: 'IN',
    dialCode: '+91',
    flag: IndiaFlagIcon,
    name: 'India',
  },
  ID: {
    alphaCode: 'ID',
    dialCode: '+62',
    flag: IndonesiaFlagIcon,
    name: 'Indonesia',
  },
  IR: {
    alphaCode: 'IR',
    dialCode: '+98',
    flag: IranFlagIcon,
    name: 'Iran, Islamic Republic of',
  },
  IQ: {
    alphaCode: 'IQ',
    dialCode: '+964',
    flag: IraqFlagIcon,
    name: 'Iraq',
  },
  IE: {
    alphaCode: 'IE',
    dialCode: '+353',
    flag: IrelandFlagIcon,
    name: 'Ireland',
  },
  IM: {
    alphaCode: 'IM',
    dialCode: '+44',
    flag: IsleOfManFlagIcon,
    name: 'Isle of Man',
  },
  IL: {
    alphaCode: 'IL',
    dialCode: '+972',
    flag: IsraelFlagIcon,
    name: 'Israel',
  },
  IT: {
    alphaCode: 'IT',
    dialCode: '+39',
    flag: ItalyFlagIcon,
    name: 'Italy',
  },
  JM: {
    alphaCode: 'JM',
    dialCode: '+1 876',
    flag: JamaicaFlagIcon,
    name: 'Jamaica',
  },
  JP: {
    alphaCode: 'JP',
    dialCode: '+81',
    flag: JapanFlagIcon,
    name: 'Japan',
  },
  JE: {
    alphaCode: 'JE',
    dialCode: '+44',
    flag: JerseyFlagIcon,
    name: 'Jersey',
  },
  JO: {
    alphaCode: 'JO',
    dialCode: '+962',
    flag: JordanFlagIcon,
    name: 'Jordan',
  },
  KZ: {
    alphaCode: 'KZ',
    dialCode: '+77',
    flag: KazakhstanFlagIcon,
    name: 'Kazakhstan',
  },
  KE: {
    alphaCode: 'KE',
    dialCode: '+254',
    flag: KenyaFlagIcon,
    name: 'Kenya',
  },
  KI: {
    alphaCode: 'KI',
    dialCode: '+686',
    flag: KiribatiFlagIcon,
    name: 'Kiribati',
  },
  KP: {
    alphaCode: 'KP',
    dialCode: '+850',
    flag: NorthKoreaFlagIcon,
    name: "Korea, Democratic People's Republic of",
  },
  KR: {
    alphaCode: 'KR',
    dialCode: '+82',
    flag: SouthKoreaFlagIcon,
    name: 'Korea, Republic of',
  },
  KW: {
    alphaCode: 'KW',
    dialCode: '+965',
    flag: KuwaitFlagIcon,
    name: 'Kuwait',
  },
  KG: {
    alphaCode: 'KG',
    dialCode: '+996',
    flag: KyrgyzstanFlagIcon,
    name: 'Kyrgyzstan',
  },
  LA: {
    alphaCode: 'LA',
    dialCode: '+856',
    flag: LaosFlagIcon,
    name: "Lao People's Democratic Republic",
  },
  LV: {
    alphaCode: 'LV',
    dialCode: '+371',
    flag: LatviaFlagIcon,
    name: 'Latvia',
  },
  LB: {
    alphaCode: 'LB',
    dialCode: '+961',
    flag: LebanonFlagIcon,
    name: 'Lebanon',
  },
  LS: {
    alphaCode: 'LS',
    dialCode: '+266',
    flag: LesothoFlagIcon,
    name: 'Lesotho',
  },
  LR: {
    alphaCode: 'LR',
    dialCode: '+231',
    flag: LiberiaFlagIcon,
    name: 'Liberia',
  },
  LY: {
    alphaCode: 'LY',
    dialCode: '+218',
    flag: LibyanArabJamahiriyaFlagIcon,
    name: 'Libyan Arab Jamahiriya',
  },
  LI: {
    alphaCode: 'LI',
    dialCode: '+423',
    flag: LiechtensteinFlagIcon,
    name: 'Liechtenstein',
  },
  LT: {
    alphaCode: 'LT',
    dialCode: '+370',
    flag: LithuaniaFlagIcon,
    name: 'Lithuania',
  },
  LU: {
    alphaCode: 'LU',
    dialCode: '+352',
    flag: LuxembourgFlagIcon,
    name: 'Luxembourg',
  },
  MO: {
    alphaCode: 'MO',
    dialCode: '+853',
    flag: MacaoFlagIcon,
    name: 'Macao',
  },
  MK: {
    alphaCode: 'MK',
    dialCode: '+389',
    flag: MacedoniaFlagIcon,
    name: 'Macedonia, The Former Yugoslav Republic of',
  },
  MG: {
    alphaCode: 'MG',
    dialCode: '+261',
    flag: MadagaskarFlagIcon,
    name: 'Madagascar',
  },
  MW: {
    alphaCode: 'MW',
    dialCode: '+265',
    flag: MalawiFlagIcon,
    name: 'Malawi',
  },
  MY: {
    alphaCode: 'MY',
    dialCode: '+60',
    flag: MalaysiaFlagIcon,
    name: 'Malaysia',
  },
  MV: {
    alphaCode: 'MV',
    dialCode: '+960',
    flag: MaldivesFlagIcon,
    name: 'Maldives',
  },
  ML: {
    alphaCode: 'ML',
    dialCode: '+223',
    flag: MaliFlagIcon,
    name: 'Mali',
  },
  MT: {
    alphaCode: 'MT',
    dialCode: '+356',
    flag: MaltaFlagIcon,
    name: 'Malta',
  },
  MH: {
    alphaCode: 'MH',
    dialCode: '+692',
    flag: MarshallIslandsFlagIcon,
    name: 'Marshall Islands',
  },
  MQ: {
    alphaCode: 'MQ',
    dialCode: '+596',
    flag: MartiniqueFlagIcon,
    name: 'Martinique',
  },
  MR: {
    alphaCode: 'MR',
    dialCode: '+222',
    flag: MauritaniaFlagIcon,
    name: 'Mauritania',
  },
  MU: {
    alphaCode: 'MU',
    dialCode: '+230',
    flag: MauritiusFlagIcon,
    name: 'Mauritius',
  },

  MX: {
    alphaCode: 'MX',
    dialCode: '+52',
    flag: MexicoFlagIcon,
    name: 'Mexico',
  },
  FM: {
    alphaCode: 'FM',
    dialCode: '+691',
    flag: MicronesiaFlagIcon,
    name: 'Micronesia, Federated States of',
  },
  MD: {
    alphaCode: 'MD',
    dialCode: '+373',
    flag: MoldovaFlagIcon,
    name: 'Moldova, Republic of',
  },
  MC: {
    alphaCode: 'MC',
    dialCode: '+377',
    flag: MonacoFlagIcon,
    name: 'Monaco',
  },
  MN: {
    alphaCode: 'MN',
    dialCode: '+976',
    flag: MongoliaFlagIcon,
    name: 'Mongolia',
  },
  ME: {
    alphaCode: 'ME',
    dialCode: '+382',
    flag: MontenegroFlagIcon,
    name: 'Montenegro',
  },
  MS: {
    alphaCode: 'MS',
    dialCode: '+1 664',
    flag: MontserratFlagIcon,
    name: 'Montserrat',
  },
  MA: {
    alphaCode: 'MA',
    dialCode: '+212',
    flag: MoroccoFlagIcon,
    name: 'Morocco',
  },
  MZ: {
    alphaCode: 'MZ',
    dialCode: '+258',
    flag: MozambiqueFlagIcon,
    name: 'Mozambique',
  },
  MM: {
    alphaCode: 'MM',
    dialCode: '+95',
    flag: MyanmarFlagIcon,
    name: 'Myanmar',
  },
  NA: {
    alphaCode: 'NA',
    dialCode: '+264',
    flag: NamibiaFlagIcon,
    name: 'Namibia',
  },
  NR: {
    alphaCode: 'NR',
    dialCode: '+674',
    flag: NauruFlagIcon,
    name: 'Nauru',
  },
  NP: {
    alphaCode: 'NP',
    dialCode: '+977',
    flag: NepalFlagIcon,
    name: 'Nepal',
  },
  NL: {
    alphaCode: 'NL',
    dialCode: '+31',
    flag: NetherlandsFlagIcon,
    name: 'Netherlands',
  },

  NZ: {
    alphaCode: 'NZ',
    dialCode: '+64',
    flag: NewZealandFlagIcon,
    name: 'New Zealand',
  },
  NI: {
    alphaCode: 'NI',
    dialCode: '+505',
    flag: NicaraguaFlagIcon,
    name: 'Nicaragua',
  },
  NE: {
    alphaCode: 'NE',
    dialCode: '+227',
    flag: NigerFlagIcon,
    name: 'Niger',
  },
  NG: {
    alphaCode: 'NG',
    dialCode: '+234',
    flag: NigeriaFlagIcon,
    name: 'Nigeria',
  },
  NU: {
    alphaCode: 'NU',
    dialCode: '+683',
    flag: NiueFlagIcon,
    name: 'Niue',
  },
  NF: {
    alphaCode: 'NF',
    dialCode: '+672',
    flag: NorfolkIslandFlagIcon,
    name: 'Norfolk Island',
  },
  MP: {
    alphaCode: 'MP',
    dialCode: '+1 670',
    flag: NorthernMarianaIslandsFlagIcon,
    name: 'Northern Mariana Islands',
  },
  NO: {
    alphaCode: 'NO',
    dialCode: '+47',
    flag: NorwayFlagIcon,
    name: 'Norway',
  },
  OM: {
    alphaCode: 'OM',
    dialCode: '+968',
    flag: OmanFlagIcon,
    name: 'Oman',
  },
  PK: {
    alphaCode: 'PK',
    dialCode: '+92',
    flag: PakistanFlagIcon,
    name: 'Pakistan',
  },
  PW: {
    alphaCode: 'PW',
    dialCode: '+680',
    flag: PalauFlagIcon,
    name: 'Palau',
  },
  PA: {
    alphaCode: 'PA',
    dialCode: '+507',
    flag: PanamaFlagIcon,
    name: 'Panama',
  },
  PG: {
    alphaCode: 'PG',
    dialCode: '+675',
    flag: PapuaNewGuineaFlagIcon,
    name: 'Papua New Guinea',
  },
  PY: {
    alphaCode: 'PY',
    dialCode: '+595',
    flag: ParaguayFlagIcon,
    name: 'Paraguay',
  },
  PE: {
    alphaCode: 'PE',
    dialCode: '+51',
    flag: PeruFlagIcon,
    name: 'Peru',
  },
  PH: {
    alphaCode: 'PH',
    dialCode: '+63',
    flag: PhilippinesFlagIcon,
    name: 'Philippines',
  },
  PL: {
    alphaCode: 'PL',
    dialCode: '+48',
    flag: PolandFlagIcon,
    name: 'Poland',
  },
  PT: {
    alphaCode: 'PT',
    dialCode: '+351',
    flag: PortugalFlagIcon,
    name: 'Portugal',
  },
  PR: {
    alphaCode: 'PR',
    dialCode: '+1939',
    flag: PuertoRicoFlagIcon,
    name: 'Puerto Rico',
  },
  QA: {
    alphaCode: 'QA',
    dialCode: '+974',
    flag: QatarFlagIcon,
    name: 'Qatar',
  },
  RO: {
    alphaCode: 'RO',
    dialCode: '+40',
    flag: RomaniaFlagIcon,
    name: 'Romania',
  },
  RU: {
    alphaCode: 'RU',
    dialCode: '+7',
    flag: RussiaFlagIcon,
    name: 'Russia',
  },
  RW: {
    alphaCode: 'RW',
    dialCode: '+250',
    flag: RwandaFlagIcon,
    name: 'Rwanda',
  },

  BL: {
    alphaCode: 'BL',
    dialCode: '+590',
    flag: SaintBarthelemyFlagIcon,
    name: 'Saint Barthélemy',
  },

  KN: {
    alphaCode: 'KN',
    dialCode: '+1 869',
    flag: SaintKittsAndNevisFlagIcon,
    name: 'Saint Kitts and Nevis',
  },
  LC: {
    alphaCode: 'LC',
    dialCode: '+1 758',
    flag: SaintLuciaFlagIcon,
    name: 'Saint Lucia',
  },

  VC: {
    alphaCode: 'VC',
    dialCode: '+1 784',
    flag: SaintVincentAndTheGrenadinesFlagIcon,
    name: 'Saint Vincent and the Grenadines',
  },
  WS: {
    alphaCode: 'WS',
    dialCode: '+685',
    flag: SamoaFlagIcon,
    name: 'Samoa',
  },
  SM: {
    alphaCode: 'SM',
    dialCode: '+378',
    flag: SanMarinoFlagIcon,
    name: 'San Marino',
  },
  ST: {
    alphaCode: 'ST',
    dialCode: '+239',
    flag: SaoTomeAndPrincipeFlagIcon,
    name: 'Sao Tome and Principe',
  },
  SA: {
    alphaCode: 'SA',
    dialCode: '+966',
    flag: SaudiArabiaFlagIcon,
    name: 'Saudi Arabia',
  },
  SN: {
    alphaCode: 'SN',
    dialCode: '+221',
    flag: SenegalFlagIcon,
    name: 'Senegal',
  },
  RS: {
    alphaCode: 'RS',
    dialCode: '+381',
    flag: SerbiaFlagIcon,
    name: 'Serbia',
  },
  SC: {
    alphaCode: 'SC',
    dialCode: '+248',
    flag: SeychellesFlagIcon,
    name: 'Seychelles',
  },
  SL: {
    alphaCode: 'SL',
    dialCode: '+232',
    flag: SierraLeoneFlagIcon,
    name: 'Sierra Leone',
  },
  SG: {
    alphaCode: 'SG',
    dialCode: '+65',
    flag: SingaporeFlagIcon,
    name: 'Singapore',
  },
  SK: {
    alphaCode: 'SK',
    dialCode: '+421',
    flag: SlovakiaFlagIcon,
    name: 'Slovakia',
  },
  SI: {
    alphaCode: 'SI',
    dialCode: '+386',
    flag: SloveniaFlagIcon,
    name: 'Slovenia',
  },
  SB: {
    alphaCode: 'SB',
    dialCode: '+677',
    flag: SolomonIslandsFlagIcon,
    name: 'Solomon Islands',
  },
  SO: {
    alphaCode: 'SO',
    dialCode: '+252',
    flag: SomaliaFlagIcon,
    name: 'Somalia',
  },
  ZA: {
    alphaCode: 'ZA',
    dialCode: '+27',
    flag: SouthAfricaFlagIcon,
    name: 'South Africa',
  },

  ES: {
    alphaCode: 'ES',
    dialCode: '+34',
    flag: SpainFlagIcon,
    name: 'Spain',
  },
  LK: {
    alphaCode: 'LK',
    dialCode: '+94',
    flag: SriLankaFlagIcon,
    name: 'Sri Lanka',
  },
  SD: {
    alphaCode: 'SD',
    dialCode: '+249',
    flag: SudanFlagIcon,
    name: 'Sudan',
  },
  SR: {
    alphaCode: 'SR',
    dialCode: '+597',
    flag: SurinameFlagIcon,
    name: 'Suriname',
  },

  SZ: {
    alphaCode: 'SZ',
    dialCode: '+268',
    flag: SwazilandFlagIcon,
    name: 'Swaziland',
  },
  SE: {
    alphaCode: 'SE',
    dialCode: '+46',
    flag: SwedenFlagIcon,
    name: 'Sweden',
  },
  CH: {
    alphaCode: 'CH',
    dialCode: '+41',
    flag: SwitzerlandFlagIcon,
    name: 'Switzerland',
  },
  SY: {
    alphaCode: 'SY',
    dialCode: '+963',
    flag: SyrianArabRepublicFlagIcon,
    name: 'Syrian Arab Republic',
  },
  TW: {
    alphaCode: 'TW',
    dialCode: '+886',
    flag: TaiwanFlagIcon,
    name: 'Taiwan, Province of China',
  },
  TJ: {
    alphaCode: 'TJ',
    dialCode: '+992',
    flag: TajikistanFlagIcon,
    name: 'Tajikistan',
  },
  TZ: {
    alphaCode: 'TZ',
    dialCode: '+255',
    flag: TanzaniaFlagIcon,
    name: 'Tanzania, United Republic of',
  },
  TH: {
    alphaCode: 'TH',
    dialCode: '+66',
    flag: ThailandFlagIcon,
    name: 'Thailand',
  },
  TL: {
    alphaCode: 'TL',
    dialCode: '+670',
    flag: TimorLesteFlagIcon,
    name: 'Timor-Leste',
  },
  TG: {
    alphaCode: 'TG',
    dialCode: '+228',
    flag: TogoFlagIcon,
    name: 'Togo',
  },
  TK: {
    alphaCode: 'TK',
    dialCode: '+690',
    flag: TokelauFlagIcon,
    name: 'Tokelau',
  },
  TO: {
    alphaCode: 'TO',
    dialCode: '+676',
    flag: TongaFlagIcon,
    name: 'Tonga',
  },
  TT: {
    alphaCode: 'TT',
    dialCode: '+1 868',
    flag: TrinidadAndTobagoFlagIcon,
    name: 'Trinidad and Tobago',
  },
  TN: {
    alphaCode: 'TN',
    dialCode: '+216',
    flag: TunisiaFlagIcon,
    name: 'Tunisia',
  },
  TR: {
    alphaCode: 'TR',
    dialCode: '+90',
    flag: TurkeyFlagIcon,
    name: 'Turkey',
  },
  TM: {
    alphaCode: 'TM',
    dialCode: '+993',
    flag: TurkmenistanFlagIcon,
    name: 'Turkmenistan',
  },
  TC: {
    alphaCode: 'TC',
    dialCode: '+1 649',
    flag: TurksAndCaicosIslandsFlagIcon,
    name: 'Turks and Caicos Islands',
  },
  TV: {
    alphaCode: 'TV',
    dialCode: '+688',
    flag: TuvaluFlagIcon,
    name: 'Tuvalu',
  },
  UG: {
    alphaCode: 'UG',
    dialCode: '+256',
    flag: UgandaFlagIcon,
    name: 'Uganda',
  },
  UA: {
    alphaCode: 'UA',
    dialCode: '+380',
    flag: UkraineFlagIcon,
    name: 'Ukraine',
  },
  AE: {
    alphaCode: 'AE',
    dialCode: '+971',
    flag: UnitedArabEmiratesFlagIcon,
    name: 'United Arab Emirates',
  },
  GB: {
    alphaCode: 'GB',
    dialCode: '+44',
    flag: UnitedKingdomFlagIcon,
    name: 'United Kingdom',
  },
  US: {
    alphaCode: 'US',
    dialCode: '+1',
    flag: UnitedStatesFlagIcon,
    name: 'United States',
  },
  UY: {
    alphaCode: 'UY',
    dialCode: '+598',
    flag: UruguayFlagIcon,
    name: 'Uruguay',
  },
  UZ: {
    alphaCode: 'UZ',
    dialCode: '+998',
    flag: UzbekistanFlagIcon,
    name: 'Uzbekistan',
  },
  VU: {
    alphaCode: 'VU',
    dialCode: '+678',
    flag: VanuatuFlagIcon,
    name: 'Vanuatu',
  },
  VE: {
    alphaCode: 'VE',
    dialCode: '+58',
    flag: VenezuelaFlagIcon,
    name: 'Venezuela, Bolivarian Republic of',
  },
  VN: {
    alphaCode: 'VN',
    dialCode: '+84',
    flag: VietnamFlagIcon,
    name: 'Vietnam',
  },
  VG: {
    alphaCode: 'VG',
    dialCode: '+1 284',
    flag: BritishVirginIslandsFlagIcon,
    name: 'Virgin Islands, British',
  },
  VI: {
    alphaCode: 'VI',
    dialCode: '+1 340',
    flag: UnitedStatesVirginIslandsFlagIcon,
    name: 'Virgin Islands, U.S.',
  },
  YE: {
    alphaCode: 'YE',
    dialCode: '+967',
    flag: YemenFlagIcon,
    name: 'Yemen',
  },
  ZM: {
    alphaCode: 'ZM',
    dialCode: '+260',
    flag: ZambiaFlagIcon,
    name: 'Zambia',
  },
  ZW: {
    alphaCode: 'ZW',
    dialCode: '+263',
    flag: ZimbabweFlagIcon,
    name: 'Zimbabwe',
  },
};

export default COUNTRIES;
