import styled, { css } from 'styled-components';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';

export const ScrollableContainer = styled(Flex)`
${() => css`
    flex-direction: column;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: ${theme.color.gray[100]};
    }
  `}
`;

export const ItemTitle = styled(Typography)`
  ${() => css`
    &:hover {
      font-weight: 600;
    }
  `}
`;
