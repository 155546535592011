import styled, { css } from 'styled-components';

import { Icon } from '@atoms';

export const StyledDropdown = styled(Icon)`
  ${({ isExpanded }) => css`
    ${isExpanded && 'transform: rotate(180deg);'}
    transition: transform 0.3s;
  `}
`;
