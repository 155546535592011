import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { exact, string, number } from 'prop-types';

import GoogleMapMarker from '@assets/svgs/GoogleMapMarker.svg';

const Map = ({ width, height, position, zoom }) => {
  const containerStyle = {
    width,
    height,
  };

  const defaultMapOptions = {
    fullscreenControl: false,
    scaleControl: false,
    streetViewControl: false,
    zoomControl: false,
    scrollwheel: false,
    mapTypeControlOptions: {
      mapTypeIds: [],
    },
    gestureHandling: 'none',
    draggableCursor: 'pointer',
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  return isLoaded && (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={position}
      zoom={zoom}
      options={defaultMapOptions}
    >
      <Marker icon={GoogleMapMarker} position={position} />
    </GoogleMap>
  );
};

Map.defaultProps = {
  position: {
    lat: 1,
    lng: 1,
  },
};

Map.propTypes = {
  width: string.isRequired,
  height: string.isRequired,
  position: exact({
    lat: number,
    lng: number,
  }),
  zoom: number.isRequired,
};

export default Map;
