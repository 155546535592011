import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { string, bool, arrayOf, func } from 'prop-types';

import { Modal, Icon } from '@atoms';
import { TextInput } from '@atoms/Input';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';
import { useDebounce } from '@hooks';
import { ReactComponent as Close } from '@assets/svgs/Close.svg';
import { ReactComponent as Search } from '@assets/svgs/Search.svg';
import { ReactComponent as NoResults } from '@assets/svgs/NoResults.svg';

import { ItemBlock, ScrollableContainer } from './styles';

const SearchListModal = ({
  isOpen,
  itemsList,
  title,
  onModalClose,
  onSelect,
  withMultipleSelection,
  selectedItemsList,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [items, setItems] = useState(itemsList);
  const [selectedItems, setSelectedItems] = useState(selectedItemsList);

  const debouncedInput = useDebounce(searchInput, 200);

  const onSearchChange = useCallback(event => {
    setSearchInput(event.target.value);
  }, []);

  const handleItemSelect = id => () => {
    setSearchInput('');
    onSelect(id);
  };

  const onItemClick = name => () => {
    setSelectedItems(prevItems => {
      const updatedItems = [...prevItems];

      const isAlreadySelected = !!selectedItems.find(selectedItem => selectedItem === name);

      if (!isAlreadySelected) {
        updatedItems.push(itemsList.find(item => item === name));
      }

      return updatedItems;
    });
  };

  const onItemRemove = name => () => {
    setSelectedItems(prevItems => prevItems.filter(item => item !== name));
  };

  const handleModalClose = () => {
    setSelectedItems(selectedItemsList);
    setSearchInput('');
    onModalClose();
  };

  useEffect(() => {
    setItems(
      itemsList.filter(item => item.toUpperCase().includes(debouncedInput.toUpperCase())),
    );
  }, [debouncedInput]);

  useEffect(() => {
    setItems(itemsList);
  }, [itemsList]);

  const itemsListBlock = useMemo(() => items.map(item => (
    <ItemBlock
      fontSize={[14, 16]}
      onClick={withMultipleSelection ? onItemClick(item) : handleItemSelect(item)}
      key={item}
      width="100%"
      pointer
      pl={10}
      py={12}
    >
      <Typography
        color={selectedItems.find(selectedItem => selectedItem === item)
          ? theme.color.gray[200]
          : theme.color.gray[500]}
        variant="proximaNova-400"
      >
        {item}
      </Typography>
    </ItemBlock>
  )), [items, selectedItems, withMultipleSelection]);

  const selectedItemsBlock = useMemo(() => selectedItems.map(selectedItem => (
    <Flex
      alignItems="center"
      border={`1px solid ${theme.color.gray[500]}`}
      px={['7px', '9px', 11]}
      py={['2px']}
      mb={[11, 13, 15]}
      mr={[11, 13, 15]}
      key={selectedItem}
    >
      <Typography mr={[9, 11, 13]} variant="proximaNova-400" fontSize={[12, 14, 16]}>
        {selectedItem}
      </Typography>
      <Icon
        color={theme.color.black}
        onClick={onItemRemove(selectedItem)}
        pointer
        SVG={Close}
        width={[8, 10, 12]}
        height={[8, 10, 12]}
      />
    </Flex>
  )), [selectedItems]);

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <Flex
        height={[500]}
        width={[540]}
        py={['8px', 12, 16]}
        px={[12, 16, 20]}
        flexDirection="column"
        alignItems="center"
      >
        <Typography mb={[18, 24, 30]} variant="proximaNova-600" fontSize={[16, 18, 20]}>
          {title}
        </Typography>
        {withMultipleSelection && !!selectedItems.length && (
          <Flex width="100%" justifyContent="start" flexWrap="wrap">
            {selectedItemsBlock}
          </Flex>
        )}
        <TextInput
          placeholder="Search"
          onChange={onSearchChange}
          value={searchInput}
        >
          <Icon
            mr={9}
            color={theme.color.black}
            pointer
            SVG={Search}
            width={12}
            height={12}
          />
        </TextInput>
        <ScrollableContainer height="100%" mb={20} mt={16} width="100%">
          {items.length
            ? itemsListBlock
            : (
              <Flex flexDirection="column" height="100%" alignItems="center" justifyContent="center">
                <Icon
                  SVG={NoResults}
                  width="45"
                  height="45"
                />
                <Typography variant="proximaNova-400">No results</Typography>
              </Flex>
            )}
        </ScrollableContainer>
        {withMultipleSelection && (
          <Button
            disabled={JSON.stringify(selectedItems) === JSON.stringify(selectedItemsList)}
            onClick={onSelect(selectedItems)}
            py={12}
            px={74}
            variant="primary"
          >
            Done
          </Button>
        )}
      </Flex>
    </Modal>
  );
};

SearchListModal.defaultProps = {
  selectedItemsList: [],
  itemsList: [],
};

SearchListModal.propTypes = {
  selectedItemsList: arrayOf(string),
  withMultipleSelection: bool.isRequired,
  isOpen: bool.isRequired,
  title: string.isRequired,
  onModalClose: func.isRequired,
  onSelect: func.isRequired,
  itemsList: arrayOf(string),
};

export default SearchListModal;
