import React, { useState, useCallback } from 'react';
import { func, instanceOf, bool, string, number } from 'prop-types';

import { Icon, DatePicker } from '@atoms';
import { Flex, Button } from '@mixins';
import { theme } from '@styles';
import { dayjs } from '@utils';

import { ReactComponent as Close } from '@assets/svgs/Close.svg';

const DateSelectingModal = ({
  canBeClosed,
  onSelect,
  calendarDate,
  onModalClose,
  type,
  topPosition,
  hasSelectingButton,
}) => {
  const [selectedDate, setSelectedDate] = useState(calendarDate);

  const handleDateSelect = useCallback(newDate => {
    setSelectedDate(newDate);
  }, []);

  const handleModalClose = useCallback(() => {
    setSelectedDate(null);
    onModalClose();
  }, []);

  return (
    <Flex
      zIndex={3}
      backgroundColor={theme.color.white}
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.15)"
      flexDirection="column"
      alignItems="center"
      position="absolute"
      top={topPosition}
      left={0}
      p={type === 'month' ? 20 : 18}
      pt={canBeClosed ? 45 : 20}
    >
      {canBeClosed && (
        <Icon
          pointer
          onClick={handleModalClose}
          position="absolute"
          SVG={Close}
          width={11}
          height={11}
          {...(type === 'month' && { right: 17 })}
          {...(type === 'year' && { left: 17 })}
          top={17}
        />
      )}
      <DatePicker
        type={type}
        selectedDate={selectedDate}
        onDateSelect={hasSelectingButton ? handleDateSelect : onSelect}
      />
      {hasSelectingButton && (
        <Button
          mt={20}
          onClick={onSelect(selectedDate)}
          py={12}
          px={106}
          variant="primary"
        >
          {type === 'month' ? 'Apply' : 'Select'}
        </Button>
      )}
    </Flex>
  );
};

DateSelectingModal.defaultProps = {
  calendarDate: dayjs().$d,
  onModalClose: () => {},
  canBeClosed: true,
  hasSelectingButton: true,
  topPosition: 24,
};

DateSelectingModal.propTypes = {
  onSelect: func.isRequired,
  calendarDate: instanceOf(Date),
  onModalClose: func,
  canBeClosed: bool,
  type: string.isRequired,
  hasSelectingButton: bool,
  topPosition: number,
};

export default DateSelectingModal;
