import React from 'react';
import { bool, func, number, object, string, arrayOf, exact } from 'prop-types';

import { Loader } from '@atoms';
import { RoomsFilter, RoomsList, NoBookingPermissionModal } from '@organisms';
import { Main } from '@templates';
import { Typography, Flex } from '@mixins';
import { theme } from '@styles';

const Rooms = ({
  onTypeChange,
  type,
  isCompanyManagersModalOpen,
  times,
  error,
  areRoomsLoading,
  onRoomClick,
  onCustomFilterClick,
  isLoading,
  onDateChange,
  onStartTimeChange,
  onEndTimeChange,
  onFloorChange,
  onSeatsChange,
  seats,
  startTime,
  endTime,
  floor,
  date,
  selectedCustomFilters,
}) => (
  <Main
    isWhiteLabelingOpen={false}
    isLoading={isLoading}
    error={error}
  >
    <Typography alignSelf="start" variant="garamond-500" fontSize={[30, 34]} mb={30}>
      Book a Room
    </Typography>
    <RoomsFilter
      type={type}
      onTypeChange={onTypeChange}
      timeList={times}
      onCustomFilterClick={onCustomFilterClick}
      onDateChange={onDateChange}
      onStartTimeChange={onStartTimeChange}
      onEndTimeChange={onEndTimeChange}
      onFloorChange={onFloorChange}
      onSeatsChange={onSeatsChange}
      seats={seats}
      startTime={startTime}
      endTime={endTime}
      floor={floor}
      date={date}
      isLoading={isLoading}
      selectedCustomFilters={selectedCustomFilters}
    />
    {areRoomsLoading
      ? (
        <Flex flexGrow={1} alignItems="center" justifyContent="center">
          <Loader position="relative" background={theme.color.white} width={64} height={64} />
        </Flex>
      )
      : <RoomsList roomTypeSelected={type} timeList={times} date={date} onRoomClick={onRoomClick} />}
    <NoBookingPermissionModal isOpen={isCompanyManagersModalOpen} />
  </Main>
);

Rooms.defaultProps = {
  selectedCustomFilters: [],
  error: {},
  times: [],
  startTime: {},
  endTime: {},
  isCompanyManagersModalOpen: false,
  floor: null,
  seats: null,
  type: null,
};

Rooms.propTypes = {
  onTypeChange: func.isRequired,
  type: string,
  isCompanyManagersModalOpen: bool,
  times: arrayOf(object),
  error: exact({
    status: number,
    message: string,
  }),
  areRoomsLoading: bool.isRequired,
  onRoomClick: func.isRequired,
  onCustomFilterClick: func.isRequired,
  isLoading: bool.isRequired,
  onDateChange: func.isRequired,
  onStartTimeChange: func.isRequired,
  onEndTimeChange: func.isRequired,
  onFloorChange: func.isRequired,
  onSeatsChange: func.isRequired,
  seats: string,
  startTime: object,
  endTime: object,
  floor: number,
  date: object.isRequired,
  selectedCustomFilters: arrayOf(number),
};

export default Rooms;
