import React from 'react';
import { string, exact, arrayOf, number, bool } from 'prop-types';
import { useSelector } from 'react-redux';

import { Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { getCurrency } from '@helpers/common';

import VideoConferencing from '@assets/svgs/VideoConferencing.svg';
import { ReactComponent as Credits } from '@assets/svgs/Credits.svg';
import { ReactComponent as Seats } from '@assets/svgs/Seats.svg';
import { PricingDetails } from '@molecules';

const RoomDetailsPanel = ({ seats, features, currencyPrice, creditsPrice, isActivate, creditPrices }) => {
  const { currentLocation } = useSelector(store => store.locationStore);

  const featuresBlock = features.map(({ id: featureId, displayName, iconUrl }) => (
    <Flex mb={10} mr={['6px', '8px', 10]} alignItems="center" key={featureId}>
      <svg width="24" height="24">
        <image xlinkHref={iconUrl || VideoConferencing} width="24" height="24" />
      </svg>
      <Typography
        ml={['4px', '6px', '8px']}
        variant="proximaNova-400"
        fontSize={[10, 12, 12, 14]}
        color={theme.color.gray[300]}
      >
        {displayName}
      </Typography>
    </Flex>
  ));

  return (
    <Flex pb="5px" borderBottom="1px solid #EFECE6" justifyContent="space-between">
      <Flex mr={15} flexWrap="wrap" alignItems="center">
        <Icon mb={10} SVG={Seats} width={[10, 14, 18]} height={[8, 10, 12]} mr={['6px', '8px', 10]} />
        <Typography
          {...(!!features.length && { borderRight: `1px solid ${theme.color.black}` })}
          pr={['6px', '6px', '8px', 10]}
          variant="proximaNova-400"
          fontSize={[10, 12, 14, 16]}
          mb={10}
          color={theme.color.gray[300]}
          mr={['6px', '8px', 10]}
        >
          {`${seats} ${seats === 1 ? 'seat' : 'seats'}`}
        </Typography>
        {featuresBlock}
      </Flex>
      <PricingDetails
        baseCreditPrice={creditPrices?.base?.creditsPerHour}
        peakTimeCreditPrice={creditPrices?.peakPrice?.creditsPerHour}
        peakTimeDays={creditPrices?.peakPrice?.timeFrames.daysOfWeek}
        peakTimeIntervals={creditPrices?.peakPrice?.timeFrames.times}
        locationTimeFormat={currentLocation.timeFormat}
        isClickable={!isActivate && creditPrices?.peakPrice}
      >
        <Flex
          flexShrink={0}
        >
          {!isActivate && creditPrices.peakPrice && (
            <Typography
              mr="5px"
              variant="proximaNova-400"
              fontSize={[11, 12, 13, 14]}
              color={theme.color.gray[300]}
            >
              from
            </Typography>
          )}
          <Icon mr="5px" SVG={Credits} width={[10, 13, 16]} heigh={[10, 13, 16]} />
          <Typography variant="proximaNova-400" fontSize={[11, 12, 13, 14]} color={theme.color.gray[300]}>
            {`${creditsPrice} / hr${isActivate ? ` or ${getCurrency(
              currentLocation.currency, currencyPrice,
            )} / hr` : ''}`}
          </Typography>
        </Flex>
      </PricingDetails>
    </Flex>
  );
};

RoomDetailsPanel.defaultProps = {
  creditPrices: {},
  seats: 0,
  features: [],
  currencyPrice: 0,
  creditsPrice: 0,
  isActivate: false,
};

RoomDetailsPanel.propTypes = {
  creditPrices: exact({
    base: exact({
      creditsPerHour: number,
    }),
    peakPrice: exact({
      creditsPerHour: number,
      timeFrames: exact({
        daysOfWeek: number,
        times: arrayOf(exact({
          from: string,
          to: string,
        })),
      }),
    }),
  }),
  isActivate: bool,
  seats: number,
  features: arrayOf(
    exact({
      id: number,
      key: string,
      displayName: string,
      iconUrl: string,
    }),
  ),
  currencyPrice: number,
  creditsPrice: number,
};

export default RoomDetailsPanel;
