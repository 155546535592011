import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import * as api from './api';

// < ----- ACTIONS ----- > //
const resetPhasesAction = 'RESET_COMPANY_PHASES';
const resetStoreToInitialStateAction = 'RESET_STORE';
const getMyCompanyAvailableCreditsRoutine = createRoutine(
  'GET_MY_COMPANY_AVAILABLE_CREDITS',
);
const getCompanyMembersByAccessIdRoutine = createRoutine(
  'GET_COMPANY_MEMBERS_BY_ACCESS_ID',
);
const getCompanyByIdRoutine = createRoutine(
  'GET_COMPANY_BY_ID',
);

const getCompanyByAccessIdRoutine = createRoutine(
  'GET_COMPANY_BY_ACCESS_ID',
);

const getCompanyListRoutine = createRoutine(
  'GET_COMPANY_LIST',
);

const getCompanyMembersRoutine = createRoutine(
  'GET_COMPANY_MEMBERS',
);

const getAdminCompanyByIdRoutine = createRoutine(
  'GET_ADMIN_COMPANY_BY_ID',
);

const updateCompanyMemberRoutine = createRoutine(
  'UPDATE_COMPANY_MEMBER',
);

const createCompanyMemberRoutine = createRoutine(
  'CREATE_COMPANY_MEMBER',
);

const getCompanyManagersRoutine = createRoutine(
  'GET_COMPANY_MANAGERS',
);

const editCompanyRoutine = createRoutine(
  'EDIT_COMPANY',
);

const getCreditsReportRoutine = createRoutine(
  'GET_CREDIT_REPORT',
);

// < ----- ACTION CREATORS ----- > //
export const getCompanyMembers = (id, data) => async dispatch => {
  try {
    dispatch(getCompanyMembersRoutine.request());

    const response = await api.getCompanyMembers(id, data);

    return dispatch(getCompanyMembersRoutine.success(response.data));
  } catch (error) {
    return dispatch(getCompanyMembersRoutine.failure(error.response));
  }
};

export const getCreditsReport = data => async dispatch => {
  try {
    dispatch(getCreditsReportRoutine.request());

    const response = await api.getCreditsReport(data);

    return dispatch(getCreditsReportRoutine.success(response.data));
  } catch (error) {
    return dispatch(getCreditsReportRoutine.failure(error.response));
  }
};

export const updateCompanyMember = (id, data, accessId = 'my') => async dispatch => {
  try {
    dispatch(updateCompanyMemberRoutine.request());

    const response = await api.updateCompanyMember(id, data, accessId);

    return dispatch(updateCompanyMemberRoutine.success(response.data));
  } catch (error) {
    return dispatch(updateCompanyMemberRoutine.failure(error.response));
  }
};

export const createCompanyMember = (data, accessId = 'my') => async dispatch => {
  try {
    dispatch(createCompanyMemberRoutine.request());

    const response = await api.createCompanyMember(data, accessId);

    return dispatch(createCompanyMemberRoutine.success(response.data));
  } catch (error) {
    return dispatch(createCompanyMemberRoutine.failure(error.response));
  }
};

export const getCompanyMembersByAccessId = (accessId, data) => async dispatch => {
  try {
    dispatch(getCompanyMembersByAccessIdRoutine.request());

    const response = await api.getCompanyMembersByAccessId(accessId, data);

    return dispatch(getCompanyMembersByAccessIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getCompanyMembersByAccessIdRoutine.failure(error.response));
  }
};

export const getMyCompanyAvailableCredits = () => async dispatch => {
  try {
    dispatch(getMyCompanyAvailableCreditsRoutine.request());

    const response = await api.getMyCompanyAvailableCredits();

    return dispatch(getMyCompanyAvailableCreditsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getMyCompanyAvailableCreditsRoutine.failure(error.response));
  }
};

export const getCompanyManagers = id => async dispatch => {
  try {
    dispatch(getCompanyManagersRoutine.request());

    const response = await api.getCompanyManagers(id);

    return dispatch(getCompanyManagersRoutine.success(response.data));
  } catch (error) {
    return dispatch(getCompanyManagersRoutine.failure(error.response));
  }
};

export const getCompanyByAccessId = accessId => async dispatch => {
  try {
    dispatch(getCompanyByAccessIdRoutine.request());

    const response = await api.getCompanyByAccessId(accessId);

    return dispatch(getCompanyByAccessIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getCompanyByAccessIdRoutine.failure(error.response));
  }
};

export const getCompanyById = (id, membersAmount) => async dispatch => {
  try {
    dispatch(getCompanyByIdRoutine.request());

    const response = await api.getCompanyById(id);

    dispatch(getCompanyByIdRoutine.success(response.data));

    return dispatch(getCompanyMembers(id, {
      page: 1,
      pageSize: membersAmount,
    }));
  } catch (error) {
    return dispatch(getCompanyByIdRoutine.failure(error.response));
  }
};

export const getAdminCompanyById = id => async dispatch => {
  try {
    dispatch(getAdminCompanyByIdRoutine.request());

    const response = await api.getAdminCompanyById(id);

    return dispatch(getAdminCompanyByIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getAdminCompanyByIdRoutine.failure(error.response));
  }
};

export const getCompanyList = data => async dispatch => {
  try {
    dispatch(getCompanyListRoutine.request());

    const response = await api.getCompanyList(data);

    return dispatch(getCompanyListRoutine.success(response.data));
  } catch (error) {
    return dispatch(getCompanyListRoutine.failure(error.response));
  }
};

export const editCompany = (id, companyData) => async dispatch => {
  try {
    dispatch(editCompanyRoutine.request());

    const response = await api.editCompany(id, companyData);

    return dispatch(editCompanyRoutine.success(response.data));
  } catch (error) {
    return dispatch(editCompanyRoutine.failure(error.response));
  }
};

export const resetStore = () => ({
  type: resetStoreToInitialStateAction,
});

export const resetPhases = () => ({
  type: resetPhasesAction,
});

// < ----- STATE ----- > //
export const companyStorePersistWhitelist = [];

const initialState = {
  companyById: {
    members: {
      data: [],
      pagination: {},
    },
  },
  creditPackages: [],
  adminCompanyById: {},
  companyByAccessId: {},
  companyMembersByAccessId: {
    data: [],
    pagination: {},
  },
  companies: {
    data: [],
    pagination: {},
  },
  availableCredits: 0,

  userCompanyManagers: [],

  getCompanyByIdPhase: INIT,
  getCompanyByIdError: null,

  getCompanyByAccessIdPhase: INIT,
  getCompanyByAccessIdError: null,

  getCompanyListPhase: INIT,
  getCompanyListError: null,

  getCompanyMembersPhase: INIT,
  getCompanyMembersError: null,

  getCompanyMembersByAccessIdPhase: INIT,
  getCompanyMembersByAccessIdError: null,

  editCompanyPhase: INIT,
  editCompanyError: null,

  getCompanyManagersPhase: INIT,
  getCompanyManagersError: null,

  getMyCompanyAvailableCreditsPhase: INIT,
  getMyCompanyAvailableCreditsError: null,

  updateCompanyMemberPhase: INIT,
  updateCompanyMemberError: null,

  createCompanyMemberPhase: INIT,
  createCompanyMemberError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;
      // RESET PHASES
    case resetPhasesAction:
      return {
        ...state,
        getCompanyMembersPhase: INIT,
        getCompanyMembersError: null,
        getCompanyByIdPhase: INIT,
        getCompanyByIdError: null,
        editCompanyPhase: INIT,
        editCompanyError: null,
        getCompanyManagersPhase: INIT,
        getCompanyManagersError: null,
        getMyCompanyAvailableCreditsPhase: INIT,
        getMyCompanyAvailableCreditsError: null,
        getCompanyMembersByAccessIdPhase: INIT,
        getCompanyMembersByAccessIdError: null,
        createCompanyMemberPhase: INIT,
        createCompanyMemberError: null,
        updateCompanyMemberPhase: INIT,
        updateCompanyMemberError: null,
        getCompanyByAccessIdPhase: INIT,
        getCompanyByAccessIdError: null,
        getCreditsReportPhase: INIT,
        getCreditsReportError: null,
      };
      // GET ADMIN COMPANY
    case getAdminCompanyByIdRoutine.REQUEST:
      return {
        ...state,
        getAdminCompanyByIdPhase: LOADING,
      };
    case getAdminCompanyByIdRoutine.SUCCESS:
      return {
        ...state,
        adminCompanyById: {
          ...state.adminCompanyById,
          ...payload.data,
          logoUrl: payload.data.logoUrl ? `${payload.data.logoUrl}?t=${Date.now()}` : null,
        },
        getAdminCompanyByIdPhase: SUCCESS,
      };
    case getAdminCompanyByIdRoutine.FAILURE:
      return {
        ...state,
        getAdminCompanyByIdPhase: FAILURE,
        getAdminCompanyByIdError: payload,
      };
    // GET USER COMPANY
    case getCompanyByIdRoutine.REQUEST:
      return {
        ...state,
        getCompanyByIdPhase: LOADING,
      };
    case getCompanyByIdRoutine.SUCCESS:
      return {
        ...state,
        companyById: {
          ...state.companyById,
          ...payload.data,
          logoUrl: payload.data.logoUrl ? `${payload.data.logoUrl}?t=${Date.now()}` : null,
          members: {
            ...initialState.companyById.members,
          },
        },
        getCompanyByIdPhase: SUCCESS,
      };
    case getCompanyByIdRoutine.FAILURE:
      return {
        ...state,
        getCompanyByIdPhase: FAILURE,
        getCompanyByIdError: payload,
      };
      // GET USER COMPANY BY ACCESS ID
    case getCompanyByAccessIdRoutine.REQUEST:
      return {
        ...state,
        getCompanyByAccessIdPhase: LOADING,
      };
    case getCompanyByAccessIdRoutine.SUCCESS:
      return {
        ...state,
        companyByAccessId: {
          ...state.companyByAccessId,
          ...payload.data,
        },
        getCompanyByAccessIdPhase: SUCCESS,
      };
    case getCompanyByAccessIdRoutine.FAILURE:
      return {
        ...state,
        getCompanyByAccessIdPhase: FAILURE,
        getCompanyByAccessIdError: payload,
      };
      // GET CREDIT REPORT
    case getCreditsReportRoutine.REQUEST:
      return {
        ...state,
        getCreditsReportPhase: LOADING,
      };
    case getCreditsReportRoutine.SUCCESS:
      return {
        ...state,
        creditPackages: {
          packages: payload.data.creditPackages.map((creditPackage, index) => ({
            id: index,
            title: creditPackage.title,
            startDate: creditPackage.availableFromDate,
            expireDate: creditPackage.availableToDate,
            used: creditPackage.used,
            remaining: creditPackage.remaining,
            available: creditPackage.available,
          })),
          totals: payload.data.totals,
        },
        getCreditsReportPhase: SUCCESS,
      };
    case getCreditsReportRoutine.FAILURE:
      return {
        ...state,
        getCreditsReportPhase: FAILURE,
        getCreditsReportError: payload,
      };

    // GET COMPANY MEMBERS
    case getCompanyMembersRoutine.REQUEST:
      return {
        ...state,
        getCompanyMembersPhase: LOADING,
      };
    case getCompanyMembersRoutine.SUCCESS:
      return {
        ...state,
        companyById: {
          ...state.companyById,
          members: {
            ...state.companyById.members,
            data: [
              ...state.companyById.members.data,
              ...payload.data.map(({ id, firstName, lastName, imageUrl, jobTitle, role }) => ({
                id,
                firstName,
                lastName,
                imageUrl: imageUrl ? `${imageUrl}?${Date.now()}` : imageUrl,
                jobTitle,
                role,
              })),
            ],
            pagination: {
              ...state.companyById.members.pagination,
              ...payload.pagination,
            },
          },
        },
        getCompanyMembersPhase: SUCCESS,
      };
    case getCompanyMembersRoutine.FAILURE:
      return {
        ...state,
        getCompanyMembersPhase: FAILURE,
        getCompanyMembersError: payload,
      };

    // GET COMPANY MEMBERS BY ACCESS ID
    case getCompanyMembersByAccessIdRoutine.REQUEST:
      return {
        ...state,
        getCompanyMembersByAccessIdPhase: LOADING,
      };
    case getCompanyMembersByAccessIdRoutine.SUCCESS:
      return {
        ...state,
        companyMembersByAccessId: {
          data: [...payload.data],
          pagination: {
            ...state.companyMembersByAccessId.pagination,
            ...payload.pagination,
          },
        },
        getCompanyMembersByAccessIdPhase: SUCCESS,
      };
    case getCompanyMembersByAccessIdRoutine.FAILURE:
      return {
        ...state,
        getCompanyMembersByAccessIdPhase: FAILURE,
        getCompanyMembersByAccessIdError: payload,
      };

    // GET COMPANY MANAGERS
    case getCompanyManagersRoutine.REQUEST:
      return {
        ...state,
        getCompanyManagersPhase: LOADING,
      };
    case getCompanyManagersRoutine.SUCCESS:
      return {
        ...state,
        userCompanyManagers: payload.data,
        getCompanyManagersPhase: SUCCESS,
      };
    case getCompanyManagersRoutine.FAILURE:
      return {
        ...state,
        getCompanyManagersPhase: FAILURE,
        getCompanyManagersError: payload,
      };

    // UPDATE COMPANY MEMBER
    case updateCompanyMemberRoutine.REQUEST:
      return {
        ...state,
        updateCompanyMemberPhase: LOADING,
      };
    case updateCompanyMemberRoutine.SUCCESS:
      return {
        ...state,
        companyMembersByAccessId: {
          ...state.companyMembersByAccessId,
          data: state.companyMembersByAccessId.data.map(member => {
            if (member.id === payload.data.id) {
              return payload.data;
            }
            return member;
          }),
        },
        updateCompanyMemberPhase: SUCCESS,
      };
    case updateCompanyMemberRoutine.FAILURE:
      return {
        ...state,
        updateCompanyMemberPhase: FAILURE,
        updateCompanyMemberError: payload,
      };

      // CREATE COMPANY MEMBER
    case createCompanyMemberRoutine.REQUEST:
      return {
        ...state,
        createCompanyMemberPhase: LOADING,
      };
    case createCompanyMemberRoutine.SUCCESS: {
      const membersList = [...state.companyMembersByAccessId.data];
      if (Object.keys(payload.data).length) {
        membersList.unshift(payload.data);
      }

      return {
        ...state,
        companyMembersByAccessId: {
          ...state.companyMembersByAccessId,
          data: membersList,
        },
        createCompanyMemberPhase: SUCCESS,
      };
    }
    case createCompanyMemberRoutine.FAILURE:
      return {
        ...state,
        createCompanyMemberPhase: FAILURE,
        createCompanyMemberError: payload,
      };

      // GET MY COMPANY AVAILABLE CREDITS
    case getMyCompanyAvailableCreditsRoutine.REQUEST:
      return {
        ...state,
        getMyCompanyAvailableCreditsPhase: LOADING,
      };
    case getMyCompanyAvailableCreditsRoutine.SUCCESS:
      return {
        ...state,
        availableCredits: payload.data.amount,
        getMyCompanyAvailableCreditsPhase: SUCCESS,
      };
    case getMyCompanyAvailableCreditsRoutine.FAILURE:
      return {
        ...state,
        getMyCompanyAvailableCreditsPhase: FAILURE,
        getMyCompanyAvailableCreditsError: payload,
      };

      // EDIT COMPANY
    case editCompanyRoutine.REQUEST:
      return {
        ...state,
        editCompanyPhase: LOADING,
      };
    case editCompanyRoutine.SUCCESS:
      return {
        ...state,
        companyById: {
          ...state.companyById,
          ...payload.data,
          logoUrl: payload.data.logoUrl ? `${payload.data.logoUrl}?t=${Date.now()}` : null,
        },
        editCompanyPhase: SUCCESS,
      };
    case editCompanyRoutine.FAILURE:
      return {
        ...state,
        editCompanyPhase: FAILURE,
        editCompanyError: payload,
      };

    // GET COMPANY LIST
    case getCompanyListRoutine.REQUEST:
      return {
        ...state,
        getCompanyListPhase: LOADING,
      };
    case getCompanyListRoutine.SUCCESS:
      return {
        ...state,
        companies: !payload.pagination || payload.pagination.page === 1
          ? payload
          : {
            pagination: payload.pagination,
            data: [
              ...state.companies.data,
              ...payload.data,
            ],
          },
        getCompanyListPhase: SUCCESS,
      };
    case getCompanyListRoutine.FAILURE:
      return {
        ...state,
        getCompanyListPhase: FAILURE,
        getCompanyListError: payload,
      };
    default:
      return state;
  }
};

export default store;
