import styled, { css } from 'styled-components';
import { Flex } from '@mixins';

export const HoverBlock = styled(Flex)`
  ${() => css`
    &:hover {
      background-color: #F7F6F3;
    }
  `}
`;
