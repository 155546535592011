import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOf, bool, exact, number, string } from 'prop-types';
import { Typography, Flex, Button } from '@mixins';
import { theme } from '@styles';
import { Icon } from '@atoms';
import { ManageCompanyMembersErrorModal, Footer, ManageCompanyMembersSuccessModal } from '@molecules';
import { getCompanyMembersByAccessId, resetPhases } from '@store/company/duck';
import { useDebounce } from '@hooks';
import { DataTable, ManageCompanyMemberModal } from '@organisms';
import { TextInput } from '@atoms/Input';

import { ReactComponent as Search } from '@assets/svgs/Search.svg';
import { ReactComponent as Logo } from '@assets/svgs/Logo.svg';
import Image from '@assets/images/ManageCompany.png';

import { columns } from './columns';

const CompanyMembers = ({ companyMembers, pagination, companyName, accessId }) => {
  const dispatch = useDispatch();
  const { updateCompanyMemberError } = useSelector(store => store.companyStore);

  const [sortingModel, setSortingModel] = useState({
    direction: null,
    sortBy: null,
  });
  const [isManagingFinished, setIsManagingFinished] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    dispatch(getCompanyMembersByAccessId(accessId, {
      page, search: debouncedSearch, pageSize: 8, ...sortingModel,
    }));
  }, [page, debouncedSearch, sortingModel]);

  const handleAddUser = () => {
    setIsModalOpen(true);
  };

  const handleResetState = () => {
    dispatch(resetPhases());
  };

  const handleSortModelChange = sorting => {
    setSortingModel({
      orderBy: sorting[0]?.field,
      direction: sorting[0]?.sort.toUpperCase(),
    });
  };

  const handlePageChange = useCallback(value => {
    setPage(value.page + 1);
  }, []);

  const handleUserClick = id => {
    setIsModalOpen(true);
    setSelectedUserId(id);
  };

  const handleSearch = event => {
    setSearch(event.target.value);
  };

  const handleDoneClick = () => {
    setIsManagingFinished(true);
  };

  const handleModalClose = () => {
    setSelectedUserId(null);
    setIsModalOpen(false);
  };

  const handleSuccessModalClose = () => {
    setIsManagingFinished(false);
  };

  const selectedUser = useMemo(() => (
    companyMembers.find(user => user.id === selectedUserId)
  ), [companyMembers, selectedUserId]);

  return (
    <Flex
      position="relative"
      flexDirection="column"
      pb={[162, 307, 260]}
      alignItems="center"
      minHeight="100vh"
    >
      <Flex
        width="100%"
        alignItems="center"
        flexGrow={1}
        pt={30}
        maxWidth={1440}
        px={[20, 30, 40, 60]}
        pb={80}
        flexDirection="column"
      >
        <Icon mb="60px" SVG={Logo} />
        <Typography variant="garamond-500" fontSize={32} mb={10}>
          Add your team members
        </Typography>
        <Typography mb="45px" variant="proximaNova-400">Fill in the details of each member below.</Typography>
        <Flex alignItems="start" width="100%" flexDirection="column">
          <Flex flexDirection="column" width="100%" border="1px solid #D9D9D9" py="55px" px="60px">
            <Flex mb="25px" justifyContent="space-between">
              <Typography variant="proximaNova-600">
                {pagination.total}
                {' '}
                Team Members at
                {' '}
                {companyName}
              </Typography>
              <Button onClick={handleAddUser} variant="primary">
                <Typography py="6px" px="12px" variant="proximaNova-400" fontSize="14px" color={theme.color.white}>
                  + Add Team Member
                </Typography>
              </Button>
            </Flex>
            <Flex mb="20px" maxWidth="400px">
              <TextInput
                value={search}
                onChange={handleSearch}
                isRound
                placeholder="Search with name, email or phone..."
              >
                <Icon mr={[10, 12]} SVG={Search} />
              </TextInput>
            </Flex>
            <DataTable
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              rows={companyMembers}
              columns={columns(accessId)}
              onRowClick={handleUserClick}
              paginationMode="server"
              paginationModel={{
                pageSize: 8,
                page: page - 1,
              }}
              rowCount={pagination?.total}
              onPaginationModelChange={handlePageChange}
            />
          </Flex>
        </Flex>
        <Button onClick={handleDoneClick} mt="60px" variant="primary" py="12px" px="55px">Done</Button>
        <Flex mt="25px">
          <Typography mr="5px" color={theme.color.darkGreen} fontSize="14px" variant="proximaNova-400">
            Need help?
          </Typography>
          <Typography
            pointer
            textDecoration="underline"
            color={theme.color.darkGreen}
            fontSize="14px"
            variant="proximaNova-400"
          >
            Contact us
          </Typography>
        </Flex>
      </Flex>
      <Flex mt="100px" justifyContent="center" width="100%" height="300px" background={theme.color.darkGreen}>
        <Flex width="660px" backgroundSize="cover" background={`url(${Image}) center`} height="100%" />
      </Flex>
      <Footer />
      {isModalOpen && (
        <ManageCompanyMemberModal
          accessId={accessId}
          user={selectedUser}
          onModalClose={handleModalClose}
          isOpen={isModalOpen}
        />
      )}
      {isManagingFinished && (
        <ManageCompanyMembersSuccessModal
          onModalClose={handleSuccessModalClose}
          isOpen={isManagingFinished}
        />
      )}
      {!!updateCompanyMemberError && (
        <ManageCompanyMembersErrorModal
          error={{
            title: 'Cannot edit team member',
            status: updateCompanyMemberError?.data.error.status,
            message: updateCompanyMemberError?.data.error.message,
          }}
          onModalClose={handleResetState}
          isOpen={!!updateCompanyMemberError}
        />
      )}
    </Flex>
  );
};

CompanyMembers.propTypes = {
  accessId: string,
  companyName: string,
  companyMembers: arrayOf(exact({
    id: number,
    imageUrl: string,
    email: string,
    firstName: string,
    lastName: string,
    isCompanyAdmin: bool,
    phone: string,
    status: string,
    isAbleToBookMeetingRoom: bool,
    isAbleToInvoice: bool,
    isAbleToPayByCard: bool,
  })).isRequired,
  pagination: exact({
    pageSize: number,
    page: number,
    cursor: number,
    total: number,
  }).isRequired,
};

CompanyMembers.defaultProps = {
  companyName: null,
  accessId: null,
};

export default CompanyMembers;
