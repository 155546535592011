import React from 'react';
import { NetworkErrorToast } from '@molecules';

const withNetwork = Component => () => (
  <>
    <Component />
    <NetworkErrorToast />
  </>
);

export default withNetwork;
