import React, { useState, useEffect, useCallback } from 'react';
import { number, func, exact, string, bool } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';

import { ChangeCardModal, PaymentModal, ConfirmationModal } from '@molecules';
import { AddCardModal } from '@organisms';
import { SUCCESS, FAILURE } from '@constants/requestPhase';
import {
  getPaymentMethods, resetPhases as resetPaymentPhases, initPaymentMethod, buyCreditsConfirm, buyCredits,
} from '@store/payment/duck';
import {
  bookRoom,
  confirmBooking,
  editBooking,
  resetPhases as resetBookingPhases,
} from '@store/booking/duck';

import { ReactComponent as CreditsAdded } from '@assets/svgs/CreditsAdded.svg';
import { getCurrentUser } from '@store/user/duck';

const PayByCard = ({ creditPackageId, booking, onClose, onProcessFinish, isEditing }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();

  const {
    paymentMethods, getPaymentMethodsPhase, addPaymentMethodPhase, addPaymentMethodError, buyCreditsPhase,
    buyCreditsError, paymentInfo, stripeKeys, buyCreditsConfirmPhase,
  } = useSelector(store => store.paymentStore);

  const {
    bookRoomError, bookRoomPhase, bookingById, confirmBookingPhase, editBookingPhase, editBookingError,
  } = useSelector(store => store.bookingStore);

  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    paymentMethods.find(method => method.isDefault)?.id,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);

  useEffect(() => {
    if (!Object.keys(stripeKeys).length) {
      dispatch(initPaymentMethod());
    }
  }, []);

  useEffect(() => {
    if (buyCreditsConfirmPhase === SUCCESS) {
      dispatch(resetPaymentPhases());
      onProcessFinish('card');
      onClose();
    }
    if (confirmBookingPhase === SUCCESS) {
      dispatch(resetBookingPhases());
      onProcessFinish();
      onClose();
    }
  }, [buyCreditsConfirmPhase, confirmBookingPhase]);

  useEffect(() => {
    if (addPaymentMethodPhase === SUCCESS) {
      handleGoBackFromAddCard();
      dispatch(resetPaymentPhases());
      dispatch(getPaymentMethods());
    }
    if (addPaymentMethodPhase === FAILURE) {
      setErrorMessage(addPaymentMethodError.data.error.message);
      dispatch(resetPaymentPhases());
    }
  }, [addPaymentMethodPhase]);

  useEffect(() => {
    if (buyCreditsPhase === SUCCESS) {
      if (paymentInfo.result) {
        dispatch(resetPaymentPhases());
        onProcessFinish('card');
        handleClose();
      } else {
        setIsLoading(true);

        stripe.confirmCardPayment(paymentInfo.intentSecret)
          .then(result => {
            setIsLoading(false);
            if (result.error) {
              setErrorMessage(result.error.message);
            } else if (result.paymentIntent.status === 'succeeded') {
              dispatch(buyCreditsConfirm({ intentId: result.paymentIntent.id }));
              dispatch(getCurrentUser());
            }
          });
      }
    }
    if (bookRoomPhase === SUCCESS || editBookingPhase === SUCCESS) {
      if (!bookingById.paymentInfo?.intentSecret) {
        dispatch(resetBookingPhases());
        onProcessFinish();
        handleClose();
      } else {
        setIsLoading(true);

        stripe.confirmCardPayment(bookingById.paymentInfo?.intentSecret)
          .then(result => {
            setIsLoading(false);
            if (result.error) {
              setErrorMessage(result.error.message);
            } else if (result.paymentIntent.status === 'succeeded') {
              dispatch(confirmBooking({
                intentId: result.paymentIntent.id,
              }, bookingById.id));
            }
          });
      }
    }
    if (buyCreditsPhase === FAILURE) {
      setErrorMessage(buyCreditsError?.data.error.message);
      dispatch(resetPaymentPhases());
    }
    if (bookRoomPhase === FAILURE) {
      setErrorMessage(bookRoomError?.data.error.message);
      dispatch(resetBookingPhases());
    }
    if (editBookingPhase === FAILURE) {
      setErrorMessage(editBookingError?.data.error.message);
      dispatch(resetBookingPhases());
    }
  }, [buyCreditsPhase, bookRoomPhase, editBookingPhase]);

  const handlePayNow = () => {
    if (creditPackageId) {
      dispatch(buyCredits({
        paymentMethodId: selectedPaymentMethodId,
        creditPackageId,
      }));
    } else if (isEditing) {
      dispatch(editBooking(bookingById.id, {
        ...booking,
        payBy: 'money',
        paymentMethodId: selectedPaymentMethodId,
      }));
    } else {
      dispatch(bookRoom({
        ...booking,
        payBy: 'money',
        paymentMethodId: selectedPaymentMethodId,
      }));
    }
  };

  const handleClose = useCallback(() => {
    onClose();
    setErrorMessage('');
    dispatch(resetPaymentPhases());
    setIsPaymentModalOpen(false);
    setIsAddModalOpen(false);
    setIsChangeModalOpen(false);
  }, []);

  const handleAddCardError = useCallback(error => {
    setErrorMessage(error);
    handleGoBackFromAddCard();
  }, []);

  const handleCardSelect = id => () => {
    setSelectedPaymentMethodId(id);
  };

  const handleErrorModalClose = useCallback(() => {
    handleClose();
  }, []);

  const handleGoBackFromAddCard = useCallback(() => {
    setIsAddModalOpen(false);
    if (paymentMethods.length) {
      setIsChangeModalOpen(true);
    } else {
      setIsPaymentModalOpen(true);
    }
  }, [paymentMethods]);

  const handleGoBackFromChangeCard = useCallback(() => {
    setIsAddModalOpen(false);
    setIsChangeModalOpen(false);
    setIsPaymentModalOpen(true);
  }, []);

  const handleAddCard = useCallback(() => {
    dispatch(initPaymentMethod());
    setIsPaymentModalOpen(false);
    setIsChangeModalOpen(false);
    setIsAddModalOpen(true);
  }, [paymentMethods]);

  const handleChangeCard = useCallback(() => {
    setIsPaymentModalOpen(false);
    setIsChangeModalOpen(true);
  }, []);

  useEffect(() => {
    setIsPaymentModalOpen(true);

    if (!paymentMethods.length) {
      dispatch(getPaymentMethods());
    }
  }, []);

  useEffect(() => {
    if (getPaymentMethodsPhase === SUCCESS) {
      dispatch(resetPaymentPhases());
      setSelectedPaymentMethodId(
        paymentMethods.find(method => method.isDefault)?.id,
      );
    }
  }, [getPaymentMethodsPhase]);

  return (
    <>
      <PaymentModal
        isLoading={isLoading}
        onAddCard={handleAddCard}
        onChangeCard={handleChangeCard}
        bookingPrice={booking.cost}
        paymentMethodId={selectedPaymentMethodId}
        creditPackageId={creditPackageId}
        onClose={handleClose}
        isOpen={isPaymentModalOpen}
        onPayNow={handlePayNow}
      />
      <ChangeCardModal
        onCardSelect={handleCardSelect}
        onAddCard={handleAddCard}
        onGoBack={handleGoBackFromChangeCard}
        isOpen={isChangeModalOpen}
        onClose={handleClose}
        selectedPaymentMethodId={selectedPaymentMethodId}
      />
      {isAddModalOpen && (
        <AddCardModal
          onError={handleAddCardError}
          hasBackButton
          onGoBack={handleGoBackFromAddCard}
          onClose={handleClose}
          isOpen={isAddModalOpen}
        />
      )}
      {!!errorMessage && (
        <ConfirmationModal
          icon={CreditsAdded}
          isOpen={!!errorMessage}
          onPrimaryButtonClick={handleErrorModalClose}
          title={errorMessage}
          text="Please go back to check your payment method details."
          primaryButtonText="Go back"
        />
      )}
    </>
  );
};

PayByCard.defaultProps = {
  creditPackageId: 0,
  booking: {},
  isEditing: false,
};

PayByCard.propTypes = {
  onProcessFinish: func.isRequired,
  creditPackageId: number,
  booking: exact({
    fromDateTime: string,
    toDateTime: string,
    meetingRoomId: number,
    title: string,
    companyId: number,
    cost: number,
  }),
  onClose: func.isRequired,
  isEditing: bool,
};

export default PayByCard;
