import { init as sentryInit, withScope, captureMessage } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SENTRY_DSN, ENV } from '@constants/common';

const URL = window.location.host;

export const init = () => {
  if (!URL.includes('localhost')) {
    sentryInit({
      dsn: SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      environment: ENV,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,
    });
  }
};

export const reportError = (error, tagKey, tagValue, extraInfo) => {
  withScope(scope => {
    if (tagKey && tagValue) {
      scope.setTag(tagKey, tagValue);
    }
    if (extraInfo) {
      scope.setExtras(extraInfo);
    }
    scope.setLevel('error');

    captureMessage(error);
  });
};
