import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { func } from 'prop-types';

import { Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { routes } from '@constants';
import { getCloudinaryImageLinkBySize } from '@helpers/common';
import { theme } from '@styles';

import { ReactComponent as Arrow } from '@assets/svgs/Arrow.svg';
import DefaultImage from '@assets/images/DefaultImage.png';
import Gif from '@assets/gifs/no-results.gif';

import { Container, Description, Title } from './styles';

const NeighborsList = ({ onClickLoadMore }) => {
  const history = useHistory();

  const { companies: { data, pagination } } = useSelector(store => (
    store.companyStore
  ));

  const handleCompanyClick = id => () => {
    history.push({
      pathname: `${routes.companyProfile}/${id}`,
      state: {
        from: routes.knowYourNeighbors,
      } });
  };

  const companiesBlock = useMemo(() => (
    data?.map(({ id, logoUrl, name, industry, description }) => (
      <Flex
        width="100%"
        minHeight={[130, 180, 210]}
        key={id}
        pointer
        border="1px solid #D9D9D9"
        onClick={handleCompanyClick(id)}
      >
        <Flex
          height="100%"
          minWidth={[130, 180, 210]}
          background={`url(${getCloudinaryImageLinkBySize(
            logoUrl, theme.sizes.longGrid,
          ) || DefaultImage
          }) no-repeat center`}
          backgroundSize="cover"
        />
        <Flex justifyContent="space-between" p={[20, 25, 30]} flexDirection="column">
          <Flex flexDirection="column">
            <Title lineHeight={1} mb={[10, 15]} variant="garamond-500" fontSize={[26, 30, 34]}>
              {name.replace(/\s/g, ' ')}
            </Title>
            <Typography lineHeight={1} mb={['7px', 10]} variant="proximaNova-600" fontSize={[12, 14, 16]}>
              {industry}
            </Typography>
            <Description variant="proximaNova-400" fontSize={[10, 12, 14]}>
              {description}
            </Description>
          </Flex>
          <Icon SVG={Arrow} width={[30, 40, 50]} color={theme.color.gray[500]} />
        </Flex>
      </Flex>
    ))
  ), [data]);

  return companiesBlock.length ? (
    <>
      <Container display={['flex', 'grid']} width="100%" flexWrap="wrap">
        {companiesBlock}
      </Container>
      {data.length < pagination.total && (
        <Typography
          pointer
          onClick={onClickLoadMore}
          mt={15}
          fontSize={[12, 14, 16]}
          alignSelf="start"
          color={theme.color.darkGreen}
          variant="proximaNova-400"
        >
          Load more
        </Typography>
      )}
    </>
  )
    : (
      <Flex width="100%" alignItems="center" flexDirection="column">
        <Flex as="img" mb={25} width={['225px', '310px']} height={['200px', '280px']} src={Gif} />
        <Typography mb={10} variant="garamond-500" fontSize={[28, 34, 40]}>
          No results found
        </Typography>
      </Flex>
    );
};

NeighborsList.propTypes = {
  onClickLoadMore: func.isRequired,
};

export default NeighborsList;
