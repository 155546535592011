import { common } from '@constants';

const setToken = token => localStorage.setItem(common.TOKEN, token);

const getToken = () => localStorage.getItem(common.TOKEN);

const setUserId = id => localStorage.setItem(common.USER_ID, id);

const getUserId = () => localStorage.getItem(common.USER_ID);

const setAmplifyAuthUser = user =>
  localStorage.setItem(common.AMPLIFY_AUTH_USER, user);

const getAmplifyAuthUser = () => localStorage.getItem(common.AMPLIFY_AUTH_USER);

const removeToken = () => localStorage.removeItem(common.TOKEN);

const getFromLocalStorage = key => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    // Ignore
  }

  return null;
};

const removeFromLocalStorage = key => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // Ignore
  }
};

const setToLocalStorage = (key, data) => {
  try {
    localStorage.setItem(
      key,
      typeof data === 'string' ? data : JSON.stringify(data),
    );
  } catch (e) {
    // Ignore
  }
};

const clear = () => localStorage.clear();

export default {
  setToken,
  getToken,
  removeToken,
  clear,
  setUserId,
  getUserId,
  setAmplifyAuthUser,
  getAmplifyAuthUser,
  getFromLocalStorage,
  removeFromLocalStorage,
  setToLocalStorage,
};
