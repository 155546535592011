import React, { useMemo, useState, useEffect } from 'react';
import { bool, func, number } from 'prop-types';
import { useSelector } from 'react-redux';

import { Modal, Icon } from '@atoms';
import { dayjs } from '@utils';
import { Flex, Typography, Button } from '@mixins';
import { ExpiredCardBrandsLogos, CardBrandsLogos } from '@constants/cards';
import { theme } from '@styles';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import { ReactComponent as Checkmark } from '@assets/svgs/Checkmark.svg';

import { PrevButton } from '../BenefitsCarousel/styles';
import { ScrollableContainer } from './styles';

const ChangeCardModal = ({ isOpen, onClose, onCardSelect, onGoBack, selectedPaymentMethodId, onAddCard }) => {
  const { paymentMethods } = useSelector(store => store.paymentStore);

  const [methodId, setMethodId] = useState(selectedPaymentMethodId);

  useEffect(() => {
    setMethodId(selectedPaymentMethodId);
  }, [selectedPaymentMethodId]);

  const cards = useMemo(() => paymentMethods.map(({ id, brand, last4Digits, expirationDate }) => {
    const isExpired = dayjs(expirationDate) < dayjs();

    return (
      <Flex
        key={id}
        p={[15, 20]}
        mb={[15, 20]}
        width="100%"
        pointer={!isExpired}
        {...(!isExpired && { onClick: onCardSelect(id) })}
        border="1px solid #EFECE6"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center">
          <Icon
            mr={[20, 30]}
            SVG={isExpired ? ExpiredCardBrandsLogos[brand] : CardBrandsLogos[brand]}
          />
          <Typography
            color={isExpired ? '#8A8A8A' : theme.color.gray[500]}
            fontSize={[14, 20]}
            variant="proximaNova-600"
          >
            {`${brand[0].toUpperCase()}${brand.slice(1)} ···· ${last4Digits}`}
          </Typography>
        </Flex>
        <Flex alignItems="center">
          {isExpired && (
            <Typography mr={[15, 20]} color={theme.color.red} variant="proximaNova-400">
              Expired
            </Typography>
          )}
          {methodId === id && <Icon width={16} height={12} SVG={Checkmark} />}
        </Flex>
      </Flex>
    );
  }), [paymentMethods, methodId]);

  return (
    <Modal onClose={onClose} topPosition={100} isOpen={isOpen} withPadding={false}>
      <Flex
        position="relative"
        alignItems="start"
        width={[380, 560]}
        flexDirection="column"
        p={[25, 40]}
        pr={[10, 20]}
        pt={[20, 35]}
      >
        <PrevButton
          pointer
          mb={[20, 35]}
          onClick={onGoBack}
          color={theme.color.black}
          SVG={Dropdown}
        />
        <Typography mb={25} variant="proximaNova-600" fontSize={[16, 18, 20]}>
          Change Card
        </Typography>
        <ScrollableContainer
          pr={20}
          maxHeight={400}
          overflowY="scroll"
          width="100%"
          flexDirection="column"
        >
          {cards}
          <Button mt={50} alignSelf="center" onClick={onAddCard} variant="primary" py={12} px={60}>
            + Add credit card
          </Button>
        </ScrollableContainer>
      </Flex>
    </Modal>
  );
};

ChangeCardModal.defaultProps = {
  selectedPaymentMethodId: 0,
};

ChangeCardModal.propTypes = {
  onAddCard: func.isRequired,
  selectedPaymentMethodId: number,
  onCardSelect: func.isRequired,
  onGoBack: func.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default ChangeCardModal;
