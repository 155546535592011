import styled, { css } from 'styled-components';
import Pattern from '@assets/svgs/Pattern.svg';

import { theme } from '@styles';
import { Flex } from '@mixins';

export const ScrollableContainer = styled(Flex)`
  ${() => css`
    overflow-x: scroll;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:horizontal {
      height: 6px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: #EDEDED;
    }
  `}
`;

export const Track = styled(Flex)`
  z-index: 1;
  min-width: 1920px;
  left: 6px;
  bottom: 45%;
`;

export const Handle = styled(Flex)`
  ${({ value }) => css`
    display: ${value === -1 ? 'none' : 'block'};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${theme.color.white};
    border: 1px solid ${theme.color.darkGreen};
    cursor: pointer;
  `}
`;

export const getBackground = (availableTimes, time, timeIndex, endTime, startTime, times) => {
  const selectedTimeline = times.slice(startTime, endTime);

  const isSelectedTimelineAvailable = selectedTimeline.every(timeline => availableTimes.includes(timeline));

  switch (true) {
    case timeIndex < endTime &&
      timeIndex >= startTime &&
      !isSelectedTimelineAvailable &&
      !availableTimes.includes(time):
      return `linear-gradient(0deg, rgba(8, 8, 8, 0.2), rgba(8, 8, 8, 0.2)), url(${Pattern})`;
    case timeIndex < endTime && timeIndex >= startTime && !isSelectedTimelineAvailable:
      return 'rgba(8, 8, 8, 0.2)';
    case !availableTimes.includes(time):
      return `url(${Pattern})`;
    case timeIndex < endTime && timeIndex >= startTime:
      return theme.color.darkGreen;
    default:
      return theme.color.white;
  }
};

export const getBookingBorderRadius = (isBookingStart, isBookingEnd) => {
  if (isBookingStart && isBookingEnd) {
    return '19px';
  }
  if (isBookingStart) {
    return '19px 0px 0px 19px';
  }
  if (isBookingEnd) {
    return '0px 19px 19px 0px';
  }
  return '0px';
};

export const getBookingJustifyContent = (isBookingStart, isBookingEnd) => {
  if (isBookingStart && isBookingEnd) {
    return 'center';
  }
  if (isBookingStart) {
    return 'end';
  }
  return 'start';
};
