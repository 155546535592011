import React, { useMemo } from 'react';
import { arrayOf, string, number, exact, func } from 'prop-types';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';

const RadioButtonGroup = ({ onOptionChoose, optionList, selectedOption, ...props }) => {
  const options = useMemo(() => optionList.map((option, index) => (
    <Flex
      key={option.id}
      width="fit-content"
      onClick={onOptionChoose(option.id)}
      pointer
      {...(index !== optionList.length - 1 && { mb: '10px' })}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        border={`1px solid ${selectedOption === option.id ? '#262424' : theme.color.gray[300]}`}
        width="18px"
        height="18px"
        borderRadius="50%"
      >
        {selectedOption === option.id && (
          <Flex backgroundColor={theme.color.darkGreen} width="10px" height="10px" borderRadius="50%" />
        )}
      </Flex>
      <Typography ml="10px" variant="proximaNova-400" fontSize="14px" color={theme.color.gray[500]}>
        {option.label}
      </Typography>
    </Flex>
  )), [selectedOption, optionList]);
  return (
    <Flex {...props} flexDirection="column">
      {options}
    </Flex>
  );
};

RadioButtonGroup.propTypes = {
  onOptionChoose: func.isRequired,
  selectedOption: number.isRequired,
  optionList: arrayOf(exact({
    id: number,
    label: string,
  })).isRequired,
};

export default RadioButtonGroup;
