import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CompanyMembersApp as View } from '@views';
import { getAdminCompanyById, resetPhases } from '@store/company/duck';
import { SUCCESS } from '@constants/requestPhase';
import { trackEvent } from '@utils/mixpanel';

const CompanyMembersApp = () => {
  const { user } = useSelector(store => store.userStore);
  const { updateCompanyMemberPhase, createCompanyMemberPhase,
    adminCompanyById, companyMembersByAccessId: { data, pagination } } = useSelector(store => (
    store.companyStore
  ));

  const dispatch = useDispatch();

  useEffect(() => {
    trackEvent('Page Viewed', {
      Page: 'Company Members',
    });
  }, []);

  useEffect(() => {
    if ((!adminCompanyById?.activeMembers && user.company)
      || updateCompanyMemberPhase === SUCCESS || createCompanyMemberPhase === SUCCESS) {
      dispatch(resetPhases());
      dispatch(getAdminCompanyById(user.company.id));
    }
  }, [adminCompanyById?.name, user.company, updateCompanyMemberPhase, createCompanyMemberPhase]);

  return (
    <View
      activeMembers={adminCompanyById?.activeMembers}
      companyStatus={adminCompanyById?.status}
      companyName={adminCompanyById?.name}
      companyMembers={data}
      pagination={pagination}
    />
  );
};

export default CompanyMembersApp;
