import { apiEndpoints } from '@constants';
import { client } from '@utils';

const getMetadata = () => (
  client.authorized.get(apiEndpoints.metadata)
);

const getMetadataNavigation = id => (
  client.authorized.get(`${apiEndpoints.metadataNavigation}/${id}`)
);

export {
  getMetadata,
  getMetadataNavigation,
};

