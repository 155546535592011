import { apiEndpoints } from '@constants';
import { client } from '@utils';

const getAnnouncements = locationId => (
  client.authorized.get(apiEndpoints.announcement, {
    params: {
      locationId,
    },
  })
);

export {
  getAnnouncements,
};

