import React, { useState } from 'react';
import { string, number, exact } from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon } from '@atoms';
import { WifiModal } from '@molecules';
import { routes } from '@constants';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { ReactComponent as Printer } from '@assets/svgs/Printer.svg';
import { ReactComponent as Wifi } from '@assets/svgs/Wifi.svg';
import { ReactComponent as Address } from '@assets/svgs/Address.svg';

const Services = ({
  longitude,
  latitude,
  address,
  wifiSettings,
}) => {
  const history = useHistory();
  const { currentLocation: { details } } = useSelector(store => (
    store.locationStore
  ));

  const [isWifiModalOpen, setIsWifiModalOpen] = useState(false);

  const onWifiClick = () => {
    setIsWifiModalOpen(true);
  };

  const onModalClose = () => {
    setIsWifiModalOpen(false);
  };

  const onPrinterClick = () => {
    history.push(routes.printingDetails);
  };

  const onAddressClick = () => {
    window.open(`https://www.google.com/maps/place/${address}/@${latitude},${longitude},${18}z`);
  };

  const services = [
    {
      id: 1,
      icon: Wifi,
      title: 'WiFi',
      text: 'One always needs the Wifi Details.',
      handler: onWifiClick,
      isShown: wifiSettings.guests || wifiSettings.members,
    },
    {
      id: 2,
      icon: Printer,
      title: 'Printing',
      text: 'Using printers in your building. Easy.',
      handler: onPrinterClick,
      isShown: details?.printerInstructionsPC || details?.printerInstructionsMAC
      || details?.securePrinting || details?.scanningInstructions || details?.faxNumbers,
    },
  ];

  const servicesBlock = services.reduce((acc, { id, icon, title, text, handler, isShown }, index) => {
    if (isShown) {
      acc.push(
        <Flex
          key={id}
          onClick={handler}
          pointer
          border={`1px solid ${theme.color.gray[100]}`}
          px={[14, 20]}
          py={[10, 16]}
          justifyContent="center"
          alignItems="center"
          mb={!index && services.every(service => service.isShown) ? 20 : 0}
        >
          <Icon SVG={icon} width={15} height={15} />
          <Flex ml={10} flexDirection="column">
            <Typography color={theme.color.white} mb="6px" variant="proximaNova-600" fontSize={[12, 16]}>
              {title}
            </Typography>
            <Typography color={theme.color.gray[100]} variant="proximaNova-400" fontSize={[10, 14]}>
              {text}
            </Typography>
          </Flex>
        </Flex>,
      );
    }

    return acc;
  }, []);

  return (
    <>
      {(wifiSettings.guests || wifiSettings.members) && (
        <WifiModal
          onModalClose={onModalClose}
          isOpen={isWifiModalOpen}
          membersNetwork={wifiSettings.members.network}
          membersPassword={wifiSettings.members.password}
          guestsNetwork={wifiSettings.guests.network}
          guestsPassword={wifiSettings.guests.password}
        />
      )}
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        backgroundColor={theme.color.darkGreen}
        py="60px"
      >
        <Typography
          color={theme.color.white}
          mb={[40, 50]}
          variant="garamond-500"
          fontSize={['40px', '52px']}
        >
          This is Handy
        </Typography>
        <Flex>
          <Flex flexDirection="column">
            {servicesBlock}
          </Flex>
          {address && (
            <Flex
              flexDirection="column"
              pl={[20, 32]}
              justifyContent="center"
              alignItems="start"
              ml={[16, 26]}
              borderLeft={`1px solid ${theme.color.gray[100]}`}
            >
              <Flex alignItems="center" mb="10px">
                <Icon SVG={Address} />
                <Typography
                  color={theme.color.white}
                  ml={['6px', '10px']}
                  variant="proximaNova-600"
                  fontSize={['12px', '16px']}
                >
                  Address
                </Typography>
              </Flex>
              <Typography
                color={theme.color.gray[100]}
                maxWidth={[100, 200]}
                pointer
                onClick={onAddressClick}
                variant="proximaNova-400"
                fontSize={['10px', '14px']}
              >
                {address}
              </Typography>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

Services.defaultProps = {
  address: '',
  longitude: 0,
  latitude: 0,
  wifiSettings: {},
};

Services.propTypes = {
  address: string,
  longitude: number,
  latitude: number,
  wifiSettings: exact({
    members: exact({
      network: string,
      password: string,
    }),
    guests: exact({
      network: string,
      password: string,
    }),
  }),
};

export default Services;
