import React, { useEffect } from 'react';
import { bool, node, string } from 'prop-types';
import { Route, useHistory } from 'react-router-dom';
import { routes } from '@constants';
import { storage } from '@utils';

const UnauthorizedRoute = ({ exact, path, children }) => {
  const history = useHistory();
  const token = storage.getToken();

  useEffect(() => {
    if (token) {
      history.push(routes.home);
    }
  }, []);

  return (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  );
};

UnauthorizedRoute.defaultProps = {
  exact: false,
};

UnauthorizedRoute.propTypes = {
  exact: bool,
  path: string.isRequired,
  children: node.isRequired,
};

export default UnauthorizedRoute;
