import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FlexWithNoGradientBackground } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { formatTime } from '@helpers/time';
import { getRSVPButtonLabel, getCloudinaryImageLinkBySize } from '@helpers/common';
import { dayjs } from '@utils';
import { routes } from '@constants';
import { theme } from '@styles';

import Gif from '@assets/gifs/no-events.gif';

import { Container } from '../BenefitsGrid/styles';

const IMAGE_WIDTH = 492;

const EventsGrid = () => {
  const history = useHistory();

  const { currentLocation } = useSelector(store => (
    store.locationStore
  ));

  const { events } = useSelector(store => (
    store.eventStore
  ));

  const { eventsNearby } = useSelector(store => (
    store.eventStore
  ));

  const handleEventClick = id => () => {
    history.push({ pathname: `${routes.events}/${id}`, from: routes.events });
  };

  const handleRSVPClick = (id, shouldOpenModal) => e => {
    e.stopPropagation();
    history.push({ pathname: `${routes.events}/${id}`, state: { shouldOpenModal, from: routes.events } });
  };

  const upcomingEvent = useMemo(() => events.filter(event => event.locationId === currentLocation.id)?.[0],
    [currentLocation.id, events]);

  const isToday = upcomingEvent && dayjs(`${upcomingEvent.upcomingDate}T${upcomingEvent.startTime}.000Z`)
    .$d.toString().substring(0, 15) === dayjs().$d.toString().substring(0, 15);

  const startDate = upcomingEvent ? `${upcomingEvent.upcomingDate}T${upcomingEvent.startTime}.000Z` : 0;
  const endDate = upcomingEvent ? `${upcomingEvent.upcomingDate}T${upcomingEvent.endTime}.000Z` : 0;

  const renderEventsListBlock = useCallback(eventsList =>
    eventsList.filter(event => event.id !== upcomingEvent?.id).map(({
      id, imageUrl, name, location, upcomingDate, startTime, endTime, hasSlots, isRecurring, bookingId,
    }) => {
      const startDateEvent = `${upcomingDate}T${startTime}.000Z`;
      const endDateEvent = `${upcomingDate}T${endTime}.000Z`;

      return (
        <Flex
          pointer
          onClick={handleEventClick(id)}
          key={id}
          flexDirection="column"
          alignItems="start"
        >
          <FlexWithNoGradientBackground
            width="100%"
            height={215}
            imageUrl={getCloudinaryImageLinkBySize(imageUrl, theme.sizes.shortGrid)}
          />
          <Flex
            flexGrow={1}
            borderRadius="0px 0px 2px 2px"
            backgroundColor="#EFECE6"
            width="100%"
            p={[15, 20, 25]}
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography mb={15} variant="garamond-500" fontSize={[14, 16, 18, 20]}>
              {name}
            </Typography>
            <Flex flexDirection="column">
              <Typography mb="5px" variant="proximaNova-600" fontSize={[10, 12, 14]}>
                {`${dayjs(startDateEvent).format('ddd')}, ${dayjs(startDateEvent).format('MMMM')}
            ${dayjs(startDateEvent).$D}, ${formatTime(
          startDateEvent, currentLocation.timeFormat,
        )}-${formatTime(endDateEvent, currentLocation.timeFormat)}`}
              </Typography>
              <Typography mb={[20, 25]} variant="proximaNova-400" fontSize={[10, 12, 14]}>
                {location}
              </Typography>
              <Flex width="100%" justifyContent="space-between" alignItems="end">
                <Button
                  onClick={handleRSVPClick(id, hasSlots || isRecurring)}
                  width="fit-content"
                  variant="primary"
                  px={[25, 30, 35]}
                  py="8px"
                >
                  {getRSVPButtonLabel(!!bookingId, hasSlots, isRecurring, 'available')}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      );
    }), [events, upcomingEvent]);

  return (
    <Flex width="100%" flexDirection="column">
      <Typography alignSelf="start" variant="garamond-500" fontSize={[20, 34]} mb={[20, 30]}>
        At
        {' '}
        {currentLocation.title}
      </Typography>
      {events.length
        ? (
          <>
            {upcomingEvent && (
              <Flex
                mb={40}
                onClick={handleEventClick(upcomingEvent.id)}
                pointer
                maxWidth={985}
                height="100%"
                boxShadow="0px 8px 30px rgba(80, 85, 136, 0.06)"
                border="1px solid #EFECE6"
              >
                <FlexWithNoGradientBackground
                  width={['40%', '40%', '40%', '50%']}
                  minHeight="100%"
                  imageUrl={getCloudinaryImageLinkBySize(
                    upcomingEvent.imageUrl, IMAGE_WIDTH,
                  )}
                />
                <Flex
                  alignItems="center"
                  px={[25, 30, 30, 55]}
                  py={[20, 20, 30, 40]}
                  width="50%"
                >
                  <Flex flexDirection="column">
                    <Typography
                      width="fit-content"
                      backgroundColor="#EFECE6"
                      p="5px"
                      mb={10}
                      variant="proximaNova-600"
                      fontSize={[10, 13]}
                    >
                      {isToday ? 'TODAY' : 'UPCOMING'}
                    </Typography>
                    <Typography
                      lineHeight={1.2}
                      mb={[10, 15]}
                      variant="garamond-500"
                      fontSize={[22, 26, 30, 34]}
                    >
                      {upcomingEvent.name}
                    </Typography>
                    <Typography mb="5px" variant="proximaNova-600" fontSize={[12, 14, 16]}>
                      {`${dayjs(startDate).format('ddd')}, ${dayjs(startDate).format('MMMM')} 
                      ${dayjs(startDate).$D}, ${formatTime(
                startDate, currentLocation.timeFormat,
              )}-${formatTime(endDate, currentLocation.timeFormat)}`}
                    </Typography>
                    <Typography mb={[20, 25]} variant="proximaNova-400" fontSize={[12, 14, 16]}>
                      {upcomingEvent.location}
                    </Typography>
                    <Button width="fit-content" variant="primary" px={[25, 30, 35]} py="8px">
                      {getRSVPButtonLabel(
                        !!upcomingEvent.bookingId,
                        upcomingEvent.hasSlots,
                        upcomingEvent.isRecurring,
                        'available',
                      )}
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            )}
            <Container>{renderEventsListBlock(events)}</Container>
          </>
        )
        : (
          <Flex width="100%" alignItems="center" flexDirection="column">
            <Flex as="img" mb={[20, 40]} width={['220px', '320px']} height={['220px', '320px']} src={Gif} />
            <Typography mb={10} variant="garamond-500" fontSize={[30, 40, 50]}>
              Take a nap...
            </Typography>
            <Typography variant="proximaNova-400" fontSize={[13, 17, 21]}>
              No events scheduled
            </Typography>
          </Flex>
        )}
      {!!eventsNearby?.length && !currentLocation.isWhiteLabeled && (
        <>
          <Typography alignSelf="start" variant="garamond-500" fontSize={[20, 34]} my={[20, 30]}>
            At Nearby Locations
          </Typography>
          <Container>{renderEventsListBlock(eventsNearby)}</Container>
        </>
      )}
    </Flex>
  );
};

export default EventsGrid;
