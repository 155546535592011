import React, { useRef, useState } from 'react';
import { func, bool, string } from 'prop-types';
import AvatarEditor from 'react-avatar-editor';

import { Modal, RangeSlider } from '@atoms';
import Input from '@atoms/Input/styles';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';

const PhotoScalingModal = ({ isOpen, onModalClose, onScalingDone, image, onImageUpload }) => {
  const editor = useRef(null);

  const [scale, setScale] = useState(1);

  const editorState = {
    image,
    allowZoomOut: false,
    scale,
    rotate: 0,
    border: 0,
    borderRadius: 50,
    preview: undefined,
    width: 200,
    height: 200,
    disableCanvasRotation: true,
    isTransparent: true,
    color: [255, 255, 255, 0.3],
  };

  const handleScale = e => {
    const newScale = parseFloat(e.target.value);
    setScale(newScale);
  };

  const handleModalClose = () => {
    setScale(1);
    onModalClose();
  };

  const handleScalingDone = async () => {
    const dataUrl = editor?.current?.getImage().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();

    onScalingDone(blob);

    setScale(1);
  };

  const fileRef = useRef(null);

  const onButtonClick = () => {
    fileRef.current.click();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <Flex
        pt={10}
        pb={20}
        px={[50, 75, 100]}
        flexDirection="column"
        alignItems="center"
      >
        <Typography mb={[10, 15, 20]} variant="proximaNova-600" fontSize={[12, 16, 20]}>
          Scale
        </Typography>
        <AvatarEditor
          ref={editor}
          scale={editorState.scale}
          width={editorState.width}
          height={editorState.height}
          position={editorState.position}
          rotate={editorState.rotate}
          borderRadius={
            editorState.width / (100 / editorState.borderRadius)
          }
          border={editorState.border}
          color={editorState.color}
          image={editorState.image}
          disableCanvasRotation={editorState.disableCanvasRotation}
        />
        <Flex color={theme.color.darkGreen} my={30} alignItems="center" width={[200, 250, 400]}>
          -
          <RangeSlider
            mx="7px"
            type="range"
            height="4px"
            onChange={handleScale}
            min={1}
            max={2}
            step={0.01}
            defaultValue={1}
          />
          +
        </Flex>
        <Button
          width={[150, 215]}
          py={['8px', 10, 12]}
          variant="primary"
          fontSize={[12, 14, 16]}
          onClick={handleScalingDone}
          mb={18}
        >
          Perfect!
        </Button>
        <Button
          width={[150, 215]}
          backgroundColor="#EFECE6"
          onClick={onButtonClick}
          py={['8px', 10, 12]}
          fontSize={[12, 14, 16]}
        >
          Try another picture
        </Button>
        <Input
          accept=".png, .jpg, .gif"
          display="none"
          ref={fileRef}
          type="file"
          onChange={onImageUpload}
        />
      </Flex>
    </Modal>
  );
};

PhotoScalingModal.defaultProps = {
  image: '',
};

PhotoScalingModal.propTypes = {
  onImageUpload: func.isRequired,
  isOpen: bool.isRequired,
  onModalClose: func.isRequired,
  onScalingDone: func.isRequired,
  image: string,
};

export default PhotoScalingModal;
