import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { BenefitDetails as View } from '@views';
import { routes } from '@constants';
import { trackEvent } from '@utils/mixpanel';
import { LOADING, FAILURE } from '@constants/requestPhase';
import { getBenefitById, resetPhases } from '@store/benefit/duck';

import { ReactComponent as Instagram } from '@assets/svgs/InstagramProfile.svg';
import { ReactComponent as Facebook } from '@assets/svgs/FacebookProfile.svg';
import { ReactComponent as Phone } from '@assets/svgs/Phone.svg';
import { ReactComponent as Website } from '@assets/svgs/Website.svg';

const BenefitDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { benefitById, getBenefitByIdPhase } = useSelector(store => (
    store.benefitStore
  ));

  useEffect(() => {
    if (benefitById?.title) {
      const pageTitle = `Mindspace: Benefit ${benefitById.title}`;
      document.title = pageTitle;

      window.gtag('event', 'page_view', {
        page_title: pageTitle,
      });

      trackEvent('Page Viewed', {
        Page: 'Benefit',
        'Benefit Name': benefitById?.title,
      });
    }
  }, [benefitById]);

  const handleBackClick = useCallback(() => {
    history.push({
      pathname: routes.benefits,
      state: {
        selectedCategory: location.state?.selectedCategory,
      },
    });
  }, []);

  useEffect(() => {
    dispatch(getBenefitById(id));
  }, [id]);

  useEffect(() => {
    if (getBenefitByIdPhase === FAILURE) {
      dispatch(resetPhases());
      history.goBack();
    }
  }, [getBenefitByIdPhase]);

  const contactDetails = [
    {
      content: benefitById.website,
      link: benefitById.website?.includes('https://')
        ? benefitById.website : `https://${benefitById.website}`,
      icon: Website,
    },
    {
      content: benefitById.instagram,
      link: benefitById.instagram?.includes('https://')
        ? benefitById.instagram : `https://${benefitById.instagram}`,
      icon: Instagram,
    },
    {
      content: benefitById.facebook,
      link: benefitById.facebook?.includes('https://')
        ? benefitById.facebook : `https://${benefitById.facebook}`,
      icon: Facebook,
    },
    {
      content: benefitById.phone,
      link: `tel:${benefitById.phone}`,
      icon: Phone,
    },
  ];

  return (
    <View
      onBackClick={handleBackClick}
      isLoading={getBenefitByIdPhase === LOADING}
      couponDescription={benefitById.couponDescription}
      coupon={benefitById.couponCode}
      title={benefitById.title}
      discountText={benefitById.tagline}
      description={benefitById.description}
      image={benefitById.image}
      contactDetails={contactDetails}
      latitude={benefitById.latitude}
      longitude={benefitById.longitude}
      place={benefitById.place?.city}
      address={benefitById.address}
    />
  );
};

export default BenefitDetails;
