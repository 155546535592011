import styled, { css } from 'styled-components';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';

export const ScrollableContainer = styled(Flex)`
${() => css`
    flex-direction: column;
    overflow-y: scroll;
    padding-right: 4px;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: ${theme.color.gray[100]};
    }
  `}
`;

export const ItemBlock = styled(Flex)`
  ${() => css`
    &:hover {
      background-color: #ECE2D4;
    }
  `}
`;

export const ItemTitle = styled(Typography)`
  ${() => css`
    &:hover {
      font-weight: 600;
    }
  `}
`;

export const StyledNavBar = styled(Flex)`
  ${({ isExpanded }) => css`
    opacity: ${isExpanded ? '1' : '0'};
    transition: all .2s;
    visibility: ${isExpanded ? 'visible' : 'hidden'};
  `}
`;
