import React from 'react';
import { func, bool } from 'prop-types';

import { Modal } from '@atoms';
import { StripeForm } from '@molecules';
import { withStripe } from '@hocs';
import { theme } from '@styles';
import { Flex, Typography } from '@mixins';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { PrevButton } from '@molecules/BenefitsCarousel/styles';

const AddCardModal = ({ isOpen, onClose, onError, hasBackButton, onGoBack }) => (
  <Modal onClose={onClose} withPadding={false} isOpen={isOpen}>
    <Flex flexDirection="column" width={[380, 540]} pl={[0, 30]} pr={[0, 30]} pt={50}>
      {hasBackButton
        ? (
          <>
            <PrevButton
              pointer
              mb={[15, 20]}
              onClick={onGoBack}
              color={theme.color.black}
              SVG={Dropdown}
            />
            <Typography mb={30} variant="proximaNova-600" fontSize={[16, 18, 20]}>
              Add Credit Card
            </Typography>
          </>
        )
        : (
          <Typography alignSelf="center" mb={35} variant="garamond-500" fontSize={36}>
            Add Credit Card
          </Typography>
        )}
      <StripeForm onError={onError} />
    </Flex>
  </Modal>
);

AddCardModal.defaultProps = {
  hasBackButton: false,
  onGoBack: () => {},
};

AddCardModal.propTypes = {
  hasBackButton: bool,
  onGoBack: func,
  onError: func.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default withStripe(AddCardModal);
