/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { string, bool, exact, arrayOf, number } from 'prop-types';

import {
  ButtonsGrid,
  UpcomingEvent,
  WhiteLabeling,
  FlexWithGradientBackground,
  Announcement,
} from '@atoms';
import { HomeGrid, LocationTeam, Services, BookedRooms, ReferFriend, DiscountModal } from '@molecules';
import { GuestInvitation } from '@organisms';
import { storage } from '@utils';
import { Main } from '@templates';
import { theme } from '@styles';
import { Typography, Flex } from '@mixins';
import { IS_DISCOUNT_DISPLAYED_KEY } from '@constants/common';
import { getCloudinaryImageLinkBySize } from '@helpers/common';

import TestLocation from '@assets/images/TestLocation.jpg';
import TestLogo from '@assets/images/TestLogo.png';

const IMAGE_WIDTH = 564;
const BIG_IMAGE_WIDTH = 1680;

const Home = ({
  isLoading,
  firstName,
  location,
  upcomingEvent,
  locationTeam,
  locationLongitude,
  locationLatitude,
  locationAddress,
  locationImage,
  wifiSettings,
  locationLogo,
  isWhiteLabelingOpen,
  announcement,
  headerAnnouncement,
  parsedHeaderAnnouncementSavedLocally,
  isDiscountAvailable,
}) => {
  const [isInvitationOpen, setIsInvitationOpen] = useState(false);
  const [isDiscountOpen, setIsDiscountOpen] = useState(isDiscountAvailable);

  useEffect(() => {
    setIsDiscountOpen(isDiscountAvailable);
  }, [isDiscountAvailable]);

  const { bookedRooms } = useSelector(store => store.bookingStore);

  const handleInviteGuestsModalChangeState = useCallback(() => {
    setIsInvitationOpen(prevValue => !prevValue);
  }, []);

  const handleDiscountModalClose = () => {
    setIsDiscountOpen(false);
    storage.setToLocalStorage(IS_DISCOUNT_DISPLAYED_KEY, true);
  };

  return (
    <>
      <WhiteLabeling
        location={location}
        isOpen={isWhiteLabelingOpen}
        image={getCloudinaryImageLinkBySize(locationImage, BIG_IMAGE_WIDTH)}
        logo={locationLogo}
      />
      <DiscountModal onModalClose={handleDiscountModalClose} isOpen={isDiscountOpen} />
      <Main
        withPaddingX={false}
        isWhiteLabelingOpen={isWhiteLabelingOpen}
        isLoading={isLoading}
        afterHeaderContent={headerAnnouncement?.content}
        afterHeaderContentId={headerAnnouncement?.id}
        parsedHeaderAnnouncementSavedLocally={parsedHeaderAnnouncementSavedLocally}
      >
        <Flex
          width="100%"
          alignItems="center"
          maxWidth={1440}
          px={[20, 30, 40, 60]}
          flexDirection="column"
        >
          {!!bookedRooms.length && <BookedRooms rooms={bookedRooms} />}
          <Flex
            flexDirection={['column', 'column', 'column', 'row']}
            mb={20}
            width="100%"
            alignItems="start"
            justifyContent="space-between"
          >
            <Typography
              lineHeight={1.25}
              variant="garamond-500"
              fontSize={[30, 34]}
            >
              Hey
              <br />
              {firstName}!
            </Typography>
            <Flex py={18} alignSelf={['start', 'start', 'start', 'end']}>
              <ButtonsGrid
                onInviteGuests={handleInviteGuestsModalChangeState}
              />
            </Flex>
          </Flex>
          <Flex
            justifyContent="space-between"
            minHeight={['300px', '400px', '540px']}
            width="100%"
            mb={80}
          >
            {upcomingEvent ? (
              <UpcomingEvent eventDetails={upcomingEvent} />
            ) : (
              <FlexWithGradientBackground
                pointer
                mr={['4px', '7px']}
                imageUrl={getCloudinaryImageLinkBySize(
                  locationImage,
                  IMAGE_WIDTH,
                )}
                width="50%"
                minHeight="100%"
                flexDirection="column"
                p={[15, 20, 35]}
                justifyContent="end"
              >
                <Typography
                  color={theme.color.white}
                  variant="garamond-500"
                  fontSize={['20px', '30px', '40px']}
                >
                  {`Welcome to ${location}`}
                </Typography>
              </FlexWithGradientBackground>
            )}
            <HomeGrid />
          </Flex>
          {announcement && (
            <Flex mb={80} width="100%">
              <Announcement
                title={announcement.title}
                content={announcement.content}
                url={announcement.url}
              />
            </Flex>
          )}
          {!!locationTeam.length && (
            <LocationTeam team={locationTeam} locationName={location} />
          )}
          <ReferFriend />
        </Flex>
        <Services
          address={locationAddress}
          latitude={locationLatitude}
          longitude={locationLongitude}
          wifiSettings={wifiSettings}
        />
        {isInvitationOpen && (
          <GuestInvitation
            onClose={handleInviteGuestsModalChangeState}
            isOpen={isInvitationOpen}
          />
        )}
      </Main>
    </>
  );
};

Home.defaultProps = {
  isDiscountAvailable: false,
  isWhiteLabelingOpen: false,
  announcement: {},
  firstName: '',
  location: '',
  locationLatitude: 0,
  locationLongitude: 0,
  locationAddress: '',
  locationLogo: TestLogo,
  locationImage: TestLocation,
  upcomingEvent: {},
  locationTeam: [],
  wifiSettings: {},
  headerAnnouncement: {},
  parsedHeaderAnnouncementSavedLocally: {},
};

Home.propTypes = {
  isDiscountAvailable: bool,
  isLoading: bool.isRequired,
  firstName: string,
  location: string,
  upcomingEvent: exact({
    id: number,
    name: string,
    description: string,
    hasSlots: bool,
    locationDescription: string,
    upcomingId: number,
    upcomingDate: string,
    startTime: string,
    isRecurring: bool,
    endTime: string,
    location: string,
    imageUrl: string,
    locationId: number,
  }),
  locationTeam: arrayOf(
    exact({
      id: number,
      firstName: string,
      lastName: string,
      role: string,
      jobTitle: string,
      imageUrl: string,
    }),
  ),
  locationLatitude: number,
  locationLongitude: number,
  locationAddress: string,
  wifiSettings: exact({
    members: exact({
      network: string,
      password: string,
    }),
    guests: exact({
      network: string,
      password: string,
    }),
  }),
  locationLogo: string,
  isWhiteLabelingOpen: bool,
  locationImage: string,
  announcement: exact({
    id: number,
    type: string,
    title: string,
    content: string,
    url: string,
  }),
  headerAnnouncement: exact({
    id: number,
    type: string,
    title: string,
    content: string,
    url: string,
  }),
  parsedHeaderAnnouncementSavedLocally: exact({
    id: number || null,
    locationId: number || null,
  }) || {},
};

export default Home;
