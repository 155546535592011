import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import { Login as View } from '@views';
import { routes } from '@constants';
import COUNTRIES from '@constants/countries';
import { validateEmail } from '@helpers/common';
import { getAmplifyConfig } from '@utils/amplify';
import { login } from '@store/user/duck';
import { trackEvent } from '@utils/mixpanel';

const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(0);
  const [emailValue, setEmailValue] = useState('');
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('IL');

  useEffect(() => {
    const pageTitle = 'Mindspace: Welcome';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Welcome',
    });
  }, []);

  useEffect(() => {
    const webClientId = [
      process.env.REACT_APP_AWS_EMAIL_CLIENT_ID,
      process.env.REACT_APP_AWS_PHONE_CLIENT_ID,
    ][activeTab];

    Auth.configure(getAmplifyConfig(webClientId));
  }, [activeTab]);

  const redirect = new URLSearchParams(location.search).get('redirect') || location.state?.redirect;

  const handleTabClick = index => () => {
    if (index !== activeTab) {
      setActiveTab(index);
      setError('');
      setEmailValue('');
      setPhoneNumberValue('');
      setSelectedCountry('IL');
    }
  };

  const handleFormSubmit = async () => {
    let isValid = true;
    if (activeTab) {
      isValid = isValidPhoneNumber(phoneNumberValue, selectedCountry);
      if (!isValid) {
        setError('*Hmm.. this is not a valid phone number');
      }
    } else {
      isValid = validateEmail(emailValue.trim());
      if (!isValid) {
        setError('*Hmm.. this is not a valid email');
      }
    }

    const apiCreds = activeTab
      ? parsePhoneNumber(`${COUNTRIES[selectedCountry]?.dialCode}${phoneNumberValue}`).number
      : emailValue.trim().toLowerCase();

    const formCreds = [emailValue, `${COUNTRIES[selectedCountry]?.dialCode} ${phoneNumberValue}`][activeTab];

    if (isValid) {
      try {
        setIsLoading(true);

        const cognitoUser = await Auth.signIn(apiCreds);

        dispatch(login({ cognitoUser }));

        setIsLoading(false);

        history.push({
          pathname: routes.verificationCode,
          state: { source: apiCreds, redirect },
        });
      } catch (err) {
        setIsLoading(false);
        history.push({ pathname: routes.loginFailure, state: { source: formCreds, redirect } });
      }
    }
  };

  const handleEmailChange = event => {
    setEmailValue(event.target.value);

    if (error) {
      setError('');
    }
  };

  const handleCountrySelect = country => {
    setSelectedCountry(country);
  };

  const handlePhoneNumberValueChange = phoneNumber => {
    setPhoneNumberValue(phoneNumber);
  };

  return (
    <View
      isLoading={isLoading}
      error={error}
      onTabClick={handleTabClick}
      onFormSubmit={handleFormSubmit}
      onEmailChange={handleEmailChange}
      activeTab={activeTab}
      selectedCountry={selectedCountry}
      onCountrySelect={handleCountrySelect}
      onPhoneNumberValueChange={handlePhoneNumberValueChange}
      phoneNumberValue={phoneNumberValue}
      emailValue={emailValue}
    />
  );
};

export default Login;
