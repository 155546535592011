import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { string, exact, number, bool } from 'prop-types';

import { FlexWithGradientBackground } from '@atoms';
import { Typography } from '@mixins';
import { getCloudinaryImageLinkBySize } from '@helpers/common';
import { formatTime } from '@helpers/time';
import { dayjs } from '@utils';
import { theme } from '@styles';
import { routes } from '@constants';

const IMAGE_WIDTH = 564;

const UpcomingEvent = ({ eventDetails }) => {
  const history = useHistory();

  const { currentLocation: { timeFormat } } = useSelector(store => store.locationStore);

  const handleEventClick = useCallback(() => {
    history.push({ pathname: `${routes.events}/${eventDetails?.id}`, state: { from: routes.home } });
  }, [eventDetails?.id]);

  const startDate = eventDetails?.upcomingDate
    ? `${eventDetails.upcomingDate}T${eventDetails.startTime}.000Z`
    : 0;

  const endDate = eventDetails?.upcomingDate
    ? `${eventDetails.upcomingDate}T${eventDetails.endTime}.000Z`
    : 0;

  const isToday = dayjs(startDate).$d.toString().substring(0, 15) === dayjs().$d.toString().substring(0, 15);

  return (
    <FlexWithGradientBackground
      pointer
      onClick={handleEventClick}
      mr={['4px', '7px']}
      imageUrl={getCloudinaryImageLinkBySize(
        eventDetails?.imageUrl, IMAGE_WIDTH,
      )}
      width="50%"
      minHeight="100%"
      flexDirection="column"
      p={[15, 20, 35]}
      justifyContent="end"
    >
      <Typography
        backgroundColor={theme.color.white}
        px={['8px', '10px']}
        py="5px"
        variant="proximaNova-600"
        fontSize={['10px', '12px']}
        borderRadius={2}
        width="max-content"
        mb="13px"
      >
        {isToday ? 'TODAY' : 'UPCOMING'}
      </Typography>
      <Typography
        mb="20px"
        color={theme.color.white}
        variant="garamond-500"
        fontSize={['20px', '30px', '38px']}
      >
        {eventDetails?.name}
      </Typography>
      <Typography mb="5px" color={theme.color.white} variant="proximaNova-600" fontSize={['12px', '16px']}>
        {`${dayjs(startDate).format('MMMM')} ${dayjs(startDate).$D}
        ${formatTime(startDate, timeFormat)}-${formatTime(endDate, timeFormat)}`}
      </Typography>
      <Typography color={theme.color.white} mb="5px" variant="proximaNova-400" fontSize={['12px', '16px']}>
        {eventDetails?.location}
      </Typography>
      <Typography color={theme.color.white} variant="proximaNova-400" fontSize={['12px', '16px']}>
        {eventDetails?.locationDescription}
      </Typography>
    </FlexWithGradientBackground>
  );
};

UpcomingEvent.defaultProps = {
  eventDetails: {},
};

UpcomingEvent.propTypes = {
  eventDetails: exact({
    id: number,
    name: string,
    description: string,
    hasSlots: bool,
    isRecurring: bool,
    locationDescription: string,
    upcomingId: number,
    upcomingDate: string,
    startTime: string,
    endTime: string,
    location: string,
    imageUrl: string,
    locationId: number,
  }),
};

export default UpcomingEvent;
