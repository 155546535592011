import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import * as api from './api';

// < ----- ACTIONS ----- > //
const resetPhasesAction = 'RESET_BOOKING_PHASES';

const inviteGuestToRoomRoutine = createRoutine(
  'INVITE_GUEST_TO_ROOM',
);

const getBookedRoomsRoutine = createRoutine(
  'GET_BOOKED_ROOMS',
);

const getBookingHistoryRoutine = createRoutine(
  'GET_BOOKING_HISTORY',
);

const getBookingByIdRoutine = createRoutine(
  'GET_BOOKING_BY_ID',
);

const getCompanyAvailableCreditsByIdRoutine = createRoutine(
  'GET_COMPANY_AVAILABLE_CREDITS_BY_ID',
);

const bookRoomRoutine = createRoutine(
  'BOOK_ROOM',
);

const editBookingRoutine = createRoutine(
  'EDIT_BOOKING',
);

const cancelBookingRoutine = createRoutine(
  'CANCEL_BOOKING',
);

const confirmBookingRoutine = createRoutine(
  'CONFIRM_BOOKING',
);

const resetStoreToInitialStateAction = 'RESET_STORE';

// < ----- ACTION CREATORS ----- > //
export const getBookingById = id => async dispatch => {
  try {
    dispatch(getBookingByIdRoutine.request());

    const response = await api.getBookingById(id);

    return dispatch(getBookingByIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getBookingByIdRoutine.failure(error.response));
  }
};

export const getCompanyAvailableCreditsById = id => async dispatch => {
  try {
    dispatch(getCompanyAvailableCreditsByIdRoutine.request());

    const response = await api.getCompanyAvailableCreditsById(id);

    return dispatch(getCompanyAvailableCreditsByIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getCompanyAvailableCreditsByIdRoutine.failure(error.response));
  }
};

export const getBookedRooms = () => async dispatch => {
  try {
    dispatch(getBookedRoomsRoutine.request());

    const response = await api.getBookingHistory();

    return dispatch(getBookedRoomsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getBookedRoomsRoutine.failure(error.response));
  }
};

export const editBooking = (id, data) => async dispatch => {
  try {
    dispatch(editBookingRoutine.request());

    const response = await api.editBooking(id, data);

    return dispatch(editBookingRoutine.success(response.data));
  } catch (error) {
    return dispatch(editBookingRoutine.failure(error.response));
  }
};

export const confirmBooking = (data, id) => async dispatch => {
  try {
    dispatch(confirmBookingRoutine.request());

    await api.confirmBooking(data, id);

    return dispatch(confirmBookingRoutine.success());
  } catch (error) {
    return dispatch(confirmBookingRoutine.failure(error.response));
  }
};

export const cancelBooking = id => async dispatch => {
  try {
    dispatch(cancelBookingRoutine.request());

    const response = await api.cancelBooking(id);

    return dispatch(cancelBookingRoutine.success(response.data));
  } catch (error) {
    return dispatch(cancelBookingRoutine.failure(error.response));
  }
};

export const inviteGuestToRoom = (id, data) => async dispatch => {
  try {
    dispatch(inviteGuestToRoomRoutine.request());

    const response = await api.inviteGuestToRoom(id, data);

    return dispatch(inviteGuestToRoomRoutine.success(response.data));
  } catch (error) {
    return dispatch(inviteGuestToRoomRoutine.failure(error.response));
  }
};

export const getBookingHistory = data => async dispatch => {
  try {
    dispatch(getBookingHistoryRoutine.request());

    const response = await api.getBookingHistory(data);

    return dispatch(getBookingHistoryRoutine.success(response.data));
  } catch (error) {
    return dispatch(getBookingHistoryRoutine.failure(error.response));
  }
};

export const bookRoom = data => async dispatch => {
  try {
    dispatch(bookRoomRoutine.request());

    const response = await api.bookRoom(data);

    return dispatch(bookRoomRoutine.success(response.data));
  } catch (error) {
    return dispatch(bookRoomRoutine.failure(error.response));
  }
};

export const resetStore = () => ({
  type: resetStoreToInitialStateAction,
});

export const resetPhases = () => ({
  type: resetPhasesAction,
});

// < ----- STATE ----- > //
export const roomStorePersistWhitelist = [];

const initialState = {
  bookingHistory: [],
  bookedRooms: [],
  bookingById: {},

  refundedCredits: 0,
  refundedMoney: 0,
  companyBookingAvailableCredits: 0,

  getBookingHistoryPhase: INIT,
  getBookingHistoryError: null,

  bookRoomPhase: INIT,
  bookRoomError: null,

  inviteGuestToRoomPhase: INIT,
  inviteGuestToRoomError: null,

  getBookingByIdPhase: INIT,
  getBookingByIdError: null,

  editBookingPhase: INIT,
  editBookingError: null,

  cancelBookingPhase: INIT,
  cancelBookingError: null,

  getBookedRoomsPhase: INIT,
  getBookedRoomsError: null,

  getCompanyAvailableCreditsByIdPhase: INIT,
  getCompanyAvailableCreditsByIdError: null,

  confirmBookingPhase: INIT,
  confirmBookingError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;

    // RESET PHASES
    case resetPhasesAction:
      return {
        ...state,

        getBookingHistoryPhase: INIT,
        getBookingHistoryError: null,

        getCompanyAvailableCreditsByIdPhase: INIT,
        getCompanyAvailableCreditsByIdError: null,

        bookRoomPhase: INIT,
        bookRoomError: null,

        inviteGuestToRoomPhase: INIT,
        inviteGuestToRoomError: null,

        getBookingByIdPhase: INIT,
        getBookingByIdError: null,

        editBookingPhase: INIT,
        editBookingError: null,

        cancelBookingPhase: INIT,
        cancelBookingError: null,

        confirmBookingPhase: INIT,
        confirmBookingError: null,
      };
    // GET BOOKING BY ID
    case getBookingByIdRoutine.REQUEST:
      return {
        ...state,
        getBookingByIdPhase: LOADING,
      };
    case getBookingByIdRoutine.SUCCESS:
      return {
        ...state,
        getBookingByIdPhase: SUCCESS,
        bookingById: payload.data,
      };
    case getBookingByIdRoutine.FAILURE:
      return {
        ...state,
        getBookingByIdPhase: FAILURE,
        getBookingByIdError: payload,
      };
      // CONFIRM BOOKING
    case confirmBookingRoutine.REQUEST:
      return {
        ...state,
        confirmBookingPhase: LOADING,
      };
    case confirmBookingRoutine.SUCCESS:
      return {
        ...state,
        confirmBookingPhase: SUCCESS,
      };
    case confirmBookingRoutine.FAILURE:
      return {
        ...state,
        confirmBookingPhase: FAILURE,
        confirmBookingError: payload,
      };
      // GET COMPANY AVAILABLE CREDITS BY ID
    case getCompanyAvailableCreditsByIdRoutine.REQUEST:
      return {
        ...state,
        getCompanyAvailableCreditsByIdPhase: LOADING,
      };
    case getCompanyAvailableCreditsByIdRoutine.SUCCESS:
      return {
        ...state,
        getCompanyAvailableCreditsByIdPhase: SUCCESS,
        companyBookingAvailableCredits: payload.data.amount,
      };
    case getCompanyAvailableCreditsByIdRoutine.FAILURE:
      return {
        ...state,
        getCompanyAvailableCreditsByIdPhase: FAILURE,
        getCompanyAvailableCreditsByIdError: payload,
      };
    // GET BOOKING HISTORY
    case getBookingHistoryRoutine.REQUEST:
      return {
        ...state,
        getBookingHistoryPhase: LOADING,
      };
    case getBookingHistoryRoutine.SUCCESS:
      return {
        ...state,
        getBookingHistoryPhase: SUCCESS,
        bookingHistory: payload.data,
      };
    case getBookingHistoryRoutine.FAILURE:
      return {
        ...state,
        getBookingHistoryPhase: FAILURE,
        getBookingHistoryError: payload,
      };
    // GET BOOKED ROOMS
    case getBookedRoomsRoutine.REQUEST:
      return {
        ...state,
        getBookedRoomsPhase: LOADING,
      };
    case getBookedRoomsRoutine.SUCCESS:
      return {
        ...state,
        getBookedRoomsPhase: SUCCESS,
        bookedRooms: payload.data,
      };
    case getBookedRoomsRoutine.FAILURE:
      return {
        ...state,
        getBookedRoomsPhase: FAILURE,
        getBookedRoomsError: payload,
      };
    // EDIT BOOKING
    case editBookingRoutine.REQUEST:
      return {
        ...state,
        editBookingPhase: LOADING,
      };
    case editBookingRoutine.SUCCESS:
      return {
        ...state,
        editBookingPhase: SUCCESS,
        bookingById: payload.data,
      };
    case editBookingRoutine.FAILURE:
      return {
        ...state,
        editBookingPhase: FAILURE,
        editBookingError: payload,
      };
      // CANCEL BOOKING
    case cancelBookingRoutine.REQUEST:
      return {
        ...state,
        cancelBookingPhase: LOADING,
      };
    case cancelBookingRoutine.SUCCESS:
      return {
        ...state,
        refundedCredits: payload.data.refundedCredits || 0,
        refundedMoney: payload.data.refundedMoney || 0,
        cancelBookingPhase: SUCCESS,
      };
    case cancelBookingRoutine.FAILURE:
      return {
        ...state,
        cancelBookingPhase: FAILURE,
        cancelBookingError: payload,
      };
    // BOOK A ROOM
    case bookRoomRoutine.REQUEST:
      return {
        ...state,
        bookRoomPhase: LOADING,
      };
    case bookRoomRoutine.SUCCESS:
      return {
        ...state,
        bookRoomPhase: SUCCESS,
        bookingById: payload.data,
      };
    case bookRoomRoutine.FAILURE:
      return {
        ...state,
        bookRoomPhase: FAILURE,
        bookRoomError: payload,
      };
      // INVITE GUEST TO ROOM
    case inviteGuestToRoomRoutine.REQUEST:
      return {
        ...state,
        inviteGuestToRoomPhase: LOADING,
      };
    case inviteGuestToRoomRoutine.SUCCESS: {
      return {
        ...state,
        bookingById: {
          ...state.bookingById,
          guests: payload.data,
        },
        inviteGuestToRoomPhase: SUCCESS,
      };
    }
    case inviteGuestToRoomRoutine.FAILURE:
      return {
        ...state,
        inviteGuestToRoomPhase: FAILURE,
        inviteGuestToRoomError: payload,
      };
    default:
      return state;
  }
};

export default store;
