import styled, { css } from 'styled-components';
import { Flex } from '@mixins';

export const Container = styled(Flex)`
  ${({ isOpen }) => css`
    opacity: ${isOpen ? '1' : '0'};
    transition: all .5s;
    visibility: ${isOpen ? 'visible' : 'hidden'};
  `}
`;
