import React from 'react';
import styled from 'styled-components';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}>
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#000',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
}));

export default BootstrapTooltip;
