import { apiEndpoints } from '@constants';
import { client } from '@utils';

const getLocationById = id => (
  client.authorized.get(`${apiEndpoints.location}/${id}`)
);

const getLocationNeighbors = id => (
  client.authorized.get(`${apiEndpoints.location}/${id}/neighbors`)
);

const getLocations = data => (
  client.authorized.get(apiEndpoints.location, {
    params: data,
  })
);

const inviteGuestToLocation = (id, data) => (
  client.authorized.post(`${apiEndpoints.location}/${id}/invite`, data)
);

export {
  getLocationById,
  getLocations,
  getLocationNeighbors,
  inviteGuestToLocation,
};

