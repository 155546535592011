import styled, { css } from 'styled-components';
import { Flex } from '@mixins';
import { theme } from '@styles';

export const Package = styled(Flex)`
${({ isSelected, isDiscountAvailable }) => css`
    :hover {
      box-shadow: 0px 8px 30px rgba(80, 85, 136, 0.06);
    }

    ${isSelected && `
        box-shadow: '0px 4px 30px rgba(0, 0, 0, 0.15)';

        ${!isDiscountAvailable && `
          p {
            color: ${theme.color.darkGreen};
            font-weight: 600;
          }
        `}

        svg {
          path {
            fill: ${theme.color.darkGreen};
          }
        }`
}
  `}
`;
