import React, { useState, useEffect } from 'react';
import { bool, func, string, arrayOf, number } from 'prop-types';

import { Modal, TextArea } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';

const TextModal = ({
  onSave, isOpen, onModalClose, title, description, inputPlaceholder, initialText, buttonText, fontSize,
}) => {
  const [text, setText] = useState(initialText);

  const onTextChange = event => {
    setText(event.target.value);
  };

  const handleModalClose = () => {
    onModalClose();
    setText(initialText);
  };

  const handleSave = () => {
    onSave(text);
    setText(initialText);
    onModalClose();
  };

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  return (
    <Modal borderRadius="2px" isOpen={isOpen} onClose={handleModalClose}>
      <Flex
        height={[430]}
        width={[540]}
        py={['8px', 12, 16]}
        px={[12, 16, 20]}
        flexDirection="column"
        alignItems="center"
      >
        <Typography mb={['9px', 11, 13, 15]} variant="proximaNova-600" fontSize={[16, 18, 20]}>
          {title}
        </Typography>
        <Typography
          variant="proximaNova-400"
          fontSize={[10, 12, 14]}
          color={theme.color.gray[300]}
          alignSelf="start"
        >
          {description}
        </Typography>
        <TextArea
          withBorder
          height="100%"
          width="100%"
          fontSize={fontSize}
          my={[15, 20]}
          value={text}
          onChange={onTextChange}
          placeholder={inputPlaceholder}
        />
        <Button disabled={initialText === text} onClick={handleSave} py={12} px={74} variant="primary">
          {buttonText}
        </Button>
      </Flex>
    </Modal>
  );
};

TextModal.defaultProps = {
  initialText: '',
  description: '',
  buttonText: 'Save',
  title: '',
  fontSize: [14, 16],
};

TextModal.propTypes = {
  initialText: string,
  title: string,
  isOpen: bool.isRequired,
  onModalClose: func.isRequired,
  onSave: func.isRequired,
  inputPlaceholder: string.isRequired,
  description: string,
  buttonText: string,
  fontSize: arrayOf(number),
};

export default TextModal;
