import React from 'react';
import { string } from 'prop-types';
import DOMPurify from 'dompurify';

import { Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { ReactComponent as ArrowLongIcon } from '@assets/svgs/ArrowLong.svg';
import { StyledWrap, StyledLink } from './styles';

const Announcement = ({ content, title, url }) => (
  <StyledLink href={url} target="_blank" rel="noreferrer" width="100%">
    <Flex p={80} backgroundColor={theme.color.milk} width="100%">
      <Flex width="100%" flexDirection="column" maxWidth={600} margin="0 auto">
        <Typography variant="garamond-500" fontSize={34} mb={[30, 50]}>
          {title}
        </Typography>
        <StyledWrap alignItems="flex-end" justifyContent="space-between">
          <Flex
            display="block"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content),
            }}
            pl={40}
            maxWidth={520}
          />
          {url && <Icon SVG={ArrowLongIcon} width={51} height={8} />}
        </StyledWrap>
      </Flex>
    </Flex>
  </StyledLink>
);

Announcement.propTypes = {
  content: string,
  title: string,
  url: string,
};

Announcement.defaultProps = {
  content: '',
  title: '',
  url: '',
};

export default Announcement;
