import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { func } from 'prop-types';

import { Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { ExpiredCardBrandsLogos, CardBrandsLogos } from '@constants/cards';
import { dayjs } from '@utils';
import { theme } from '@styles';

import { ReactComponent as TrashCan } from '@assets/svgs/TrashCan.svg';
import { ReactComponent as Checkmark } from '@assets/svgs/Checkmark.svg';

const CardsList = ({ onCardClick, onCardDelete }) => {
  const { paymentMethods } = useSelector(store => (
    store.paymentStore
  ));

  const cards = useMemo(() => paymentMethods.map(({ id, brand, last4Digits, isDefault, expirationDate }) => {
    const isExpired = dayjs(expirationDate) < dayjs();

    return (
      <Flex
        key={id}
        mb={20}
        width="100%"
        p={22}
        pointer
        onClick={onCardClick(id)}
        border="1px solid #EFECE6"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center">
          <Icon
            mr={[20, 40]}
            SVG={isExpired ? ExpiredCardBrandsLogos[brand] : CardBrandsLogos[brand]}
          />
          <Typography
            color={isExpired ? '#8A8A8A' : theme.color.gray[500]}
            fontSize={20}
            variant="proximaNova-600"
          >
            {`${brand[0].toUpperCase()}${brand.slice(1)} ···· ${last4Digits}`}
          </Typography>
        </Flex>
        <Flex alignItems="center">
          {isDefault && (
            <Icon height={12} mr={35} width={16} SVG={Checkmark} />
          )}
          {isExpired && (
            <Typography mr={20} color={theme.color.red} variant="proximaNova-400">
              Expired
            </Typography>
          )}
          <Icon onClick={onCardDelete(id)} pointer SVG={TrashCan} />
        </Flex>
      </Flex>
    );
  }), [paymentMethods]);

  return cards;
};

CardsList.propTypes = {
  onCardClick: func.isRequired,
  onCardDelete: func.isRequired,
};

export default CardsList;
