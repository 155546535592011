import React, { useMemo, useEffect, useState } from 'react';
import { bool, func, number } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, EventHeader, Loader, Icon } from '@atoms';
import { ErrorModal } from '@molecules';
import { Flex, Typography, Button } from '@mixins';
import { LOADING, FAILURE } from '@constants/requestPhase';
import { getCurrency } from '@helpers/common';
import { useViewport } from '@hooks';
import { dayjs } from '@utils';
import { trackEvent } from '@utils/mixpanel';
import {
  getServicesAvailability, bookEvent, cancelBookingEvent, resetPhases, getEventAvailableDates,
} from '@store/event/duck';
import { theme } from '@styles';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import { ReactComponent as Checkmark } from '@assets/svgs/Checkmark.svg';

import { NextButton } from '@molecules/BenefitsCarousel/styles';
import { ScrollableContainer as HorizontalContainer } from '@atoms/Timeline/styles';
import { ScrollableContainer as VerticalContainter } from './styles';

const getDateStatus = (id, myBooking, status, handleBookEvent) => {
  switch (true) {
    case !!myBooking:
      return (
        <Button onClick={handleBookEvent(id, myBooking?.id)} variant="primary" px={20} py="6px">
          <Icon mr={10} color={theme.color.white} SVG={Checkmark} />
          Attending
        </Button>
      );
    case status === 'full':
      return (
        <Typography
          color="#8A8A8A"
          variant="proximaNova-600"
          py="5px"
          px={10}
          border="1px solid #8A8A8A"
        >
          Fully booked
        </Typography>
      );
    default:
      return (
        <Button onClick={handleBookEvent(id, myBooking?.id)} variant="primary" px={20} py="6px">
          RSVP
        </Button>
      );
  }
};

const EventDateSelectingModal = ({
  isOpen,
  onClose,
  onDateChange,
  onServiceOpen,
  selectedDateId,
}) => {
  const dispatch = useDispatch();
  const screenSize = useViewport();

  const {
    eventById: {
      details: { hasSlots, isRecurring, id: eventId, upcomingId },
      dates: { services, availableDates, availability },
      bookings,
    }, bookEventError, getEventAvailableDatesPhase,
    getServicesAvailabilityPhase, bookEventPhase, cancelBookingEventPhase,
  } = useSelector(store => store.eventStore);

  const { currentLocation } = useSelector(store => store.locationStore);

  const [error, setError] = useState({});

  const isLoadingState = getEventAvailableDatesPhase === LOADING || getServicesAvailabilityPhase === LOADING
  || bookEventPhase === LOADING || cancelBookingEventPhase === LOADING;

  const handleBookEvent = (id, bookingId) => () => {
    if (bookingId) {
      trackEvent('Event booking canceled', {
        scope: 'Events',
        id,
      });

      dispatch(cancelBookingEvent(eventId, id, {
        bookingId,
      }));
    } else {
      trackEvent('Event booked', {
        scope: 'Events',
        id,
      });

      dispatch(bookEvent(eventId, id));
    }
  };

  const handleErrorClose = () => {
    dispatch(getEventAvailableDates(eventId));

    setError({});
  };

  useEffect(() => {
    const id = isRecurring ? selectedDateId : upcomingId;

    if (isOpen && id && hasSlots) {
      dispatch(getServicesAvailability(eventId, id));
    }
  }, [isOpen, selectedDateId]);

  useEffect(() => {
    if (bookEventPhase === FAILURE) {
      setError({
        status: bookEventError.data.error.status,
        message: 'Yikes, this date is already fully booked',
        buttonLabel: 'RSVP for a different date',
      });
      dispatch(resetPhases());
    }
  }, [bookEventPhase]);

  const availableSlotDatesBlock = useMemo(() => availableDates.map(({ id, date, startTime }, index) => (
    <Typography
      key={id}
      onClick={onDateChange(id)}
      variant="proximaNova-400"
      color={selectedDateId === id ? theme.color.darkGreen : theme.color.gray[500]}
      px={index !== availableDates.length - 1 ? 15 : 0}
      pl={index ? 15 : 0}
      pointer
      fontWeight={selectedDateId === id ? 600 : 400}
      borderRight={index !== availableDates.length - 1 ? `1px solid ${theme.color.gray[300]}` : 0}
    >
      {`${dayjs(`${date.substring(0, 10)}T${startTime}.000Z`).format('MMM')} 
      ${dayjs(`${date.substring(0, 10)}T${startTime}.000Z`).$D}`}
    </Typography>
  )), [availableDates, selectedDateId, currentLocation.timeZone]);

  const servicesBlock = useMemo(() => (
    services?.map(({ id, name, duration, price, currency }, index) => {
      const serviceAvailability = availability.find(av => av.serviceId === id);
      const hasBooking = bookings.find(booking => booking.serviceId === id
        && booking.eventId === selectedDateId);
      const isAvailable = serviceAvailability?.available || hasBooking;

      return (
        <Flex
          key={id}
          pointer={isAvailable}
          {...(isAvailable && { onClick: onServiceOpen(id) })}
          justifyContent="space-between"
          alignItems="center"
          borderBottom={services && index !== services.length - 1
            ? `1px solid ${theme.color.gray[100]}` : 0}
        >
          <Flex py={12} px={10} flexDirection="column">
            <Typography
              color={isAvailable ? theme.color.gray[500] : '#8A8A8A'}
              variant="proximaNova-400"
            >
              {name}
            </Typography>
            <Typography
              color={isAvailable ? theme.color.gray[200] : '#8A8A8A'}
              variant="proximaNova-400"
              fontSize={14}
            >
              {`${duration} min | ${getCurrency(currency, price)}`}
            </Typography>
          </Flex>
          {isAvailable
            ? <NextButton color={theme.color.black} SVG={Dropdown} />
            : (
              <Typography
                color="#8A8A8A"
                variant="proximaNova-600"
                py="5px"
                px={10}
                border="1px solid #8A8A8A"
              >
                Fully booked
              </Typography>
            )}
        </Flex>
      );
    })), [services, availability]);

  const datesBlock = useMemo(() => (
    availableDates.map(({ id, date, availableState, startTime }, index) => {
      const myBooking = bookings.find(booking => booking.eventId === id);

      return (
        <Flex
          key={date}
          justifyContent="space-between"
          py={15}
          alignItems="center"
          borderBottom={index !== availableDates.length - 1 ? `1px solid ${theme.color.gray[100]}` : 0}
        >
          <Typography variant="proximaNova-400" fontSize={16}>
            {`${dayjs(`${date.substring(0, 10)}T${startTime}.000Z`).format('ddd')}, 
          ${dayjs(`${date.substring(0, 10)}T${startTime}.000Z`).format('MMMM')} 
          ${dayjs(`${date.substring(0, 10)}T${startTime}.000Z`).date()}`}
          </Typography>
          {getDateStatus(id, myBooking, availableState.status, handleBookEvent)}
        </Flex>
      );
    })), [availableDates, bookings, currentLocation.timeZone]);

  return (
    <Modal withPadding={false} onClose={onClose} topPosition={50} isOpen={isOpen} withCloseButton>
      <Flex
        flexGrow={1}
        width={[300, 450, 600]}
        height={[400, 510, 720]}
        {...(screenSize.height < 780 && {
          height: screenSize.height - 150,
          overflow: 'scroll',
        })}
        flexDirection="column"
      >
        <Flex
          pt={[15, 25, 40]}
          pb={hasSlots && isRecurring ? '4px' : 30}
          px={[10, 20, 30]}
          borderBottom={!isLoadingState && getServicesAvailabilityPhase !== LOADING
            ? `1px solid ${theme.color.gray[100]}` : 0}
          flexDirection="column"
        >
          <Typography mb={[20, 30]} variant="garamond-500" fontSize={[24, 30, 36]}>
            {`Select ${isRecurring ? 'date' : 'time'}${hasSlots ? ' and service' : ''}`}
          </Typography>
          <EventHeader />
          {hasSlots && isRecurring && !isLoadingState && (
            <HorizontalContainer pb="5px" mt={[20, 35]}>
              {availableSlotDatesBlock}
            </HorizontalContainer>
          )}
        </Flex>
        {isLoadingState || getServicesAvailabilityPhase === LOADING
          ? (
            <Flex alignSelf="center" flexGrow={1}>
              <Loader position="relative" background={theme.color.white} width={40} height={40} />
            </Flex>
          )
          : (
            <VerticalContainter
              m="4px"
              px={[20, 25, 30]}
              flexDirection="column"
            >
              {hasSlots ? servicesBlock : datesBlock}
            </VerticalContainter>
          )}
      </Flex>
      <ErrorModal
        error={error}
        onModalClose={handleErrorClose}
        isOpen={!!Object.keys(error)?.length}
      />
    </Modal>
  );
};

EventDateSelectingModal.defaultProps = {
  selectedDateId: 0,
  onServiceOpen: () => {},
  onDateChange: () => {},
};

EventDateSelectingModal.propTypes = {
  selectedDateId: number,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onDateChange: func,
  onServiceOpen: func,
};

export default EventDateSelectingModal;
