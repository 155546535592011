import React, { useEffect, useMemo, useRef } from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { useOutsideClick } from '@hooks';
import { getMeetingRooms } from '@store/room/duck';
import { getMyCompanyAvailableCredits } from '@store/company/duck';
import { getLocationNeighbors, resetPhases } from '@store/location/duck';
import { theme } from '@styles';
import { routes } from '@constants';
import { SUCCESS } from '@constants/requestPhase';
import {
  COMMUNITY_MANAGER_ROLE, ADMIN_ROLE, EMPLOYEE_ROLE, CMS_EMPLOYEE_ROLE, EMPLOYEE_CM_ROLE,
} from '@constants/common';

import { ReactComponent as Close } from '@assets/svgs/Close.svg';
import { ReactComponent as Discount } from '@assets/svgs/Discount.svg';
import { ReactComponent as Burger } from '@assets/svgs/Burger.svg';
import { ReactComponent as LongArrow } from '@assets/svgs/LongArrow.svg';
import { ReactComponent as Arrow } from '@assets/svgs/Arrow.svg';
import { ReactComponent as Credits } from '@assets/svgs/Credits.svg';

import { StyledNavBar, ItemBlock, ItemTitle, ScrollableContainer } from './styles';
import { getNavItems } from './utils';

const Navigation = ({ companyName }) => {
  const dispatch = useDispatch();
  const navigationRef = useRef();
  const history = useHistory();

  const { user } = useSelector(store => (
    store.userStore
  ));

  const { availableCredits } = useSelector(store => (
    store.companyStore
  ));

  const { rooms: { meetingRooms }, getMeetingRoomsPhase } = useSelector(store => (
    store.roomStore
  ));

  const { currentLocation, companies, getCurrentLocationPhase } = useSelector(store => (
    store.locationStore
  ));

  const { navigationItems } = useSelector(store => (
    store.metadataStore
  ));

  const isAdmin = user.role === COMMUNITY_MANAGER_ROLE || user.role === ADMIN_ROLE;

  const isMindspaceEmployee = isAdmin || user.role === EMPLOYEE_ROLE || user.role === CMS_EMPLOYEE_ROLE
  || user.role === EMPLOYEE_CM_ROLE;

  const items = useMemo(() =>
    getNavItems(navigationItems, user, companies, currentLocation),
  [navigationItems, user, companies, currentLocation]);

  const { isOpen, setIsOpen } = useOutsideClick(false, [navigationRef]);

  const handleStatusOpenChange = () => {
    setIsOpen(prevValue => !prevValue);
  };

  const handleMemberBenefitsClick = () => {
    history.push(routes.benefits);
  };

  const handleBookRoomClick = () => {
    history.push(routes.rooms);
  };

  const handleEventsClick = () => {
    history.push(routes.events);
  };

  useEffect(() => {
    dispatch(getMyCompanyAvailableCredits());
  }, []);

  useEffect(() => {
    if (getCurrentLocationPhase === SUCCESS) {
      dispatch(getMeetingRooms({
        locationId: currentLocation.id,
      }));
      dispatch(getLocationNeighbors(currentLocation.id));
      dispatch(resetPhases());
    }
  }, [getCurrentLocationPhase]);

  useEffect(() => {
    if (getMeetingRoomsPhase === SUCCESS) {
      dispatch(resetPhases());
    }
  }, [getMeetingRoomsPhase]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  const navigationItemsBlock = useMemo(() => items.reduce((acc, { title, handler, isShown }, index) => {
    if (isShown) {
      acc.push(
        <Flex
          py="7px"
          alignItems="center"
          borderBottom={index === items.length - 1 ? 0 : '1px solid #E7D2BA'}
          key={title}
        >
          <ItemBlock onClick={handler} width="100%" pointer pl={20} py="8px">
            <ItemTitle mr={15} variant="proximaNova-400">
              {title}
            </ItemTitle>
            {title === 'Buy Credits' && user.isDiscountApplicable && (
              <Flex alignItems="center" px="8px" backgroundColor={theme.color.darkGreen} borderRadius={100}>
                <Icon mr="4px" SVG={Discount} width={10} height={10} />
                <Typography color={theme.color.white} variant="proximaNova-400" fontSize={12}>
                  Save 20% your first week
                </Typography>
              </Flex>
            )}
          </ItemBlock>
        </Flex>,
      );
    }
    return acc;
  }, []), [items]);

  return (
    <>
      <Button id="nav" onClick={handleStatusOpenChange} variant="burger" px={[10, 15, 20]} py={[10, 15, 20]}>
        <Flex alignItems="center">
          <Icon SVG={Burger} width={[14, 16, 18]} height={[14, 16, 18]} />
        </Flex>
      </Button>
      <StyledNavBar
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={11}
        backgroundColor="rgba(0, 0, 0, 0.5)"
        isExpanded={isOpen}
      >
        <Flex
          ref={navigationRef}
          pt={65}
          px={16}
          backgroundColor="#EFECE6"
          position="absolute"
          zIndex={12}
          right={0}
          width={[350, 410, 410]}
          top={0}
          bottom={0}
          boxShadow="0px 4px 30px rgba(0, 0, 0, 0.15)"
          flexDirection="column"
        >
          <Icon
            pointer
            top={25}
            right={25}
            position="absolute"
            SVG={Close}
            width={[10, 13]}
            height={[10, 13]}
            onClick={handleStatusOpenChange}
          />
          <ScrollableContainer>
            <Flex mb={30} flexDirection="column">
              {!!meetingRooms.length && (
                <Button onClick={handleBookRoomClick} mb="5px" width="100%" variant="tertiary" p={19}>
                  <Flex flexDirection="column">
                    <Typography variant="proximaNova-400" textAlign="left">
                      Book a Room
                    </Typography>
                    <Icon color={theme.color.black} mt={20} SVG={LongArrow} />
                  </Flex>
                </Button>
              )}
              <Flex justifyContent="space-between">
                <Button onClick={handleEventsClick} width="50%" variant="tertiary" p={19}>
                  <Flex flexDirection="column">
                    <Typography variant="proximaNova-400" textAlign="left">
                      Events
                    </Typography>
                    <Icon width={45} SVG={Arrow} color={theme.color.black} mt={20} />
                  </Flex>
                </Button>
                <Button onClick={handleMemberBenefitsClick} width="50%" ml="5px" variant="tertiary" p={19}>
                  <Flex flexDirection="column">
                    <Typography variant="proximaNova-400" textAlign="left">
                      Member Benefits
                    </Typography>
                    <Icon width={45} SVG={Arrow} color={theme.color.black} mt={20} />
                  </Flex>
                </Button>
              </Flex>
            </Flex>
            {!isMindspaceEmployee && (
              <Flex flexDirection="column" mb={20}>
                <Flex alignItems="center">
                  <Icon width={20} height={20} SVG={Credits} mr={10} />
                  <Typography variant="proximaNova-600" fontSize={20}>
                    {`${availableCredits.toFixed(2)} ${availableCredits === 1 ? 'Credit' : 'Credits'}`}
                  </Typography>
                </Flex>
                <Typography mt="8px" variant="proximaNova-400" fontSize={14} color={theme.color.gray[200]}>
                  {`Available for ${companyName}`}
                </Typography>
              </Flex>
            )}
            {navigationItemsBlock}
          </ScrollableContainer>
        </Flex>
      </StyledNavBar>
    </>
  );
};

Navigation.defaultProps = {
  companyName: '',
};

Navigation.propTypes = {
  companyName: string,
};

export default Navigation;
