import React from 'react';
import { bool, func } from 'prop-types';

import { Modal, Icon } from '@atoms';
import { Button, Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { storage } from '@utils';
import { ReactComponent as Discount } from '@assets/svgs/Discount.svg';
import DiscountImage from '@assets/images/DiscountImage.png';
import { IS_DISCOUNT_DISPLAYED_KEY } from '@constants/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { routes } from '@constants';

const DiscountModal = ({
  isOpen,
  onModalClose,
}) => {
  const history = useHistory();

  const handleBuyCreditsClick = () => {
    storage.setToLocalStorage(IS_DISCOUNT_DISPLAYED_KEY, true);
    history.push(routes.creditsPackages);
  };

  return (
    <Modal
      onClose={onModalClose}
      closeButtonColor={theme.color.white}
      background="rgba(0, 0, 0, 0.6)"
      maxWidth={[900]}
      withPadding={false}
      topPosition={60}
      isOpen={isOpen}
      withCloseButton
    >
      <Flex>
        <Flex alignSelf="center" alignItems="center" width={400} flexDirection="column">
          <Icon overflow="auto" SVG={Discount} mb={25} />
          <Typography lineHeight="40px" textAlign="center" variant="garamond-500" fontSize={40}>
            Save 20%
          </Typography>
          <Typography mb={10} textAlign="center" variant="garamond-500" fontSize={20}>
            Your first week only
          </Typography>
          <Typography
            maxWidth={324}
            textAlign="center"
            variant="proximaNova-400"
            fontSize={14}
            color={theme.color.gray[300]}
          >
            As a token of appreciation for joining us as a member,
            we`re offering you a one-time discount on credits, which you can use to book meeting rooms.
          </Typography>
          <Button
            mt={20}
            py={12}
            px={56}
            onClick={handleBuyCreditsClick}
            variant="primary"
          >
            Buy Credits
          </Button>
        </Flex>
        <Flex
          minWidth={500}
          height={600}
          background={`url(${DiscountImage}) center no-repeat`}
          backgroundSize="cover"
        />
      </Flex>
    </Modal>
  );
};

DiscountModal.propTypes = {
  onModalClose: func.isRequired,
  isOpen: bool.isRequired,
};

export default DiscountModal;
