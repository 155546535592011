import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Avatar } from '@atoms';
import { Flex, Typography } from '@mixins';
import { routes } from '@constants';
import { theme } from '@styles';

const CompanyManagers = () => {
  const history = useHistory();
  const location = useLocation();

  const { userCompanyManagers } = useSelector(store => (
    store.companyStore
  ));

  const handleManagerClick = id => () => {
    history.push({
      pathname: `${routes.userProfile}/${id}`,
      state: { from: location.pathname },
    });
  };

  const userCompanyManagersBlock = useMemo(() => (
    userCompanyManagers.map(({ id, firstName, lastName, jobTitle, imageUrl, isHidden }) => (
      <Flex
        pointer
        onClick={handleManagerClick(id)}
        mb={['7px', 10]}
        alignSelf="start"
        alignItems="center"
        key={id}
      >
        <Avatar
          fontSize={['22px']}
          width={[50]}
          height={[50]}
          firstName={firstName}
          lastName={lastName}
          imageUrl={imageUrl}
        />
        <Flex flexDirection="column">
          <Flex ml={[11, 13, 15]} flexDirection="column">
            <Typography variant="proximaNova-600" fontSize={[12, 12, 14, 16]}>
              {isHidden ? 'Mindspace Member' : `${firstName} ${lastName}`}
            </Typography>
            <Typography variant="proximaNova-400" fontSize={[12, 12, 14, 14]} color={theme.color.gray[300]}>
              {jobTitle}
            </Typography>
          </Flex>
        </Flex>
      </Flex>
    ))
  ), [userCompanyManagers]);

  return userCompanyManagersBlock;
};

export default CompanyManagers;
