import styled, { css } from 'styled-components';

import { Flex } from '@mixins';
import { theme } from '@styles';

export const ScrollableContainer = styled(Flex)`
${({ usersLength }) => css`
    flex-direction: column;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: ${theme.color.darkGreen};
    }

    ::-webkit-scrollbar-track {
      width: 4px;
      border-radius: 50px;
      background: ${usersLength > 3 ? '#D6DFE0' : theme.color.white};
    }
  `}
`;
