import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const withStripe = Component => props => {
  const { stripeKeys } = useSelector(store => store.paymentStore);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (stripeKeys.publishableKey) {
      setStripePromise(loadStripe(stripeKeys.publishableKey));
    }
  }, [stripeKeys.publishableKey]);

  return (
    <Elements stripe={stripePromise}>
      <Component {...props} />
    </Elements>
  );
};

export default withStripe;
