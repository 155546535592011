import React from 'react';
import { func, number, bool } from 'prop-types';
import { useSelector } from 'react-redux';

import { Modal, Icon, Loader } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { getCurrency } from '@helpers/common';
import { ExpiredCardBrandsLogos, CardBrandsLogos } from '@constants/cards';
import { LOADING } from '@constants/requestPhase';
import { dayjs } from '@utils';
import { theme } from '@styles';

import { ReactComponent as Credits } from '@assets/svgs/Credits.svg';

const PaymentModal = ({
  creditPackageId, bookingPrice, isOpen, onClose, paymentMethodId, onChangeCard, onAddCard, isLoading,
  onPayNow,
}) => {
  const { currentLocation } = useSelector(store => store.locationStore);
  const { bookRoomPhase, confirmBookingPhase } = useSelector(store => store.bookingStore);
  const {
    paymentMethods, getPaymentMethodsPhase, buyCreditsPhase, buyCreditsConfirmPhase, packages, vat,
  } = useSelector(store => store.paymentStore);

  const creditPackage = packages.find(pack => pack.id === creditPackageId);
  const paymentMethod = paymentMethods.find(method => method.id === paymentMethodId);

  const isExpired = dayjs(paymentMethod?.expirationDate ?? 0) < dayjs();

  return (
    <Modal onClose={onClose} withPadding={false} isOpen={isOpen}>
      <Flex position="relative" flexDirection="column" width={[380, 540]} px={20} py={30}>
        <Typography alignSelf="center" mb={35} variant="garamond-500" fontSize={34}>
          Payment
        </Typography>
        {creditPackage && (
          <Flex
            mb={20}
            borderBottom={`1px solid ${theme.color.gray[100]}`}
            pb={20}
            px={20}
            flexDirection="column"
          >
            <Flex mb={10} alignItems="center">
              <Icon width={30} height={30} mr={10} SVG={Credits} />
              <Typography variant="proximaNova-600">{`${creditPackage?.amount} Credits Package`}</Typography>
            </Flex>
            <Typography color={theme.color.gray[300]} variant="proximaNova-400" fontSize={14}>
              Credit packages are valid for 90 days.
            </Typography>
          </Flex>
        )}
        <Flex flexDirection="column">
          <Typography mb={15} variant="proximaNova-600">Payment Method</Typography>
          <Flex
            p={15}
            border={`1px solid ${theme.color.gray[100]}`}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            {paymentMethod && (
              <Flex>
                <Icon
                  mr={20}
                  SVG={isExpired
                    ? ExpiredCardBrandsLogos[paymentMethod?.brand] : CardBrandsLogos[paymentMethod?.brand]}
                />
                <Typography
                  color={isExpired ? '#8A8A8A' : theme.color.gray[500]}
                  variant="proximaNova-400"
                >
                  {`${paymentMethod?.brand[0].toUpperCase()}${paymentMethod?.brand.slice(1)} ···· 
            ${paymentMethod?.last4Digits}`}
                </Typography>
              </Flex>
            )}
            <Typography
              fontSize={14}
              variant="proximaNova-400"
              pointer
              color={theme.color.darkGreen}
              onClick={paymentMethod ? onChangeCard : onAddCard}
            >
              {paymentMethod ? 'Change card' : '+ Add Card'}
            </Typography>
          </Flex>
        </Flex>
        {creditPackage && (
          <>
            <Flex px={20} pt={20} flexDirection="column">
              <Flex alignItems="center" justifyContent="space-between">
                <Typography fontSize={14} variant="proximaNova-600">
                  Credit Package
                </Typography>
                <Typography fontSize={14} variant="proximaNova-600">
                  {getCurrency(creditPackage.currency, creditPackage.price)}
                </Typography>
              </Flex>
            </Flex>
            <Flex borderBottom={`1px solid ${theme.color.gray[100]}`} p={20} flexDirection="column">
              <Flex alignItems="center" justifyContent="space-between">
                <Typography fontSize={14} variant="proximaNova-600">
                  {`VAT (${vat}%)`}
                </Typography>
                <Typography fontSize={14} variant="proximaNova-600">
                  {getCurrency(creditPackage.currency, creditPackage.vatCalculated)}
                </Typography>
              </Flex>
            </Flex>
          </>
        )}
        <Flex p={20} flexDirection="column">
          <Flex alignItems="center" justifyContent="space-between">
            <Typography fontSize={14} variant="proximaNova-600">
              Total
            </Typography>
            <Typography fontSize={14} variant="proximaNova-600">
              {creditPackage
                ? getCurrency(creditPackage.currency, creditPackage.totalPrice)
                : getCurrency(currentLocation.currency, bookingPrice)}
            </Typography>
          </Flex>
        </Flex>
        <Button
          alignSelf="center"
          width="fit-content"
          disabled={!paymentMethod || isExpired}
          variant="primary"
          py={12}
          px={70}
          onClick={onPayNow}
        >
          Pay now
        </Button>
        {(getPaymentMethodsPhase === LOADING || buyCreditsPhase === LOADING
        || buyCreditsConfirmPhase === LOADING || bookRoomPhase === LOADING
        || confirmBookingPhase === LOADING || isLoading) && (
          <Loader background="white" position="absolute" width={40} height={40} />
        )}
      </Flex>
    </Modal>
  );
};

PaymentModal.defaultProps = {
  paymentMethodId: 0,
  bookingPrice: 0,
  creditPackageId: 0,
};

PaymentModal.propTypes = {
  onPayNow: func.isRequired,
  isLoading: bool.isRequired,
  onChangeCard: func.isRequired,
  onAddCard: func.isRequired,
  bookingPrice: number,
  creditPackageId: number,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  paymentMethodId: number,
};

export default PaymentModal;
