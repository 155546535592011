import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import {
  border, color, flexbox, layout, position, space, background, shadow, typography,
} from 'styled-system';

export const AnimatedFlex = styled(motion.div)`
${({ pointer }) => css`
    display: flex;
    ${pointer && 'cursor: pointer;'}
    
    ${typography};
    ${shadow};
    ${background}
    ${border};
    ${color};
    ${flexbox};
    ${layout};
    ${position};
    ${space};
  `}
`;
