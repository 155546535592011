import { combineReducers } from 'redux';
import userStore from './user/duck';
import locationStore from './location/duck';
import companyStore from './company/duck';
import roomStore from './room/duck';
import bookingStore from './booking/duck';
import metadataStore from './metadata/duck';
import benefitStore from './benefit/duck';
import paymentStore from './payment/duck';
import eventStore from './event/duck';
import announcementsStore from './announcement/duck';

const rootReducer = combineReducers({
  userStore,
  locationStore,
  companyStore,
  roomStore,
  metadataStore,
  bookingStore,
  benefitStore,
  paymentStore,
  eventStore,
  announcementsStore,
});

export default rootReducer;
