import styled, { css } from 'styled-components';

import { Icon } from '@atoms';
import { Flex } from '@mixins';

export const PrevButton = styled(Icon)`
  ${() => css`
    transform: rotate(180deg);
  `}
`;

export const ButtonContainer = styled(Flex)`
  ${() => css`
    background: rgba(255, 255, 255, 0.25);

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  `}
`;

export const Line = styled(Flex)`
${({ isSelected }) => css`
    background: rgba(255, 255, 255, 0.25);

    ${isSelected && 'background: white;'}

    transition: background-color 200ms;
  `}
`;
