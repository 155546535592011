import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/fr';

dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isToday);
dayjs.extend(timezone);
dayjs.extend(utc);

const timezonedDayjs = (...args) => dayjs(...args).tz();

const timezonedUnix = value => dayjs.unix(value).tz();

timezonedDayjs.unix = timezonedUnix;
timezonedDayjs.duration = dayjs.duration;

export default timezonedDayjs;
