import React, { useMemo } from 'react';
import { func, number, arrayOf, exact, string, bool } from 'prop-types';

import { Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { getCurrency } from '@helpers/common';
import { theme } from '@styles';

import { ReactComponent as Credits } from '@assets/svgs/Credits.svg';

import { Package } from './styles';

const PackagesList = ({ onPackageClick, packages, selectedPackageId, isDiscountAvailable }) => {
  const packagesBlock = useMemo(() => packages.map(({ id, currency, price, amount, originalPrice }, index) => {
    const pricePerCredit = Number(price) / Number(amount);
    const isPackageSelected = selectedPackageId === id;

    return (
      <Package
        pointer
        isDiscountAvailable={isDiscountAvailable}
        isSelected={isPackageSelected}
        width="100%"
        mb={index === packages.length - 1 ? 0 : '10px'}
        justifyContent="space-between"
        alignItems="center"
        py={['8px', '13px']}
        px={['12px', '20px']}
        border={`1px solid ${isPackageSelected ? theme.color.darkGreen : '#EFECE6'}`}
        key={id}
        onClick={onPackageClick(id)}
      >
        <Flex alignItems="center">
          <Icon SVG={Credits} width={['20px', '24px']} height={['20px', '24px']} mr={['10px', '15px']} />
          <Typography
            color={isPackageSelected ? theme.color.darkGreen : theme.color.gray[500]}
            fontSize={[10, 12, 14]}
            variant={isPackageSelected ? 'proximaNova-600' : 'proximaNova-400'}
          >
            {`${amount} Credits`}
          </Typography>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Flex alignSelf="flex-end" mb="4px">
            {isDiscountAvailable && (
              <Typography
                mr={10}
                color={theme.color.darkGreen}
                fontSize={[10, 12, 14]}
                variant="proximaNova-600"
              >
                {getCurrency(currency, price)}
              </Typography>
            )}
            <Typography
              color={theme.color.gray[500]}
              {...(isDiscountAvailable && { textDecoration: 'line-through' })}
              fontSize={[10, 12, 14]}
              variant="proximaNova-400"
            >
              {getCurrency(currency, originalPrice)}
            </Typography>
          </Flex>
          <Typography fontSize={[10, 12, 14]} variant="proximaNova-400">
            {`(${getCurrency(currency, pricePerCredit, 2)} per credit)`}
          </Typography>
        </Flex>
      </Package>
    );
  }), [packages, selectedPackageId, isDiscountAvailable]);

  return packagesBlock;
};

PackagesList.propTypes = {
  isDiscountAvailable: bool.isRequired,
  selectedPackageId: number,
  onPackageClick: func.isRequired,
  packages: arrayOf(
    exact({
      id: number,
      currency: string,
      amount: number,
      vatCalculated: number,
      totalPrice: number,
      price: number,
    }),
  ).isRequired,
};

export default PackagesList;
