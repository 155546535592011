import React from 'react';
import { bool, func, string } from 'prop-types';

import { Modal, Icon } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';

import { ReactComponent as ThankYou } from '@assets/svgs/ThankYou.svg';

const SuccessModal = ({
  isOpen,
  title,
  text,
  onDoneClick,
}) => (
  <Modal withCloseButton={false} topPosition={170} isOpen={isOpen}>
    <Flex
      alignItems="center"
      maxWidth={300}
      flexDirection="column"
      px={50}
      pb={15}
    >
      <Icon mb={[15, 25]} SVG={ThankYou} />
      <Typography textAlign="center" mb={20} variant="proximaNova-600" fontSize={20}>
        {title}
      </Typography>
      {text && (
        <Typography
          textAlign="center"
          mb={20}
          variant="proximaNova-400"
          fontSize={14}
          color={theme.color.gray[200]}
        >
          {text}
        </Typography>
      )}
      <Button onClick={onDoneClick} py={12} width="100%" variant="primary">
        Done
      </Button>
    </Flex>
  </Modal>
);

SuccessModal.propTypes = {
  isOpen: bool.isRequired,
  title: string.isRequired,
  text: string.isRequired,
  onDoneClick: func.isRequired,
};

export default SuccessModal;
