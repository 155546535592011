import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { Router } from '@router';
import configureStore from '@store/configureStore';
import { ResetStyles, theme, muiTheme } from '@styles';

import './styles/fonts.css';
import { MixpanelProvider } from '@providers';

export const { store } = configureStore();

const App = () => (
  <MixpanelProvider>
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={theme}>
          <ResetStyles />
          <Router />
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  </MixpanelProvider>
);

export default App;
