import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { BookingHistory as View } from '@views';
import { dayjs } from '@utils';
import { LOADING, FAILURE } from '@constants/requestPhase';
import { getBookingHistory, resetPhases } from '@store/booking/duck';
import { getCreditsReport } from '@store/company/duck';
import { common } from '@constants';
import { trackEvent } from '@utils/mixpanel';

const BookingHistory = () => {
  const { bookingHistory, getBookingHistoryPhase, getBookingHistoryError } = useSelector(store => (
    store.bookingStore
  ));

  const { user } = useSelector(store => (
    store.userStore
  ));

  const [error, setError] = useState({});
  const [date, setDate] = useState(dayjs());

  const dispatch = useDispatch();
  const isAdmin = user.role === common.COMPANY_ADMIN_ROLE;

  useEffect(() => {
    dispatch(getBookingHistory({
      fromMonth: date.date(1).startOf('day').toISOString(),
      includeCanceled: true,
    }));

    if (isAdmin) {
      dispatch(getCreditsReport({
        month: date.date(1).format('YYYY-MM-DD'),
      }));
    }
  }, [date.$M, date.$y, isAdmin]);

  useEffect(() => {
    if (getBookingHistoryPhase === FAILURE) {
      setError({
        status: getBookingHistoryError.data.error.status,
        message: getBookingHistoryError.data.error.message,
      });
      dispatch(resetPhases());
    }
  }, [getBookingHistoryPhase]);

  useEffect(() => {
    const pageTitle = isAdmin
      ? 'Mindspace: Bookings and Credit Usage' : 'Mindspace: Room Booking History';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Room Booking History',
    });
  }, []);

  const handleDateChange = useCallback(selectedDate => {
    setDate(
      dayjs(selectedDate)
        .add(-(dayjs(selectedDate).$offset + new Date(selectedDate).getTimezoneOffset()), 'minutes')
        .add((dayjs().$u ? -new Date(selectedDate).getTimezoneOffset() : 0), 'minute'),
    );
  }, [dayjs().$offset, dayjs().$u, new Date().getTimezoneOffset()]);

  const spent = useMemo(() => (
    bookingHistory.reduce((acc, booking) => {
      if (booking.paymentType === 'money') {
        acc.money += booking.money;
        acc.currency = booking.meetingRoom.location.currency;
      } else {
        acc.credits += booking.credits;
      }
      return acc;
    }, { credits: 0, money: 0, currency: '' })
  ), [bookingHistory, date]);

  return (
    <View
      isAdmin={isAdmin}
      error={error}
      areBookingsLoading={getBookingHistoryPhase === LOADING}
      onDateChange={handleDateChange}
      date={date}
      isLoading={false}
      bookings={bookingHistory.length}
      spent={spent}
    />
  );
};

export default BookingHistory;
