import React, { useState, useCallback, useMemo } from 'react';
import { number, func, bool, exact, string, arrayOf, object } from 'prop-types';
import { useSelector } from 'react-redux';

import { Loader, Icon } from '@atoms';
import { RoomPhotosCarousel, CompanyManagers, ManageBookingButtons, PricingDetails } from '@molecules';
import { CompanyListModal, ExternalCompanyModal } from '@organisms';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { LOADING } from '@constants/requestPhase';
import { ADMIN_ROLE, EMPLOYEE_ROLE, COMMUNITY_MANAGER_ROLE, CMS_EMPLOYEE_ROLE } from '@constants/common';
import { getCurrency } from '@helpers/common';
import { dayjs } from '@utils';

import { ReactComponent as Close } from '@assets/svgs/Close.svg';
import { ReactComponent as Credits } from '@assets/svgs/Credits.svg';
import { ReactComponent as Edit } from '@assets/svgs/Edit.svg';
import { EMPLOYEE_CM_ROLE } from 'src/constants/common';

const ManageBookingPanel = ({
  selectedCompany, roomPhotos, endTime, startTime, availableTimes, times, onCompanySelect, isEditing,
  onEditBooking, onSubmit, companyName, onBuyCreditsClick, onPayNowClick, externalCompany, onExternalCompanySelect,
  isEventSpace,
}) => {
  const { user } = useSelector(store => (
    store.userStore
  ));

  const { roomById, getBookingCostPhase } = useSelector(store => (
    store.roomStore
  ));

  const { bookingById: { credits, paymentType, money, fromDateTime } } = useSelector(store => (
    store.bookingStore
  ));

  const { currentLocation } = useSelector(store => (
    store.locationStore
  ));

  const [isCompanyListModalOpen, setIsCompanyListModalOpen] = useState(false);
  const [isExternalCompanyModalOpen, setIsExternalCompanyModalOpen] = useState(false);

  const isTimelineSelected = endTime !== -1 && startTime !== -1;
  const bookingHours = isTimelineSelected ? (endTime - startTime) * 0.5 : 0;
  const availableCredits = isEditing && credits
    ? Math.round((roomById.availableCredits + credits) * 100) / 100
    : roomById.availableCredits;
  const isEnoughCredits = roomById.creditsCost <= availableCredits;

  const isAbleToPurchase = user.isAbleToInvoice || user.isAbleToPayByCard;
  const isAdmin = user.role === COMMUNITY_MANAGER_ROLE || user.role === ADMIN_ROLE || user.role === EMPLOYEE_CM_ROLE;
  const isMindspaceEmployee = isAdmin || user.role === EMPLOYEE_ROLE || user.role === CMS_EMPLOYEE_ROLE;
  const isCostDecreased = Math.sign(credits - roomById.creditsCost) === 1
      || Math.sign(money - roomById.cost) === 1;

  const isSelectedTimelineAvailable = isTimelineSelected
    ? times.slice(startTime, endTime).every(timeline => (
      availableTimes.includes(timeline)
    ))
    : false;

  const withPriceBreakdown = useMemo(() => (
    roomById.costBreakdown?.find(breakdown => breakdown.type === 'peakPrice')
  ), [roomById, endTime, startTime]);

  const isModifyingAvailable = dayjs(fromDateTime) >= dayjs().add(user.isActivate ? 24 : 1, 'hour');

  const paymentInfo = useMemo(() => {
    if (!isEnoughCredits) {
      if (!isEditing && currentLocation.isWhiteLabeled && !isMindspaceEmployee) {
        return `No credits available. Please purchase company credits or pay ${
          getCurrency(currentLocation.currency, roomById.cost)} for this booking`;
      }
      if ((isEditing && paymentType === 'credits') || !isEditing) {
        return 'Not enough credits available for this booking';
      }
    }

    return `You can cancel or modify up to ${user.isActivate || isEventSpace ? '24 hours' : '1 hour'} ` +
        'before the booking start time and receive a full refund' +
        `${currentLocation.isWhiteLabeled ? '.' : ' of credits.'}`;
  }, [
    isEnoughCredits,
    isEditing,
    currentLocation.isWhiteLabeled,
    isMindspaceEmployee,
    currentLocation.currency,
    roomById.cost,
  ]);

  const handleCompanyListModalChangeState = useCallback(() => {
    setIsCompanyListModalOpen(prevState => !prevState);
  }, []);

  const handleExternalCompanyModalChangeState = useCallback(() => {
    setIsExternalCompanyModalOpen(prevState => !prevState);
  }, []);

  const handleCompanyRemove = useCallback(() => {
    onExternalCompanySelect('');
    onCompanySelect({ id: 0, name: '' });
  }, []);

  return (
    <>
      <Flex
        width={['100%']}
        maxWidth={480}
        flexDirection="column"
        border="1px solid #EFECE6"
        height="fit-content"
        position="relative"
      >
        {getBookingCostPhase === LOADING && (
          <Loader position="absolute" background="rgba(255, 255, 255, 0.2)" width={40} height={40} />
        )}
        <Flex
          flexDirection="column"
          px={[10, 15, 20, 25]}
          pt={[10, 15, 20, 25]}
          pb={!isAdmin || selectedCompany?.id || externalCompany ? [10, 15, 20, 25] : 0}
        >
          <Flex flexDirection="column" width="100%">
            <Flex height={[100, 140, 180, 260]}>
              <RoomPhotosCarousel photos={roomPhotos} />
            </Flex>
            {isAdmin && (
              <>
                {!isEditing && (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom={selectedCompany.id || externalCompany ? `1px solid ${theme.color.gray[100]}` : 0}
                    width="100%"
                    py={25}
                  >
                    <Typography
                      {...(!selectedCompany?.id && !externalCompany && { onClick: handleCompanyListModalChangeState })}
                      pointer={!selectedCompany?.id && !externalCompany}
                      alignSelf="start"
                      variant="proximaNova-400"
                      color={selectedCompany?.id || externalCompany ? theme.color.gray[500] : theme.color.darkGreen}
                    >
                      {`${selectedCompany?.id || externalCompany ? 'Booking' : 'Book'} 
                      for ${externalCompany ? 'External' : 'Member'} Company`}
                    </Typography>
                    {!selectedCompany?.id && !externalCompany && isEventSpace && (
                      <Typography
                        {...(!externalCompany && { onClick: handleExternalCompanyModalChangeState })}
                        pointer={!externalCompany}
                        alignSelf="start"
                        variant="proximaNova-400"
                        color={externalCompany ? theme.color.gray[500] : theme.color.darkGreen}
                      >
                        Book for External Company
                      </Typography>
                    )}
                    {(!!selectedCompany?.id || !!externalCompany) && (
                      <Flex onClick={handleCompanyRemove} pointer alignItems="center">
                        <Icon height={10} width={10} color={theme.color.darkGreen} SVG={Close} />
                        <Typography variant="proximaNova-400" color={theme.color.darkGreen} ml="7px">
                          Remove
                        </Typography>
                      </Flex>
                    )}
                  </Flex>
                )}
                {(!!selectedCompany?.id || externalCompany) && (
                  <Flex alignItems="center" mt={20} justifyContent="space-between">
                    <Typography variant="proximaNova-400" color={theme.color.gray[500]}>
                      {selectedCompany.name || externalCompany}
                    </Typography>
                    {!isEditing && !externalCompany && (
                      <Icon
                        width={12}
                        height={12}
                        color={theme.color.gray[500]}
                        SVG={Edit}
                        pointer
                        onClick={handleCompanyListModalChangeState}
                      />
                    )}
                  </Flex>
                )}
              </>
            )}
          </Flex>
          {(!isMindspaceEmployee || !!selectedCompany?.id)
              && ((isEditing && paymentType === 'credits') || !isEditing)
              && (
                <>
                  <Typography
                    mt={[10, 12, 12, 15]}
                    mb="12px"
                    variant="garamond-500"
                    fontSize={[14, 16, 18, 20]}
                  >
                    Credits
                  </Typography>
                  <Flex mb={['6px', '6px', '8px', 14]} justifyContent="space-between">
                    <Typography
                      variant="proximaNova-400"
                      fontSize={[10, 12, 12, 14]}
                      color={theme.color.gray[300]}
                      mr="6px"
                    >
                      {`Available for ${selectedCompany?.id ? selectedCompany.name : companyName}`}
                    </Typography>
                    <Typography
                      color={theme.color.gray[300]}
                      variant="proximaNova-400"
                      fontSize={[10, 12, 12, 14]}
                    >
                      {availableCredits.toFixed(2)}
                    </Typography>
                  </Flex>
                  {withPriceBreakdown ? (
                    <>
                      <Typography mb="14px" variant="proximaNova-600" fontSize={[10, 12, 12, 14]} mr="6px">
                        Credits for booking
                      </Typography>
                      {roomById.costBreakdown.map(breakdown => (
                        <Flex mb="14px" justifyContent="space-between">
                          <Flex>
                            <Typography
                              mr="5px"
                              variant="proximaNova-400"
                              fontSize={[11, 12, 13, 14]}
                              color={theme.color.gray[300]}
                            >
                              {`${breakdown.durationInMinutes / 60} 
                              ${breakdown.durationInMinutes / 60 === 1 ? 'hr' : 'hrs'} at`}
                            </Typography>
                            {breakdown.type === 'peakPrice' ?
                              (
                                <PricingDetails
                                  locationTimeFormat={currentLocation.timeFormat}
                                  baseCreditPrice={roomById.prices?.base.creditsPerHour}
                                  peakTimeCreditPrice={roomById.prices?.peakPrice.creditsPerHour}
                                  peakTimeDays={roomById.prices?.peakPrice.timeFrames.daysOfWeek}
                                  peakTimeIntervals={roomById.prices?.peakPrice.timeFrames.times}
                                  isClickable={roomById?.prices?.peakPrice}
                                >
                                  <Typography
                                    variant="proximaNova-400"
                                    fontSize={[11, 12, 13, 14]}
                                    color={theme.color.gray[300]}
                                  >
                                    Peak Time
                                  </Typography>
                                </PricingDetails>
                              ) : (
                                <Typography
                                  variant="proximaNova-400"
                                  fontSize={[11, 12, 13, 14]}
                                  color={theme.color.gray[300]}
                                >
                                  base
                                </Typography>
                              ) }
                            <Typography
                              ml="4px"
                              mr="8px"
                              variant="proximaNova-400"
                              fontSize={[11, 12, 13, 14]}
                              color={theme.color.gray[300]}
                            >
                              rate
                            </Typography>
                            <Typography
                              variant="proximaNova-400"
                              fontSize={[11, 12, 13, 14]}
                              color={theme.color.gray[300]}
                            >
                              (
                            </Typography>
                            <Icon mr="5px" SVG={Credits} width={[10, 13, 16]} heigh={[10, 13, 16]} />
                            <Typography
                              variant="proximaNova-400"
                              fontSize={[11, 12, 13, 14]}
                              color={theme.color.gray[300]}
                            >
                              {`${breakdown.creditsPerHour} / hr`}
                            </Typography>
                            <Typography
                              variant="proximaNova-400"
                              fontSize={[11, 12, 13, 14]}
                              color={theme.color.gray[300]}
                            >
                              )
                            </Typography>
                          </Flex>
                          <Typography
                            variant="proximaNova-400"
                            fontSize={[11, 12, 13, 14]}
                            color={theme.color.gray[300]}
                          >
                            {breakdown.totalCredits.toFixed(2)}
                          </Typography>
                        </Flex>
                      ))}
                      <Flex justifyContent="space-between">
                        <Typography variant="proximaNova-600" fontSize={[10, 12, 12, 14]}>
                          Total
                        </Typography>
                        <Typography variant="proximaNova-600" fontSize={[10, 12, 12, 14]}>
                          {roomById.creditsCost.toFixed(2)}
                        </Typography>
                      </Flex>
                    </>
                  ) : (
                    <Flex justifyContent="space-between">
                      <Typography variant="proximaNova-600" fontSize={[10, 12, 12, 14]} mr="6px">
                        Credits for booking
                      </Typography>
                      <Typography variant="proximaNova-600" fontSize={[10, 12, 12, 14]}>
                        {roomById.creditsCost.toFixed(2)}
                      </Typography>
                    </Flex>
                  )}
                  {/* eslint-disable-next-line max-len */}
                  {!isMindspaceEmployee && !isEditing && currentLocation.isWhiteLabeled && isEnoughCredits && (
                    <Typography
                      color={theme.color.gray[300]}
                      variant="proximaNova-400"
                      fontSize={[10, 12, 12, 14]}
                      mt={['6px', '6px', '8px', 10]}
                    >
                      {`You can either use company credits or pay ${
                        getCurrency(currentLocation.currency, roomById.cost)} for this booking.`}
                    </Typography>
                  )}
                </>
              )}
        </Flex>
        {(!isMindspaceEmployee || !!selectedCompany?.id) && (
          <Flex width="100%" border="0.5px solid #EFECE6" />
        )}
        <Flex
          flexDirection="column"
          px={[10, 15, 20, 25]}
          pb={[10, 15, 20, 25]}
          pt={!isMindspaceEmployee || selectedCompany?.id ? [10, 15, 20, 25] : 0}
        >
          {(!isMindspaceEmployee || !!selectedCompany?.id) && (
            <Typography
              color={!isEnoughCredits && ((isEditing && paymentType === 'credits')
                  || !currentLocation.isWhiteLabeled
                  || isMindspaceEmployee)
                ? '#FF4747'
                : theme.color.gray[300]}
              variant="proximaNova-400"
              fontSize={[10, 12, 12, 14]}
              mb={[10, 15, 15, 20]}
            >
              {paymentInfo}
            </Typography>
          )}
          {!isEnoughCredits && !isMindspaceEmployee && !user.isActivate && !isAbleToPurchase && (
            <>
              <Typography
                maxWidth={[200, 250, 350]}
                variant="proximaNova-400"
                fontSize={[12, 14]}
                color={theme.color.gray[300]}
                mb={[10, 20]}
              >
                Please reach out to your company admin in order to purchase more credits.
              </Typography>
              <Typography variant="proximaNova-600" fontSize={[12, 14]} mb={[10, 20]}>
                Your company admins
              </Typography>
              <Flex
                mb={[20, 30]}
                width="100%"
                display="grid"
                gridGap="5px"
                gridTemplateColumns="repeat(2,1fr)"
              >
                <CompanyManagers />
              </Flex>
            </>
          )}
          <ManageBookingButtons
            onPayNowClick={onPayNowClick}
            onBuyCreditsClick={onBuyCreditsClick}
            isEventSpace={isEventSpace}
            isEnoughCredits={isEnoughCredits}
            isCompanySelected={!!selectedCompany?.id}
            isEditing={isEditing}
            bookingHours={bookingHours}
            onSubmit={isEditing ? onEditBooking : onSubmit}
            isSelectedTimelineAvailable={isSelectedTimelineAvailable}
            paymentType={paymentType}
            money={money}
            credits={credits}
          />
          {isEditing && !isMindspaceEmployee && isCostDecreased && isModifyingAvailable ? (
            <Typography
              color={theme.color.gray[300]}
              variant="proximaNova-400"
              fontSize={[10, 12, 12, 14]}
              mt={[10, 15, 15, 20]}
            >
              {paymentType === 'credits'
                ? `You will receive a refund of ${credits - roomById.creditsCost} ` +
                  `${credits - roomById.creditsCost > 1 ? 'credits' : 'credit'} based on your modification.`
                : 'You will receive a refund of ' +
                  `${getCurrency(currentLocation.currency, money - roomById.cost)} `
                  + 'based on your modification.'}
            </Typography>
          ) : null}
        </Flex>
      </Flex>
      {isCompanyListModalOpen && (
        <CompanyListModal
          selectedCompany={selectedCompany}
          onModalClose={handleCompanyListModalChangeState}
          onCompanySelect={onCompanySelect}
          isOpen={isCompanyListModalOpen}
        />
      )}
      {isExternalCompanyModalOpen && (
        <ExternalCompanyModal
          onModalClose={handleExternalCompanyModalChangeState}
          onCompanySelect={onExternalCompanySelect}
          isOpen={isExternalCompanyModalOpen}
        />
      )}
    </>
  );
};

ManageBookingPanel.defaultProps = {
  roomPhotos: [],
  availableTimes: [],
  companyName: '',
  isEditing: false,
  onEditBooking: () => {},
  externalCompany: '',
  onExternalCompanySelect: () => {},
  selectedCompany: {
    id: 0,
    name: '',
  },
};

ManageBookingPanel.propTypes = {
  isEventSpace: bool.isRequired,
  externalCompany: string,
  onExternalCompanySelect: func,
  onPayNowClick: func.isRequired,
  onBuyCreditsClick: func.isRequired,
  onCompanySelect: func.isRequired,
  selectedCompany: exact({
    name: string,
    id: number,
  }),
  onEditBooking: func,
  isEditing: bool,
  onSubmit: func.isRequired,
  times: arrayOf(object).isRequired,
  availableTimes: arrayOf(object),
  startTime: number.isRequired,
  endTime: number.isRequired,
  roomPhotos: arrayOf(string),
  companyName: string,
};

export default ManageBookingPanel;
