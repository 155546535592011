import styled, { css } from 'styled-components';
import { theme } from '@styles';
import { Flex } from '@mixins';

export const Container = styled(Flex)`
  ${() => css`
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: ${theme.color.gray[100]};
    }

    .Number {
      padding: 15px;
      font-size: 16px;
      border: 1px solid #D9D9D9;
    }
    .Cvc, .Expiry {
      border: 1px solid #D9D9D9;
      border-top: none;
      padding: 15px;
      font-size: 16px;
      width: 50%;
    }
  `}
`;
