import React, { useMemo } from 'react';
import { string, arrayOf, exact, func, number, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Avatar, Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { routes } from '@constants';
import { theme } from '@styles';

import { ReactComponent as Edit } from '@assets/svgs/Edit.svg';

const CompanyTeam = ({ amount, team, onLoadMore, from, isEditable }) => {
  const history = useHistory();

  const handleMemberClick = id => () => {
    history.push({
      pathname: `${routes.userProfile}/${id}`,
      state: {
        from: from || routes.companyProfile,
      },
    });
  };

  const handleManage = () => {
    history.push(routes.companyMembersApp);
  };

  const teamBlock = useMemo(() => team.map(({ firstName, lastName, id, imageUrl, isHidden, jobTitle }) => (
    <Flex
      pointer
      onClick={handleMemberClick(id)}
      key={id}
      alignItems="center"
      mb={[10, 15, 20]}
      mr="8px"
      width={['100%', '100%', '100%', '48%']}
    >
      <Avatar
        isHidden={isHidden}
        firstName={firstName}
        lastName={lastName}
        width={[70, 80, 90]}
        height={[70, 80, 90]}
        imageUrl={imageUrl}
        fontSize={['25px', '30px', '35px']}
      />
      <Flex ml={[11, 13, 15]} flexDirection="column">
        <Typography
          overflow="hidden"
          maxWidth="220px"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          mb="5px"
          variant="proximaNova-600"
          fontSize={[12, 12, 14, 14]}
        >
          {isHidden ? 'Mindspace Member' : `${firstName} ${lastName}`}
        </Typography>
        <Typography variant="proximaNova-400" fontSize={[12, 12, 14, 14]} color={theme.color.gray[300]}>
          {jobTitle}
        </Typography>
      </Flex>
    </Flex>
  )), [team]);

  return (
    <Flex
      mb={[10, 16, 20]}
      width="100%"
      flexDirection="column"
      alignItems="start"
    >
      <Typography fontSize={[24, 28, 32]} variant="garamond-500" mb={[20, 24, 28]}>
        The Team
      </Typography>
      <Flex mb={[12, 16, 20]} alignItems="center">
        <Typography
          mr="23px"
          fontSize={[10, 12, 14]}
          variant="proximaNova-400"
          color={theme.color.gray[300]}
        >
          {`${amount} ${amount === 1 ? 'teammate' : 'teammates'}`}
        </Typography>
        {isEditable && (
          <Flex alignItems="center" pointer onClick={handleManage}>
            <Icon height="15px" width="15px" SVG={Edit} />
            <Typography
              variant="proximaNova-400"
              color={theme.color.darkGreen}
              ml="6px"
            >
              Manage
            </Typography>
          </Flex>
        )}
      </Flex>
      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems="center"
        mb={[20, 30, 40]}
        flexWrap="wrap"
        width="100%"
      >
        {teamBlock}
      </Flex>
      {team.length !== amount && (
        <Typography
          pointer
          onClick={onLoadMore}
          variant="proximaNova-400"
          fontSize={[12, 14, 16]}
          color={theme.color.darkGreen}
        >
          Load more
        </Typography>
      )}
    </Flex>
  );
};

CompanyTeam.defaultProps = {
  team: [],
  amount: 0,
};

CompanyTeam.propTypes = {
  isEditable: bool.isRequired,
  from: string.isRequired,
  amount: number,
  team: arrayOf(
    exact({
      id: number,
      firstName: string,
      lastName: string,
      imageUrl: string,
      jobTitle: string,
      role: string,
    }),
  ),
  onLoadMore: func.isRequired,
};

export default CompanyTeam;
