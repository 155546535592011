import React from 'react';
import { func, string } from 'prop-types';

import { TextInput } from '@atoms/Input';
import { Typography, Button } from '@mixins';
import { theme } from '@styles';

import { Error } from '@molecules/WelcomeForm/styles';

const GuestForm = ({
  onAddUser,
  email,
  firstName,
  lastName,
  error,
  onEmailChange,
  onFirstNameChange,
  onLastNameChange,
}) => (
  <>
    <Typography mb="8px" variant="garamond-500" fontSize={[18, 20, 22, 24]}>
      First name*
    </Typography>
    <TextInput value={firstName} onChange={onFirstNameChange} placeholder="First name" />
    <Typography mt={22} mb="8px" variant="garamond-500" fontSize={[18, 20, 22, 24]}>
      Last name*
    </Typography>
    <TextInput value={lastName} onChange={onLastNameChange} placeholder="Last name" />
    <Typography mt={22} mb="8px" variant="garamond-500" fontSize={[18, 20, 22, 24]}>
      Email*
    </Typography>
    <TextInput value={email} onChange={onEmailChange} placeholder="Email" />
    <Error mt={10} hasError={error} fontSize={[10, 12]} variant="proximaNova-400" color={theme.color.red}>
      {error}
    </Error>
    <Button
      onClick={onAddUser}
      width="fit-content"
      alignSelf="center"
      mt={30}
      px={95}
      py={10}
      variant="primary"
      disabled={!firstName || !lastName || !email}
    >
      Next
    </Button>
  </>
);

GuestForm.propTypes = {
  onAddUser: func.isRequired,
  email: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  error: string.isRequired,
  onEmailChange: func.isRequired,
  onFirstNameChange: func.isRequired,
  onLastNameChange: func.isRequired,
};

export default GuestForm;
