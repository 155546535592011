export const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        minWidth: '1280px',
      },
      '.MuiDataGrid-root': {
        '& .MuiDataGrid-columnHeader': {
          '&:focus-within': {
            outline: 'none !important',
            outlineWidth: 'none !important',
            outlineOffset: 'none !important',
          },
        },
        '& .MuiDataGrid-cell': {
          '&:focus-within': {
            outline: 'none !important',
            outlineWidth: 'none !important',
            outlineOffset: 'none !important',
          },
        },
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      row: {
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '&.Mui-selected.Mui-hovered': {
          backgroundColor: 'transparent',
        },
      },
      root: {
        mt: 3,
        border: '1px solid gray[500]',
        '& .MuiDataGrid-main': {
          backgroundColor: '#fff',
          borderRadius: '4px',
        },
        '& .MuiDataGrid-row.Mui-hovered': {
          backgroundColor: 'transparent',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        // Take out the hover colour
        '& .MuiDataGrid-row:hover': {
          backgroundColor: 'transparent',
        },
        '&.MuiDataGrid-root .MuiDataGrid-row .Mui-selected': {
          backgroundColor: 'transparent',
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, , .MuiDataGrid-columnHeader:focus-within': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-footerContainer': {
          display: 'block',
        },
      },
    },
  },
};
