import { object } from 'yup';

import { shapes } from './shapes';

const schemas = {
  companyMember: object().shape({
    email: shapes.email,
    firstName: shapes.firstName,
    lastName: shapes.lastName,
  }),
};

export default schemas;
