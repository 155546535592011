import React, { useMemo } from 'react';
import { func, object, arrayOf, string } from 'prop-types';
import { useSelector } from 'react-redux';

import { RoomCard } from '@molecules';
import { Flex, Typography } from '@mixins';
import { getAvailableTimelines, getClosestTime, formatTime } from '@helpers/time';
import { dayjs } from '@utils';
import { theme } from '@styles';

import Gif from '@assets/gifs/no-rooms.gif';

const RoomsList = ({ onRoomClick, date, timeList, roomTypeSelected }) => {
  const { rooms: { meetingRooms } } = useSelector(store => (
    store.roomStore
  ));

  const isEventSpaceSelected = roomTypeSelected === 'Event Space';

  const closestTime = getClosestTime();

  const timelineScrollToIndex = timeList.findIndex(
    time => formatTime(time, '24h') === formatTime(closestTime, '24h'),
  );

  const isToday = date.$d.toString().substring(0, 15) === dayjs().$d.toString().substring(0, 15);

  const timelinesList = isToday
    ? timeList.filter(timeline => {
      const time = timeline.$d;

      const now = dayjs()
        .minute(dayjs().minute() - 10)
        .second(0)
        .millisecond(0).$d;

      return time >= now;
    })
    : timeList;

  const roomsBlock = useMemo(() => meetingRooms?.map(({
    id, image, roomNumber, floor, workstationCount, features, creditsPerHour, bookings, pricePerHour, roomType,
    maxBookingMinutes, minBookingMinutes, prices,
  }) => {
    const roomAvailableTimelines = getAvailableTimelines(timelinesList, bookings);

    return (
      <RoomCard
        creditPrices={prices}
        maxBookingHours={maxBookingMinutes ? maxBookingMinutes / 60 : 24}
        minBookingHours={minBookingMinutes ? minBookingMinutes / 60 : 0.5}
        isEventSpace={roomType === 'event_space'}
        bookings={bookings}
        timelineScrollTo={isToday ? timelineScrollToIndex : 20}
        timeList={timeList}
        onRoomClick={onRoomClick}
        key={id}
        id={id}
        creditsPrice={creditsPerHour}
        currencyPrice={pricePerHour}
        imageUrl={image}
        number={roomNumber}
        floor={floor}
        seats={workstationCount}
        availableTimes={roomAvailableTimelines}
        features={features}
      />
    );
  }), [meetingRooms, date, timeList]);

  return meetingRooms?.length
    ? roomsBlock
    : (
      <Flex width="100%" alignItems="center" flexDirection="column">
        {!isEventSpaceSelected && (
          <Flex as="img" mb={[25, 40, 55]} width={[200, 300]} height={[150, 230]} src={Gif} />
        )}
        <Typography mb={10} variant="garamond-500" fontSize={[30, 50]}>
          {isEventSpaceSelected ? 'Ugh! Not available' : 'Ugh! All taken'}
        </Typography>
        <Typography variant="proximaNova-400" fontSize={[15, 21]} color={theme.color.gray[300]}>
          {isEventSpaceSelected
            ? 'Please contact your Community Team for assistance'
            : 'No results found for your search.. try different filters'}
        </Typography>
      </Flex>
    );
};

RoomsList.defaultProps = {
  roomTypeSelected: 'meeting_room',
  timeList: [],
};

RoomsList.propTypes = {
  roomTypeSelected: string,
  timeList: arrayOf(object),
  date: object.isRequired,
  onRoomClick: func.isRequired,
};

export default RoomsList;
