import React from 'react';
import { ReactComponent as MissingValue } from '@assets/svgs/MissingValue.svg';
import { ReactComponent as Info } from '@assets/svgs/Info.svg';
import { ReactComponent as Edit } from '@assets/svgs/Edit.svg';
import { ReactComponent as Check } from '@assets/svgs/Check.svg';
import { ReactComponent as Prohibit } from '@assets/svgs/Prohibit.svg';
import { Typography, Flex } from '@mixins';
import { BootstrapTooltip, Icon, Checkbox, Switcher, Avatar, DataTableEllipsisCell } from '@atoms';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js/max';
import { updateCompanyMember } from '@store/company/duck';
import { theme } from '@styles';
import COUNTRIES from '@constants/countries';
import { store } from '../../../App';

export const columns = userId => [
  {
    id: 1,
    maxWidth: 130,
    flex: 1,
    field: 'firstName',
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        First Name
      </DataTableEllipsisCell>
    ),
    sortable: true,
    renderCell: ({ value, row: { lastName, imageUrl, status } }) => (
      <BootstrapTooltip title={value} placement="top">
        <Flex maxWidth={130} alignItems="center">
          <Avatar
            width={[24]}
            height={[24]}
            imageUrl={imageUrl}
            firstName={value}
            lastName={lastName}
            fontSize={['10px']}
          />
          <Flex ml="10px">
            <DataTableEllipsisCell
              fontSize={['8px', '10px', '10px', '12px']}
              color={status === 'active' ? theme.color.black : theme.color.gray[200]}
            >
              {value}
            </DataTableEllipsisCell>
          </Flex>
        </Flex>
      </BootstrapTooltip>
    ),
  },
  {
    id: 2,
    maxWidth: 130,
    field: 'lastName',
    flex: 1,
    sortable: true,
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        Last Name
      </DataTableEllipsisCell>
    ),
    renderCell: ({ value, row: { status } }) => (
      <BootstrapTooltip title={value} placement="top">
        <DataTableEllipsisCell
          fontSize={['8px', '10px', '10px', '12px']}
          color={status === 'active' ? theme.color.black : theme.color.gray[200]}
        >
          {value}
        </DataTableEllipsisCell>
      </BootstrapTooltip>
    ),
  },
  {
    id: 3,
    flex: 1,
    maxWidth: 180,
    field: 'email',
    sortable: true,
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        Email
      </DataTableEllipsisCell>
    ),
    renderCell: ({ value, row: { status } }) => (
      <BootstrapTooltip title={value} placement="top">
        <DataTableEllipsisCell
          fontSize={['8px', '10px', '10px', '12px']}
          color={status === 'active' ? theme.color.black : theme.color.gray[200]}
        >
          {value}
        </DataTableEllipsisCell>
      </BootstrapTooltip>
    ),
  },
  {
    id: 4,
    flex: 1,
    maxWidth: 130,
    field: 'phone',
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        Phone Number
      </DataTableEllipsisCell>
    ),
    sortable: true,
    renderCell: ({ value, row: { status } }) =>
      <BootstrapTooltip title={value} placement="top">
        <DataTableEllipsisCell
          fontSize={['8px', '10px', '10px', '12px']}
          color={status === 'active' ? theme.color.black : theme.color.gray[200]}
        >
          {value ? new AsYouType().input(value) : '-'}
        </DataTableEllipsisCell>
      </BootstrapTooltip>
     || <MissingValue />,
  },
  {
    id: 5,
    maxWidth: 110,
    flex: 1,
    field: 'isCompanyAdmin',
    renderHeader: () => (
      <BootstrapTooltip
        title={(
          <>
            <Typography color="inherit">Admins have the following permissions:</Typography>
            <Typography color="inherit">- to add/remove team members for easy onboarding/off-boarding</Typography>
            <Typography color="inherit">- to see all room bookings made by the team</Typography>
            <Typography color="inherit">- to see the team’s Credit Usage history</Typography>
            <Typography color="inherit">- to manage Company profile</Typography>
          </>
        )}
        placement="top"
      >
        <Flex alignItems="center">
          <Typography
            overflow="hidden"
            white-space="nowrap"
            mr="5px"
            fontSize={['8px', '10px', '12px', '14px']}
            variant="proximaNova-600"
          >
            Admin
          </Typography>
          <Icon SVG={Info} />
        </Flex>
      </BootstrapTooltip>
    ),
    sortable: true,
    renderCell: ({ value }) => <Checkbox onClick={() => {}} isEnabled={value} />,
  },
  {
    id: 6,
    flex: 1,
    maxWidth: 160,
    field: 'isAbleToBookMeetingRoom',
    renderHeader: () => (
      <BootstrapTooltip title="Whether or not the member has permission to book meeting rooms." placement="top">
        <Flex alignItems="center">
          <Typography
            overflow="hidden"
            whiteSpace="nowrap"
            mr="5px"
            fontSize={['8px', '10px', '12px', '14px']}
            variant="proximaNova-600"
          >
            Room Booking
          </Typography>
          <Icon SVG={Info} />
        </Flex>
      </BootstrapTooltip>
    ),
    sortable: true,
    renderCell: ({ value, row: { status } }) => (
      <Flex
        px={['6px', '6px', '8px', '10px']}
        alignItems="center"
        py="5px"
        border="1px solid #D9D9D9"
        borderRadius="16px"
      >
        {value ? <Icon SVG={Check} /> : <Icon SVG={Prohibit} />}
        <Typography
          color={status === 'active' ? theme.color.black : theme.color.gray[200]}
          ml="5px"
          fontSize={['8px', '10px', '10px', '12px']}
        >
          {value ? 'Allowed' : 'Not allowed'}
        </Typography>
      </Flex>
    ),
  },
  {
    id: 7,
    flex: 1,
    maxWidth: 200,
    field: 'isAbleToInvoice',
    renderHeader: () => (
      <BootstrapTooltip
        title="Permissions on how a member can purchase credits
      or specifying if a member is not permitted to make credit purchases for the company."
        placement="top"
      >
        <Flex alignItems="center">
          <Typography
            overflow="hidden"
            whiteSpace="nowrap"
            mr="5px"
            fontSize={['8px', '10px', '12px', '14px']}
            variant="proximaNova-600"
          >
            Credit Purchase
          </Typography>
          <Icon SVG={Info} />
        </Flex>
      </BootstrapTooltip>
    ),
    sortable: false,
    valueGetter: ({ row: { isAbleToPayByCard, isAbleToInvoice } }) => {
      if (isAbleToInvoice && isAbleToPayByCard) {
        return 'Credit card or Invoice';
      }
      if (isAbleToInvoice) {
        return 'Invoice';
      }
      if (isAbleToPayByCard) {
        return 'Credit card';
      }
      return 'Not allowed';
    },
    renderCell: ({ value, row: { isAbleToPayByCard, isAbleToInvoice, status } }) => (
      isAbleToInvoice || isAbleToPayByCard ? (
        <Typography
          color={status === 'active' ? theme.color.black : theme.color.gray[200]}
          px={['6px', '8px', '8px', '10px']}
          py="5px"
          borderRadius="16px"
          backgroundColor="#EFECE6"
          fontSize={['8px', '10px', '10px', '12px']}
        >
          {value}
        </Typography>
      )
        : (
          <Flex
            px={['6px', '6px', '8px', '10px']}
            alignItems="center"
            py="5px"
            border="1px solid #D9D9D9"
            borderRadius="16px"
          >
            <Icon SVG={Prohibit} />
            <Typography
              color={status === 'active' ? theme.color.black : theme.color.gray[200]}
              ml="5px"
              fontSize={['8px', '10px', '10px', '12px']}
            >
              {value}
            </Typography>
          </Flex>
        )
    ),
  },
  {
    id: 8,
    maxWidth: 90,
    field: 'status',
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        Status
      </DataTableEllipsisCell>
    ),
    sortable: true,
    renderCell: ({ value, row: { id, phone, ...member } }) => (
      (id !== userId && (
        <Switcher
          isEnabled={value === 'active'}
          onSwitch={event => {
            event.stopPropagation();
            store.dispatch(updateCompanyMember(id, {
              ...member,
              ...(phone && {
                phoneCode: COUNTRIES[parsePhoneNumber(phone).country]?.dialCode.slice(1),
                phoneNumber: parsePhoneNumber(phone).nationalNumber,
              }),
              status: value === 'active' ? 'inactive' : 'active',
            }));
          }}
        />
      )
      )
    ),
  },
  {
    id: 9,
    maxWidth: 50,
    field: 'edit',
    headerName: '',
    sortable: false,
    renderCell: () => <Icon width="20px" height="20px" SVG={Edit} />,
  },
];
