import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { EditUserProfile as View } from '@views';
import { routes } from '@constants';
import { SUCCESS, LOADING, FAILURE } from '@constants/requestPhase';
import { editUser, resetPhases } from '@store/user/duck';
import { getMetadata } from '@store/metadata/duck';

import { ReactComponent as Twitter } from '@assets/svgs/TwitterProfile.svg';
import { ReactComponent as Facebook } from '@assets/svgs/FacebookProfile.svg';
import { ReactComponent as LinkedIn } from '@assets/svgs/LinkedInProfile.svg';
import { ReactComponent as Instagram } from '@assets/svgs/InstagramProfile.svg';
import { trackEvent } from '@utils/mixpanel';

const EditUserProfile = () => {
  const { user, getCurrentUserPhase, editUserPhase, editUserError } = useSelector(store => (
    store.userStore
  ));

  const { getMetadataPhase, metadata } = useSelector(store => (
    store.metadataStore
  ));

  const [error, setError] = useState({});
  const [myStory, setMyStory] = useState(user.myStory ?? '');
  const [linkedinLink, setLinkedinLink] = useState(user.linkedin ?? '');
  const [instagramLink, setInstagramLink] = useState(user.instagram ?? '');
  const [facebookLink, setFacebookLink] = useState(user.facebook ?? '');
  const [twitterLink, setTwitterLink] = useState(user.twitter ?? '');
  const [skills, setSkills] = useState(user.skills ?? []);
  const [jobTitle, setJobTitle] = useState(user.jobTitle ?? '');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [blockedPath, setBlockedPath] = useState('');
  const [isPhotoLoading, setIsPhotoLoading] = useState(false);

  const isMyStoryTheSame = myStory === (user.myStory ?? '');
  const isLinkedinTheSame = linkedinLink === (user.linkedin ?? '');
  const isInstagramTheSame = instagramLink === (user.instagram ?? '');
  const isFacebookTheSame = facebookLink === (user.facebook ?? '');
  const isTwitterTheSame = twitterLink === (user.twitter ?? '');
  const areSkillsTheSame = JSON.stringify(skills) === JSON.stringify(user.skills ?? []);
  const isJobTitleTheSame = jobTitle === (user.jobTitle ?? '');

  const { state } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleConfirmationModalChangeState = () => {
    setIsConfirmationModalOpen(prevValue => !prevValue);
  };

  const resetValues = useCallback(() => {
    setMyStory(user.myStory ?? '');
    setLinkedinLink(user.linkedin ?? '');
    setInstagramLink(user.instagram ?? '');
    setTwitterLink(user.twitter ?? '');
    setFacebookLink(user.facebook ?? '');
    setSkills(user.skills ?? []);
    setJobTitle(user.jobTitle ?? '');
  }, []);

  const unblock = history.block(pathname => {
    if (hasChanges) {
      handleConfirmationModalChangeState();
      setBlockedPath(pathname);
      return false;
    }

    return true;
  });

  useEffect(() => {
    const pageTitle = 'Mindspace: Edit Profile';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'User Profile Editing',
    });

    if (!Object.keys(metadata).length) {
      dispatch(getMetadata());
    }

    return () => unblock();
  }, []);

  useEffect(() => {
    if (editUserPhase === SUCCESS) {
      dispatch(resetPhases());
      if (isPhotoLoading) {
        setIsPhotoLoading(false);
      } else {
        history.push({
          pathname: blockedPath || `${routes.userProfile}/${user.id}`,
          ...(state?.from && {
            state: {
              from: state.from,
            },
          }),
        });
      }
    }
    if (getCurrentUserPhase === SUCCESS) {
      if (user.id !== parseInt(id, 10)) {
        history.goBack();
      }
    }
    if (editUserPhase === FAILURE) {
      if (isPhotoLoading) {
        setIsPhotoLoading(false);
      }
      setError({
        status: editUserError.data.error.status,
        message: editUserError.data.error.message,
      });
      dispatch(resetPhases());
    }
  }, [editUserPhase, getCurrentUserPhase]);

  useEffect(() => {
    resetValues();
  }, [user]);

  const handleMyStorySave = text => {
    setMyStory(text);
  };

  const handleSkillsSave = newSkills => {
    setSkills(newSkills);
  };

  const handleJobTitleChange = e => {
    setJobTitle(e.target.value);
  };

  const handleEmailChange = () => {};

  const handleSave = () => {
    if (isConfirmationModalOpen) {
      handleConfirmationModalChangeState();
    }

    const userData = {};
    if (!isMyStoryTheSame) {
      userData.myStory = myStory;
    }
    if (!isLinkedinTheSame) {
      userData.linkedin = linkedinLink;
    }
    if (!isInstagramTheSame) {
      userData.instagram = instagramLink;
    }
    if (!isFacebookTheSame) {
      userData.facebook = facebookLink;
    }
    if (!isTwitterTheSame) {
      userData.twitter = twitterLink;
    }
    if (!isJobTitleTheSame) {
      userData.jobTitle = jobTitle;
    }
    if (!areSkillsTheSame) {
      userData.skills = skills;
    }

    dispatch(editUser(
      userData,
    ));
  };

  const handleDiscardChanges = () => {
    resetValues();

    history.block(() => {});

    history.push(blockedPath);
  };

  const handleBackClick = () => {
    history.push({
      pathname: `${routes.userProfile}/${user.id}`,
      ...(state?.from && {
        state: {
          from: state.from,
        },
      }),
    });
  };

  const handleSavePhoto = useCallback(file => {
    setIsPhotoLoading(true);
    const userData = new FormData();

    userData.append('image', file);

    dispatch(editUser(
      userData,
    ));
  }, []);

  const handleRemovePhoto = useCallback(() => {
    setIsPhotoLoading(true);
    dispatch(editUser({
      id,
      image: null,
    }));
  }, [id]);

  const socials = [
    {
      name: 'LinkedIn',
      value: linkedinLink,
      handler: e => { setLinkedinLink(e.target.value); },
      placeholder: 'linkedin.com/username/',
      icon: LinkedIn,
    },
    {
      name: 'Instagram',
      value: instagramLink,
      handler: e => { setInstagramLink(e.target.value); },
      placeholder: 'instagram.com/username/',
      icon: Instagram,
    },
    {
      name: 'Twitter',
      value: twitterLink,
      handler: e => { setTwitterLink(e.target.value); },
      placeholder: 'twitter.com/username/',
      icon: Twitter,
    },
    {
      name: 'Facebook',
      value: facebookLink,
      handler: e => { setFacebookLink(e.target.value); },
      placeholder: 'facebook.com/username/',
      icon: Facebook,
    },
  ];

  const hasChanges = !isJobTitleTheSame || !isMyStoryTheSame || !isLinkedinTheSame
  || !isInstagramTheSame || !isFacebookTheSame || !isTwitterTheSame || !areSkillsTheSame;

  const isLoading = getMetadataPhase === LOADING || (editUserPhase === LOADING && !isPhotoLoading);

  return (
    <View
      isPhotoLoading={isPhotoLoading}
      avatar={user.imageUrl}
      onSavePhoto={handleSavePhoto}
      onRemovePhoto={handleRemovePhoto}
      error={error}
      onBackClick={handleBackClick}
      isConfirmationModalOpen={isConfirmationModalOpen}
      onDiscardChanges={handleDiscardChanges}
      hasChanges={hasChanges}
      onSave={handleSave}
      onMyStorySave={handleMyStorySave}
      onSkillsSave={handleSkillsSave}
      onJobTitleChange={handleJobTitleChange}
      isLoading={isLoading}
      companyName={user.company?.name}
      firstName={user.firstName}
      lastName={user.lastName}
      socials={socials}
      jobTitle={jobTitle}
      skills={metadata.skills}
      userSkills={skills}
      myStory={myStory}
      onEmailChange={handleEmailChange}
      from={state?.from}
    />
  );
};

export default EditUserProfile;
