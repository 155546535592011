import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import * as api from './api';

// < ----- ACTIONS ----- > //
const resetPhasesAction = 'RESET_BENEFITS_PHASES';

const getBenefitsRoutine = createRoutine(
  'GET_BENEFITS',
);

const getBenefitByIdRoutine = createRoutine(
  'GET_BENEFIT_BY_ID',
);

const getBenefitsCategoriesRoutine = createRoutine(
  'GET_BENEFITS_CATEGORIES',
);

const resetStoreToInitialStateAction = 'RESET_STORE';

// < ----- ACTION CREATORS ----- > //
export const getBenefitById = id => async dispatch => {
  try {
    dispatch(getBenefitByIdRoutine.request());

    const response = await api.getBenefitById(id);

    return dispatch(getBenefitByIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getBenefitByIdRoutine.failure(error.response));
  }
};

export const getBenefitsCategories = () => async dispatch => {
  try {
    dispatch(getBenefitsCategoriesRoutine.request());

    const response = await api.getBenefitsCategories();

    return dispatch(getBenefitsCategoriesRoutine.success(response.data));
  } catch (error) {
    return dispatch(getBenefitsCategoriesRoutine.failure(error.response));
  }
};

export const getBenefits = data => async dispatch => {
  try {
    dispatch(getBenefitsRoutine.request());

    const response = await api.getBenefits(data);

    return dispatch(getBenefitsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getBenefitsRoutine.failure(error.response));
  }
};

export const resetStore = () => ({
  type: resetStoreToInitialStateAction,
});

export const resetPhases = () => ({
  type: resetPhasesAction,
});

// < ----- STATE ----- > //
export const roomStorePersistWhitelist = [];

const initialState = {
  benefitById: {},

  benefits: [],

  categories: [],

  getBenefitByIdPhase: INIT,
  getBenefitByIdError: null,

  getBenefitsPhase: INIT,
  getBenefitsError: null,

  getBenefitsCategoriesPhase: INIT,
  getBenefitsCategoriesError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;

    // RESET PHASES
    case resetPhasesAction:
      return {
        ...state,

        getBenefitByIdPhase: INIT,
        getBenefitByIdError: null,

        getBenefitsPhase: INIT,
        getBenefitsError: null,

        getBenefitsCategoriesPhase: INIT,
        getBenefitsCategoriesError: null,
      };
    // GET BENEFIT BY ID
    case getBenefitByIdRoutine.REQUEST:
      return {
        ...state,
        getBenefitByIdPhase: LOADING,
      };
    case getBenefitByIdRoutine.SUCCESS:
      return {
        ...state,
        getBenefitByIdPhase: SUCCESS,
        benefitById: payload.data,
      };
    case getBenefitByIdRoutine.FAILURE:
      return {
        ...state,
        getBenefitByIdPhase: FAILURE,
        getBenefitByIdError: payload,
      };
    // GET BENEFITS
    case getBenefitsRoutine.REQUEST:
      return {
        ...state,
        getBenefitsPhase: LOADING,
      };
    case getBenefitsRoutine.SUCCESS:
      return {
        ...state,
        getBenefitsPhase: SUCCESS,
        benefits: payload.data,
      };
    case getBenefitsRoutine.FAILURE:
      return {
        ...state,
        getBenefitsPhase: FAILURE,
        getBenefitsError: payload,
      };
    // GET BENEFITS CATEGORIES
    case getBenefitsCategoriesRoutine.REQUEST:
      return {
        ...state,
        getBenefitsCategoriesPhase: LOADING,
      };
    case getBenefitsCategoriesRoutine.SUCCESS:
      return {
        ...state,
        getBenefitsCategoriesPhase: SUCCESS,
        categories: payload.data,
      };
    case getBenefitsCategoriesRoutine.FAILURE:
      return {
        ...state,
        getBenefitsCategoriesPhase: FAILURE,
        getBenefitsCategoriesError: payload,
      };
    default:
      return state;
  }
};

export default store;
