import styled, { css } from 'styled-components';

import { Flex } from '@mixins';
import { theme } from '@styles';

export const Container = styled(Flex)`
  ${() => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, ${theme.sizes.shortGrid}px);
    justify-content: start;
    gap: 20px;
  `}
`;
