const routes = {
  home: '/',
  login: '/login',
  loginFailure: '/login/failure',
  verificationCode: '/verification-code',
  userProfile: '/user',
  companyProfile: '/company',
  companyMembersApp: '/company/members',
  companyMembers: '/company/members/:accessId',
  userProfileDetails: '/user/:id',
  editUserProfileDetails: '/user/:id/edit',
  companyProfileDetails: '/company/:id',
  editCompanyProfileDetails: '/company/:id/edit',
  benefits: '/benefits',
  benefitDetails: '/benefits/:id',
  rooms: '/rooms',
  roomDetails: '/rooms/:id',
  bookedRooms: '/booked-rooms',
  bookedRoomDetails: '/booked-rooms/:id',
  bookedRoomEdit: '/booked-rooms/:id/edit',
  bookingHistory: '/booking-history',
  knowYourNeighbors: '/know-your-neighbors',
  support: '/support',
  amenities: '/amenities',
  events: '/events',
  eventDetails: '/events/:id',
  exploreLocation: '/explore-location',
  creditsPackages: '/credits-packages',
  printingDetails: '/printing-details',
  houseRules: '/house-rules',
  paymentSettings: '/payment-settings',
  feedback: '/feedback',
};

export default routes;
