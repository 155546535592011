import React, { useState, useCallback } from 'react';
import { string, bool, exact, arrayOf, func, object, number } from 'prop-types';

import { TextInput } from '@atoms/Input';
import { EditSocials, TextModal, ConfirmationModal } from '@molecules';
import { PhotoChanger } from '@organisms';
import { Main } from '@templates';
import { theme } from '@styles';
import { Typography, Flex, Button } from '@mixins';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { BackButton } from './styles';

const EditUserProfile = ({
  avatar,
  onRemovePhoto,
  onSavePhoto,
  isPhotoLoading,
  error,
  onBackClick,
  isConfirmationModalOpen,
  onDiscardChanges,
  hasChanges,
  onMyStorySave,
  // onSkillsSave,
  // userSkills,
  onJobTitleChange,
  isLoading,
  firstName,
  lastName,
  myStory,
  jobTitle,
  companyName,
  // skills,
  socials,
  onSave,
}) => {
  const [isMyStoryModalOpen, setIsMyStoryModalOpen] = useState(false);
  // const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);

  const handleMyStoryModalChangeState = useCallback(() => {
    setIsMyStoryModalOpen(prevValue => !prevValue);
  }, []);

  // const handleSkillsModalChangeState = () => {
  //   setIsSkillsModalOpen(prevValue => !prevValue);
  // };

  const handleDiscardChanges = useCallback(() => {
    onDiscardChanges();
  }, []);

  const handleMyStorySave = useCallback(newMyStory => {
    onMyStorySave(newMyStory);
  }, []);

  // const handleSkillsSave = newSkills => () => {
  //   onSkillsSave(newSkills);
  //   handleSkillsModalChangeState();
  // };

  return (
    <Main isLoading={isLoading} error={error}>
      <Flex mb={[25, 30, 40]} width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" justifyContent="center">
          <Flex width="fit-content" mb={[20, 25, 30]} onClick={onBackClick} pointer alignItems="center">
            <BackButton mr={['9px', 11, 13]} color={theme.color.gray[500]} SVG={Dropdown} />
            <Typography variant="proximaNova-400">
              Back
            </Typography>
          </Flex>
          <Typography variant="proximaNova-600" fontSize={[26, 30, 34]}>
            Edit profile
          </Typography>
        </Flex>
        <Button
          py={['8px', 10, 12]}
          px={[40, 50, 60]}
          variant="primary"
          fontSize={[12, 14, 16]}
          onClick={onSave}
          disabled={!hasChanges}
        >
          Save
        </Button>
      </Flex>
      <Flex width="100%">
        <Flex
          mr={['6px', '8px', 10]}
          maxWidth={[150, 200, 250, 280]}
          minWidth={[150, 200, 250, 280]}
          flexDirection="column"
        >
          <Flex height={[150, 200, 250, 280]}>
            <PhotoChanger
              isLoading={isPhotoLoading}
              isEditable
              isHidden={false}
              isCompany={false}
              avatar={avatar}
              firstName={firstName}
              lastName={lastName}
              onSavePhoto={onSavePhoto}
              onRemovePhoto={onRemovePhoto}
            />
          </Flex>
          <Flex
            mt={15}
            height="fit-content"
            flexDirection="column"
          >
            <Typography
              wordBreak="break-word"
              mb={['8px', '10px', '12px']}
              variant="garamond-500"
              fontSize={['16px', '20px', '24px']}
            >
              {`${firstName} ${lastName}`}
            </Typography>
            <Typography
              wordBreak="break-word"
              color={theme.color.gray[300]}
              variant="proximaNova-400"
              fontSize={['12px', '14px', '16px']}
            >
              {companyName}
            </Typography>
          </Flex>
        </Flex>
        <Flex
          py={[20, 30, 40]}
          px={[25, 40, 55]}
          border={`1px solid ${theme.color.gray[100]}`}
          width="100%"
        >
          <Flex width="100%" flexDirection="column" maxWidth={[220, 340, 480]}>
            <Typography mb={['9px', 12, 15]} variant="garamond-500" fontSize={['16px', '18px', '20px']}>
              Role
            </Typography>
            <TextInput
              placeholder="Enter job title"
              value={jobTitle}
              onChange={onJobTitleChange}
            />
            <Typography
              mb={['9px', 12, 15]}
              mt={[30, 40, 50]}
              variant="garamond-500"
              fontSize={['16px', '18px', '20px']}
            >
              My Story
            </Typography>
            <Typography fontSize={[10, 14, 16]} variant="proximaNova-400">
              {myStory}
            </Typography>
            <Typography
              pointer
              onClick={handleMyStoryModalChangeState}
              mb={[25, 35, 45]}
              fontSize={[10, 14, 16]}
              color={theme.color.darkGreen}
              variant="proximaNova-400"
            >
              {myStory ? 'Edit' : 'Tell us about yourself'}
            </Typography>
            {/* <Flex flexDirection="column" mb={[25, 35, 45]}>
                <Typography mb={['9px', 12, 15]} variant="garamond-500" fontSize={['16px', '18px', '20px']}>
                  I&apos;m Amazing at
                </Typography>
                <UserSkills skills={userSkills} />
                <Typography
                  pointer
                  onClick={handleSkillsModalChangeState}
                  fontSize={[10, 14, 16]}
                  variant="proximaNova-400"
                  color={theme.color.darkGreen}
                >
                  {`+  Add${userSkills.length ? ' / Edit' : ''} skills`}
                </Typography>
              </Flex> */}
            <Typography mb="7px" variant="garamond-500" fontSize={['16px', '18px', '20px']}>
              Don&apos;t Be a Stranger
            </Typography>
            <EditSocials isCompany={false} socials={socials} />
          </Flex>
        </Flex>
      </Flex>
      {isMyStoryModalOpen && (
        <TextModal
          title="My Story"
          inputPlaceholder="Tell us about yourself"
          onSave={handleMyStorySave}
          isOpen={isMyStoryModalOpen}
          onModalClose={handleMyStoryModalChangeState}
          initialText={myStory}
        />
      )}
      {/* <SearchListModal
        withMultipleSelection
        onSelect={handleSkillsSave}
        title="Add Skill"
        itemsList={skills}
        selectedItemsList={userSkills}
        isOpen={isSkillsModalOpen}
        onModalClose={handleSkillsModalChangeState}
      /> */}
      <ConfirmationModal
        title="Leaving without saving?"
        text="If you quit this page now, your changes won’t be saved."
        onPrimaryButtonClick={onSave}
        onSecondaryButtonClick={handleDiscardChanges}
        isOpen={isConfirmationModalOpen}
        primaryButtonText="Save changes"
        secondaryButtonText="Discard changes"
      />
    </Main>
  );
};

EditUserProfile.defaultProps = {
  companyName: '',
  firstName: '',
  lastName: '',
  myStory: '',
  jobTitle: '',
  avatar: '',
  // skills: [],
  // userSkills: [],
  socials: [],
  error: {},
};

EditUserProfile.propTypes = {
  avatar: string,
  onRemovePhoto: func.isRequired,
  onSavePhoto: func.isRequired,
  isPhotoLoading: bool.isRequired,
  error: exact({
    status: number,
    message: string,
  }),
  onBackClick: func.isRequired,
  isConfirmationModalOpen: bool.isRequired,
  onDiscardChanges: func.isRequired,
  hasChanges: bool.isRequired,
  onJobTitleChange: func.isRequired,
  onMyStorySave: func.isRequired,
  // onSkillsSave: func.isRequired,
  onSave: func.isRequired,
  socials: arrayOf(
    exact({
      name: string,
      value: string,
      placeholder: string,
      handler: func,
      icon: object,
    }),
  ),
  companyName: string,
  myStory: string,
  jobTitle: string,
  isLoading: bool.isRequired,
  firstName: string,
  lastName: string,
  // skills: arrayOf(string),
  // userSkills: arrayOf(string),
};

export default EditUserProfile;
