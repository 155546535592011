import React, { useMemo } from 'react';
import { string, arrayOf } from 'prop-types';

import { Flex, Typography } from '@mixins';
import { theme } from '@styles';

const UserSkills = ({ skills }) => {
  const skillsBlock = useMemo(() => skills.map(skill => (
    <Typography
      key={skill}
      border={`1px solid ${theme.color.gray[100]}`}
      py="5px"
      px={['8px', 10, 12]}
      mb={['8px', 10, 12]}
      fontSize={[10, 12, 14]}
      variant="proximaNova-400"
      mr={['6px', '8px', 10]}
    >
      {skill}
    </Typography>
  )), [skills]);

  return (
    <Flex flexWrap="wrap">
      {skillsBlock}
    </Flex>
  );
};

UserSkills.defaultProps = {
  skills: [],
};

UserSkills.propTypes = {
  skills: arrayOf(string),
};

export default UserSkills;
