import React from 'react';
import { useSelector } from 'react-redux';
import { bool, func } from 'prop-types';

import { Modal, Icon } from '@atoms';
import { AddToCalendar } from '@molecules';
import { formatTime } from '@helpers/time';
import { dayjs } from '@utils';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';

import { ReactComponent as ThankYou } from '@assets/svgs/ThankYou.svg';

const EventBookedModal = ({
  isOpen,
  onClose,
  onBookAnotherDate,
}) => {
  const { currentLocation } = useSelector(store => store.locationStore);

  const { eventById: {
    details, bookedDetails, dates: { services, availableDates },
  } } = useSelector(store => store.eventStore);

  const getEventTimeline = () => {
    if (details.isRecurring && !details.hasSlots) {
      return {
        startTime: availableDates.find(availableDate => availableDate.date === bookedDetails.date)?.startTime,
        endTime: availableDates.find(availableDate => availableDate.date === bookedDetails.date)?.endTime,
      };
    }
    if (details.hasSlots) {
      return {
        startTime: bookedDetails.time,
        endTime: bookedDetails.endTime,
      };
    }
    return {
      startTime: details.startTime,
      endTime: details.endTime,
    };
  };

  const startDate = bookedDetails.date
    ? `${bookedDetails.date.substring(0, 10)}T${getEventTimeline().startTime}.000Z` : 0;

  const endDate = bookedDetails.date
    ? `${bookedDetails.date.substring(0, 10)}T${getEventTimeline().endTime}.000Z` : 0;

  const selectedService = services.find(service => service.id === bookedDetails.serviceId);

  return (
    <Modal topPosition={50} isOpen={isOpen} withCloseButton={false}>
      <Flex
        alignItems="center"
        width={[300, 350, 440]}
        flexDirection="column"
        pb={15}
        px={[60, 75, 90]}
      >
        <Icon mb={[15, 20]} SVG={ThankYou} />
        <Typography textAlign="center" mb={10} variant="proximaNova-600" fontSize={20}>
          Event Booked
        </Typography>
        <Typography
          textAlign="center"
          mb="8px"
          variant="proximaNova-400"
          fontSize={14}
          color={theme.color.gray[300]}
        >
          {selectedService ? selectedService.name : details.name}
        </Typography>
        <Typography
          textAlign="center"
          mb="8px"
          variant="proximaNova-400"
          fontSize={14}
          color={theme.color.gray[300]}
        >
          {`${dayjs(startDate).format('ddd')}, ${dayjs(startDate).format('MMMM')} 
          ${dayjs(startDate).date()} ${formatTime(startDate, currentLocation.timeFormat)}${endDate ?
      `-${formatTime(
        endDate, currentLocation.timeFormat,
      )}` : ''}`}
        </Typography>
        <Typography
          textAlign="center"
          mb={[20, 35]}
          variant="proximaNova-400"
          fontSize={14}
          color={theme.color.gray[300]}
        >
          {`${currentLocation.title}`}
        </Typography>
        {details.isRecurring && !details.hasSlots && (
          <Button
            onClick={onBookAnotherDate}
            py={12}
            mb={10}
            width="100%"
            color={theme.color.darkGreen}
            backgroundColor="#EFECE6"
          >
            RSVP for another date
          </Button>
        )}
        <Button mb={20} onClick={onClose} py={12} width="100%" variant="primary">
          Done
        </Button>
        <AddToCalendar
          startDate={dayjs(startDate)}
          endDate={endDate ? dayjs(endDate) : dayjs(startDate)}
          title={details.name}
          address={currentLocation.title}
        />
      </Flex>
    </Modal>
  );
};

EventBookedModal.defaultProps = {
  onBookAnotherDate: () => {},
};

EventBookedModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onBookAnotherDate: func,
};

export default EventBookedModal;
