import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { string, exact, number, instanceOf, bool, func, arrayOf } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Loader } from '@atoms';
import { GuestsList, GuestForm, GuestsHeader, ConfirmationModal, InviteSentModal } from '@molecules';
import { SUCCESS, LOADING } from '@constants/requestPhase';
import { getTimeList, formatTime } from '@helpers/time';
import { validateEmail } from '@helpers/common';
import { useViewport } from '@hooks';
import { Flex } from '@mixins';
import { dayjs } from '@utils';
import { theme } from '@styles';
import { inviteGuestToLocation, resetPhases as resetLocationPhases } from '@store/location/duck';
import { inviteGuestToRoom, resetPhases as resetRoomPhases } from '@store/booking/duck';

const GuestInvitation = ({ room, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const screenSize = useViewport();

  const { currentLocation, inviteGuestToLocationPhase } = useSelector(store => (
    store.locationStore
  ));

  const { inviteGuestToRoomPhase } = useSelector(store => (
    store.bookingStore
  ));

  const [users, setUsers] = useState([]);
  const [date, setDate] = useState(dayjs().add((dayjs().$u ? -new Date().getTimezoneOffset() : 0), 'minute'));
  const [time, setTime] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isInvitationFormOpen, setIsInvitationFormOpen] = useState(true);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isInviteSent, setIsInviteSent] = useState(false);

  const isInvitedToTheRoom = !!Object.keys(room).length;
  const isLoading = inviteGuestToRoomPhase === LOADING || inviteGuestToLocationPhase === LOADING;

  const times = useMemo(() => getTimeList(date), [date]);

  const selectedTime = useMemo(() => times.find(timesItem => (
    formatTime(timesItem, currentLocation.timeFormat) === time
  )), [time, date]);

  const handleModalClose = useCallback(() => {
    onClose();
    clearForm();
    setIsConfirmationModalOpen(false);
    setIsInvitationFormOpen(true);
    setIsInviteSent(false);
    setUsers([]);
    setTime(null);
    setDate(dayjs());
  }, []);

  const clearForm = useCallback(() => {
    setError('');
    setEmail('');
    setFirstName('');
    setLastName('');
  }, []);

  const handleCrossButtonClick = useCallback(() => {
    if (users.length || firstName || lastName || email) {
      setIsConfirmationModalOpen(true);
    } else {
      handleModalClose();
    }
  }, [users, firstName, lastName, email]);

  const handleAddUser = useCallback(() => {
    const isEmailUnique = !users.find(user => user.email === email);
    const isEmailValid = validateEmail(email);

    if (!isEmailUnique) {
      setError('A guest with this email has already been added');
    } else if (!isEmailValid) {
      setError('Please enter a valid email address');
    } else {
      setUsers(prevUsers => [{ email, firstName, lastName }, ...prevUsers]);
      setIsInvitationFormOpen(false);
      clearForm();
    }
  }, [users, email, firstName, lastName]);

  const handleDateSelect = useCallback(selectedDate => {
    setDate(
      dayjs(selectedDate)
        .add(-(dayjs().$offset + new Date(selectedDate).getTimezoneOffset()), 'minutes')
        .add((dayjs().$u ? -new Date(selectedDate).getTimezoneOffset() : 0), 'minute'),
    );
  }, [dayjs().$offset, dayjs().$u, new Date().getTimezoneOffset()]);

  const handleTimeSelect = useCallback(newTime => {
    setTime(newTime);
  }, []);

  const handleAddAnotherGuest = useCallback(() => {
    setIsInvitationFormOpen(true);
  }, []);

  const handleRemoveUser = userIndex => () => {
    setUsers(prevUsers => {
      const updatedUsers = [...prevUsers].filter((user, index) => index !== userIndex);

      if (!updatedUsers.length) {
        setIsInvitationFormOpen(true);
      }

      return updatedUsers;
    });
  };

  const handleGoBack = useCallback(() => {
    setIsInvitationFormOpen(false);
    clearForm();
  }, []);

  const handleKeepEditing = useCallback(() => {
    setIsConfirmationModalOpen(false);
  }, []);

  const handleSendInvite = useCallback(() => {
    if (isInvitedToTheRoom) {
      dispatch(inviteGuestToRoom(room?.bookingId, {
        guests: users,
      }));
    } else {
      dispatch(inviteGuestToLocation(currentLocation.id, {
        dateTime: selectedTime || date.toISOString(),
        guests: users,
      }));
    }
  }, [times, time, isInvitedToTheRoom, users, room, date]);

  const handleFirstNameChange = useCallback(e => {
    setFirstName(e.target.value);
  }, []);

  const handleLastNameChange = useCallback(e => {
    setLastName(e.target.value);
  }, []);

  const handleEmailChange = useCallback(e => {
    setEmail(e.target.value);

    if (error) {
      setError('');
    }
  }, [error]);

  useEffect(() => {
    if (inviteGuestToLocationPhase === SUCCESS || inviteGuestToRoomPhase === SUCCESS) {
      dispatch(resetLocationPhases());
      dispatch(resetRoomPhases());
      setIsInviteSent(true);
    }
  }, [inviteGuestToLocationPhase, inviteGuestToRoomPhase]);

  return (
    <>
      <InviteSentModal
        isOpen={isInviteSent}
        date={isInvitedToTheRoom ? room.startDate : date}
        time={isInvitedToTheRoom ? room.startDate : selectedTime}
        isInvitedToTheRoom={isInvitedToTheRoom}
        location={currentLocation.title}
        onClose={handleModalClose}
        timeFormat={currentLocation.timeFormat}
      />
      <Modal topPosition={70} onClose={handleCrossButtonClick} isOpen={isOpen && !isInviteSent}>
        {isLoading && <Loader position="absolute" background={theme.color.white} width={40} height={40} />}
        <ConfirmationModal
          title="Leaving without sending the invite?"
          text="Are you sure you don&apos;t want to invite guests?"
          onPrimaryButtonClick={handleModalClose}
          onSecondaryButtonClick={handleKeepEditing}
          isOpen={isConfirmationModalOpen}
          primaryButtonText="Yes, leave"
          secondaryButtonText="Keep editing"
        />
        <Flex
          {...(screenSize.height < 770 && {
            maxHeight: screenSize.height - 150,
            overflow: 'scroll',
          })}
          mt={10}
          width={[350, 400, 450, 500]}
          flexDirection="column"
          px={20}
        >
          <GuestsHeader
            times={times}
            isInvitationFormOpen={isInvitationFormOpen}
            room={room}
            usersAmount={users.length}
            location={currentLocation.title}
            date={date}
            time={time}
            onDateSelect={handleDateSelect}
            onTimeSelect={handleTimeSelect}
            onGoBack={handleGoBack}
            timeFormat={currentLocation.timeFormat}
          />
          {isInvitationFormOpen
            ? (
              <GuestForm
                onAddUser={handleAddUser}
                onLastNameChange={handleLastNameChange}
                onFirstNameChange={handleFirstNameChange}
                onEmailChange={handleEmailChange}
                email={email}
                lastName={lastName}
                firstName={firstName}
                error={error}
              />
            )
            : (
              <GuestsList
                users={users}
                onAddAnotherGuest={handleAddAnotherGuest}
                onSendInvite={handleSendInvite}
                onRemoveUser={handleRemoveUser}
              />
            )}
        </Flex>
      </Modal>
    </>
  );
};

GuestInvitation.defaultProps = {
  room: {},
};

GuestInvitation.propTypes = {
  isOpen: bool.isRequired,
  room: exact({
    bookingId: string,
    roomPhotos: arrayOf(string),
    startDate: instanceOf(Date),
    number: string,
    floor: number,
    title: string,
  }),
  onClose: func.isRequired,
};

export default GuestInvitation;
