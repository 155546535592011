import { apiEndpoints } from '@constants';
import { client } from '@utils';

const getMeetingRooms = data => (
  client.authorized.get(apiEndpoints.meetingRoom, {
    params: data,
  })
);

const getMeetingRoomById = id => (
  client.authorized.get(`${apiEndpoints.meetingRoom}/${id}`)
);

const getMeetingRoomsFilters = data => (
  client.authorized.get(apiEndpoints.meetingRoomFilters, {
    params: data,
  })
);

const getMeetingRoomBookingsById = (id, date) => (
  client.authorized.get(`${apiEndpoints.meetingRoom}/${id}/booking`, {
    params: {
      date,
    },
  })
);

const getBookingCost = data => (
  client.authorized.get(apiEndpoints.bookingCost, {
    params: data,
  })
);

export {
  getMeetingRooms,
  getMeetingRoomsFilters,
  getMeetingRoomBookingsById,
  getMeetingRoomById,
  getBookingCost,
};

