import React from 'react';
import { DataTableEllipsisCell } from '@atoms';
import { theme } from '@styles';
import { dayjs } from '@utils';

export const columns = [
  {
    id: 1,
    flex: 1,
    field: 'title',
    sortable: false,
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        Package
      </DataTableEllipsisCell>
    ),
    renderCell: ({ value }) => (
      <DataTableEllipsisCell
        fontSize={['8px', '10px', '10px', '12px']}
        color={theme.color.black}
      >
        {value}
      </DataTableEllipsisCell>
    ),
  },
  {
    id: 2,
    field: 'startDate',
    flex: 1,
    sortable: false,
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        Start Date
      </DataTableEllipsisCell>
    ),
    renderCell: ({ value }) => (
      <DataTableEllipsisCell
        fontSize={['8px', '10px', '10px', '12px']}
        color={theme.color.black}
      >
        {dayjs(value).format('MMM D, YYYY')}
      </DataTableEllipsisCell>
    ),
  },
  {
    id: 3,
    flex: 1,
    field: 'expireDate',
    sortable: false,
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        Expire Date
      </DataTableEllipsisCell>
    ),
    renderCell: ({ value }) => (
      <DataTableEllipsisCell
        fontSize={['8px', '10px', '10px', '12px']}
        color={theme.color.black}
      >
        {dayjs(value).format('MMM D, YYYY')}
      </DataTableEllipsisCell>
    ),
  },
  {
    id: 4,
    flex: 1,
    field: 'available',
    sortable: false,
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        Available
      </DataTableEllipsisCell>
    ),
    renderCell: ({ value }) => (
      <DataTableEllipsisCell
        fontSize={['8px', '10px', '10px', '12px']}
        color={theme.color.black}
      >
        {value}
      </DataTableEllipsisCell>
    ),
  },
  {
    id: 5,
    flex: 1,
    field: 'used',
    sortable: false,
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        Used
      </DataTableEllipsisCell>
    ),
    renderCell: ({ value }) => (
      <DataTableEllipsisCell
        fontSize={['8px', '10px', '10px', '12px']}
        color={theme.color.black}
      >
        {value}
      </DataTableEllipsisCell>
    ),
  },
  {
    id: 6,
    flex: 1,
    field: 'remaining',
    sortable: false,
    renderHeader: () => (
      <DataTableEllipsisCell
        fontSize={['10px', '10px', '12px', '14px']}
        color={theme.color.black}
        fontWeight={600}
      >
        Remaining
      </DataTableEllipsisCell>
    ),
    renderCell: ({ value }) => (
      <DataTableEllipsisCell
        fontSize={['8px', '10px', '10px', '12px']}
        color={theme.color.black}
      >
        {value}
      </DataTableEllipsisCell>
    ),
  },
];
