import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';

import { Loader } from '@atoms';
import { CardDetailsModal, ConfirmationModal } from '@molecules';
import { AddCardModal, CardsList } from '@organisms';
import { Typography, Flex, Button } from '@mixins';
import { theme } from '@styles';
import { removePaymentMethod } from '@store/payment/duck';
import { SUCCESS } from '@constants/requestPhase';
import { Main } from '@templates';

const PaymentSettings = ({ isLoading }) => {
  const dispatch = useDispatch();

  const [isAddCardOpen, setIsAddCardOpen] = useState(false);
  const [isCardDetailsOpen, setIsCardDetailsOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(0);
  const [errorObject, setErrorObject] = useState({});
  const [removeCardId, setRemoveCardId] = useState(0);

  const handleError = useCallback(error => {
    setErrorObject(error);
    handleAddCardChangeState();
  }, []);

  const { addPaymentMethodPhase, editPaymentMethodPhase } = useSelector(store => store.paymentStore);

  const handleCardDelete = id => e => {
    e.stopPropagation();
    setRemoveCardId(id);
  };

  const handleConfirmRemoving = () => {
    setIsCardDetailsOpen(false);
    setRemoveCardId(0);
    dispatch(removePaymentMethod(removeCardId));
  };

  const handleCancelRemoving = () => {
    setRemoveCardId(0);
  };

  const handleAddCardChangeState = useCallback(() => {
    setIsAddCardOpen(prevState => !prevState);
  }, []);

  const handleCardDetailsChangeState = useCallback(() => {
    setIsCardDetailsOpen(prevValue => !prevValue);
  }, []);

  const handleCardClick = id => () => {
    handleCardDetailsChangeState();
    setSelectedCard(id);
  };

  useEffect(() => {
    if (addPaymentMethodPhase === SUCCESS) {
      handleAddCardChangeState();
    }
    if (editPaymentMethodPhase === SUCCESS) {
      handleCardDetailsChangeState();
    }
  }, [addPaymentMethodPhase, editPaymentMethodPhase]);

  return (
    <Main
      isLoading={false}
      isWhiteLabelingOpen={false}
      error={errorObject}
    >
      <Flex
        flexGrow={1}
        width="100%"
        flexDirection="column"
        alignItems="start"
      >
        <Typography variant="garamond-500" fontSize={34} mb={[35, 50]}>
          Payment Settings
        </Typography>
        {isLoading
          ? (
            <Flex alignSelf="center" flexGrow={1}>
              <Loader position="relative" background={theme.color.white} width={64} height={64} />
            </Flex>
          )
          : (
            <Flex flexDirection="column" width="100%" maxWidth={900} alignSelf="center">
              <CardsList onCardDelete={handleCardDelete} onCardClick={handleCardClick} />
              <Button
                onClick={handleAddCardChangeState}
                mt={10}
                width="fit-content"
                variant="primary"
                py={12}
                px={45}
              >
                + Add credit card
              </Button>
            </Flex>
          )}
      </Flex>
      {isAddCardOpen && (
        <AddCardModal onError={handleError} onClose={handleAddCardChangeState} isOpen={isAddCardOpen} />
      )}
      <CardDetailsModal
        onCardDelete={handleCardDelete}
        isOpen={isCardDetailsOpen}
        onClose={handleCardDetailsChangeState}
        id={selectedCard}
      />
      {!!removeCardId && (
        <ConfirmationModal
          title="Delete this card?"
          text="Are you sure you want to delete this payment method?"
          primaryButtonText="Yes, delete"
          secondaryButtonText="No"
          onPrimaryButtonClick={handleConfirmRemoving}
          onSecondaryButtonClick={handleCancelRemoving}
          isOpen={!!removeCardId}
        />
      )}
    </Main>
  );
};

PaymentSettings.propTypes = {
  isLoading: bool.isRequired,
};

export default PaymentSettings;
