import React from 'react';
import { bool, number, func } from 'prop-types';

import { EventsFilter } from '@organisms';
import { Main } from '@templates';
import { Typography, Flex } from '@mixins';

const Events = ({
  isLoading,
  selectedCategory,
  onSelectedCategoryChange,
}) => (
  <Main
    isWhiteLabelingOpen={false}
    isLoading={isLoading}
  >
    <Flex
      width="100%"
      flexDirection="column"
      maxWidth="1320px"
      flexGrow={1}
    >
      <Typography alignSelf="start" variant="garamond-500" fontSize={[30, 34]} mb={[20, 30]}>
        Events
      </Typography>
      <EventsFilter
        selectedCategory={selectedCategory}
        onSelectedCategoryChange={onSelectedCategoryChange}
      />
    </Flex>
  </Main>
);

Events.defaultProps = {
  selectedCategory: 0,
};

Events.propTypes = {
  isLoading: bool.isRequired,
  onSelectedCategoryChange: func.isRequired,
  selectedCategory: number,
};

export default Events;
