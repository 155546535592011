import styled, { css } from 'styled-components';

import { Icon } from '@atoms';
import { Flex } from '@mixins';

export const PrevButton = styled(Icon)`
  ${() => css`
    transform: rotate(90deg);
  `}
`;

export const NextButton = styled(Icon)`
  ${() => css`
    transform: rotate(270deg);
  `}
`;

export const ButtonContainer = styled(Flex)`
  ${() => css`
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  `}
`;
