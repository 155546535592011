import React from 'react';
import { bool, func } from 'prop-types';

import { Modal, Icon } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { ReactComponent as Success } from '@assets/svgs/Success.svg';

const ManageCompanyMembersSuccessModal = ({
  isOpen,
  onModalClose,
}) => {
  const handleModalClose = () => {
    onModalClose();
  };

  return (
    <Modal onClose={onModalClose} topPosition={170} isOpen={isOpen} withCloseButton>
      <Flex alignItems="center" width={640} flexDirection="column" px={[30, 45]} py={15}>
        <Icon width={42} mb="30px" height={42} SVG={Success} />
        <Typography
          textAlign="center"
          mb={15}
          variant="proximaNova-600"
          fontSize={[16, 18, 20]}
        >
          Great
        </Typography>
        <Typography
          mb={15}
          textAlign="center"
          variant="proximaNova-400"
          fontSize={[12, 14, 16]}
        >
          Thanks for adding you team members.
        </Typography>
        <Typography textAlign="center" mr="5px" fontSize="16px" variant="proximaNova-400">
          and some explanation about the next thing that will happen in your onboarding process to Mindspace...
        </Typography>
        <Button onClick={handleModalClose} mt={60} py={12} px={74} variant="primary">
          Done
        </Button>
      </Flex>
    </Modal>
  );
};

ManageCompanyMembersSuccessModal.propTypes = {
  isOpen: bool.isRequired,
  onModalClose: func.isRequired,
};

export default ManageCompanyMembersSuccessModal;
