import React from 'react';
import { node, func } from 'prop-types';
import DOMPurify from 'dompurify';

import { Icon } from '@atoms';
import { Flex } from '@mixins';
import { ReactComponent as AnnouncementIcon } from '@assets/svgs/Announcement.svg';
import { ReactComponent as CloseIcon } from '@assets/svgs/Close.svg';

const HeaderAnnouncement = ({ children, handleClose }) => (
  <Flex
    width="100%"
    p={16}
    backgroundColor="#EFECE6"
    flexDirection="column"
    position="relative"
  >
    <Flex maxWidth={952} alignItems="center" margin="0 auto">
      <Icon
        mr={20}
        SVG={AnnouncementIcon}
        width={[32, 32]}
        height={[32, 32]}
        flexShrink="0"
      />
      <Flex
        display="block"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(children),
        }}
      />
    </Flex>
    <Icon
      zIndex={1}
      pointer
      onClick={handleClose}
      position="absolute"
      SVG={CloseIcon}
      width={[10, 13, 20]}
      height={[10, 13, 20]}
      top={[16, 21, 28]}
      right={[16, 21, 26]}
    />
  </Flex>
);

HeaderAnnouncement.propTypes = {
  children: node.isRequired,
  handleClose: func.isRequired,
};

export default HeaderAnnouncement;
