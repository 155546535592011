import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const rowPaddingYinPx = 8;

export const dataTableImageHeightInPx = 64;

export const dataTableColumnWidths = {
  locations: [50, 128, 216, 240, 427, 70],
};

export const SuperscriptionWrap = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));
