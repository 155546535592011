import mixpanel from 'mixpanel-browser';

export const initMixpanel = () => {
  try {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
      debug: false,
      ignore_dnt: true,
    });
  } catch (e) {
    reportError('Failed to initialize Mixpanel');
  }
};

export const trackEvent = (key, options = {}) => {
  try {
    mixpanel.track(key, options);
  } catch (e) {
    reportError('Failed to send event to Mixpanel');
  }
};
