import React, { useCallback, useEffect } from 'react';
import { number, bool, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Icon, Checkbox, Loader } from '@atoms';
import { dayjs } from '@utils';
import { LOADING } from '@constants/requestPhase';
import { CardBrandsLogos, ExpiredCardBrandsLogos } from '@constants/cards';
import { Flex, Typography } from '@mixins';
import { getCountryNameFromCode } from '@helpers/common';
import { theme } from '@styles';
import { editPaymentMethod, getPaymentMethodById } from '@store/payment/duck';

import { ReactComponent as TrashCan } from '@assets/svgs/TrashCan.svg';

const CardDetailsModal = ({ id, isOpen, onClose, onCardDelete }) => {
  const dispatch = useDispatch();

  const {
    paymentMethods, paymentMethodById, editPaymentMethodPhase, getPaymentMethodByIdPhase,
  } = useSelector(store => store.paymentStore);

  const isExpired = dayjs(paymentMethodById?.expirationDate ?? 0) < dayjs();

  const hasValidCard = paymentMethods.find(method => method.id !== paymentMethodById.id &&
    dayjs(method.expirationDate) >= dayjs());

  useEffect(() => {
    if (isOpen) {
      dispatch(getPaymentMethodById(id));
    }
  }, [isOpen]);

  const handleDefaultClick = useCallback(() => {
    dispatch(editPaymentMethod(id, {
      isDefault: !paymentMethodById.isDefault,
    }));
  }, [id, paymentMethodById]);

  return (
    <Modal onClose={onClose} topPosition={100} isOpen={isOpen} withPadding={false}>
      <Flex
        position="relative"
        alignItems="start"
        width={[380, 540]}
        flexDirection="column"
        py={60}
        px={30}
      >
        <Typography alignSelf="center" mb={35} variant="garamond-500" fontSize={36}>
          Card Details
        </Typography>
        <Typography mb={15} variant="garamond-500" fontSize={20}>
          Card information
        </Typography>
        <Flex
          mb={30}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          border="1px solid #D9D9D9"
          p={15}
        >
          <Typography
            color={isExpired ? '#8A8A8A' : theme.color.gray[500]}
            variant="proximaNova-400"
          >
            {paymentMethodById.brand &&
            `${paymentMethodById.brand[0].toUpperCase()}${paymentMethodById.brand.slice(1)} ···· 
            ${paymentMethodById.last4Digits}`}
          </Typography>
          {paymentMethodById.brand && (
            <Icon
              SVG={isExpired
                ? ExpiredCardBrandsLogos[paymentMethodById.brand] : CardBrandsLogos[paymentMethodById.brand]}
            />
          )}
        </Flex>
        {paymentMethodById?.billingDetails?.address.country && (
          <>
            <Typography mb={15} variant="garamond-500" fontSize={20}>
              Billing Address
            </Typography>
            <Flex
              justifyContent="center"
              flexDirection="column"
              width="100%"
              border="1px solid #D9D9D9"
              py="7px"
              mb={30}
              px={15}
            >
              <Typography
                color={isExpired ? '#8A8A8A' : theme.color.gray[500]}
                variant="proximaNova-400"
              >
                {`${paymentMethodById.billingDetails?.address?.line1}${paymentMethodById
                  .billingDetails?.address?.line2
                  ? `, ${paymentMethodById.billingDetails.address.line2}` : ''}`}
              </Typography>
              <Typography
                color="#8A8A8A"
                variant="proximaNova-400"
                fontSize={14}
              >
                {`${paymentMethodById.billingDetails?.address?.city},
            ${paymentMethodById.billingDetails?.address?.state},
            ${getCountryNameFromCode(paymentMethodById.billingDetails?.address?.country)}`}
              </Typography>
            </Flex>
          </>
        )}
        {!paymentMethodById.isDefault && !isExpired && hasValidCard && (
          <Flex mb={30} alignItems="center">
            <Checkbox onClick={handleDefaultClick} isEnabled={paymentMethodById.isDefault} />
            <Typography ml={10} variant="proximaNova-400">
              Default card
            </Typography>
          </Flex>
        )}
        <Flex pointer onClick={onCardDelete(paymentMethodById.id)} alignItems="center">
          <Icon SVG={TrashCan} />
          <Typography ml={10} variant="proximaNova-400">
            Delete card
          </Typography>
        </Flex>
        {(editPaymentMethodPhase === LOADING || getPaymentMethodByIdPhase === LOADING) && (
          <Loader
            position="absolute"
            background={editPaymentMethodPhase === LOADING ? 'rgba(255, 255, 255, 0.8)' : theme.color.white}
            width={40}
            height={40}
          />
        )}
      </Flex>
    </Modal>
  );
};

CardDetailsModal.defaultProps = {
  id: 0,
};

CardDetailsModal.propTypes = {
  onCardDelete: func.isRequired,
  id: number,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default CardDetailsModal;
