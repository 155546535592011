import React, { useState, useMemo, useEffect } from 'react';
import { bool, func } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Loader, Switcher } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { useViewport } from '@hooks';
import { LOADING, SUCCESS } from '@constants/requestPhase';
import { COMPANY_ADMIN_ROLE } from '@constants/common';
import { theme } from '@styles';
import { getUserSettings, updateUserSettings, resetPhases } from '@store/user/duck';

import { ScrollableContainer } from '@molecules/ChangeCardModal/styles';

const SettingsModal = ({ isOpen, onModalClose }) => {
  const screenSize = useViewport();
  const dispatch = useDispatch();

  const { user, settings, getUserSettingsPhase, updateUserSettingsPhase } = useSelector(store => (
    store.userStore
  ));

  const [isCompanyProfileHidden, setIsCompanyProfileHidden] = useState(
    settings.isCompanyProfilePrivacyEnabled ?? false,
  );
  const [isProfileHidden, setIsProfileHidden] = useState(
    settings.isUserProfilePrivacyEnabled ?? false,
  );
  const [isBookingNotifEnabled, setIsBookingNotifEnabled] = useState(
    settings.isBookingEmailNotificationEnabled ?? false,
  );
  const [isGuestInvitesNotifEnabled, setIsGuestInvitesNotifEnabled] = useState(
    settings.isBookingGuestInvitationEmailNotificationEnabled ?? false,
  );
  const [isNewsletterNotifEnabled, setIsNewsletterNotifEnabled] = useState(
    settings.isWeeklyNewsletterEnabled ?? false,
  );
  const [isBookingByTeammatesNotifEnabled, setIsBookingByTeammatesNotifEnabled] = useState(
    settings.isTeamMemberBookingEmailNotificationEnabled ?? false,
  );

  const areSettingsTheSame = isCompanyProfileHidden === settings.isCompanyProfilePrivacyEnabled
  && isProfileHidden === settings.isUserProfilePrivacyEnabled
  && isBookingNotifEnabled === settings.isBookingEmailNotificationEnabled
  && isGuestInvitesNotifEnabled === settings.isBookingGuestInvitationEmailNotificationEnabled
  && isNewsletterNotifEnabled === settings.isWeeklyNewsletterEnabled
  && isBookingByTeammatesNotifEnabled === settings.isTeamMemberBookingEmailNotificationEnabled;

  useEffect(() => {
    if (isOpen && !Object.keys(settings).length) {
      dispatch(getUserSettings());
    }
  }, [isOpen]);

  useEffect(() => {
    if (getUserSettingsPhase === SUCCESS) {
      resetValues();
      dispatch(resetPhases());
    }
    if (updateUserSettingsPhase === SUCCESS) {
      onModalClose();
      dispatch(resetPhases());
    }
  }, [getUserSettingsPhase, updateUserSettingsPhase]);

  const handleModalClose = () => {
    onModalClose();
    resetValues();
  };

  const handleSaveSettings = () => {
    dispatch(updateUserSettings({
      isBookingEmailNotificationEnabled: isBookingNotifEnabled,
      isBookingGuestInvitationEmailNotificationEnabled: isGuestInvitesNotifEnabled,
      isTeamMemberBookingEmailNotificationEnabled: isBookingByTeammatesNotifEnabled,
      isUserProfilePrivacyEnabled: isProfileHidden,
      isWeeklyNewsletterEnabled: isNewsletterNotifEnabled,
      isCompanyProfilePrivacyEnabled: isCompanyProfileHidden,
    }));
  };

  const resetValues = () => {
    setIsProfileHidden(settings.isUserProfilePrivacyEnabled);
    setIsCompanyProfileHidden(settings.isCompanyProfilePrivacyEnabled);
    setIsBookingNotifEnabled(settings.isBookingEmailNotificationEnabled);
    setIsGuestInvitesNotifEnabled(settings.isBookingGuestInvitationEmailNotificationEnabled);
    setIsNewsletterNotifEnabled(settings.isWeeklyNewsletterEnabled);
    setIsBookingByTeammatesNotifEnabled(settings.isTeamMemberBookingEmailNotificationEnabled);
  };

  const handleProfileHiddenChangeState = () => {
    setIsProfileHidden(prevState => !prevState);
  };

  const handleCompanyProfileHiddenChangeState = () => {
    setIsCompanyProfileHidden(prevState => !prevState);
  };

  const handleBookingNotifChangeState = () => {
    setIsBookingNotifEnabled(prevState => !prevState);
  };

  const handleBookingByTeammatesNotifChangeState = () => {
    setIsBookingByTeammatesNotifEnabled(prevState => !prevState);
  };

  const handleNewsletterNotifChangeState = () => {
    setIsNewsletterNotifEnabled(prevState => !prevState);
  };

  const handleGuestInvitesNotifChangeState = () => {
    setIsGuestInvitesNotifEnabled(prevState => !prevState);
  };

  const notificationSettings = [
    {
      title: 'Room bookings',
      value: isBookingNotifEnabled,
      handler: handleBookingNotifChangeState,
      isVisible: true,
    },
    {
      title: 'Room bookings by team members',
      value: isBookingByTeammatesNotifEnabled,
      handler: handleBookingByTeammatesNotifChangeState,
      isVisible: user.role === COMPANY_ADMIN_ROLE,
    },
    {
      title: 'Guest Invites',
      value: isGuestInvitesNotifEnabled,
      handler: handleGuestInvitesNotifChangeState,
      isVisible: true,
    },
    {
      title: 'Weekly newsletter',
      value: isNewsletterNotifEnabled,
      handler: handleNewsletterNotifChangeState,
      isVisible: true,
    },
  ];

  const notificationSettingsBlock = useMemo(() => notificationSettings
    .reduce((acc, { title, handler, value, isVisible }, index) => {
      if (isVisible) {
        acc.push(
          <Flex
            key={title}
            mb={index === notificationSettings.length - 1 ? 0 : ['10px', '15px']}
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              color={theme.color.gray[300]}
              variant="proximaNova-400"
              fontSize={[10, 12, 14]}
            >
              {title}
            </Typography>
            <Switcher isEnabled={value} onSwitch={handler} />
          </Flex>,
        );
      }

      return acc;
    }, []), [notificationSettings, user.role]);

  return (
    <Modal topPosition={120} closeOnClickOutside isOpen={isOpen} onClose={handleModalClose}>
      <Flex mt={['8px', '10px']} flexDirection="column" alignItems="center">
        <Typography mb={['20px', '30px']} variant="garamond-500" fontSize={['16px', '20px']}>
          Settings
        </Typography>
        <ScrollableContainer
          {...(screenSize.height < 770 && {
            maxHeight: screenSize.height - 260,
            overflowY: 'scroll',
          })}
          pr="6px"
          width={['300px', '350px', '400px']}
          alignItems="center"
          flexDirection="column"
          position="relative"
        >
          <Flex
            borderBottom="1px solid #EFECE6"
            pb="20px"
            mb="20px"
            width="100%"
            alignSelf="start"
            flexDirection="column"
          >
            <Typography mb={['10px', '15px']} variant="proximaNova-600" fontSize={['10px', '12px', '14px']}>
              Email Notifications
            </Typography>
            {notificationSettingsBlock}
          </Flex>
          <Flex
            pb="20px"
            mb="20px"
            width="100%"
            alignSelf="start"
            flexDirection="column"
          >
            <Typography mb={['10px', '15px']} variant="proximaNova-600" fontSize={['10px', '12px', '14px']}>
              Profile Management
            </Typography>
            <Flex
              mb={10}
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                color={theme.color.gray[300]}
                variant="proximaNova-400"
                fontSize={[10, 12, 14]}
              >
                Keep Personal Profile Private
              </Typography>
              <Switcher isEnabled={isProfileHidden} onSwitch={handleProfileHiddenChangeState} />
            </Flex>
            <Typography
              color={theme.color.gray[300]}
              variant="proximaNova-400"
              fontSize={['8px', 10, 12]}
            >
              We will hide all your profile details and will only show your initials.
            </Typography>
            {user.role === COMPANY_ADMIN_ROLE && (
              <>
                <Flex
                  mb={10}
                  mt={[20, 30]}
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    color={theme.color.gray[300]}
                    variant="proximaNova-400"
                    fontSize={[10, 12, 14]}
                  >
                    Keep Company Profile Private
                  </Typography>
                  <Switcher
                    isEnabled={isCompanyProfileHidden}
                    onSwitch={handleCompanyProfileHiddenChangeState}
                  />
                </Flex>
                <Typography
                  color={theme.color.gray[300]}
                  variant="proximaNova-400"
                  fontSize={['8px', 10, 12]}
                >
                  We will hide all your company profile details.
                </Typography>
              </>
            )}
          </Flex>
          <Button
            disabled={areSettingsTheSame}
            onClick={handleSaveSettings}
            variant="primary"
            py={['8px', 10, 12]}
            px={['35px', '45px', '55px']}
          >
            Save
          </Button>
          {(getUserSettingsPhase === LOADING || updateUserSettingsPhase === LOADING) && (
            <Loader background="rgba(255, 255, 255, 0.5)" width={50} height={50} position="absolute" />
          )}
        </ScrollableContainer>
      </Flex>
    </Modal>
  );
};

SettingsModal.propTypes = {
  isOpen: bool.isRequired,
  onModalClose: func.isRequired,
};

export default SettingsModal;
