import React from 'react';
import { bool } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Modal, Icon } from '@atoms';
import { CompanyManagers } from '@molecules';
import { routes } from '@constants';
import { useViewport } from '@hooks';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';

import { ReactComponent as NoPermissionToBook } from '@assets/svgs/NoPermissionToBook.svg';

import { ScrollableContainer } from '@molecules/EventDateSelectingModal/styles';

const NoBookingPermissionModal = ({
  isOpen,
}) => {
  const history = useHistory();
  const screenSize = useViewport();

  const handleGoHome = () => {
    history.push(routes.home);
  };

  return (
    <Modal zIndex={4} topPosition={100} isOpen={isOpen} withCloseButton={false}>
      <Flex
        alignItems="center"
        maxWidth={355}
        flexDirection="column"
        px={[30, 55]}
        pb={15}
        {...(screenSize.height < 780 && {
          maxHeight: screenSize.height - 250,
          overflow: 'scroll',
        })}
      >
        <Icon width={52} minHeight={52} SVG={NoPermissionToBook} mb={20} />
        <Typography
          textAlign="center"
          mb={15}
          variant="proximaNova-600"
          fontSize={[16, 18, 20]}
        >
          Looking to Book Room?
        </Typography>
        <Typography
          textAlign="center"
          variant="proximaNova-400"
          fontSize={16}
          mb={[20, 30]}
          color={theme.color.gray[300]}
        >
          Please reach out to your company admin to make the booking
        </Typography>
        <ScrollableContainer px={15} maxHeight={250} alignItems="center">
          <CompanyManagers />
        </ScrollableContainer>
        <Button onClick={handleGoHome} mt={32} py={12} width="100%" variant="primary">
          Back to Homepage
        </Button>
      </Flex>
    </Modal>
  );
};

NoBookingPermissionModal.propTypes = {
  isOpen: bool.isRequired,
};

export default NoBookingPermissionModal;
