import React from 'react';
import { bool, func, string, exact, number } from 'prop-types';

import { Modal } from '@atoms';
import { Flex, Typography, Button } from '@mixins';

const ErrorModal = ({
  isOpen,
  error,
  onModalClose,
}) => {
  const isClientError = Math.floor(error.status / 100) === 4;

  const handleModalClose = () => {
    onModalClose();

    if (!isClientError) {
      window.location.reload();
    }
  };

  return (
    <Modal onClose={onModalClose} topPosition={170} isOpen={isOpen} withCloseButton>
      <Flex alignItems="center" maxWidth={415} flexDirection="column" px={[30, 45]} pb={15} pt={[20, 25]}>
        <Typography
          textAlign="center"
          mb={10}
          variant="proximaNova-600"
          fontSize={[16, 18, 20]}
        >
          {error.title ?? 'Something went wrong'}
        </Typography>
        <Typography
          textAlign="center"
          variant="proximaNova-400"
          fontSize={[10, 12, 14]}
        >
          {isClientError ? error.message : 'Please try again later'}
        </Typography>
        <Button onClick={handleModalClose} mt={32} py={12} px={74} variant="primary">
          {error.buttonLabel || 'Try Again'}
        </Button>
      </Flex>
    </Modal>
  );
};

ErrorModal.defaultProps = {
  error: {},
};

ErrorModal.propTypes = {
  isOpen: bool.isRequired,
  onModalClose: func.isRequired,
  error: exact({
    message: string,
    title: string,
    status: number,
    buttonLabel: string,
  }),
};

export default ErrorModal;
