import styled, { css } from 'styled-components';

import { Flex } from '@mixins';

const FlexWithGradientBackground = styled(Flex)`
${({ imageUrl }) => css`
  background: linear-gradient(0.46deg, rgba(0, 0, 0, 0.7) 7.26%, rgba(0, 0, 0, 0) 99.61%), 
  url(${imageUrl}) no-repeat center center;
  background-size: cover;
`}`;

export default FlexWithGradientBackground;
