import React from 'react';
import { number, func } from 'prop-types';
import { useSelector } from 'react-redux';

import { Loader } from '@atoms';
import { EventsGrid, Filters } from '@molecules';
import { theme } from '@styles';
import { LOADING } from '@constants/requestPhase';
import { Flex } from '@mixins';

const EventsFilter = ({ selectedCategory, onSelectedCategoryChange }) => {
  const { getEventsPhase, categories } = useSelector(store => store.eventStore);

  const filters = categories.map(({ name, id }) => ({
    id,
    name,
  }));

  return (
    <>
      <Flex
        width="100%"
        alignItems="center"
        mb={30}
      >
        <Filters
          filters={filters}
          onFilterChange={onSelectedCategoryChange}
          selectedFilter={selectedCategory}
        />
      </Flex>
      {getEventsPhase === LOADING
        ? (
          <Flex flexGrow={1} alignItems="center" justifyContent="center">
            <Loader position="relative" background={theme.color.white} width={64} height={64} />
          </Flex>
        )
        : <EventsGrid selectedFilter={selectedCategory} />}
    </>
  );
};

EventsFilter.defaultProps = {
  selectedCategory: 0,
};

EventsFilter.propTypes = {
  selectedCategory: number,
  onSelectedCategoryChange: func.isRequired,
};

export default EventsFilter;
