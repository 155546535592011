import mixpanel from 'mixpanel-browser';
import { node } from 'prop-types';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

export const MixpanelContext = createContext({});

export const MixpanelProvider = ({ children }) => {
  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
      debug: false,
      ignore_dnt: true,
    });
  }, []);

  const trackEvent = useCallback(
    params => {
      if (Object.keys(mixpanel).length) {
        mixpanel.track(params.eventName, params.properties);
      }
    },
    [mixpanel],
  );

  const mixpanelContextValue = useMemo(
    () => ({
      mixpanel,
      trackEvent,
    }),
    [mixpanel, trackEvent],
  );

  return (
    <MixpanelContext.Provider value={mixpanelContextValue}>
      {children}
    </MixpanelContext.Provider>
  );
};

MixpanelProvider.propTypes = {
  children: node.isRequired,
};
