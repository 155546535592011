import React, { useState, useEffect, useRef, useCallback } from 'react';
import { string, bool, func } from 'prop-types';

import { Icon, Loader } from '@atoms';
import { PhotoModal, PhotoScalingModal } from '@molecules';
import { Flex, Typography } from '@mixins';
import { useOutsideClick } from '@hooks';
import { getBackground, getCloudinaryImageLinkBySize } from '@helpers/common';
import { theme } from '@styles';

import { ReactComponent as ChangePhoto } from '@assets/svgs/ChangePhoto.svg';
import { ReactComponent as Picture } from '@assets/svgs/Picture.svg';
import { ReactComponent as Bin } from '@assets/svgs/Bin.svg';

const IMAGE_WIDTH = 278;

const PhotoChanger = ({
  firstName, lastName, isCompany, avatar, onSavePhoto, onRemovePhoto, isEditable, isLoading, isHidden,
}) => {
  const photoRef = useRef();
  const dropdownRef = useRef();
  const { isOpen, setIsOpen } = useOutsideClick(false, [photoRef, dropdownRef]);

  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [isPhotoScalingModalOpen, setIsPhotoScalingModalOpen] = useState(false);
  const [imageUrl, setImage] = useState(avatar);
  const [imageFile, setImageFile] = useState(null);

  const handleStatusOpenChange = useCallback(() => {
    setIsOpen(prevValue => !prevValue);
  }, []);

  const handleModalStatusOpenChange = useCallback(() => {
    setIsPhotoModalOpen(prevValue => {
      if (prevValue) {
        resetValues();
      }

      return !prevValue;
    });
  }, []);

  const handleScalingModalStatusOpenChange = useCallback(() => {
    setIsPhotoScalingModalOpen(prevValue => {
      if (prevValue) {
        resetValues();
      }

      return !prevValue;
    });
  }, []);

  const onChangePhotoClick = useCallback(() => {
    handleModalStatusOpenChange();
    setIsOpen(false);
  }, []);

  const handleImageUpload = useCallback(e => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
      if (isCompany) {
        setImageFile(e.target.files[0]);
      } else {
        setIsPhotoModalOpen(false);
        setIsPhotoScalingModalOpen(true);
      }
    }
  }, [isCompany]);

  const resetValues = useCallback(() => {
    setIsPhotoScalingModalOpen(false);
    setIsPhotoModalOpen(false);
    setImageFile(null);
    setImage(avatar);
  }, []);

  const handleSaveCompanyLogo = useCallback(() => {
    handleSavePhoto(imageFile);
  }, [imageFile]);

  const handleSavePhoto = useCallback(file => {
    onSavePhoto(file);
    resetValues();
  }, []);

  const handleRemovePhoto = useCallback(() => {
    onRemovePhoto();
    handleStatusOpenChange();
  }, []);

  useEffect(() => {
    setImage(avatar);
  }, [avatar]);

  return (
    <Flex
      width="100%"
      height="100%"
      border={`1px solid ${theme.color.gray[100]}`}
      justifyContent="center"
      alignItems="center"
      position="relative"
      background={isHidden
        ? theme.color.pink
        : getBackground(isCompany, getCloudinaryImageLinkBySize(avatar, IMAGE_WIDTH))}
      backgroundSize="cover"
    >
      {isLoading && (
        <Loader position="absolute" background={theme.color.white} width={50} height={50} />
      )}
      {isEditable && (
        <Flex
          pointer
          ref={photoRef}
          onClick={avatar ? handleStatusOpenChange : handleModalStatusOpenChange}
          backgroundColor={theme.color.white}
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top={[10, 12, 15]}
          right={[10, 12, 15]}
          borderRadius="50%"
          width={[25, 30, 36]}
          height={[25, 30, 36]}
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        >
          <Icon SVG={ChangePhoto} width={[10, 13, 16]} height={[10, 13, 16]} />
        </Flex>
      )}
      {(!avatar || isCompany || isHidden) && (
        <Typography variant="garamond-500" fontSize={[30, 50, 70]} color={theme.color.darkRed}>
          {isHidden ? 'MM' : `${firstName
            ? firstName.trim().toUpperCase()[0] : ''}${lastName
            ? lastName.trim().toUpperCase()[0] : ''}`}
        </Typography>
      )}
      {isOpen && (
        <Flex
          ref={dropdownRef}
          right={[-65, -74, -80]}
          alignItems="center"
          flexDirection="column"
          position="absolute"
          top={[42, 54, 74]}
        >
          <Flex
            width={0}
            height={0}
            borderStyle="solid"
            borderWidth="0 9px 15px 9px"
            borderColor={`transparent transparent ${theme.color.white} transparent`}
          />
          <Flex
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            flexDirection="column"
            p={[15, 20, 25]}
            backgroundColor={theme.color.white}
          >
            <Flex onClick={onChangePhotoClick} pointer mb={16} alignItems="center">
              <Icon mr={[13, 15, 17]} SVG={Bin} height={15} width={20} />
              <Typography variant="proximaNova-600" fontSize={[12, 14, 16]}>
                {`Change ${isCompany ? 'Company Logo' : 'Profile Picture'}`}
              </Typography>
            </Flex>
            <Flex onClick={handleRemovePhoto} pointer alignItems="center">
              <Icon mr={[13, 15, 17]} SVG={Picture} height={17} width={20} />
              <Typography variant="proximaNova-600" fontSize={[12, 14, 16]}>
                {`Remove ${isCompany ? 'Company Logo' : 'Profile Picture'}`}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      )}
      {isPhotoModalOpen && (
        <PhotoModal
          isPhotoUploaded={avatar !== imageUrl}
          onImageUpload={handleImageUpload}
          onImageSave={handleSaveCompanyLogo}
          isCompany={isCompany}
          isOpen={isPhotoModalOpen}
          firstName={firstName}
          lastName={lastName}
          image={imageUrl}
          onModalClose={handleModalStatusOpenChange}
        />
      )}
      {isPhotoScalingModalOpen && (
        <PhotoScalingModal
          onImageUpload={handleImageUpload}
          image={imageUrl}
          isOpen={isPhotoScalingModalOpen}
          onModalClose={handleScalingModalStatusOpenChange}
          onScalingDone={handleSavePhoto}
        />
      )}
    </Flex>
  );
};

PhotoChanger.defaultProps = {
  isHidden: false,
  avatar: '',
  firstName: '',
  lastName: '',
};

PhotoChanger.propTypes = {
  isHidden: bool,
  isLoading: bool.isRequired,
  onRemovePhoto: func.isRequired,
  avatar: string,
  isCompany: bool.isRequired,
  firstName: string,
  lastName: string,
  onSavePhoto: func.isRequired,
  isEditable: bool.isRequired,
};

export default PhotoChanger;
