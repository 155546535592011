import styled, { css } from 'styled-components';
import { space, border, color, flexbox, layout, position, typography } from 'styled-system';

const RangeSlider = styled.input`
  ${() => css`
    -webkit-appearance: none;
    background: #c1d0d1;
    border-radius: 15px;
    width: 100%;
    margin: 0;

    ${typography}
    ${space}
    ${border}
    ${color}
    ${flexbox}
    ${layout}
    ${position}

    :focus {
      outline: none;
    }

    ::-webkit-slider-thumb {
      -webkit-appearance: none;
      user-select: none;
      height: 16px;
      width: 16px;
      border: 0px;
      background: #326164;
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  `}
`;

export default RangeSlider;
