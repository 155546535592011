import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { number } from 'prop-types';

import { BenefitsCarousel } from '@molecules';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { getCloudinaryImageLinkBySize } from '@helpers/common';
import { routes } from '@constants';

import Gif from '@assets/gifs/no-results.gif';

import { Container } from './styles';
import { Description } from '../../organisms/NeighborsList/styles';

const BenefitsGrid = ({ selectedCategory }) => {
  const history = useHistory();

  const { benefits } = useSelector(store => (
    store.benefitStore
  ));

  const benefitsCarousel = benefits.filter(benefit => benefit.isNew || benefit.isFeatured);

  const benefitsList = benefits.filter(benefit => !benefit.isNew && !benefit.isFeatured);

  const handleBenefitClick = id => () => {
    history.push({ pathname: `${routes.benefits}/${id}`, state: { selectedCategory } });
  };

  const benefitsListBlock = useMemo(() => benefitsList.map(({ id, image, title, tagline }) => (
    <Flex
      pointer
      onClick={handleBenefitClick(id)}
      key={id}
      height={[300, 300, 370]}
      flexDirection="column"
      alignItems="start"
      border={`1px solid ${theme.color.gray[100]}`}
      boxShadow="0px 8px 30px rgba(80, 85, 136, 0.06)"
      borderRadius="2px"
    >
      <Flex
        width="100%"
        height="60%"
        background={`url(${getCloudinaryImageLinkBySize(image, theme.sizes.shortGrid)}) no-repeat center top`}
        backgroundSize="cover"
      />
      <Flex p={20} flexDirection="column" height="40%">
        <Flex flexDirection="column">
          <Description lineHeight={1.2} variant="garamond-500" fontSize={[16, 18, 20]} mb={15}>
            {title}
          </Description>
          <Description variant="proximaNova-400" fontSize={[10, 12, 14]}>
            {tagline}
          </Description>
        </Flex>
      </Flex>
    </Flex>
  )), [benefitsList]);

  return (
    <Flex width="100%" flexDirection="column">
      {benefits.length
        ? (
          <>
            {!!benefitsCarousel.length && (
              <BenefitsCarousel onBenefitClick={handleBenefitClick} benefits={benefitsCarousel} />
            )}
            <Container>{benefitsListBlock}</Container>
          </>
        )
        : (
          <Flex width="100%" alignItems="center" flexDirection="column">
            <Flex as="img" mb={25} width={['225px', '290px']} height={['200px', '260px']} src={Gif} />
            <Typography mb={10} variant="garamond-500" fontSize={[28, 34, 40]}>
              You are our benefit!
            </Typography>
            <Typography variant="proximaNova-400" fontSize={[10, 13, 16]}>
              Also, we&apos;re working on getting some new perks
            </Typography>
          </Flex>
        )}
    </Flex>
  );
};

BenefitsGrid.defaultProps = {
  selectedCategory: 0,
};

BenefitsGrid.propTypes = {
  selectedCategory: number,
};

export default BenefitsGrid;
