import { VALIDATION } from '@constants';
import { string } from 'yup';

export const shapes = {
  email: string()
    .trim()
    .required(VALIDATION.ERROR_MESSAGE.EMAIL.REQUIRED)
    .email(VALIDATION.ERROR_MESSAGE.EMAIL.INVALID)
    .matches(VALIDATION.REG_EXP.EMAIL, {
      excludeEmptyString: true,
      message: VALIDATION.ERROR_MESSAGE.EMAIL.INVALID,
    })
    .max(
      VALIDATION.MAX_LENGTH.EMAIL,
      VALIDATION.ERROR_MESSAGE.EMAIL.MAX_LENGTH_EXCEEDED,
    ),
  lastName: string().trim().required(VALIDATION.ERROR_MESSAGE.LAST_NAME.REQUIRED).max(
    VALIDATION.MAX_LENGTH.LAST_NAME,
    VALIDATION.ERROR_MESSAGE.LAST_NAME.MAX_LENGTH_EXCEEDED,
  ),
  firstName: string().trim().required(VALIDATION.ERROR_MESSAGE.FIRST_NAME.REQUIRED).max(
    VALIDATION.MAX_LENGTH.FIRST_NAME,
    VALIDATION.ERROR_MESSAGE.FIRST_NAME.MAX_LENGTH_EXCEEDED,
  ),
};
