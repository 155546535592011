import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { UserProfile as View } from '@views';
import { getUserById, editUser, resetPhases } from '@store/user/duck';
import { SUCCESS, LOADING, FAILURE } from '@constants/requestPhase';

import { ReactComponent as Twitter } from '@assets/svgs/TwitterProfile.svg';
import { ReactComponent as Facebook } from '@assets/svgs/FacebookProfile.svg';
import { ReactComponent as LinkedIn } from '@assets/svgs/LinkedInProfile.svg';
import { ReactComponent as Instagram } from '@assets/svgs/InstagramProfile.svg';
import { trackEvent } from '@utils/mixpanel';

const UserProfile = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { state } = useLocation();

  const {
    user: currentUser, userById, getUserByIdPhase, getCurrentUserPhase, editUserPhase, editUserError,
  } = useSelector(store => (
    store.userStore
  ));

  const isEditable = currentUser.id === parseInt(id, 10);

  const user = isEditable ? currentUser : userById;

  const isHidden = user.isHidden && !isEditable;

  const [error, setError] = useState();

  useEffect(() => {
    const pageTitle = 'Mindspace: Profile';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'User Profile',
    });
  }, []);

  useEffect(() => {
    if (parseInt(id, 10) !== currentUser.id) {
      dispatch(getUserById(id));
    }
  }, [id]);

  useEffect(() => {
    if (editUserPhase === SUCCESS || getCurrentUserPhase === SUCCESS || getUserByIdPhase === SUCCESS) {
      dispatch(resetPhases());
    }
    if (getCurrentUserPhase === FAILURE || getUserByIdPhase === FAILURE) {
      history.goBack();
    }
    if (editUserPhase === FAILURE) {
      setError({
        status: editUserError?.data.error.status,
        message: editUserError?.data.error.message,
      });
      dispatch(resetPhases());
    }
  }, [getCurrentUserPhase, editUserPhase, getUserByIdPhase]);

  const handleSavePhoto = useCallback(file => {
    const userData = new FormData();

    userData.append('image', file);

    dispatch(editUser(
      userData,
    ));
  }, [id]);

  const handleRemovePhoto = useCallback(() => {
    dispatch(editUser({
      id,
      image: null,
    }));
  }, [id]);

  const socials = [
    {
      name: 'LinkedIn',
      link: user.linkedin,
      icon: LinkedIn,
    },
    {
      name: 'Instagram',
      link: user.instagram,
      icon: Instagram,
    },
    {
      name: 'Twitter',
      link: user.twitter,
      icon: Twitter,
    },
    {
      name: 'Facebook',
      link: user.facebook,
      icon: Facebook,
    },
  ];

  const isLoading = getUserByIdPhase === LOADING;

  return (
    <View
      error={error}
      isPhotoLoading={editUserPhase === LOADING}
      from={state?.from}
      companyId={user.company?.id}
      isEditable={isEditable}
      userId={user.id}
      onRemovePhoto={handleRemovePhoto}
      onSavePhoto={handleSavePhoto}
      avatar={user.imageUrl}
      isLoading={isLoading}
      companyName={user.company?.name}
      firstName={user.firstName}
      lastName={user.lastName}
      email={user.email}
      socials={socials}
      jobTitle={user.jobTitle}
      skills={user.skills}
      myStory={user.myStory}
      isHidden={isHidden}
      isCompanyHidden={user.company?.isHidden}
    />
  );
};

export default UserProfile;
