import React, { useMemo } from 'react';
import { exact, string, arrayOf, func } from 'prop-types';

import { Icon } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';

import { ReactComponent as Close } from '@assets/svgs/Close.svg';
import { ReactComponent as InviteGuests } from '@assets/svgs/InviteGuests.svg';

import { ScrollableContainer } from './styles';

const GuestsList = ({
  users,
  onAddAnotherGuest,
  onSendInvite,
  onRemoveUser,
}) => {
  const usersBlock = useMemo(() => users.map(({ firstName, lastName, email }, index) => (
    <Flex
      key={email}
      width="100%"
      justifyContent="space-between"
      pr={10}
      py={15}
      borderBottom="1px solid #EFECE6"
    >
      <Flex flexDirection="column">
        <Typography mb="5px" fontSize={[10, 12, 14, 16]} variant="proximaNova-400">
          {`${firstName} ${lastName}`}
        </Typography>
        <Typography fontSize={[10, 12, 14, 16]} variant="proximaNova-400">
          {email}
        </Typography>
      </Flex>
      <Flex pointer onClick={onRemoveUser(index)} alignItems="center">
        <Icon mr="8px" width={10} height={10} color={theme.color.darkGreen} SVG={Close} />
        <Typography fontSize={[10, 12, 14, 16]} color={theme.color.darkGreen} variant="proximaNova-400">
          Remove
        </Typography>
      </Flex>
    </Flex>
  )), [users]);

  return (
    <Flex flexDirection="column" alignItems="center" width="100%">
      <ScrollableContainer usersLength={users.length} pr={10} mb={20} width="100%" maxHeight={255}>
        {usersBlock}
      </ScrollableContainer>
      <Button onClick={onAddAnotherGuest} mb={15} py={12} width={225} backgroundColor="#EFECE6">
        <Flex justifyContent="center" alignItems="center">
          <Icon height={15} mr={10} SVG={InviteGuests} />
          <Typography variant="proximaNova-600" fontSize={[10, 12, 14, 16]}>
            Add Another Guest
          </Typography>
        </Flex>
      </Button>
      <Button onClick={onSendInvite} mb={[15, 30]} py={12} width={225} variant="primary">
        Send Invite
      </Button>
      <Typography color={theme.color.gray[300]} variant="proximaNova-400" fontSize={['9px', 11, 13]}>
        An email will be sent to your guests with the invite details.
      </Typography>
    </Flex>
  );
};

GuestsList.propTypes = {
  users: arrayOf(
    exact({
      firstName: string,
      lastName: string,
      email: string,
    }),
  ).isRequired,
  onAddAnotherGuest: func.isRequired,
  onSendInvite: func.isRequired,
  onRemoveUser: func.isRequired,
};

export default GuestsList;
