import { createRoutine } from 'redux-routines';
import dayjs from 'dayjs';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import { convertLocations } from '@helpers/common';
import * as api from './api';

// < ----- ACTIONS ----- > //
const inviteGuestToLocationRoutine = createRoutine(
  'INTIVE_GUEST_TO_LOCAITON',
);
const getCurrentLocationRoutine = createRoutine(
  'GET_CURRENT_LOCATION',
);

const getLocationNeighborsRoutine = createRoutine(
  'GET_LOCATION_NEIGHBORS',
);

const getLocationsRoutine = createRoutine(
  'GET_LOCATIONS',
);

const resetStoreToInitialStateAction = 'RESET_STORE';
const resetPhasesAction = 'RESET_LOCATION_PHASES';

// < ----- ACTION CREATORS ----- > //
export const getCurrentLocation = id => async dispatch => {
  try {
    dispatch(getCurrentLocationRoutine.request());

    const response = await api.getLocationById(id);

    dayjs.tz.setDefault(response.data.data.timeZone);

    return dispatch(getCurrentLocationRoutine.success(response.data));
  } catch (error) {
    return dispatch(getCurrentLocationRoutine.failure(error.response));
  }
};

export const getLocationNeighbors = id => async dispatch => {
  try {
    dispatch(getLocationNeighborsRoutine.request());

    const response = await api.getLocationNeighbors(id);

    return dispatch(getLocationNeighborsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getLocationNeighborsRoutine.failure(error.response));
  }
};

export const getLocations = data => async dispatch => {
  try {
    dispatch(getLocationsRoutine.request());

    const response = await api.getLocations(data);

    return dispatch(getLocationsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getLocationsRoutine.failure(error.response));
  }
};

export const inviteGuestToLocation = (id, data) => async dispatch => {
  try {
    dispatch(inviteGuestToLocationRoutine.request());

    await api.inviteGuestToLocation(id, data);

    return dispatch(inviteGuestToLocationRoutine.success());
  } catch (error) {
    return dispatch(inviteGuestToLocationRoutine.failure(error.response));
  }
};

export const resetStore = () => ({
  type: resetStoreToInitialStateAction,
});

export const resetPhases = () => ({
  type: resetPhasesAction,
});

// < ----- STATE ----- > //
export const locationStorePersistWhitelist = [];

const initialState = {
  currentLocation: {},
  locations: [],
  companies: 0,
  members: 0,

  getLocationNeighborsPhase: INIT,
  getLocationNeighborsError: null,

  getCurrentLocationPhase: INIT,
  getCurrentLocationError: null,

  getLocationsPhase: INIT,
  getLocationsError: null,

  inviteGuestToLocationPhase: INIT,
  inviteGuestToLocationError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;
    // RESET PHASES
    case resetPhasesAction:
      return {
        ...state,
        getLocationNeighborsPhase: INIT,
        getLocationNeighborsError: null,

        getCurrentLocationPhase: INIT,
        getCurrentLocationError: null,

        getLocationsPhase: INIT,
        getLocationsError: null,

        inviteGuestToLocationPhase: INIT,
        inviteGuestToLocationError: null,
      };
    // GET CURRENT LOCATION
    case getCurrentLocationRoutine.REQUEST:
      return {
        ...state,
        getCurrentLocationPhase: LOADING,
      };
    case getCurrentLocationRoutine.SUCCESS:
      return {
        ...state,
        getCurrentLocationPhase: SUCCESS,
        currentLocation: {
          ...payload.data,
        },
      };
    case getCurrentLocationRoutine.FAILURE:
      return {
        ...state,
        getCurrentLocationPhase: FAILURE,
        getCurrentLocationError: payload,
      };
      // GET LOCATIONS
    case getLocationsRoutine.REQUEST:
      return {
        ...state,
        getLocationsPhase: LOADING,
      };
    case getLocationsRoutine.SUCCESS: {
      const newLocations = convertLocations(payload.data);

      return {
        ...state,
        getLocationsPhase: SUCCESS,
        locations: newLocations,
      };
    }
    case getLocationsRoutine.FAILURE:
      return {
        ...state,
        getLocationsPhase: FAILURE,
        getLocationsError: payload,
      };
    // GET LOCATION NEIGHBORS
    case getLocationNeighborsRoutine.REQUEST:
      return {
        ...state,
        getLocationNeighborsPhase: LOADING,
      };
    case getLocationNeighborsRoutine.SUCCESS: {
      return {
        ...state,
        getLocationNeighborsPhase: SUCCESS,
        companies: payload.data.companies,
        members: payload.data.members,
      };
    }
    case getLocationNeighborsRoutine.FAILURE:
      return {
        ...state,
        getLocationNeighborsPhase: FAILURE,
        getLocationNeighborsError: payload,
      };

      // INVITE GUEST TO LOCATION
    case inviteGuestToLocationRoutine.REQUEST:
      return {
        ...state,
        inviteGuestToLocationPhase: LOADING,
      };
    case inviteGuestToLocationRoutine.SUCCESS: {
      return {
        ...state,
        inviteGuestToLocationPhase: SUCCESS,
      };
    }
    case inviteGuestToLocationRoutine.FAILURE:
      return {
        ...state,
        inviteGuestToLocationPhase: FAILURE,
        inviteGuestToLocationError: payload,
      };
    default:
      return state;
  }
};

export default store;
