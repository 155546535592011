import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { PaymentSettings as View } from '@views';
import { LOADING, SUCCESS, FAILURE } from '@constants/requestPhase';
import { getPaymentMethods, initPaymentMethod, resetPhases } from '@store/payment/duck';
import { trackEvent } from '@utils/mixpanel';

const PaymentSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    paymentMethods, getPaymentMethodsPhase, addPaymentMethodPhase, initPaymentMethodPhase,
    removePaymentMethodPhase, editPaymentMethodPhase,
  } = useSelector(store => (
    store.paymentStore
  ));

  const { currentLocation } = useSelector(store => store.locationStore);
  const { user } = useSelector(store => store.userStore);

  useEffect(() => {
    const pageTitle = 'Mindspace: Payment Settings';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Payment Settings',
    });

    if (!paymentMethods.length) {
      dispatch(getPaymentMethods());
    }
  }, []);

  useEffect(() => {
    if (addPaymentMethodPhase === SUCCESS || removePaymentMethodPhase === SUCCESS
      || editPaymentMethodPhase === SUCCESS) {
      if (addPaymentMethodPhase === SUCCESS) {
        dispatch(initPaymentMethod());
      }
      dispatch(getPaymentMethods());
      dispatch(resetPhases());
    }
  }, [addPaymentMethodPhase, removePaymentMethodPhase, editPaymentMethodPhase]);

  useEffect(() => {
    if (getPaymentMethodsPhase === FAILURE || (!user.isAbleToPayByCard && user.id)) {
      dispatch(resetPhases());
      history.goBack();
    }
  }, [getPaymentMethodsPhase]);

  useEffect(() => {
    dispatch(initPaymentMethod());
  }, [currentLocation.id]);

  return (
    <View
      isLoading={removePaymentMethodPhase === LOADING || getPaymentMethodsPhase === LOADING
        || initPaymentMethodPhase === LOADING}
      paymentMethods={paymentMethods}
    />
  );
};

export default PaymentSettings;
