import React, { useState, useEffect, useCallback } from 'react';
import { arrayOf, string, exact, number, bool, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '@atoms';
import { ConfirmationModal } from '@molecules';
import { PackagesList, PayByCard } from '@organisms';
import { Main } from '@templates';
import { getCurrency } from '@helpers/common';
import { withStripe } from '@hocs';
import { SUCCESS } from '@constants/requestPhase';
import { Typography, Flex, Button } from '@mixins';
import { resetPhases, increasePurchasedCredits } from '@store/payment/duck';
import { theme } from '@styles';
import { trackEvent } from '@utils/mixpanel';

import { ReactComponent as CreditsAdded } from '@assets/svgs/CreditsAdded.svg';
import { ReactComponent as DiscountWhite } from '@assets/svgs/Discount.svg';
import BuyingCredits from '@assets/images/BuyingCredits.jpg';

const CreditsPackages = ({
  from,
  companyName,
  isAbleToInvoice,
  isAbleToPayByCard,
  isActivate,
  packages,
  maxCreditsPerMonth,
  alreadyPurchasedCredits,
  isLoading,
  onInvoiceMe,
  vatPercent,
  onGoBack,
  isDiscountAvailable,
}) => {
  const dispatch = useDispatch();

  const { invoiceMePhase } = useSelector(store => store.paymentStore);

  const [isPayByCardOpen, setIsPayByCardOpen] = useState(false);
  const [selectedPackageId, setSelectedPackage] = useState(0);
  const [paymentType, setPaymentType] = useState(false);

  const selectedPackage = packages.find(pack => pack.id === selectedPackageId);

  const isButtonDisabled = maxCreditsPerMonth < alreadyPurchasedCredits +
  (selectedPackage?.amount ?? 0);

  const handlePackageClick = id => () => {
    setSelectedPackage(id);
  };

  const handleInvoiceMe = packageId => () => {
    trackEvent('"Invoice me" clicked', {
      scope: 'Payments',
    });

    onInvoiceMe(packageId);
  };

  const handleProcessFinish = useCallback(type => {
    dispatch(increasePurchasedCredits(selectedPackage?.amount));
    setPaymentType(type);
  }, [selectedPackage]);

  const onClearProcess = useCallback(() => {
    setPaymentType('');

    onGoBack();
  }, []);

  const handlePayByCardChangeState = useCallback(() => {
    setIsPayByCardOpen(prevState => !prevState);
  }, []);

  const handlePurchaseClick = useCallback(() => {
    trackEvent('"Pay now" clicked', {
      scope: 'Payments',
    });

    handlePayByCardChangeState();
  }, []);

  useEffect(() => {
    if (invoiceMePhase === SUCCESS) {
      handleProcessFinish('invoice');
      dispatch(resetPhases());
    }
  }, [invoiceMePhase]);

  const activateText = `Purchase discounted credit packages for everyone at ${companyName} 
to enjoy.`;

  return (
    <Main
      isLoading={isLoading}
      isWhiteLabelingOpen={false}
    >
      <Flex
        width="100%"
        flexDirection="column"
        alignItems="start"
      >
        <Typography variant="garamond-500" fontSize={[30, 34]} mb={['20px', '30px']}>
          {`Buy ${isActivate ? 'Company Credits' : 'Credits'}`}
        </Typography>
        {isActivate && (
          <>
            <Typography variant="proximaNova-600" mb="7px">
              Planning more meetings?
            </Typography>
            <Typography maxWidth="52%" variant="proximaNova-400" fontSize={[12, 14, 16]} mb="10px">
              {activateText}
            </Typography>
          </>
        )}
        <Flex width="100%" justifyContent="start">
          {!!packages.length && (
            <Flex flexDirection="column" width={['100%', '52%', '52%', '52%']}>
              {isDiscountAvailable && (
                <Flex
                  mb="10px"
                  alignSelf="center"
                  width="fit-content"
                  alignItems="center"
                  px="40px"
                  py="4px"
                  backgroundColor={theme.color.darkGreen}
                  borderRadius={100}
                >
                  <Icon mr="15px" SVG={DiscountWhite} width={30} height={30} />
                  <Typography color={theme.color.white} variant="proximaNova-400" fontSize={14}>
                    Save 20% your first week
                  </Typography>
                </Flex>
              )}
              <PackagesList
                isDiscountAvailable={isDiscountAvailable}
                packages={packages}
                selectedPackageId={selectedPackageId}
                onPackageClick={handlePackageClick}
              />
              {isButtonDisabled && (
                <Typography
                  mt={20}
                  maxWidth={400}
                  fontSize={[10, 12, 14]}
                  variant="proximaNova-400"
                  color={theme.color.red}
                >
                  {`Sorry, more than ${maxCreditsPerMonth} credits cannot be purchased per month. 
                  ${companyName} has already acquired ${alreadyPurchasedCredits} credits this month.`}
                </Typography>
              )}
              <Typography my={['10px', '15px']} mr={10} variant="proximaNova-400" fontSize={[10, 12, 14]}>
                {`${vatPercent ? `${vatPercent}% VAT will be added to these prices.` : ''}
                Credit packages are valid for 90 days.`}
              </Typography>
              <Flex justifyContent="end">
                {isAbleToInvoice && (
                  <Button
                    disabled={isButtonDisabled || !selectedPackageId}
                    onClick={handleInvoiceMe(selectedPackageId)}
                    mr={15}
                    py={['5px', '7px']}
                    width={[100, 120, 145]}
                    fontSize={[12, 14, 16]}
                    backgroundColor="#EFECE6"
                  >
                    Invoice Me
                  </Button>
                )}
                {isAbleToPayByCard && (
                  <Button
                    onClick={handlePurchaseClick}
                    disabled={!selectedPackageId || isButtonDisabled}
                    variant="primary"
                    width={[100, 120, 145]}
                    fontSize={[12, 14, 16]}
                    py={['5px', '7px']}
                  >
                    {isAbleToInvoice ? 'Pay Now' : 'Purchase'}
                  </Button>
                )}
              </Flex>
            </Flex>
          )}
          <Flex
            ml={['20px', '25px']}
            width={[0, '42%', '42%', '42%']}
            height={['160px', '200px', '250px', '310px']}
            background={`linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), 
            url(${BuyingCredits})`}
            backgroundSize="cover"
          />
        </Flex>
      </Flex>
      {paymentType && (
        <ConfirmationModal
          icon={CreditsAdded}
          isOpen={!!paymentType}
          onPrimaryButtonClick={onClearProcess}
          title="Credits Added"
          text={`A package of ${selectedPackage?.amount} has been added to ${companyName}
          ${paymentType === 'invoice'
          ? ` and ${getCurrency(selectedPackage?.currency, selectedPackage?.price)} will be invoiced`
          : ''}. The credit package is valid for 90 days.`}
          primaryButtonText={from.includes('rooms/') ? 'Confirm booking' : 'Done'}
        />
      )}
      {isPayByCardOpen && (
        <PayByCard
          onProcessFinish={handleProcessFinish}
          onClose={handlePayByCardChangeState}
          isOpen={isPayByCardOpen}
          creditPackageId={selectedPackageId}
        />
      )}
    </Main>
  );
};

CreditsPackages.defaultProps = {
  from: '',
  vatPercent: 0,
  companyName: '',
  isAbleToInvoice: false,
  isAbleToPayByCard: false,
  isActivate: false,
};

CreditsPackages.propTypes = {
  isDiscountAvailable: bool.isRequired,
  onGoBack: func.isRequired,
  from: string,
  vatPercent: number,
  isAbleToInvoice: bool,
  isAbleToPayByCard: bool,
  isActivate: bool,
  companyName: string,
  onInvoiceMe: func.isRequired,
  isLoading: bool.isRequired,
  packages: arrayOf(
    exact({
      id: number,
      currency: string,
      amount: number,
      vatCalculated: number,
      totalPrice: number,
      price: number,
    }),
  ).isRequired,
  maxCreditsPerMonth: number.isRequired,
  alreadyPurchasedCredits: number.isRequired,
};

export default withStripe(CreditsPackages);
