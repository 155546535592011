import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { BuildingAmenities as View } from '@views';
import { trackEvent } from '@utils/mixpanel';

const BuildingAmenities = () => {
  const { currentLocation } = useSelector(store => (
    store.locationStore
  ));

  useEffect(() => {
    const pageTitle = 'Mindspace: Building Amenities';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Building Amenities',
    });
  }, []);

  return (
    <View
      amenities={currentLocation.locationToAmenity}
    />
  );
};

export default BuildingAmenities;
