import React from 'react';
import { number, bool, func, string } from 'prop-types';

import { Icon, Loader } from '@atoms';
import { TextInput } from '@atoms/Input';
import { NeighborsList } from '@organisms';
import { Main } from '@templates';
import { Typography, Flex } from '@mixins';
import { theme } from '@styles';

import { ReactComponent as Search } from '@assets/svgs/Search.svg';
import { ReactComponent as Building } from '@assets/svgs/Building.svg';
import { ReactComponent as Seats } from '@assets/svgs/Seats.svg';

const KnowYourNeighbors = ({
  areNeighborsLoading,
  search,
  members,
  companies,
  isLoading,
  onSearchInputChange,
  onClickLoadMore,
}) => (
  <Main
    isWhiteLabelingOpen={false}
    isLoading={isLoading}
  >
    <Flex
      flexGrow={1}
      width="100%"
      alignItems="start"
      flexDirection="column"
    >
      <Typography alignSelf="start" variant="garamond-500" fontSize={[30, 34]} mb={[20, 30]}>
        Know Your Neighbors
      </Typography>
      <Flex mb={[15, 20, 30]} alignItems="start">
        <Icon SVG={Building} mr={12} />
        <Typography
          variant="proximaNova-400"
          fontSize={[12, 14, 16]}
          color={theme.color.gray[300]}
          mr={[21, 23, 25]}
        >
          {`${companies} companies`}
        </Typography>
        <Icon height={20} SVG={Seats} mr={12} />
        <Typography
          variant="proximaNova-400"
          fontSize={[12, 14, 16]}
          color={theme.color.gray[300]}
        >
          {`${members} members`}
        </Typography>
      </Flex>
      <Flex mb={[20, 30]} width={['100%', '30%', '30%', '420px']}>
        <TextInput value={search} onChange={onSearchInputChange} placeholder="Search companies">
          <Icon mr={[10, 12]} SVG={Search} />
        </TextInput>
      </Flex>
      {areNeighborsLoading
        ? (
          <Flex width="100%" flexGrow={1} alignItems="center" justifyContent="center">
            <Loader position="relative" background={theme.color.white} width={64} height={64} />
          </Flex>
        )
        : (
          <NeighborsList
            onClickLoadMore={onClickLoadMore}
          />
        )}
    </Flex>
  </Main>
);

KnowYourNeighbors.defaultProps = {
  members: 0,
  companies: 0,
};

KnowYourNeighbors.propTypes = {
  areNeighborsLoading: bool.isRequired,
  onClickLoadMore: func.isRequired,
  search: string.isRequired,
  onSearchInputChange: func.isRequired,
  isLoading: bool.isRequired,
  companies: number,
  members: number,
};

export default KnowYourNeighbors;
