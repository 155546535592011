import React, { useMemo, useEffect, useState } from 'react';
import { bool, func, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, EventHeader, Loader, Icon } from '@atoms';
import { ErrorModal } from '@molecules';
import { LOADING, SUCCESS, FAILURE } from '@constants/requestPhase';
import { Flex, Typography, Button } from '@mixins';
import { useViewport } from '@hooks';
import { getCurrency } from '@helpers/common';
import { formatTime, getEventsSlotsList } from '@helpers/time';
import { dayjs } from '@utils';
import { trackEvent } from '@utils/mixpanel';
import { getEventSlots, bookEvent, cancelBookingEvent, resetPhases } from '@store/event/duck';
import { theme } from '@styles';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import { ReactComponent as Checkmark } from '@assets/svgs/Checkmark.svg';

import { PrevButton } from '../BenefitsCarousel/styles';
import { ScrollableContainer } from '../EventDateSelectingModal/styles';

const getButtonLabel = isMyBooking => {
  if (isMyBooking) {
    return (
      <>
        <Icon mr={10} color={theme.color.white} SVG={Checkmark} />
        Attending
      </>
    );
  }
  return 'Book';
};

const EventTimeSelectingModal = ({
  isOpen,
  onClose,
  onGoBack,
  selectedDateId,
  selectedService,
}) => {
  const dispatch = useDispatch();
  const screenSize = useViewport();

  const {
    eventById: {
      details: {
        id: eventId, upcomingId, isRecurring,
      }, slots, dates: { services, startTime, endTime, availableDates },
      bookings,
    },
    getEventSlotsPhase, bookEventPhase, bookEventError, cancelBookingEventPhase,
  } = useSelector(store => store.eventStore);

  const { currentLocation } = useSelector(store => store.locationStore);

  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const selectedDate = useMemo(() => (
    availableDates?.find(avDate => avDate.id === selectedDateId)
  ), [availableDates, selectedDateId]);
  const slottedService = useMemo(() => (
    services?.find(service => service.id === selectedService)
  ), [selectedService, services]);

  const slottedStartTime = selectedDate?.date ? `${selectedDate.date}T${startTime}.000Z` : 0;
  const slottedEndTime = selectedDate?.date ? `${selectedDate.date}T${endTime}.000Z` : 0;

  const isLoadingState = isLoading || getEventSlotsPhase === LOADING || bookEventPhase === LOADING ||
  cancelBookingEventPhase === LOADING;
  const id = isRecurring ? selectedDateId : upcomingId;

  const allSlots = useMemo(() => (
    getEventsSlotsList(slottedStartTime, slottedEndTime, slottedService?.duration)
  ), [slottedStartTime, slottedEndTime, slottedService?.duration]);

  useEffect(() => {
    if (bookEventPhase === FAILURE) {
      setError({
        status: bookEventError.data.error.status,
        message: 'Yikes, this time slot has just been taken',
        buttonLabel: 'RSVP for a different spot',
      });
      dispatch(resetPhases());
    }
  }, [bookEventPhase]);

  useEffect(() => {
    if (isOpen) {
      dispatch(getEventSlots(eventId, id, selectedService));
    }
  }, [isOpen]);

  useEffect(() => {
    if (getEventSlotsPhase === SUCCESS) {
      setIsLoading(false);
      resetPhases();
    }
  }, [getEventSlotsPhase]);

  const handleErrorClose = () => {
    dispatch(getEventSlots(eventId, id, selectedService));

    setError({});
  };

  const handleBookSlot = (time, bookingId) => () => {
    if (bookingId) {
      trackEvent('Event booking canceled', {
        scope: 'Events',
        id,
      });

      dispatch(cancelBookingEvent(eventId, id, {
        bookingId,
      }));
    } else {
      trackEvent('Event booked', {
        scope: 'Events',
        id,
      });

      dispatch(bookEvent(eventId, id, {
        serviceId: selectedService,
        time: time.substring(11, 19),
      }));
    }
  };

  const slotsBlock = useMemo(() => (
    allSlots.reduce((acc, date, index) => {
      const isAvailable = slots?.find(slot => slot.startTime === date);
      const myBooking = bookings?.find(booking => (
        booking.serviceId === selectedService && booking.time === date.substring(11, 19)
        && booking.eventId === id
      ));

      if (allSlots[index + 1]) {
        acc.push(
          <Flex
            key={date}
            justifyContent="space-between"
            py={15}
            alignItems="center"
            borderBottom={index === allSlots.length - 2
              ? 'none' : `1px solid ${theme.color.gray[100]}`}
          >
            <Typography
              color={isAvailable || myBooking ? theme.color.gray[500] : '#8A8A8A'}
              variant="proximaNova-400"
              fontSize={16}
            >
              {`${formatTime(date, currentLocation.timeFormat)}-${formatTime(
                allSlots[index + 1], currentLocation.timeFormat,
              )}`}
            </Typography>
            {isAvailable || myBooking
              ? (
                <Button onClick={handleBookSlot(date, myBooking?.id)} variant="primary" px={20} py="6px">
                  {getButtonLabel(!!myBooking)}
                </Button>
              )
              : (
                <Typography
                  color="#8A8A8A"
                  variant="proximaNova-600"
                  py="5px"
                  px={10}
                  border="1px solid #8A8A8A"
                >
                  Booked
                </Typography>
              )}
          </Flex>,
        );
      }
      return acc;
    }, [])), [allSlots, slots, bookings]);

  return (
    <Modal withPadding={false} onClose={onClose} topPosition={50} isOpen={isOpen} withCloseButton>
      <Flex
        flexGrow={1}
        width={[300, 450, 600]}
        height={[400, 510, 720]}
        {...(screenSize.height < 780 && {
          height: screenSize.height - 150,
          overflow: 'scroll',
        })}
        flexDirection="column"
      >
        <Flex
          pt={[15, 25, 40]}
          pb={30}
          px={[10, 20, 30]}
          borderBottom={`1px solid ${theme.color.gray[100]}`}
          flexDirection="column"
        >
          {slottedService ? (
            <Flex justifyContent="space-between" alignItems="end">
              <Flex flexDirection="column">
                <PrevButton
                  pointer
                  mb={[15, 25]}
                  onClick={onGoBack}
                  color={theme.color.black}
                  SVG={Dropdown}
                />
                <Typography mb="5px" variant="proximaNova-600" fontSize={[16, 18, 20]}>
                  {slottedService.name}
                </Typography>
                <Typography variant="proximaNova-400" fontSize={[12, 14, 16]}>
                  {`${dayjs(slottedStartTime).format('ddd')}, ${dayjs(slottedStartTime).format('MMMM')} 
                  ${dayjs(slottedStartTime).date()}, ${dayjs(slottedStartTime).format('YYYY')}`}
                </Typography>
              </Flex>
              <Typography variant="proximaNova-400" fontSize={[10, 12, 14]}>
                {`${slottedService.duration} min | ${getCurrency(
                  slottedService.currency, slottedService.price,
                )}`}
              </Typography>
            </Flex>
          )
            : (
              <>
                <Typography mb={[20, 30]} variant="garamond-500" fontSize={[24, 30, 36]}>
                  Select a time
                </Typography>
                <EventHeader />
              </>
            )}
        </Flex>
        {isLoadingState
          ? (
            <Flex alignSelf="center" flexGrow={1}>
              <Loader position="relative" background={theme.color.white} width={40} height={40} />
            </Flex>
          )
          : (
            <ScrollableContainer m="4px" px={[20, 25, 30]} flexDirection="column">
              {slotsBlock}
            </ScrollableContainer>
          )}
      </Flex>
      <ErrorModal
        error={error}
        onModalClose={handleErrorClose}
        isOpen={!!Object.keys(error)?.length}
      />
    </Modal>
  );
};

EventTimeSelectingModal.defaultProps = {
  selectedDateId: 0,
  selectedService: 0,
  onGoBack: () => {},
};

EventTimeSelectingModal.propTypes = {
  selectedService: number,
  selectedDateId: number,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onGoBack: func,
};

export default EventTimeSelectingModal;
