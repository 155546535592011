import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { bool, func, string, number, exact } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AddToCalendar, EventDateSelectingModal, EventTimeSelectingModal } from '@molecules';
import { EventBookedModal } from '@organisms';
import { Main } from '@templates';
import { theme } from '@styles';
import { routes } from '@constants';
import { SUCCESS } from '@constants/requestPhase';
import { dayjs } from '@utils';
import { trackEvent } from '@utils/mixpanel';
import { Typography, Flex, Button } from '@mixins';
import { formatTime } from '@helpers/time';
import { getRSVPButtonLabel, getCloudinaryImageLinkBySize } from '@helpers/common';
import { useViewport } from '@hooks';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { BackButton } from '../EditUserProfile/styles';

const EventDetails = ({
  error,
  status,
  id,
  onUnbookEvent,
  onBookEvent,
  onSelectedServiceChange,
  onSelectedDateChange,
  selectedDateId,
  selectedService,
  isAttending,
  isLoading,
  imageUrl,
  name,
  startDate,
  endDate,
  description,
  location,
  locationDescription,
  hasSlots,
  shouldOpenModal,
}) => {
  const history = useHistory();
  const loc = useLocation();

  const { currentLocation } = useSelector(store => store.locationStore);

  const { eventById: {
    details, dates: { availableDates },
  }, bookEventPhase, cancelBookingEventPhase } = useSelector(store => store.eventStore);

  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isEventBooked, setIsEventBooked] = useState(false);
  const [isTimeslotModalOpen, setIsTimeslotModalOpen] = useState(false);

  useEffect(() => {
    onSelectedDateChange(details.upcomingId);
  }, [availableDates]);

  useEffect(() => {
    if (id && id === details.id && shouldOpenModal) {
      setIsDateModalOpen(true);
    }
  }, [details]);

  const handleDateModalChangeState = useCallback(() => {
    setIsDateModalOpen(prevState => {
      onSelectedDateChange(details.upcomingId);

      return !prevState;
    });
  }, [availableDates, details]);

  const handleTimeslotModalChangeState = useCallback(() => {
    setIsTimeslotModalOpen(prevState => {
      onSelectedDateChange(details.upcomingId);

      return !prevState;
    });
  }, [availableDates, details]);

  const handleEventBookedModalClose = useCallback(() => {
    setIsEventBooked(false);
    history.push({ pathname: loc.state?.from ?? routes.events });
  }, [loc.state?.from]);

  const handleBookAnotherDate = useCallback(() => {
    setIsEventBooked(false);
    handleDateModalChangeState();
  }, []);

  const handleDateChange = date => () => {
    onSelectedDateChange(date);
  };

  const handleServiceOpen = serviceId => () => {
    onSelectedServiceChange(serviceId);
    setIsDateModalOpen(false);
    setIsTimeslotModalOpen(true);
  };

  const handleTimeModalClickBack = useCallback(() => {
    setIsDateModalOpen(true);
    setIsTimeslotModalOpen(false);
  }, []);

  const handleRSVPClick = useCallback(() => {
    if (details.isRecurring || details.hasSlots) {
      trackEvent('"Book a Spot" clicked', {
        scope: 'Events',
        id,
      });

      handleDateModalChangeState();
    } else if (isAttending) {
      onUnbookEvent();
      trackEvent('Event booking canceled', {
        scope: 'Events',
        id,
      });
    } else {
      onBookEvent();
      trackEvent('Event booked', {
        scope: 'Events',
        id,
      });
    }
  }, [details, isAttending]);

  const onBackClick = useCallback(() => {
    history.push(routes.events);
  }, []);

  useEffect(() => {
    if (bookEventPhase === SUCCESS) {
      setIsEventBooked(true);
    }
    if ((bookEventPhase === SUCCESS || cancelBookingEventPhase === SUCCESS)
    && (details.isRecurring || details.hasSlots)) {
      setIsDateModalOpen(false);
      onSelectedDateChange(availableDates[0]?.id);
      setIsTimeslotModalOpen(false);
      onSelectedServiceChange(0);
    }
  }, [bookEventPhase, cancelBookingEventPhase]);

  const { width } = useViewport();

  const IMAGE_WIDTH = useMemo(() => (width > 1024 ? 660 : 360), [width]);

  return (
    <Main error={error} isLoading={isLoading}>
      <Flex
        width="100%"
        alignItems="start"
        flexDirection="column"
      >
        <Flex
          width="fit-content"
          alignItems="center"
          mb={[15]}
          onClick={onBackClick}
          pointer
        >
          <BackButton mr={['9px', 11, 13]} color={theme.color.gray[500]} SVG={Dropdown} />
          <Typography variant="proximaNova-400">
            Events
          </Typography>
        </Flex>
        <Flex mb={[30, 40, 50, 60]} width="100%" justifyContent="space-between">
          <Flex flexDirection="column" maxWidth={[230, 280, 365, 500]}>
            <Typography
              mb={[15, 20]}
              variant="garamond-500"
              fontSize={[30, 34]}
            >
              {name}
            </Typography>
            <Typography mb={[10, 15]} variant="proximaNova-600" fontSize={[16, 18, 20]}>
              {`${dayjs(startDate).format('ddd')}, ${dayjs(startDate).format('MMMM')} ${dayjs(startDate).$D}, 
              ${formatTime(startDate, currentLocation.timeFormat, 'title', details.timezone)}-${formatTime(
      endDate, currentLocation.timeFormat, 'title', details.timezone,
    )}`}
            </Typography>
            <Typography
              variant="proximaNova-400"
              fontSize={[12, 14, 16]}
              color={theme.color.gray[300]}
            >
              {location}
            </Typography>
            {locationDescription && (
              <Typography
                variant="proximaNova-400"
                fontSize={[12, 14, 16]}
                color={theme.color.gray[300]}
              >
                {locationDescription}
              </Typography>
            )}
            <Button
              my={[20, 30]}
              onClick={handleRSVPClick}
              width="fit-content"
              variant="primary"
              px={[60, 80, 110]}
              disabled={status === 'full' && !hasSlots && !details.isRecurring && !isAttending}
              py={['8px', 10, 12]}
            >
              {getRSVPButtonLabel(isAttending, hasSlots, details.isRecurring, status)}
            </Button>
            {!hasSlots && (
              <Flex mb={[0, 0, 0, 50]} width="fit-content">
                <AddToCalendar
                  title={name}
                  startDate={dayjs(startDate)}
                  endDate={dayjs(endDate)}
                  address={location}
                />
              </Flex>
            )}
            {width >= 1024 && (
              <Flex
                display="block"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </Flex>
          <Flex
            ml={15}
            width="50%"
            height={[200, 280, 330, 500]}
            background={`url(${getCloudinaryImageLinkBySize(
              imageUrl, IMAGE_WIDTH,
            )}) no-repeat center`}
            backgroundSize="cover"
          />
        </Flex>
        {width < 1024 && (
          <Flex
            maxWidth={540}
            display="block"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        <EventDateSelectingModal
          onDateChange={handleDateChange}
          onServiceOpen={handleServiceOpen}
          selectedDateId={selectedDateId}
          isOpen={isDateModalOpen}
          onClose={handleDateModalChangeState}
        />
        <EventTimeSelectingModal
          isOpen={isTimeslotModalOpen}
          onClose={handleTimeslotModalChangeState}
          onGoBack={handleTimeModalClickBack}
          selectedDateId={selectedDateId}
          selectedService={selectedService}
        />
        {isEventBooked && (
          <EventBookedModal
            isOpen={isEventBooked}
            onClose={handleEventBookedModalClose}
            onBookAnotherDate={handleBookAnotherDate}
          />
        )}
      </Flex>
    </Main>
  );
};

EventDetails.defaultProps = {
  shouldOpenModal: false,
  hasSlots: false,
  imageUrl: '',
  name: '',
  description: '',
  location: '',
  locationDescription: '',
  status: 'available',
  selectedDateId: 0,
  selectedService: 0,
  isAttending: false,
};

EventDetails.propTypes = {
  error: exact({
    status: number,
    message: string,
  }).isRequired,
  status: string,
  id: number.isRequired,
  shouldOpenModal: bool,
  onUnbookEvent: func.isRequired,
  selectedService: number,
  selectedDateId: number,
  onSelectedDateChange: func.isRequired,
  onSelectedServiceChange: func.isRequired,
  hasSlots: bool,
  locationDescription: string,
  isAttending: bool,
  description: string,
  location: string,
  isLoading: bool.isRequired,
  imageUrl: string,
  name: string,
  startDate: string.isRequired,
  endDate: string.isRequired,
  onBookEvent: func.isRequired,
};

export default EventDetails;
