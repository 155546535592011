import styled, { css } from 'styled-components';

import { Icon } from '@atoms';
import { Flex } from '@mixins';
import { theme } from '@styles';

export const RoomContainer = styled(Flex)`
${() => css`
  direction: ltr;
`}
`;

export const ScrollableContainer = styled(Flex)`
${() => css`
    direction: rtl;
    flex-direction: column;
    overflow-y: scroll;
    margin-bottom: 10px;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: ${theme.color.gray[100]};
    }
  `}
`;

export const StyledDropdown = styled(Icon)`
  ${({ isExpanded }) => css`
    ${!isExpanded && 'transform: rotate(90deg);'}
    transition: transform 0.5s;
  `}
`;
