import React from 'react';
import { Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { useNetwork } from '@hooks';
import { theme } from '@styles';

import { ReactComponent as Warning } from '@assets/svgs/Warning.svg';

const NetworkErrorToast = () => {
  const isOnline = useNetwork();

  return !isOnline && (
    <Flex
      width={350}
      boxShadow="0px 4px 12px rgba(0, 0, 0, 0.2)"
      backgroundColor={theme.color.pink}
      position="fixed"
      p={15}
      bottom={2}
      left={2}
      alignItems="center"
      borderRadius="2px"
    >
      <Icon width={20} height={20} mr={15} SVG={Warning} />
      <Typography variant="proximaNova-600" fontSize={16}>
        No internet connection
      </Typography>
    </Flex>
  );
};

export default NetworkErrorToast;
