import React from 'react';
import { func, number, string } from 'prop-types';

import { LoginCarousel } from '@atoms';
import { PhoneNumberInput, TextInput } from '@atoms/Input';
import { Button, Flex } from '@mixins';
import { theme } from '@styles';

import { Error } from './styles';

const WelcomeForm = ({
  onTabClick,
  activeTab,
  onFormSubmit,
  error,
  onEmailChange,
  onCountrySelect,
  selectedCountry,
  onPhoneNumberValueChange,
  phoneNumberValue,
  emailValue,
}) => {
  const tabs = [
    {
      name: 'Email',
      component: (
        <TextInput
          hasError={!!error}
          placeholder="name@workemail.com"
          onChange={onEmailChange}
          value={emailValue}
        />
      ),
    },
    {
      name: 'Phone',
      component: (
        <PhoneNumberInput
          hasError={!!error}
          selectedCountry={selectedCountry}
          onCountrySelect={onCountrySelect}
          phoneNumberValue={phoneNumberValue}
          onPhoneNumberValueChange={onPhoneNumberValueChange}
        />
      ),
    }];

  return (
    <Flex width={['241px', '334px']} flexDirection="column" alignItems="center">
      <LoginCarousel
        activeTab={activeTab}
        tabs={tabs}
        onTabClick={onTabClick}
      />
      <Error
        my="10px"
        alignSelf="start"
        hasError={error}
        fontSize={['12px', '14px']}
        variant="proximaNova-400"
        color={theme.color.red}
      >
        {error}
      </Error>
      <Button
        mt="15px"
        width="100%"
        variant="primary"
        py={['8px', '12px']}
        onClick={onFormSubmit}
      >
        Send Code
      </Button>
    </Flex>
  );
};

WelcomeForm.propTypes = {
  activeTab: number.isRequired,
  onTabClick: func.isRequired,
  onFormSubmit: func.isRequired,
  error: string.isRequired,
  onEmailChange: func.isRequired,
  onCountrySelect: func.isRequired,
  selectedCountry: string.isRequired,
  onPhoneNumberValueChange: func.isRequired,
  phoneNumberValue: string.isRequired,
  emailValue: string.isRequired,
};

export default WelcomeForm;
