import React, { useState, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import useMeasure from 'react-use-measure';
import { string, arrayOf, bool } from 'prop-types';

import { Icon } from '@atoms';
import { Flex, AnimatedFlex } from '@mixins';
import { usePrevious } from '@hooks';
import { getCloudinaryImageLinkBySize } from '@helpers/common';
import { theme } from '@styles';

import { ReactComponent as Arrow } from '@assets/svgs/SmallArrow.svg';
import { ReactComponent as TinyArrow } from '@assets/svgs/TinyArrow.svg';

import RoomImage from '@assets/images/RoomImage.png';

import { ButtonContainer, PrevButton, Line } from './styles';

const variants = {
  enter: ({ direction, width }) => ({ x: direction * width }),
  center: { x: 0 },
  exit: ({ direction, width }) => ({ x: direction * -width }),
};

const IMAGE_WIDTH = 428;

const RoomPhotosCarousel = ({ photos, isSmall }) => {
  const [count, setCount] = useState(0);

  const [ref, { width }] = useMeasure();
  const prev = usePrevious(count);

  const direction = count > prev ? 1 : -1;

  const handlePreviousClick = useCallback(event => {
    event.stopPropagation();

    setCount(prevValue => (prevValue ? prevValue - 1 : prevValue));
  }, []);

  const handleNextClick = useCallback(event => {
    event.stopPropagation();

    setCount(prevValue => (prevValue < photos.length - 1 ? prevValue + 1 : prevValue));
  }, [photos]);

  const selectionBlock = new Array(photos.length).fill().map((el, index) => (
    <Line
      key={photos[index] + Math.floor(Math.random() * 1000)}
      isSelected={index === count}
      zIndex={2}
      height="4px"
      mr={index === photos.length - 1 ? 0 : '6px'}
      width={`${100 / photos.length}%`}
      mb={15}
    />
  ));

  return (
    <Flex
      ref={ref}
      position="relative"
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="end"
      overflow="hidden"
      px={45}
    >
      {photos.length > 1 && (
        <>
          <ButtonContainer
            position="absolute"
            top="43%"
            left={isSmall ? '7px' : '15px'}
            zIndex={1}
            backgroundColor={theme.color.white}
            pointer
            alignItems="center"
            justifyContent="center"
            width={isSmall ? 14 : [25, 30]}
            height={isSmall ? 14 : [25, 30]}
            borderRadius="50%"
            onClick={handlePreviousClick}
          >
            <PrevButton
              color={theme.color.white}
              SVG={isSmall ? TinyArrow : Arrow}
            />
          </ButtonContainer>
          <ButtonContainer
            backgroundColor={theme.color.white}
            position="absolute"
            zIndex={1}
            top="43%"
            right={isSmall ? '7px' : '15px'}
            pointer
            alignItems="center"
            justifyContent="center"
            width={isSmall ? 14 : [25, 30]}
            height={isSmall ? 14 : [25, 30]}
            borderRadius="50%"
            onClick={handleNextClick}
          >
            <Icon
              color={theme.color.white}
              SVG={isSmall ? TinyArrow : Arrow}
            />
          </ButtonContainer>
        </>
      )}
      <AnimatePresence custom={{ direction, width }}>
        <AnimatedFlex
          transition={{
            x: {
              duration: 0.3,
            },
          }}
          key={count}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          custom={{ direction, width }}
          position="absolute"
          height="100%"
          width="100%"
        >
          <Flex
            width="100%"
            height="100%"
            alignItems="end"
            background={`url(${getCloudinaryImageLinkBySize(photos[count], IMAGE_WIDTH)
              || RoomImage}) no-repeat center`}
            backgroundSize="cover"
          />
        </AnimatedFlex>
      </AnimatePresence>
      {!isSmall && photos.length > 1 && selectionBlock}
    </Flex>
  );
};

RoomPhotosCarousel.defaultProps = {
  photos: [],
  isSmall: false,
};

RoomPhotosCarousel.propTypes = {
  photos: arrayOf(
    string,
  ),
  isSmall: bool,
};

export default RoomPhotosCarousel;
