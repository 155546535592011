const apiEndpoints = {
  location: 'api/location',
  currentUser: 'api/user/me',
  user: 'api/user',
  userSettings: 'api/user/settings',
  company: 'api/company',
  adminCompany: 'api/admin/company',
  meetingRoom: 'api/meeting-room',
  meetingRoomFilters: 'api/meeting-room/filter-options',
  metadata: 'api/metadata',
  metadataNavigation: 'api/metadata/navigation',
  booking: 'api/booking',
  bookingCost: 'api/booking/cost-estimate',
  supportTicket: 'api/support/ticket',
  supportFeedback: 'api/support/feedback',
  benefit: 'api/benefit',
  benefitCagerories: 'api/benefit-category',
  paymentPackages: 'api/payment/packages',
  paymentInvoice: 'api/payment/invoice-me',
  event: 'api/event',
  eventNearby: 'api/event/nearby',
  eventCategory: 'api/event-category',
  initPaymentMethod: 'api/payment/method/init',
  paymentMethod: 'api/payment/method',
  initBuyCredits: 'api/payment/buy-credits/init',
  buyCredits: 'api/payment/buy-credits',
  availableCredits: '/api/company/my/available-credits',
  announcement: '/api/announcement',
};

export default apiEndpoints;

