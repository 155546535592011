import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { string, func, bool } from 'prop-types';
import examples from 'libphonenumber-js/mobile/examples';
import { AsYouType, getExampleNumber } from 'libphonenumber-js/max';

import { Icon } from '@atoms';
import { Error } from '@molecules/WelcomeForm/styles';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { useDebounce, useOutsideClick } from '@hooks';
import COUNTRIES from '@constants/countries';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import { ReactComponent as Search } from '@assets/svgs/Search.svg';
import { ReactComponent as NoResults } from '@assets/svgs/NoResults.svg';

import Input from '../styles';
import TextInput from '../TextInput';

const PhoneNumberInput = ({
  selectedCountry,
  onCountrySelect,
  phoneNumberValue,
  onPhoneNumberValueChange,
  hasError,
  error,
}) => {
  const containerRef = useRef();
  const dropdownRef = useRef();

  const { isOpen, setIsOpen } = useOutsideClick(false, [containerRef, dropdownRef]);
  const [countryList, setCountryList] = useState(COUNTRIES);
  const [searchInput, setSearchInput] = useState('');

  const placeholder = getExampleNumber(selectedCountry, examples);
  const debouncedInput = useDebounce(searchInput, 200);

  const onDropdownClick = useCallback(() => {
    setIsOpen(prevValue => !prevValue);
    setSearchInput('');
  }, []);

  const onCountryClick = useCallback(event => {
    setIsOpen(false);
    setCountryList(COUNTRIES);
    onCountrySelect(event.currentTarget.id);
    setSearchInput('');
  }, []);

  const onSearchChange = useCallback(event => {
    setSearchInput(event.target.value);
  }, []);

  const onPhoneNumberChange = useCallback(event => {
    const phoneNumber = event.target.value;

    const formatedNumber = new AsYouType()
      .input(COUNTRIES[selectedCountry]?.dialCode + phoneNumber)
      .split(COUNTRIES[selectedCountry]?.dialCode)[1]
      .trim();

    onPhoneNumberValueChange(formatedNumber[0] === '0' ? formatedNumber.substring(1) : formatedNumber);
  }, [selectedCountry]);

  const filterCountries = useCallback(value => {
    setCountryList(
      Object.keys(COUNTRIES)
        .filter(
          country =>
            COUNTRIES[country].name.toUpperCase().includes(value.toUpperCase()) ||
            COUNTRIES[country]?.dialCode.includes(value),
        )
        .reduce(
          (obj, key) => ({
            ...obj,
            [key]: COUNTRIES[key],
          }),
          {},
        ),
    );
  }, []);

  useEffect(() => {
    filterCountries(debouncedInput);
  }, [debouncedInput]);

  const countries = useMemo(
    () =>
      Object.keys(countryList).map(country => (
        <Flex
          id={country}
          onClick={onCountryClick}
          pointer
          key={country}
          py={14}
          justifyContent="space-between"
        >
          <Flex>
            <Flex minWidth={22} height={22} mr={12} backgroundImage={`url(${COUNTRIES[country]?.flag})`} />
            <Typography variant="proximaNova-400">{COUNTRIES[country].name}</Typography>
          </Flex>
          <Typography variant="proximaNova-400">{COUNTRIES[country]?.dialCode}</Typography>
        </Flex>
      )),
    [countryList],
  );

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" position="relative">
        <Flex
          onClick={onDropdownClick}
          ref={dropdownRef}
          width="135px"
          alignItems="center"
          justifyContent="start"
          borderBottom={`1px solid ${hasError ? theme.color.red : theme.color.gray[200]}`}
          mr={30}
        >
          {selectedCountry && (
            <Flex
              minWidth={18}
              height={18}
              mr={3}
              backgroundImage={`url(${COUNTRIES[selectedCountry]?.flag})`}
              backgroundSize="cover"
            />
          )}
          <Input
            hideCursor
            width="100%"
            border="0px"
            py="8px"
            fontWeight={400}
            color={error ? theme.color.red : theme.color.gray[400]}
            value={selectedCountry ? COUNTRIES[selectedCountry]?.dialCode : ''}
            onChange={() => {}}
            placeholder="Country"
          />
          <Icon
            color={selectedCountry ? theme.color.black : theme.color.gray[200]}
            pointer
            SVG={Dropdown}
            width="15"
            height="15"
          />
        </Flex>
        <Flex flex={1} borderBottom={`1px solid ${hasError ? theme.color.red : theme.color.gray[200]}`}>
          <Input
            maxLength={15}
            width="100%"
            border="none"
            py="8px"
            fontWeight={400}
            color={error ? theme.color.red : theme.color.gray[400]}
            value={phoneNumberValue}
            onChange={onPhoneNumberChange}
            placeholder={new AsYouType()
              .input(COUNTRIES[selectedCountry]?.dialCode + placeholder?.nationalNumber)
              .split(COUNTRIES[selectedCountry]?.dialCode)[1]
              .trim()
              .replace(/[0-9]/g, '0')}
          />
        </Flex>
        {isOpen && (
          <Flex
            zIndex={3}
            isOpened={isOpen}
            flexDirection="column"
            border={`1px solid ${theme.color.gray[100]}`}
            backgroundColor={theme.color.white}
            width="100%"
            position="absolute"
            top="45px"
          >
            <Flex ref={containerRef} flexDirection="column" alignItems="center" width="100%">
              <TextInput placeholder="Search" onChange={onSearchChange} value={searchInput}>
                <Icon
                  mr={9}
                  ml={16}
                  color={selectedCountry ? theme.color.black : theme.color.gray[200]}
                  pointer
                  SVG={Search}
                  width="13"
                  height="13"
                />
              </TextInput>
              <ScrollableContainer
                width="100%"
                isOpened={isOpen}
                mx={10}
                pr={countries.length ? 26 : 0}
                pl={10}
              >
                {countries.length ? (
                  countries
                ) : (
                  <Flex flexDirection="column" height="100%" alignItems="center" justifyContent="center">
                    <Icon SVG={NoResults} width="45" height="45" />
                    <Typography variant="proximaNova-400">No results</Typography>
                  </Flex>
                )}
              </ScrollableContainer>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Error hasError={error} fontSize={[10, 12]} variant="proximaNova-400" color={theme.color.red}>
        {error}
      </Error>
    </Flex>
  );
};

const ScrollableContainer = styled(Flex)`
  ${() => css`
    flex-direction: column;
    height: 326px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background-color: ${theme.color.gray[100]};
    }
  `}
`;

PhoneNumberInput.defaultProps = {
  error: null,
  hasError: false,
};

PhoneNumberInput.propTypes = {
  error: string,
  selectedCountry: string.isRequired,
  phoneNumberValue: string.isRequired,
  onCountrySelect: func.isRequired,
  onPhoneNumberValueChange: func.isRequired,
  hasError: bool,
};

export default PhoneNumberInput;
