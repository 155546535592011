import { apiEndpoints } from '@constants';
import { client } from '@utils';

const getEvents = data => (
  client.authorized.get(apiEndpoints.event, {
    params: data,
  })
);

const getEventsNearby = data => (
  client.authorized.get(apiEndpoints.eventNearby, {
    params: data,
  })
);

const getEventById = id => (
  client.authorized.get(`${apiEndpoints.event}/${id}`)
);

const getEventServices = (eventId, upcomingId) => (
  client.authorized.get(`${apiEndpoints.event}/${eventId}/${upcomingId}/services`)
);

const getEventAvailableDates = eventId => (
  client.authorized.get(`${apiEndpoints.event}/${eventId}/available_dates`)
);

const getEventBookingInfo = (eventId, upcomingId) => (
  client.authorized.get(`${apiEndpoints.event}/${eventId}/${upcomingId}/booking_info`)
);

const getEventBookings = (eventId, upcomingId, serviceId) => (
  client.authorized.get(`${apiEndpoints.event}/${eventId}/bookings`, {
    params: {
      eventId: upcomingId,
      ...(serviceId && { serviceId }),
    },
  })
);

const getEventSlots = (eventId, upcomingId, serviceId) => (
  client.authorized.get(`${apiEndpoints.event}/${eventId}/${upcomingId}/services/availability/${serviceId}`)
);

const getEventsCategories = () => (
  client.authorized.get(apiEndpoints.eventCategory)
);

const getServicesAvailability = (eventId, upcomingId) => (
  client.authorized.get(`${apiEndpoints.event}/${eventId}/${upcomingId}/services/availability`)
);

const bookEvent = (eventId, upcomingId, data) => (
  client.authorized.post(`${apiEndpoints.event}/${eventId}/${upcomingId}/book`, data)
);

const cancelBookingEvent = (eventId, upcomingId, data) => (
  client.authorized.post(`${apiEndpoints.event}/${eventId}/${upcomingId}/cancel_booking`, data)
);

export {
  cancelBookingEvent,
  getEventBookingInfo,
  getEventBookings,
  getEvents,
  getEventSlots,
  getEventById,
  getEventAvailableDates,
  getEventServices,
  getServicesAvailability,
  bookEvent,
  getEventsCategories,
  getEventsNearby,
};

