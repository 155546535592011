import React, { useEffect } from 'react';

import { HouseRules as View } from '@views';
import { trackEvent } from '@utils/mixpanel';

const HouseRules = () => {
  useEffect(() => {
    const pageTitle = 'Mindspace: House Rules and Procedures';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'House Rules',
    });
  }, []);

  return (
    <View />
  );
};

export default HouseRules;
