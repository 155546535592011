import styled, { css } from 'styled-components';

import { Flex, Typography } from '@mixins';
import { theme } from '@styles';

export const Container = styled(Flex)`
  ${() => css`
    grid-template-columns: repeat(auto-fill, ${theme.sizes.longGrid}px);
    justify-content: start;
    gap: 20px;
  `}
`;

export const Title = styled(Typography)`
  ${() => css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `}
`;

export const Description = styled(Typography)`
  ${() => css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `}
`;
