import { ReactComponent as Visa } from '@assets/svgs/Visa.svg';
import { ReactComponent as MasterCard } from '@assets/svgs/MasterCard.svg';
import { ReactComponent as AmericanExpress } from '@assets/svgs/AmericanExpress.svg';
import { ReactComponent as Diners } from '@assets/svgs/DinersClub.svg';
import { ReactComponent as Discover } from '@assets/svgs/Discover.svg';
import { ReactComponent as Jcb } from '@assets/svgs/Jcb.svg';
import { ReactComponent as UnionPay } from '@assets/svgs/UnionPay.svg';
import { ReactComponent as ExpiredVisa } from '@assets/svgs/ExpiredVisa.svg';
import { ReactComponent as ExpiredMasterCard } from '@assets/svgs/ExpiredMasterCard.svg';
import { ReactComponent as ExpiredAmericanExpress } from '@assets/svgs/ExpiredAmericanExpress.svg';
import { ReactComponent as ExpiredDiners } from '@assets/svgs/ExpiredDinersClub.svg';
import { ReactComponent as ExpiredDiscover } from '@assets/svgs/ExpiredDiscover.svg';
import { ReactComponent as ExpiredJcb } from '@assets/svgs/ExpiredJcb.svg';
import { ReactComponent as ExpiredUnionPay } from '@assets/svgs/ExpiredUnionPay.svg';

export const CardBrandsLogos = {
  visa: Visa,
  mastercard: MasterCard,
  amex: AmericanExpress,
  diners: Diners,
  discover: Discover,
  jcb: Jcb,
  unionpay: UnionPay,
};

export const ExpiredCardBrandsLogos = {
  visa: ExpiredVisa,
  mastercard: ExpiredMasterCard,
  amex: ExpiredAmericanExpress,
  diners: ExpiredDiners,
  discover: ExpiredDiscover,
  jcb: ExpiredJcb,
  unionpay: ExpiredUnionPay,
};
