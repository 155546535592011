import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ExploreLocation as View } from '@views';
import { trackEvent } from '@utils/mixpanel';

const ExploreLocation = () => {
  const { currentLocation } = useSelector(store => (
    store.locationStore
  ));

  useEffect(() => {
    const pageTitle = 'Mindspace: Explore Location';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Explore Location',
    });
  }, []);

  return (
    <View
      exploreLocation={currentLocation.details?.exploreLocation}
      imageUrl={currentLocation.details?.exploreLocationImageUrl}
    />
  );
};

export default ExploreLocation;
