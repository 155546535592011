import { apiEndpoints } from '@constants';
import { client } from '@utils';

const getBookingHistory = data => (
  client.authorized.get(apiEndpoints.booking, {
    params: data,
  })
);

const getBookingById = id => (
  client.authorized.get(`${apiEndpoints.booking}/${id}`)
);

const bookRoom = data => (
  client.authorized.post(apiEndpoints.booking, data)
);

const inviteGuestToRoom = (id, data) => (
  client.authorized.post(`${apiEndpoints.booking}/${id}/invite`, data)
);

const cancelBooking = id => (
  client.authorized.delete(`${apiEndpoints.booking}/${id}`)
);

const editBooking = (id, data) => (
  client.authorized.put(`${apiEndpoints.booking}/${id}`, data)
);

const getCompanyAvailableCreditsById = id => (
  client.authorized.get(`${apiEndpoints.company}/${id}/available-credits`)
);

const confirmBooking = (data, id) => (
  client.authorized.post(`${apiEndpoints.booking}/${id}/confirm`, data)
);

export {
  getBookingHistory,
  bookRoom,
  inviteGuestToRoom,
  getBookingById,
  cancelBooking,
  editBooking,
  getCompanyAvailableCreditsById,
  confirmBooking,
};

