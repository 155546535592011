import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import * as api from './api';

// < ----- ACTIONS ----- > //
const getMetadataRoutine = createRoutine(
  'GET_METADATA',
);

const getMetadataNavigationRoutine = createRoutine(
  'GET_METADATA_NAVIGATION',
);

// < ----- ACTION CREATORS ----- > //
export const getMetadata = () => async dispatch => {
  try {
    dispatch(getMetadataRoutine.request());

    const response = await api.getMetadata();

    return dispatch(getMetadataRoutine.success(response.data));
  } catch (error) {
    return dispatch(getMetadataRoutine.failure(error.response));
  }
};

export const getMetadataNavigation = id => async dispatch => {
  try {
    dispatch(getMetadataNavigationRoutine.request());

    const response = await api.getMetadataNavigation(id);

    return dispatch(getMetadataNavigationRoutine.success(response.data));
  } catch (error) {
    return dispatch(getMetadataNavigationRoutine.failure(error.response));
  }
};

// < ----- STATE ----- > //
export const userStorePersistWhitelist = [];

const initialState = {
  metadata: {},
  navigationItems: [],

  getMetadataPhase: INIT,
  getMetadataError: null,

  getMetadataNavigationPhase: INIT,
  getMetadataNavigationError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // GET METADATA
    case getMetadataRoutine.REQUEST:
      return {
        ...state,
        getMetadataPhase: LOADING,
      };
    case getMetadataRoutine.SUCCESS:
      return {
        ...state,
        metadata: payload.data,
        getMetadataPhase: SUCCESS,
      };
    case getMetadataRoutine.FAILURE:
      return {
        ...state,
        getMetadataPhase: FAILURE,
        getMetadataError: payload,
      };
    case getMetadataNavigationRoutine.REQUEST:
      return {
        ...state,
        getMetadataNavigationPhase: LOADING,
      };
    case getMetadataNavigationRoutine.SUCCESS:
      return {
        ...state,
        navigationItems: payload.data.items,
        getMetadataNavigationPhase: SUCCESS,
      };
    case getMetadataNavigationRoutine.FAILURE:
      return {
        ...state,
        getMetadataNavigationPhase: FAILURE,
        getMetadataNavigationError: payload,
      };
    default:
      return state;
  }
};

export default store;
