import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import { dayjs } from '@utils';
import * as api from './api';

// < ----- ACTIONS ----- > //
const resetPhasesAction = 'RESET_EVENTS_PHASES';
const resetStoreToInitialStateAction = 'RESET_STORE';
const getEventByIdRoutine = createRoutine(
  'GET_EVENT_BY_ID',
);

const bookEventRoutine = createRoutine(
  'BOOK_EVENT',
);

const cancelBookingEventRoutine = createRoutine(
  'CANCEL_BOOKING_EVENT',
);

const getEventBookingsRoutine = createRoutine(
  'GET_EVENT_BOOKINGS',
);

const getEventsCategoriesRoutine = createRoutine(
  'GET_EVENT_CATEGORIES',
);

const getEventBookingInfoRoutine = createRoutine(
  'GET_EVENT_BOOKING_INFO',
);

const getEventAvailableDatesRoutine = createRoutine(
  'GET_EVENT_AVAILABLE_DATES',
);

const getServicesAvailabilityRoutine = createRoutine(
  'GET_SERVICES_AVAILABILITY',
);

const getEventsRoutine = createRoutine(
  'GET_EVENTS',
);

const getEventsNearbyRoutine = createRoutine(
  'GET_EVENTS_NEARBY',
);

const getEventSlotsRoutine = createRoutine(
  'GET_EVENT_SLOTS',
);

// < ----- ACTION CREATORS ----- > //
export const getEvents = data => async dispatch => {
  try {
    dispatch(getEventsRoutine.request());

    const response = await api.getEvents(data);

    return dispatch(getEventsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getEventsRoutine.failure(error.response));
  }
};

export const getEventsNearby = data => async dispatch => {
  try {
    dispatch(getEventsNearbyRoutine.request());

    const response = await api.getEventsNearby(data);

    return dispatch(getEventsNearbyRoutine.success(response.data));
  } catch (error) {
    return dispatch(getEventsNearbyRoutine.failure(error.response));
  }
};

export const getEventBookings = (id, upcomingId, serviceId) => async dispatch => {
  try {
    dispatch(getEventBookingsRoutine.request());

    const response = await api.getEventBookings(id, upcomingId, serviceId);

    return dispatch(getEventBookingsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getEventBookingsRoutine.failure(error.response));
  }
};

export const getEventBookingInfo = (id, upcomingId) => async dispatch => {
  try {
    dispatch(getEventBookingInfoRoutine.request());

    const response = await api.getEventBookingInfo(id, upcomingId);

    return dispatch(getEventBookingInfoRoutine.success(response.data));
  } catch (error) {
    return dispatch(getEventBookingInfoRoutine.failure(error.response));
  }
};

export const getEventById = id => async dispatch => {
  try {
    dispatch(getEventByIdRoutine.request());

    const response = await api.getEventById(id);

    if (!response.data.data) {
      return dispatch(getEventByIdRoutine.failure());
    }
    return dispatch(getEventByIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getEventByIdRoutine.failure(error.response));
  }
};

export const getEventAvailableDates = id => async dispatch => {
  try {
    dispatch(getEventAvailableDatesRoutine.request());

    const response = await api.getEventAvailableDates(id);

    return dispatch(getEventAvailableDatesRoutine.success(response.data));
  } catch (error) {
    return dispatch(getEventAvailableDatesRoutine.failure(error.response));
  }
};

export const getEventSlots = (eventId, upcomingId, serviceId) => async dispatch => {
  try {
    dispatch(getEventSlotsRoutine.request());

    const response = await api.getEventSlots(eventId, upcomingId, serviceId);

    return dispatch(getEventSlotsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getEventSlotsRoutine.failure(error.response));
  }
};

export const getServicesAvailability = (eventId, upcomingId) => async dispatch => {
  try {
    dispatch(getServicesAvailabilityRoutine.request());

    const response = await api.getServicesAvailability(eventId, upcomingId);

    return dispatch(getServicesAvailabilityRoutine.success(response.data));
  } catch (error) {
    return dispatch(getServicesAvailabilityRoutine.failure(error.response));
  }
};

export const getEventsCategories = () => async dispatch => {
  try {
    dispatch(getEventsCategoriesRoutine.request());

    const response = await api.getEventsCategories();

    return dispatch(getEventsCategoriesRoutine.success(response.data));
  } catch (error) {
    return dispatch(getEventsCategoriesRoutine.failure(error.response));
  }
};

export const bookEvent = (eventId, upcomingId, data) => async dispatch => {
  try {
    dispatch(bookEventRoutine.request());

    const response = await api.bookEvent(eventId, upcomingId, data);

    return dispatch(bookEventRoutine.success({
      ...response.data.data, ...(data?.serviceId && { serviceId: data.serviceId }),
    }));
  } catch (error) {
    return dispatch(bookEventRoutine.failure(error.response));
  }
};

export const cancelBookingEvent = (eventId, upcomingId, data) => async dispatch => {
  try {
    dispatch(cancelBookingEventRoutine.request());

    const response = await api.cancelBookingEvent(eventId, upcomingId, data);

    return dispatch(cancelBookingEventRoutine.success(response.data));
  } catch (error) {
    return dispatch(cancelBookingEventRoutine.failure(error.response));
  }
};

export const resetStore = () => ({
  type: resetStoreToInitialStateAction,
});

export const resetPhases = () => ({
  type: resetPhasesAction,
});

// < ----- STATE ----- > //
export const companyStorePersistWhitelist = [];

const initialState = {
  eventById: {
    bookings: [],
    bookingInfo: {},
    details: {},
    slots: [],
    dates: {
      startTime: '',
      endTime: '',
      availableDates: [],
      services: [],
      availability: [],
    },
    bookedDetails: {},
  },
  events: [],
  eventsNearby: [],
  categories: [],

  getEventByIdPhase: INIT,
  getEventByIdError: null,

  getEventsPhase: INIT,
  getEventsError: null,

  getEventsNearbyPhase: INIT,
  getEventsNearbyError: null,

  getEventAvailableDatesPhase: INIT,
  getEventAvailableDatesError: null,

  getEventSlotsPhase: INIT,
  getEventSlotsError: null,

  getServicesAvailabilityPhase: INIT,
  getServicesAvailabilityError: null,

  bookEventPhase: INIT,
  bookEventError: null,

  cancelBookingEventPhase: INIT,
  cancelBookingEventError: null,

  getEventBookingInfoPhase: INIT,
  getEventBookingInfoError: null,

  getEventBookingsPhase: INIT,
  getEventBookingsError: null,

  getEventCategoriesPhase: INIT,
  getEventCategoriesError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;
      // RESET PHASES
    case resetPhasesAction:
      return {
        ...state,
        getEventByIdPhase: INIT,
        getEventByIdError: null,

        getEventsPhase: INIT,
        getEventsError: null,

        getEventsNearbyPhase: INIT,
        getEventsNearbyError: null,

        getEventAvailableDatesPhase: INIT,
        getEventAvailableDatesError: null,

        getEventSlotsPhase: INIT,
        getEventSlotsError: null,

        getEventServicesPhase: INIT,
        getEventServicesError: null,

        getServicesAvailabilityPhase: INIT,
        getServicesAvailabilityError: null,

        bookEventPhase: INIT,
        bookEventError: null,

        getEventBookingInfoPhase: INIT,
        getEventBookingInfoError: null,

        getEventBookingsPhase: INIT,
        getEventBookingsError: null,

        cancelBookingEventPhase: INIT,
        cancelBookingEventError: null,

        getEventCategoriesPhase: INIT,
        getEventCategoriesError: null,
      };
    // GET EVENT BY ID
    case getEventByIdRoutine.REQUEST:
      return {
        ...state,
        getEventByIdPhase: LOADING,
      };
    case getEventByIdRoutine.SUCCESS:
      return {
        ...state,
        eventById: {
          ...state.eventById,
          details: payload.data,
        },
        getEventByIdPhase: SUCCESS,
      };
    case getEventByIdRoutine.FAILURE:
      return {
        ...state,
        getEventByIdPhase: FAILURE,
        getEventByIdError: payload,
      };

    // BOOK EVENT
    case bookEventRoutine.REQUEST:
      return {
        ...state,
        bookEventPhase: LOADING,
      };
    case bookEventRoutine.SUCCESS:
      return {
        ...state,
        eventById: {
          ...state.eventById,
          bookedDetails: {
            ...payload,
            endTime: payload.serviceId
              ? dayjs(`${payload.date}T${payload.time}.000Z`)
                .add(state.eventById.dates.services.find(service => service.id === payload.serviceId)
                  ?.duration ?? 0, 'minute').toISOString().substring(11, 19)
              : null,
          },
        },
        bookEventPhase: SUCCESS,
      };
    case bookEventRoutine.FAILURE:
      return {
        ...state,
        bookEventPhase: FAILURE,
        bookEventError: payload,
      };
      // CANCEL BOOKING EVENT
    case cancelBookingEventRoutine.REQUEST:
      return {
        ...state,
        cancelBookingEventPhase: LOADING,
      };
    case cancelBookingEventRoutine.SUCCESS:
      return {
        ...state,
        cancelBookingEventPhase: SUCCESS,
      };
    case cancelBookingEventRoutine.FAILURE:
      return {
        ...state,
        cancelBookingEventPhase: FAILURE,
        cancelBookingEventError: payload,
      };
      // GET EVENT BOOKING INFO
    case getEventBookingInfoRoutine.REQUEST:
      return {
        ...state,
        getEventBookingInfoPhase: LOADING,
      };
    case getEventBookingInfoRoutine.SUCCESS:
      return {
        ...state,
        eventById: {
          ...state.eventById,
          bookingInfo: payload.data,
        },
        getEventBookingInfoPhase: SUCCESS,
      };
    case getEventBookingInfoRoutine.FAILURE:
      return {
        ...state,
        getEventBookingInfoPhase: FAILURE,
        getEventBookingInfoError: payload,
      };
    // GET EVENT CATEGORIES
    case getEventsCategoriesRoutine.REQUEST:
      return {
        ...state,
        getEventsCategoriesPhase: LOADING,
      };
    case getEventsCategoriesRoutine.SUCCESS:
      return {
        ...state,
        categories: payload.data,
        getEventsCategoriesPhase: SUCCESS,
      };
    case getEventsCategoriesRoutine.FAILURE:
      return {
        ...state,
        getEventsCategoriesPhase: FAILURE,
        getEventsCategoriesError: payload,
      };
      // GET EVENT BOOKINGS
    case getEventBookingsRoutine.REQUEST:
      return {
        ...state,
        getEventBookingsPhase: LOADING,
      };
    case getEventBookingsRoutine.SUCCESS:
      return {
        ...state,
        eventById: {
          ...state.eventById,
          bookings: payload.data,
        },
        getEventBookingsPhase: SUCCESS,
      };
    case getEventBookingsRoutine.FAILURE:
      return {
        ...state,
        getEventBookingsPhase: FAILURE,
        getEventBookingsError: payload,
      };

    // GET SERVICES AVAILABILITY
    case getServicesAvailabilityRoutine.REQUEST:
      return {
        ...state,
        getServicesAvailabilityPhase: LOADING,
      };
    case getServicesAvailabilityRoutine.SUCCESS:
      return {
        ...state,
        eventById: {
          ...state.eventById,
          dates: {
            ...state.eventById.dates,
            availability: payload.data,
          },
        },
        getServicesAvailabilityPhase: SUCCESS,
      };
    case getServicesAvailabilityRoutine.FAILURE:
      return {
        ...state,
        getServicesAvailabilityPhase: FAILURE,
        getServicesAvailabilityError: payload,
      };

      // GET EVENT AVAILABLE DATES
    case getEventAvailableDatesRoutine.REQUEST:
      return {
        ...state,
        getEventAvailableDatesPhase: LOADING,
      };
    case getEventAvailableDatesRoutine.SUCCESS:
      return {
        ...state,
        eventById: {
          ...state.eventById,
          dates: {
            ...state.eventById.dates,
            ...payload.data,
          },
        },
        getEventAvailableDatesPhase: SUCCESS,
      };
    case getEventAvailableDatesRoutine.FAILURE:
      return {
        ...state,
        getEventAvailableDatesPhase: FAILURE,
        getEventAvailableDatesError: payload,
      };

    // GET EVENTS
    case getEventsRoutine.REQUEST:
      return {
        ...state,
        getEventsPhase: LOADING,
      };
    case getEventsRoutine.SUCCESS:
      return {
        ...state,
        events: payload.data,
        getEventsPhase: SUCCESS,
      };
    case getEventsRoutine.FAILURE:
      return {
        ...state,
        getEventsPhase: FAILURE,
        getEventsError: payload,
      };
    // GET EVENTS NEARBY
    case getEventsNearbyRoutine.REQUEST:
      return {
        ...state,
        getEventsNearbyPhase: LOADING,
      };
    case getEventsNearbyRoutine.SUCCESS:
      return {
        ...state,
        eventsNearby: payload.data,
        getEventsNearbyPhase: SUCCESS,
      };
    case getEventsNearbyRoutine.FAILURE:
      return {
        ...state,
        getEventsNearbyPhase: FAILURE,
        getEventsNearbyError: payload,
      };

      // GET EVENT SLOTS
    case getEventSlotsRoutine.REQUEST:
      return {
        ...state,
        getEventSlotsPhase: LOADING,
      };
    case getEventSlotsRoutine.SUCCESS:
      return {
        ...state,
        eventById: {
          ...state.eventById,
          slots: payload.data.slots,
          dates: {
            ...state.eventById.dates,
            startTime: payload.data.startTime,
            endTime: payload.data.endTime,
          },
        },
        getEventSlotsPhase: SUCCESS,
      };
    case getEventSlotsRoutine.FAILURE:
      return {
        ...state,
        getEventSlotsPhase: FAILURE,
        getEventSlotsError: payload,
      };
    default:
      return state;
  }
};

export default store;
