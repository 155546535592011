import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { useHistory } from 'react-router-dom';

import { Filters } from '@molecules';
import { Main } from '@templates';
import { Typography, Flex } from '@mixins';
import { theme } from '@styles';
import { routes } from '@constants';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { BackButton } from '../EditUserProfile/styles';

const PrintingDetails = () => {
  const history = useHistory();

  const [selectedFilter, setSelectedFilter] = useState(0);

  const { currentLocation: { details: locationDetails } } = useSelector(store => (
    store.locationStore
  ));

  const handleBackClick = () => {
    history.push(routes.home);
  };

  const handleFilterSelect = filterId => () => {
    setSelectedFilter(filterId);
  };

  const categories = [
    {
      id: 1,
      name: 'PC Printing',
      isVisible: locationDetails?.printerInstructionsPC,
    },
    {
      id: 2,
      name: 'MAC Printing',
      isVisible: locationDetails?.printerInstructionsMAC,
    },
    {
      id: 3,
      name: 'Secure Printing',
      isVisible: locationDetails?.securePrinting,
    },
    {
      id: 4,
      name: 'Scanning',
      isVisible: locationDetails?.scanningInstructions,
    },
    {
      id: 5,
      name: 'Fax',
      isVisible: locationDetails?.faxNumbers,
    },
  ];

  const filters = categories.filter(category => category.isVisible);

  const details = [
    {
      id: 1,
      title: 'Printing instructions for PC',
      content: locationDetails?.printerInstructionsPC,
    },
    {
      id: 2,
      title: 'Printing instructions for Mac',
      content: locationDetails?.printerInstructionsMAC,
    },
    {
      id: 3,
      title: 'Secure printing',
      content: locationDetails?.securePrinting,
    },
    {
      id: 4,
      title: 'Scanning instructions',
      content: locationDetails?.scanningInstructions,
    },
    {
      id: 5,
      title: 'Fax Numbers',
      content: locationDetails?.faxNumbers,
    },
  ];

  const detailsBlock = useMemo(() => details.reduce((acc, { id, title, content }) => {
    if ((selectedFilter === id || !selectedFilter) && content) {
      acc.push(
        <Flex
          key={id}
          flexDirection="column"
          mb={[15, 20]}
          border={`1px solid ${theme.color.gray[100]}`}
          p={45}
          width="100%"
        >
          <Typography lineHeight={1.2} variant="garamond-500" fontSize={[26, 30, 34]} mb={[20, 25, 30]}>
            {title}
          </Typography>
          <Flex
            width="100%"
            display="block"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
          />
        </Flex>,
      );
    }

    return acc;
  }, []), [details, selectedFilter]);

  return (
    <Main
      isWhiteLabelingOpen={false}
    >
      <Flex
        flexGrow={1}
        width="100%"
        alignItems="start"
        flexDirection="column"
      >
        <Flex
          width="fit-content"
          alignItems="center"
          mb={[20, 25, 30]}
          onClick={handleBackClick}
          pointer
        >
          <BackButton mr={['9px', 11, 13]} SVG={Dropdown} color={theme.color.gray[500]} />
          <Typography variant="proximaNova-400">
            Back
          </Typography>
        </Flex>
        <Typography variant="garamond-500" fontSize={[30, 34]} mb={[20, 25, 30]}>
          Printing & Scanning
        </Typography>
        {filters.length > 1 && (
          <Flex mb={[15, 20]}>
            <Filters
              onFilterChange={handleFilterSelect}
              selectedFilter={selectedFilter}
              filters={filters}
            />
          </Flex>
        )}
        {detailsBlock}
      </Flex>
    </Main>
  );
};

export default PrintingDetails;
