import { func, oneOf } from 'prop-types';
import { layout, border, space, position, typography, color } from 'styled-system';
import styled, { css } from 'styled-components';

const TextArea = styled.textarea`
  ${({ withBorder }) => css`
    font-family: 'Proxima Nova';
    outline: none;
    background-color: transparent;
    border-radius: 3px;
    border: 0;
    
    ${withBorder && 'border: 1px solid #D9D9D9; padding: 10px;'}

    ${color}
    ${position}
    ${typography}
    ${border}
    ${layout}
    ${space}

    resize: none;
  `}
`;

TextArea.defaultProps = {
  type: 'text',
  onChange: () => null,
};

TextArea.propTypes = {
  type: oneOf(['password', 'text']),
  onChange: func,
};

export default TextArea;
