import React from 'react';
import styled, { css } from 'styled-components';
import { space, flexbox, layout, position } from 'styled-system';
import { bool, func } from 'prop-types';

import { Icon } from '@atoms';
import { Flex } from '@mixins';
import { theme } from '@styles';

import { ReactComponent as CheckboxIcon } from '@assets/svgs/Checkbox.svg';

const Checkbox = ({ isEnabled, onClick }) => (
  <Flex
    position="relative"
    pointer
    onClick={onClick}
    background={isEnabled ? theme.color.darkGreen : theme.color.white}
    alignItems="center"
    justifyContent="center"
    width={[14, 16, 18]}
    minHeight={[14, 16, 18]}
    border={`1px solid ${theme.color.darkGreen}`}
  >
    <Icon SVG={CheckboxIcon} />
  </Flex>
);

Checkbox.propTypes = {
  isEnabled: bool.isRequired,
  onClick: func.isRequired,
};

export default styled(Checkbox)`
  ${() => css`
    ${space};
    ${flexbox};
    ${layout};
    ${position};
  `}
`;
