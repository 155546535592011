import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FlexWithGradientBackground, Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { getCloudinaryImageLinkBySize } from '@helpers/common';
import { theme } from '@styles';
import { routes } from '@constants';

import Events from '@assets/images/Events.png';
import ExploreLocation from '@assets/images/ExploreLocation.jpg';
import FitnessClasses from '@assets/images/FitnessClasses.jpg';
import MemberBenefits from '@assets/images/MemberBenefits.png';
import { ReactComponent as Arrow } from '@assets/svgs/Arrow.svg';

const IMAGE_WIDTH = 325;

const HomeGrid = () => {
  const history = useHistory();

  const { currentLocation } = useSelector(store => (
    store.locationStore
  ));

  const { categories } = useSelector(store => (
    store.eventStore
  ));

  const category = categories.find(cat => cat.displayOnHomePage);

  const onEventsClick = () => {
    history.push(routes.events);
  };

  const onMemberBenefitsClick = () => {
    history.push(routes.benefits);
  };

  const onExploreLocationClick = () => {
    history.push(routes.exploreLocation);
  };

  const onEventCategoryClick = () => {
    history.push({
      pathname: routes.events,
      state: {
        selectedCategory: category?.id,
      },
    });
  };

  const getImageUrl = () => {
    if (!currentLocation.details?.exploreLocation) return FitnessClasses;
    if (currentLocation.details?.exploreLocationImageUrl) {
      return getCloudinaryImageLinkBySize(
        currentLocation.details.exploreLocationImageUrl, IMAGE_WIDTH,
      );
    }
    return ExploreLocation;
  };

  return (
    <Flex width="50%" flexDirection="column">
      <FlexWithGradientBackground
        onClick={onEventsClick}
        flexDirection="column"
        p={[12, 16, 28]}
        imageUrl={Events}
        minHeight="50%"
        justifyContent="end"
        pointer
      >
        <Typography
          color={theme.color.white}
          variant="garamond-500"
          fontSize={['20px', '30px', '40px']}
          mb="18px"
        >
          Events
        </Typography>
        <Icon
          SVG={Arrow}
          width={53}
        />
      </FlexWithGradientBackground>
      <Flex pt={['4px', '7px']} justifyContent="space-between" minHeight="50%">
        <FlexWithGradientBackground
          pointer
          p={[12, 16, 28]}
          flexDirection="column"
          mr={['4px', '7px']}
          imageUrl={MemberBenefits}
          width="50%"
          justifyContent="end"
          onClick={onMemberBenefitsClick}
        >
          <Typography
            color={theme.color.white}
            variant="garamond-500"
            fontSize={['20px', '30px', '40px']}
            mb="18px"
          >
            Member Benefits
          </Typography>
          <Icon
            SVG={Arrow}
            width={53}
          />
        </FlexWithGradientBackground>
        <FlexWithGradientBackground
          pointer
          p={[12, 16, 30]}
          flexDirection="column"
          imageUrl={getImageUrl()}
          width="50%"
          justifyContent="end"
          onClick={currentLocation.details?.exploreLocation ? onExploreLocationClick : onEventCategoryClick}
        >
          <Typography
            color={theme.color.white}
            variant="garamond-500"
            fontSize={['20px', '30px', '40px']}
            mb="18px"
          >
            {currentLocation.details?.exploreLocation ? 'Explore Location' : 'Wellness Classes'}
          </Typography>
          <Icon
            SVG={Arrow}
            width={53}
          />
        </FlexWithGradientBackground>
      </Flex>
    </Flex>
  );
};

export default HomeGrid;
