/* eslint-disable no-underscore-dangle */
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import {
  CardCvcElement, CardExpiryElement, CardNumberElement, AddressElement, useStripe, useElements,
} from '@stripe/react-stripe-js';

import { Checkbox, Loader } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { LOADING } from '@constants/requestPhase';
import { getCountryCodeFromName } from '@helpers/common';
import { theme } from '@styles';
import { addPaymentMethod } from '@store/payment/duck';

import { Container } from './styles';

const StripeForm = ({ onError }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const { stripeKeys, addPaymentMethodPhase, paymentMethods } = useSelector(store => store.paymentStore);
  const { currentLocation } = useSelector(store => store.locationStore);
  const { user } = useSelector(store => store.userStore);

  const [isCardDefault, setIsCardDefault] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const areElementsLoading = (!elements?.getElement(CardCvcElement)
  || !elements?.getElement(CardNumberElement) || !elements?.getElement(CardExpiryElement)
  || !elements?.getElement(AddressElement));

  const defaultCountry = useMemo(() => (
    getCountryCodeFromName(currentLocation.country)
  ), [currentLocation.country]);

  const [isCardComplete, setIsCardComplete] = useState({
    cardCvc: false,
    cardExpiry: false,
    cardNumber: false,
    address: false,
  });

  const handleCardChange = e => {
    setIsCardComplete({ ...isCardComplete, [e.elementType]: e.complete });
  };

  const handleCheckboxClick = () => {
    setIsCardDefault(prevValue => !prevValue);
  };

  const handleAddCard = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    elements.getElement(AddressElement).getValue()
      .then(async validation => {
        if (validation.complete) {
          setIsLoading(true);

          await stripe.confirmCardSetup(stripeKeys.intentSecret, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: validation.value,
            },
          })
            .then(result => {
              setIsLoading(false);
              if (result.error) {
                onError({
                  message: result.error.message,
                  title: 'Unable to add credit card',
                  status: 400,
                });
              } else {
                dispatch(addPaymentMethod({
                  paymentMethodId: result.setupIntent.payment_method,
                  isDefault: isCardDefault,
                }));
              }
            });
        }
      });
  };

  return (
    <Flex pb="5px" width="100%" flexDirection="column" position="relative">
      <Container px={20} pb={30} flexDirection="column" maxHeight={470} overflowY="scroll">
        <Typography mb={15} variant="garamond-500" fontSize={20}>Card information</Typography>
        <CardNumberElement
          onChange={handleCardChange}
          className="StripeElement Number"
          options={{
            placeholder: 'Card Number',
          }}
        />
        <Flex>
          <CardExpiryElement
            onChange={handleCardChange}
            className="StripeElement Expiry"
          />
          <CardCvcElement
            onChange={handleCardChange}
            className="StripeElement Cvc"
          />
        </Flex>
        <Typography mt={30} mb={20} variant="garamond-500" fontSize={20}>
          Billing address
        </Typography>
        <AddressElement
          onChange={handleCardChange}
          options={{
            mode: 'billing',
            defaultValues: {
              name: `${user.firstName} ${user.lastName}`,
              address: {
                country: defaultCountry,
              },
            },
            autocomplete: {
              mode: 'google_maps_api',
              apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            },
            fields: {
              phone: 'never',
            },
          }}
        />
        <Flex alignItems="center" width="100%" mb={35} mt={23}>
          <Checkbox
            isEnabled={isCardDefault}
            {...(paymentMethods.length && { onClick: handleCheckboxClick })}
          />
          <Typography ml={10} variant="proximaNova-400">Set as default card</Typography>
        </Flex>
        <Button
          onClick={handleAddCard}
          alignSelf="center"
          py={12}
          px={[70, 130]}
          width="fit-content"
          disabled={!isCardComplete.cardCvc || !isCardComplete.cardExpiry || !isCardComplete.cardNumber
            || !isCardComplete.address}
          variant="primary"
        >
          Save Card
        </Button>
      </Container>
      {(areElementsLoading || isLoading || addPaymentMethodPhase === LOADING) && (
        <Loader position="absolute" background={theme.color.white} width={50} height={50} />
      )}
    </Flex>
  );
};

StripeForm.propTypes = {
  onError: func.isRequired,
};

export default StripeForm;
