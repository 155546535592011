import React from 'react';
import { bool, string } from 'prop-types';

import { Typography } from '@mixins';
import { theme } from '@styles';

import { Container } from './styles';

const WhiteLabeling = ({
  isOpen,
  image,
  location,
}) => (
  <Container
    isOpen={isOpen}
    top={0}
    left={0}
    right={0}
    bottom={0}
    zIndex={10}
    justifyContent="center"
    alignItems="center"
    position="fixed"
    background={`linear-gradient(0.46deg, rgba(0, 0, 0, 0) 7.26%, rgba(0, 0, 0, 0.8) 99.61%), 
    url(${image}) no-repeat center`}
    backgroundSize="cover"
  >
    <Typography variant="garamond-500" fontSize={56} color={theme.color.white}>{location}</Typography>
  </Container>
);

WhiteLabeling.defaultProps = {
  isOpen: false,
  image: '',
  location: '',
};

WhiteLabeling.propTypes = {
  isOpen: bool,
  image: string,
  location: string,
};

export default WhiteLabeling;
