import React from 'react';
import { ReactComponent as MissingValue } from '@assets/svgs/MissingValue.svg';
import { ReactComponent as Info } from '@assets/svgs/Info.svg';
import { ReactComponent as Edit } from '@assets/svgs/Edit.svg';
import { ReactComponent as Check } from '@assets/svgs/Check.svg';
import { ReactComponent as Prohibit } from '@assets/svgs/Prohibit.svg';
import { Typography, Flex } from '@mixins';
import { BootstrapTooltip, Icon, Checkbox, Switcher, Avatar, DataTableEllipsisCell } from '@atoms';
import { AsYouType } from 'libphonenumber-js/max';
import { updateCompanyMember } from '@store/company/duck';
import { theme } from '@styles';
import { store } from '../../../App';

export const columns = accessId => ([
  {
    id: 1,
    width: 130,
    field: 'firstName',
    headerName: 'First Name',
    sortable: true,
    renderCell: ({ value, row: { lastName, imageUrl, status } }) => (
      <BootstrapTooltip title={value} placement="top">
        <Flex maxWidth={130} alignItems="center">
          <Avatar
            width={[24]}
            height={[24]}
            imageUrl={imageUrl}
            firstName={value}
            lastName={lastName}
            fontSize={['10px']}
          />
          <Flex ml="10px">
            <DataTableEllipsisCell color={status === 'active' ? theme.color.black : theme.color.gray[200]}>
              {value}
            </DataTableEllipsisCell>
          </Flex>
        </Flex>
      </BootstrapTooltip>
    ),
  },
  {
    id: 2,
    width: 130,
    field: 'lastName',
    headerName: 'Last Name',
    sortable: true,
    renderCell: ({ value, row: { status } }) => (
      <BootstrapTooltip title={value} placement="top">
        <DataTableEllipsisCell color={status === 'active' ? theme.color.black : theme.color.gray[200]}>
          {value}
        </DataTableEllipsisCell>
      </BootstrapTooltip>
    ),
  },
  {
    id: 3,
    width: 180,
    field: 'email',
    headerName: 'Email',
    sortable: true,
    renderCell: ({ value, row: { status } }) => (
      <BootstrapTooltip title={value} placement="top">
        <DataTableEllipsisCell color={status === 'active' ? theme.color.black : theme.color.gray[200]}>
          {value}
        </DataTableEllipsisCell>
      </BootstrapTooltip>
    ),
  },
  {
    id: 4,
    width: 130,
    field: 'phone',
    headerName: 'Phone Number',
    sortable: true,
    renderCell: ({ value, row: { status } }) =>
      <BootstrapTooltip title={value} placement="top">
        <DataTableEllipsisCell color={status === 'active' ? theme.color.black : theme.color.gray[200]}>
          {value ? new AsYouType().input(value) : '-'}
        </DataTableEllipsisCell>
      </BootstrapTooltip>
     || <MissingValue />,
  },
  {
    id: 5,
    width: 110,
    field: 'isCompanyAdmin',
    renderHeader: () => (
      <BootstrapTooltip title="Some explanation will be here" placement="top">
        <Flex alignItems="center">
          <Typography
            overflow="hidden"
            white-space="nowrap"
            mr="5px"
            fontSize="14px"
            variant="proximaNova-600"
          >
            Admin
          </Typography>
          <Icon SVG={Info} />
        </Flex>
      </BootstrapTooltip>
    ),
    sortable: true,
    renderCell: ({ value }) => <Checkbox onClick={() => {}} isEnabled={value} />,
  },
  {
    id: 6,
    width: 160,
    field: 'isAbleToBookMeetingRoom',
    renderHeader: () => (
      <BootstrapTooltip title="Some explanation will be here" placement="top">
        <Flex alignItems="center">
          <Typography
            overflow="hidden"
            whiteSpace="nowrap"
            mr="5px"
            fontSize="14px"
            variant="proximaNova-600"
          >
            Room Booking
          </Typography>
          <Icon SVG={Info} />
        </Flex>
      </BootstrapTooltip>
    ),
    sortable: true,
    renderCell: ({ value, row: { status } }) => (
      <Flex
        px="10px"
        alignItems="center"
        py="5px"
        border="1px solid #D9D9D9"
        borderRadius="16px"
      >
        {value ? <Icon SVG={Check} /> : <Icon SVG={Prohibit} />}
        <Typography color={status === 'active' ? theme.color.black : theme.color.gray[200]} ml="5px" fontSize="12px">
          {value ? 'Allowed' : 'Not allowed'}
        </Typography>
      </Flex>
    ),
  },
  {
    id: 7,
    width: 200,
    field: 'isAbleToInvoice',
    renderHeader: () => (
      <BootstrapTooltip title="Some explanation will be here" placement="top">
        <Flex alignItems="center">
          <Typography
            overflow="hidden"
            whiteSpace="nowrap"
            mr="5px"
            fontSize="14px"
            variant="proximaNova-600"
          >
            Credit Purchase
          </Typography>
          <Icon SVG={Info} />
        </Flex>
      </BootstrapTooltip>
    ),
    sortable: false,
    valueGetter: ({ row: { isAbleToPayByCard, isAbleToInvoice } }) => {
      if (isAbleToInvoice && isAbleToPayByCard) {
        return 'Credit card or Invoice';
      }
      if (isAbleToInvoice) {
        return 'Invoice';
      }
      if (isAbleToPayByCard) {
        return 'Credit card';
      }
      return 'Not allowed';
    },
    renderCell: ({ value, row: { isAbleToPayByCard, isAbleToInvoice, status } }) => (
      isAbleToInvoice || isAbleToPayByCard ? (
        <Typography
          color={status === 'active' ? theme.color.black : theme.color.gray[200]}
          px="10px"
          py="5px"
          borderRadius="16px"
          backgroundColor="#EFECE6"
          fontSize="12px"
        >
          {value}
        </Typography>
      )
        : (
          <Flex
            px="10px"
            alignItems="center"
            color={status === 'active' ? theme.color.black : theme.color.gray[200]}
            py="5px"
            border="1px solid #D9D9D9"
            borderRadius="16px"
          >
            <Icon SVG={Prohibit} />
            <Typography ml="5px" fontSize="12px">{value}</Typography>
          </Flex>
        )
    ),
  },
  {
    id: 8,
    width: 100,
    field: 'status',
    headerName: 'Status',
    sortable: true,
    renderCell: ({ value, row: { id, phone, ...member } }) => (
      <Switcher
        isEnabled={value === 'active'}
        onSwitch={event => {
          event.stopPropagation();
          store.dispatch(updateCompanyMember(id,
            { ...member, status: value === 'active' ? 'inactive' : 'active' }, accessId));
        }}
      />
    ),
  },
  {
    id: 9,
    width: 50,
    field: 'edit',
    headerName: '',
    sortable: false,
    renderCell: () => <Icon width="20px" height="20px" SVG={Edit} />,
  },
]);
