import React from 'react';
import { bool, func, string, elementType } from 'prop-types';

import { Modal, Icon } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';

const ConfirmationModal = ({
  icon,
  wrapText,
  isOpen,
  title,
  text,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) => (
  <Modal topPosition={170} isOpen={isOpen} withCloseButton={false}>
    <Flex alignItems="center" maxWidth={436} flexDirection="column" px={50} py="8px">
      {icon && <Icon SVG={icon} heigth={45} height mb={25} />}
      <Typography textAlign="center" mb={14} variant="proximaNova-600" fontSize={20}>
        {title}
      </Typography>
      <Typography
        overflow="hidden"
        textOverflow="ellipsis"
        maxWidth={436}
        whiteSpace={wrapText ? 'pre' : 'no-wrap'}
        textAlign="center"
        mb={32}
        variant="proximaNova-400"
        fontSize={14}
        color={theme.color.gray[300]}
      >
        {text}
      </Typography>
      <Button onClick={onPrimaryButtonClick} py={12} px={74} variant="primary">
        {primaryButtonText}
      </Button>
      {secondaryButtonText && (
        <Button onClick={onSecondaryButtonClick} mt={30} color={theme.color.darkGreen}>
          {secondaryButtonText}
        </Button>
      )}
    </Flex>
  </Modal>
);

ConfirmationModal.defaultProps = {
  wrapText: false,
  secondaryButtonText: '',
  onSecondaryButtonClick: () => {},
  icon: null,
};

ConfirmationModal.propTypes = {
  wrapText: bool,
  icon: elementType,
  isOpen: bool.isRequired,
  onPrimaryButtonClick: func.isRequired,
  onSecondaryButtonClick: func,
  text: string.isRequired,
  title: string.isRequired,
  primaryButtonText: string.isRequired,
  secondaryButtonText: string,
};

export default ConfirmationModal;
