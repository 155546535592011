import styled, { css } from 'styled-components';

import { Flex } from '@mixins';
import { theme } from '@styles';

export const HoveredBlock = styled(Flex)`
  ${({ isSelected, isAvailable = true }) => css`
    ${!isSelected
      && `&:hover {
        background-color: #EFECE6;
      }`
}
    ${isSelected && 'background-color: #EBEFEF; border-radius: 2px;'}

    p {
      white-space: pre;
      color: ${theme.color.gray[500]};

      ${!isAvailable
        && `text-decoration-line: line-through;
        color: ${theme.color.gray[200]};`
}

      ${isSelected
        && `color: ${theme.color.darkGreen};
        font-weight: 600;`
}
  `}
`;

export const ScrollableContainer = styled(Flex)`
${() => css`
    flex-direction: column;
    overflow-y: auto;
    position: relative;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 3px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: #D9D9D9;;
    }

    ::-webkit-scrollbar-track {
      width: 3px;
      border-radius: 50px;
      background: #F0F0F0;
      opacity: 0.2;
    }
  `}
`;
