import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Feedback as View } from '@views';
import { resetPhases, sendFeedback } from '@store/user/duck';
import { LOADING, SUCCESS } from '@constants/requestPhase';
import { trackEvent } from '@utils/mixpanel';

const Feedback = () => {
  const { sendFeedbackPhase } = useSelector(store => store.userStore);

  const dispatch = useDispatch();

  const [isSuccess, setIsSuccess] = useState(false);

  const handleSendFeedback = content => {
    dispatch(sendFeedback({ content }));
  };

  useEffect(() => {
    const pageTitle = 'Mindspace: Share Your Experience';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Feedback',
    });
  }, []);

  useEffect(() => {
    if (sendFeedbackPhase === SUCCESS) {
      setIsSuccess(true);
      dispatch(resetPhases());
    }
  }, [sendFeedbackPhase]);

  return (
    <View
      onSubmit={handleSendFeedback}
      isLoading={sendFeedbackPhase === LOADING}
      isSuccess={isSuccess}
    />
  );
};

export default Feedback;
