import React, { useState, useEffect, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import useMeasure from 'react-use-measure';
import { string, bool, exact, arrayOf, number, func } from 'prop-types';

import { Flex, AnimatedFlex, Typography } from '@mixins';
import { usePrevious } from '@hooks';
import { theme } from '@styles';
import { trackEvent } from '@utils/mixpanel';

import { ReactComponent as Arrow } from '@assets/svgs/Dropdown.svg';

import { NextButton, PrevButton, ButtonContainer } from './styles';

const variants = {
  enter: ({ direction, width }) => ({ x: direction * width }),
  center: { x: 0 },
  exit: ({ direction, width }) => ({ x: direction * -width }),
};

const BenefitsCarousel = ({ benefits, onBenefitClick }) => {
  const [count, setCount] = useState(0);

  const [ref, { width }] = useMeasure();
  const prev = usePrevious(count);

  const direction = count > prev ? 1 : -1;

  const handlePreviousClick = useCallback(event => {
    event.stopPropagation();

    trackEvent('"Previous featured benefit" clicked', {
      scope: 'Benefits',
    });

    setCount(prevValue => (prevValue ? prevValue - 1 : prevValue));
  }, []);

  const handleNextClick = useCallback(event => {
    event.stopPropagation();

    trackEvent('"Next featured benefit" clicked', {
      scope: 'Benefits',
    });

    setCount(prevValue => (prevValue < benefits.length - 1 ? prevValue + 1 : prevValue));
  }, []);

  useEffect(() => {
    setCount(0);
  }, [benefits]);

  return (
    <Flex
      mb={40}
      ref={ref}
      position="relative"
      height={[300, 300, 330, 330]}
      maxWidth={[985]}
      pointer
      onClick={onBenefitClick(benefits[count]?.id)}
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      boxShadow="0px 8px 30px rgba(80, 85, 136, 0.06)"
      borderRadius="2px"
    >
      {benefits.length > 1 && (
        <Flex zIndex={1} right={[20, 28]} top={[20, 28]} position="absolute">
          <ButtonContainer
            backgroundColor={theme.color.white}
            pointer
            alignItems="center"
            justifyContent="center"
            width={[25, 30]}
            height={[25, 30]}
            borderRadius="50%"
            mr={15}
            onClick={handlePreviousClick}
          >
            <PrevButton color={count ? theme.color.black : theme.color.gray[200]} SVG={Arrow} mr="3px" />
          </ButtonContainer>
          <ButtonContainer
            backgroundColor={theme.color.white}
            pointer
            alignItems="center"
            justifyContent="center"
            width={[25, 30]}
            height={[25, 30]}
            borderRadius="50%"
            onClick={handleNextClick}
          >
            <NextButton
              color={count !== benefits.length - 1 ? theme.color.black : theme.color.gray[200]}
              SVG={Arrow}
              ml="3px"
            />
          </ButtonContainer>
        </Flex>
      )}
      <AnimatePresence custom={{ direction, width }}>
        <AnimatedFlex
          transition={{
            x: {
              duration: 0.4,
            },
          }}
          key={count}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          custom={{ direction, width }}
          position="absolute"
          height="100%"
          width="100%"
        >
          <Flex width="100%" height="100%">
            <Flex
              width="50%"
              height="100%"
              background={`url(${benefits[count]?.image}) no-repeat center`}
              backgroundSize="cover"
            />
            <Flex
              alignItems="center"
              pl={[30, 35, 40, 50]}
              width="50%"
              border="1px solid #EFECE6"
            >
              <Flex maxWidth={350} flexDirection="column">
                <Typography
                  width="fit-content"
                  backgroundColor="#EFECE6"
                  p="7px"
                  mb={15}
                  variant="proximaNova-600"
                  fontSize={[13]}
                  borderRadius="2px"
                >
                  {benefits[count]?.isNew ? 'New' : 'Featured'}
                </Typography>
                <Typography lineHeight={1.2} mb={15} variant="garamond-500" fontSize={[22, 26, 30, 34]}>
                  {benefits[count]?.title}
                </Typography>
                <Typography
                  mb={20}
                  variant="proximaNova-600"
                  fontSize={[12, 14, 14, 16]}
                >
                  {benefits[count]?.tagline}
                </Typography>
              </Flex>
            </Flex>
          </Flex>
        </AnimatedFlex>
      </AnimatePresence>
    </Flex>
  );
};

BenefitsCarousel.defaultProps = {
  benefits: [],
};

BenefitsCarousel.propTypes = {
  onBenefitClick: func.isRequired,
  benefits: arrayOf(
    exact({
      id: number,
      title: string,
      image: string,
      isNew: bool,
      isFeatured: bool,
      tagline: string,
    }),
  ),
};

export default BenefitsCarousel;
