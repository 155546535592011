import React, { useMemo } from 'react';
import { string } from 'prop-types';
import DOMPurify from 'dompurify';

import { Main } from '@templates';
import { getCloudinaryImageLinkBySize } from '@helpers/common';
import { Typography, Flex } from '@mixins';
import { useViewport } from '@hooks';

import ExploreLocationImage from '@assets/images/ExploreLocation.jpg';

const ExploreLocation = ({ exploreLocation, imageUrl }) => {
  const { width } = useViewport();

  const IMAGE_WIDTH = useMemo(() => (width > 1024 ? 634 : 360), [width]);

  return (
    <Main
      isWhiteLabelingOpen={false}
    >
      <Flex
        flexGrow={1}
        width="100%"
        alignItems="start"
        flexDirection="column"
      >
        <Typography variant="garamond-500" fontSize={[30, 34]} mb={[20, 30]}>
          Explore Location
        </Typography>
        <Flex justifyContent="space-between" width="100%">
          <Flex
            display="block"
            width="48%"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(exploreLocation) }}
          />
          <Flex
            width="48%"
            height={[200, 250, 350, 500]}
            background={`url(${getCloudinaryImageLinkBySize(
              imageUrl, IMAGE_WIDTH,
            ) || ExploreLocationImage
            }) center top`}
            backgroundSize="cover"
          />
        </Flex>
      </Flex>
    </Main>
  );
};

ExploreLocation.defaultProps = {
  exploreLocation: '',
  imageUrl: '',
};

ExploreLocation.propTypes = {
  exploreLocation: string,
  imageUrl: string,
};

export default ExploreLocation;
