import React from 'react';
import { number, func, string, arrayOf, exact, node } from 'prop-types';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';

const LoginCarousel = ({ activeTab, onTabClick, tabs }) => {
  const carousel = (
    <Flex mb="40px">
      {tabs.map(({ name }, index) => (
        <Flex
          key={name}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={['90px', '130px']}
          pointer
          onClick={onTabClick(index)}
        >
          <Typography
            fontSize={['16px', '20px']}
            color={index === activeTab ? theme.color.gray[500] : theme.color.gray[200]}
            mb="10px"
            variant={index === activeTab ? 'proximaNova-600' : 'proximaNova-400'}
          >
            {name}
          </Typography>
          <Flex
            width="100%"
            backgroundColor={index === activeTab ? theme.color.gray[500] : theme.color.gray[200]}
            height={index === activeTab ? '3px' : '1px'}
          />
        </Flex>
      ))}
    </Flex>
  );

  return (
    <Flex flexDirection="column" alignItems="center" width="100%">
      {carousel}
      {tabs[activeTab].component}
    </Flex>
  );
};

LoginCarousel.propTypes = {
  activeTab: number.isRequired,
  onTabClick: func.isRequired,
  tabs: arrayOf(
    exact({
      name: string,
      component: node,
    }),
  ).isRequired,
};

export default LoginCarousel;
