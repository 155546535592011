import styled, { css } from 'styled-components';

import { Flex } from '@mixins';
import { theme } from '@styles';

export const Container = styled(Flex)`
  ${() => css`
    &:hover {
      p:first-child {
        color: ${theme.color.darkGreen};
      }
    }
  `}
`;
