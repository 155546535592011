import React from 'react';
import { string, func, number } from 'prop-types';
import { useSelector } from 'react-redux';

import { Icon, Loader } from '@atoms';
import { TextInput } from '@atoms/Input';
import { BenefitsGrid, Filters } from '@molecules';
import { LOADING } from '@constants/requestPhase';
import { theme } from '@styles';
import { Flex } from '@mixins';
import { ReactComponent as Search } from '@assets/svgs/Search.svg';

const BenefitsFilter = ({ selectedCategory, onSelectedCategoryChange, searchText, onSearchTextChange }) => {
  const { categories, getBenefitsPhase } = useSelector(store => (
    store.benefitStore
  ));

  return (
    <>
      <Flex
        width="100%"
        flexDirection={['column', 'row', 'row', 'row']}
        justifyContent="space-between"
        alignItems={['start', 'center', 'center', 'center']}
        mb={40}
      >
        <Flex mb={[20, 0, 0, 0]}>
          <Filters
            filters={categories}
            onFilterChange={onSelectedCategoryChange}
            selectedFilter={selectedCategory}
          />
        </Flex>
        <Flex width={['100%', '30%', '30%', '30%']}>
          <TextInput value={searchText} onChange={onSearchTextChange} placeholder="Search for benefits">
            <Icon mr={12} SVG={Search} />
          </TextInput>
        </Flex>
      </Flex>
      {getBenefitsPhase === LOADING
        ? (
          <Flex flexGrow={1} alignItems="center" justifyContent="center">
            <Loader position="relative" background={theme.color.white} width={64} height={64} />
          </Flex>
        )
        : <BenefitsGrid selectedCategory={selectedCategory} />}
    </>
  );
};

BenefitsFilter.defaultProps = {
  selectedCategory: 0,
};

BenefitsFilter.propTypes = {
  onSearchTextChange: func.isRequired,
  selectedCategory: number,
  searchText: string.isRequired,
  onSelectedCategoryChange: func.isRequired,
};

export default BenefitsFilter;
