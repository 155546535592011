import React, { useState } from 'react';
import { string, bool, func } from 'prop-types';

import { Modal, Icon, Hint } from '@atoms';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { ReactComponent as Copy } from '@assets/svgs/Copy.svg';

const description = `Note: Members network is the preferred network for Mindspace members as 
it allows connectivity to the printers and Apple TVs installed in the meeting rooms.`;

const WifiModal = ({
  isOpen,
  onModalClose,
  membersNetwork,
  membersPassword,
  guestsNetwork,
  guestsPassword,
}) => {
  const [isGuestsPasswordCopied, setIsGuestsPasswordCopied] = useState(false);
  const [isMembersPasswordCopied, setIsMembersPasswordCopied] = useState(false);

  const onCopy = password => {
    navigator.clipboard.writeText(password);
  };

  const handleMembersPasswordCopy = () => {
    setIsMembersPasswordCopied(true);
    onCopy(membersPassword);
  };

  const handleMembersHintClose = () => {
    setIsMembersPasswordCopied(false);
  };

  const handleGuestsPasswordCopy = () => {
    setIsGuestsPasswordCopied(true);
    onCopy(guestsPassword);
  };

  const handleGuestsHintClose = () => {
    setIsGuestsPasswordCopied(false);
  };

  const wifiSettings = [
    {
      id: 1,
      network: membersNetwork,
      password: membersPassword,
      title: 'Members',
      onClick: handleMembersPasswordCopy,
      onHintClose: handleMembersHintClose,
      isHintOpen: isMembersPasswordCopied,
    },
    {
      id: 2,
      network: guestsNetwork,
      password: guestsPassword,
      title: 'Guests',
      onClick: handleGuestsPasswordCopy,
      onHintClose: handleGuestsHintClose,
      isHintOpen: isGuestsPasswordCopied,
    },
  ];

  const wifiSettingsBlock = wifiSettings.map(({
    id, network, title, password, onClick, onHintClose, isHintOpen,
  }, index) => (
    <Flex
      py={[15, 20, 25]}
      pl={[
        index ? 20 : 0,
        index ? 40 : 0,
      ]}
      pr={[
        index ? 0 : 20,
        index ? 0 : 40,
      ]}
      borderLeft={index ? `1px solid ${theme.color.gray[100]}` : 0}
      key={id}
      flexDirection="column"
      alignItems="start"
    >
      <Typography wordBreak="break-all" mb={[10, 20, 30]} variant="proximaNova-600" fontSize={[14, 16, 20]}>
        {title}
      </Typography>
      <Flex mb={[10, 20]}>
        <Typography
          mr={[10, 15]}
          variant="proximaNova-400"
          fontSize={[10, 12, 16]}
          color={theme.color.gray[200]}
          width={[40, 60, 75]}
        >
          Network
        </Typography>
        <Typography wordBreak="break-all" variant="proximaNova-600" fontSize={[10, 12, 16]}>
          {network}
        </Typography>
      </Flex>
      <Flex alignItems="center">
        <Typography
          mr={[10, 15]}
          variant="proximaNova-400"
          fontSize={[10, 12, 16]}
          color={theme.color.gray[200]}
          width={[40, 60, 75]}
        >
          Password
        </Typography>
        <Typography wordBreak="break-all" mr={[10, 15]} variant="proximaNova-600" fontSize={[10, 12, 16]}>
          {password}
        </Typography>
        <Flex position="relative">
          <Hint title="Password copied!" isOpen={isHintOpen} onClose={onHintClose} />
          <Icon pointer onClick={onClick} SVG={Copy} width={[10, 16]} height={[10, 16]} />
        </Flex>
      </Flex>
    </Flex>
  ));

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <Flex p={[12, 14, 20]} flexDirection="column" alignItems="center">
        <Typography mb={[20, 30, 40]} variant="garamond-500" fontSize={[20, 27, 34]}>
          Wifi Details
        </Typography>
        <Flex
          mb={[20, 30, 40]}
        >
          {wifiSettingsBlock}
        </Flex>
        <Typography
          variant="proximaNova-400"
          fontSize={[10, 12, 14]}
          color={theme.color.gray[200]}
        >
          {description}
        </Typography>
      </Flex>
    </Modal>
  );
};

WifiModal.propTypes = {
  isOpen: bool.isRequired,
  membersNetwork: string.isRequired,
  membersPassword: string.isRequired,
  guestsNetwork: string.isRequired,
  guestsPassword: string.isRequired,
  onModalClose: func.isRequired,
};

export default WifiModal;
