import React, { useState, useEffect, useMemo } from 'react';
import { bool, arrayOf, exact, string, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '@atoms';
import { TextModal, SuccessModal } from '@molecules';
import { Main } from '@templates';
import { ICON_BY_KEY } from '@constants/common';
import { SUCCESS } from '@constants/requestPhase';
import { resetPhases, sendSupportTicket } from '@store/user/duck';
import { Typography, Flex } from '@mixins';
import { theme } from '@styles';

import SupportImage from '@assets/images/Support.jpg';

import { Container } from './styles';

const Support = ({
  isLoading,
  topics,
}) => {
  const { sendSupportTicketPhase } = useSelector(store => (
    store.userStore
  ));

  const [selectedTopicId, setSelectedTopicId] = useState(-1);
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  const selectedTopic = useMemo(() => (
    topics.find(topic => topic.id === selectedTopicId)
  ), [selectedTopicId, topics]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (sendSupportTicketPhase === SUCCESS) {
      handleSupportTicketSentModalChangeState();
      dispatch(resetPhases());
    }
  }, [sendSupportTicketPhase]);

  const handleSupportClick = id => () => {
    setSelectedTopicId(id);
  };

  const handleModalClose = () => {
    setSelectedTopicId(-1);
  };

  const handleSupportTicketSentModalChangeState = () => {
    setIsFeedbackSent(prevState => !prevState);
  };

  const handleSendSupportTicket = feedback => {
    dispatch(sendSupportTicket({
      topicId: selectedTopicId,
      content: feedback,
      origin: 'web',
    }));
    setSelectedTopicId(-1);
  };

  const topicsBlock = topics.map(({ id, title, explanation }, index) => (
    <Container
      py={[10, 14, 14, 20]}
      px={[16, 20, 20, 26]}
      pointer
      onClick={handleSupportClick(id)}
      mb={index < topics.length - 2 ? 10 : [10, 10, 10, 0]}
      border="1px solid #EFECE6"
      width="49%"
      key={title}
      alignItems="center"
    >
      <Icon mr={['8px', 12, 12, 18]} SVG={ICON_BY_KEY[title]} height={[14, 16, 18, 20]} />
      <Flex flexDirection="column">
        <Typography variant="proximaNova-400" fontSize={[12, 14, 16, 16]}>
          {title}
        </Typography>
        <Typography variant="proximaNova-400" fontSize={['8px', 10, 12, 12]} color={theme.color.gray[300]}>
          {explanation}
        </Typography>
      </Flex>
    </Container>
  ));

  return (
    <Main
      isWhiteLabelingOpen={false}
      isLoading={isLoading}
    >
      <Flex
        width="100%"
        flexDirection="column"
      >
        <Flex
          width={['100%', '100%', '85%', '100%']}
          alignSelf={['start', 'start', 'start', 'center']}
          flexDirection="column"
          maxWidth="1320px"
        >
          <Typography mb={[20, 30, 30]} variant="garamond-500" fontSize={34}>
            We’re here for you
          </Typography>
          <Typography mb={[10, 15, 20, 25]} variant="garamond-500" fontSize={[18, 20, 24, 24]}>
            What can we help you with?
          </Typography>
          <Flex width="100%" flexDirection={['column', 'column', 'column', 'row']}>
            <Flex
              justifyContent="space-between"
              mr={10}
              flexWrap="wrap"
              width={['100%', '100%', '100%', '55%']}
            >
              {topicsBlock}
            </Flex>
            <Flex
              width={['100%', '100%', '100%', '46%']}
              height={['240px', '270px', '300px', 'auto']}
              maxHeight="320px"
              background={`url(${SupportImage}) no-repeat right top`}
              backgroundSize="cover"
            />
          </Flex>
        </Flex>
      </Flex>
      <TextModal
        title={selectedTopic?.title}
        description={selectedTopic?.details}
        buttonText="Submit"
        inputPlaceholder="What seems to be the issue?"
        isOpen={selectedTopicId > 0}
        onModalClose={handleModalClose}
        onSave={handleSendSupportTicket}
        fontSize={[10, 12, 14]}
      />
      {isFeedbackSent && (
        <SuccessModal
          text="Your feedback is appreciated! We will respond really soon."
          onDoneClick={handleSupportTicketSentModalChangeState}
          title="Thank you!"
          isOpen={isFeedbackSent}
        />
      )}
    </Main>
  );
};

Support.defaultProps = {
  topics: [],
};

Support.propTypes = {
  isLoading: bool.isRequired,
  topics: arrayOf(
    exact({
      id: number,
      title: string,
      explanation: string,
      details: string,
    }),
  ),
};

export default Support;
