import styled, { css } from 'styled-components';

import { Icon } from '@atoms';
import { Flex } from '@mixins';

export const PrevIcon = styled(Icon)`
  ${() => css`
    transform: rotate(90deg);
  `}
`;

export const NextIcon = styled(Icon)`
  ${() => css`
    transform: rotate(270deg);
  `}
`;

export const CalendarContainer = styled(Flex)`
  ${({ type }) => css`
    .react-calendar {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 260px;
      max-width: 100%;
      border-radius: 2px;
      padding: 0;
    }
    
    .react-calendar button {
      cursor: pointer;
      margin: 0;
      border: 0;
      outline: none;
    }

    .react-calendar__navigation {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-family: Proxima Nova;
      font-weight: 400;
      font-size: 14px;
    }

    .react-calendar__navigation {
      font-family: EB Garamond;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 15px;
    }

    .react-calendar__navigation button {
      ${type === 'month' && 'min-width: 44px;'}
      padding: 0;
      background: none;
    }

    .react-calendar__navigation button:disabled {
      color: #151515;

      svg {
        path:first-child {
          fill: #8A8A8A;
        }
      }
    }

    .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
      display: none;
    }

    .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__viewContainer {
      font-family: Proxima Nova;
      font-weight: 400;
    }

    .react-calendar__viewContainer button:disabled {
      color: #8A8A8A;
    }
    
    .react-calendar__month-view {
      font-family: Proxima Nova;
      font-weight: 400;
      font-size: 14px;
      color: #8A8A8A;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #757575;
    }

    .react-calendar__month-view__weekdays {
      margin-bottom: 20px;
    }

    .react-calendar__month-view__weekdays__weekday {
      overflow: visible;
      flex: auto;
      display: flex;
      justify-content: center;
      color: #494949;
    }

    .react-calendar__month-view__weekdays__weekday abbr{
      text-decoration: none;
    }

    .react-calendar__tile.react-calendar__month-view__days__day {
      text-align: center;
      flex: none;
      padding: 0;
      height: 32px;
      background-color: #fff;
      margin: 3px 0px 3px 0px;
    }

    .react-calendar__tile--active.react-calendar__month-view__days__day:enabled:focus {
      background-color: #326164;
      color: #fff;
      font-size: 16px;
    }

    .react-calendar__tile--active.react-calendar__month-view__days__day {
      background-color: #326164;
      color: #fff;
    }

    .react-calendar__tile.react-calendar__year-view__months__month {
      background-color: #fff;
      height: 32px;
      margin: 5px 0px;
    }

    .react-calendar__tile--active.react-calendar__year-view__months__month {
      background-color: #326164;
      color: #fff;
    }
  `}
`;
