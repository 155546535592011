import React, { useCallback } from 'react';
import { string, bool, exact, arrayOf, number, func, object } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Icon } from '@atoms';
import { Socials } from '@molecules';
import { PhotoChanger } from '@organisms';
import { Main } from '@templates';
import { theme } from '@styles';
import { routes } from '@constants';
import { Typography, Flex } from '@mixins';

import { ReactComponent as Edit } from '@assets/svgs/Edit.svg';
import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import Gif from '@assets/gifs/no-profile-details.gif';

import { EditButton } from '../CompanyProfile/styles';
import { BackButton } from '../EditUserProfile/styles';

const UserProfile = ({
  error,
  isHidden,
  isCompanyHidden,
  isPhotoLoading,
  isEditable,
  userId,
  onRemovePhoto,
  onSavePhoto,
  email,
  isLoading,
  firstName,
  lastName,
  myStory,
  jobTitle,
  avatar,
  companyName,
  companyId,
  // skills,
  socials,
  from,
}) => {
  const history = useHistory();

  const onEdit = useCallback(() => {
    history.push({
      pathname: `${routes.userProfile}/${userId}/edit`,
      ...(from && {
        state: {
          from,
        },
      }),
    });
  }, [from, userId]);

  const handleBackClick = useCallback(() => {
    if (from === routes.knowYourNeighbors || from === routes.companyProfile) {
      history.push({
        pathname: `${routes.companyProfile}/${companyId}`,
        state: {
          from,
        },
      });
    } else {
      history.push(from);
    }
  }, [from, companyId]);

  const hasSocials = socials.find(social => !!social.link);

  const hasProfileDetails = isEditable || myStory || hasSocials || email;

  return (
    <Main isLoading={isLoading} error={error}>
      <Flex mb={[20, 25, 30]} width="100%" alignItems="center" justifyContent="space-between">
        {from
          ? (
            <Flex pointer onClick={handleBackClick} alignItems="center">
              <BackButton mr={['9px', 11, 13]} color={theme.color.gray[500]} SVG={Dropdown} />
              <Typography variant="proximaNova-400" fontSize={[12, 14, 16]}>
                Back
              </Typography>
            </Flex>
          )
          : (
            <Typography variant="garamond-500" fontSize={[30, 34]}>
              My profile
            </Typography>
          )}
        {isEditable && (
          <EditButton
            pointer
            onClick={onEdit}
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            width={[25, 35, 48]}
            height={[25, 35, 48]}
          >
            <Icon onClick={onEdit} SVG={Edit} width={[14, 18, 22]} height={[14, 18, 22]} />
          </EditButton>
        )}
      </Flex>
      <Flex width="100%">
        <Flex
          mr={['6px', '8px', 10]}
          maxWidth={[150, 200, 250, 280]}
          minWidth={[150, 200, 250, 280]}
          flexDirection="column"
        >
          <Flex height={[150, 200, 250, 280]}>
            <PhotoChanger
              isLoading={isPhotoLoading}
              isEditable={isEditable}
              isHidden={isHidden}
              isCompany={false}
              avatar={avatar}
              firstName={firstName}
              lastName={lastName}
              onSavePhoto={onSavePhoto}
              onRemovePhoto={onRemovePhoto}
            />
          </Flex>
          <Flex
            flexDirection="column"
            width="100%"
            p={[16, 20, 25]}
            mt="6px"
            alignItems="start"
            backgroundColor={theme.color.darkGreen}
          >
            <Typography
              color={theme.color.white}
              mb={[20, 30]}
              lineHeight={1.2}
              variant="garamond-500"
              fontSize={[18, 25, 30, 34]}
              wordBreak="break-word"
            >
              {isHidden ? 'Mindspace Member' : `${firstName} ${lastName}`}
            </Typography>
            <Typography color={theme.color.white} mb={10} variant="proximaNova-600" fontSize={[12, 16, 20]}>
              {isCompanyHidden ? 'Member Company' : companyName.replace(/\s/g, ' ')}
            </Typography>
            {((jobTitle && !isHidden) || isEditable) && (
              <Typography
                color={jobTitle ? theme.color.white : theme.color.gray[100]}
                variant="proximaNova-400"
                fontSize={[12, 14, 16]}
              >
                {jobTitle || 'My big-fat-greek title'}
              </Typography>
            )}
          </Flex>
        </Flex>
        <Flex
          py={[20, 30, 30, 40]}
          px={[25, 40, 40, 55]}
          border={`1px solid ${theme.color.gray[100]}`}
          width="100%"
          justifyContent={hasProfileDetails ? 'start' : 'center'}
        >
          {hasProfileDetails && !isHidden
            ? (
              <Flex width="100%" flexDirection="column" maxWidth={[220, 340, 460]}>
                {(isEditable || myStory) && (
                  <>
                    <Typography mb={[14, 16, 20]} variant="garamond-500" fontSize={[22, 26, 32]}>
                      My Story
                    </Typography>
                    <Typography
                      fontSize={[10, 14, 16]}
                      mb={[25, 35, 45]}
                      variant="proximaNova-400"
                      color={myStory ? theme.color.gray[500] : theme.color.gray[200]}
                    >
                      {myStory || `Who are you, ${firstName} ${lastName}?`}
                    </Typography>
                  </>
                )}
                {/* {(!!skills.length) && (
                    <Flex flexDirection="column" mb={[25, 35, 45]}>
                      <Typography mb={[14, 16, 20]} variant="garamond-500" fontSize={[20, 26, 32]}>
                        I&apos;m Amazing at
                      </Typography>
                      {skills.length
                        ? <UserSkills skills={skills} />
                        : (
                          <Typography
                            fontSize={[10, 14, 16]}
                            variant="proximaNova-400"
                            color={theme.color.gray[200]}
                          >
                            What are you great at? Edit your profile to add Skills
                          </Typography>
                        )}
                    </Flex>
                  )} */}
                {(hasSocials || isEditable || email) && (
                  <>
                    <Typography mb={[16, 20, 24]} variant="garamond-500" fontSize={[22, 26, 32]}>
                      Don&apos;t Be a Stranger
                    </Typography>
                    <Socials isEditable={isEditable} isCompany={false} socials={socials} email={email} />
                  </>
                )}
              </Flex>
            )
            : (
              <Flex alignItems="center" alignSelf="center" flexDirection="column">
                <Flex
                  as="img"
                  mb={[20, 30]}
                  width={['260px', '350px']}
                  height={['200px', '260px']}
                  src={Gif}
                />
                <Typography variant="garamond-500" fontSize={['14px', '18px', '22px']}>
                  No details available
                </Typography>
              </Flex>
            )}
        </Flex>
      </Flex>
    </Main>
  );
};

UserProfile.defaultProps = {
  error: {},
  userId: 1,
  companyName: '',
  avatar: '',
  firstName: '',
  lastName: '',
  myStory: '',
  jobTitle: '',
  // skills: [],
  socials: [],
  email: '',
  companyId: 0,
  from: '',
  isCompanyHidden: false,
  isHidden: false,
};

UserProfile.propTypes = {
  error: exact({
    status: number,
    message: string,
  }),
  isCompanyHidden: bool,
  isHidden: bool,
  isPhotoLoading: bool.isRequired,
  from: string,
  companyId: number,
  isEditable: bool.isRequired,
  userId: number,
  onRemovePhoto: func.isRequired,
  onSavePhoto: func.isRequired,
  email: string,
  socials: arrayOf(
    exact({
      name: string,
      link: string,
      icon: object,
    }),
  ),
  companyName: string,
  myStory: string,
  jobTitle: string,
  isLoading: bool.isRequired,
  firstName: string,
  lastName: string,
  avatar: string,
  // skills: arrayOf(string),
};

export default UserProfile;
