import React from 'react';

import { Flex, Typography } from '@mixins';
import { theme } from '@styles';
import { func, arrayOf, number, string, bool } from 'prop-types';

import { getBackground, getCloudinaryImageLinkBySize } from '@helpers/common';

const IMAGE_WIDTH = 100;

const Avatar = ({ firstName, lastName, onClick, imageUrl, width, height, fontSize, isHidden }) => {
  const url = getCloudinaryImageLinkBySize(imageUrl, IMAGE_WIDTH);

  return (
    <Flex
      onClick={onClick}
      pointer
      borderRadius="50%"
      minWidth={width}
      height={height}
      background={getBackground(false, isHidden ? '' : url)}
      alignItems="center"
      justifyContent="center"
      backgroundSize="cover"
    >
      {(!imageUrl) && (
        <Typography variant="garamond-500" fontSize={fontSize} color={theme.color.darkRed}>
          {isHidden ? 'MM' : `${firstName
            ? firstName.trim().toUpperCase()[0] : ''}${lastName
            ? lastName.trim().toUpperCase()[0] : ''}`}
        </Typography>
      )}
    </Flex>
  );
};

Avatar.defaultProps = {
  isHidden: false,
  fontSize: ['16px'],
  firstName: '',
  lastName: '',
  imageUrl: '',
  width: [0],
  height: [0],
  onClick: () => {},
};

Avatar.propTypes = {
  isHidden: bool,
  fontSize: arrayOf(string),
  firstName: string,
  lastName: string,
  onClick: func,
  imageUrl: string,
  width: arrayOf(number),
  height: arrayOf(number),
};

export default Avatar;
