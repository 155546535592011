import React, { useState } from 'react';
import { arrayOf, bool, exact, node, number, oneOf, string } from 'prop-types';
import { Icon } from '@atoms';
import { Flex, Typography } from '@mixins';
import { weekDays } from '@constants/common';
import { format24hTimeTo12h } from '@helpers/time';
import { theme } from '@styles';
import { ReactComponent as CloseIcon } from '@assets/svgs/Close.svg';
import { ReactComponent as Credits } from '@assets/svgs/Credits.svg';

const PricingDetails = ({
  locationTimeFormat,
  children,
  isClickable,
  baseCreditPrice,
  peakTimeCreditPrice,
  peakTimeDays,
  peakTimeIntervals,
}) => {
  const [isOpen, setIsOpen] = useState();

  const handleDetailsClick = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <Flex position="relative">
      <Flex
        height="fit-content"
        {...(isClickable && {
          onClick: handleDetailsClick, pointer: true, borderBottom: `0.5px solid ${theme.color.gray[300]}`,
        })}
      >
        {children}
      </Flex>
      {isOpen && (
        <Flex top={35} zIndex={3} position="absolute" flexDirection="column">
          <Flex
            position="relative"
            zIndex={0}
            marginBottom={-13}
            marginLeft={23}
            width="20px"
            height="20px"
            transform="rotate(45deg)"
            background="white"
            boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
          />
          <Flex
            pt="40px"
            alignItems="center"
            flexDirection="column"
            backgroundColor="white"
            position="relative"
            width="290px"
            height="250px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
          >
            <Icon
              zIndex={1}
              pointer
              onClick={handleDetailsClick}
              position="absolute"
              SVG={CloseIcon}
              width={14}
              height={14}
              top={16}
              right={16}
            />
            <Typography mb="6px" variant="proximaNova-600" fontSize={[14, 16, 18, 20]}>
              Base rate
            </Typography>
            <Flex alignItems="center" flexDirection="row" mb="10px">
              <Icon mr="5px" SVG={Credits} width={[10, 13, 16]} height={[10, 13, 16]} />
              <Typography variant="proximaNova-400" fontSize={[11, 12, 13, 14]} color={theme.color.gray[300]}>
                {`${baseCreditPrice} / hr`}
              </Typography>
            </Flex>
            {!!peakTimeCreditPrice && (
              <>
                <Typography mb="6px" variant="proximaNova-600" fontSize={[14, 16, 18, 20]}>
                  Peak Time rate
                </Typography>
                <Flex alignItems="center" flexDirection="row" mb="10px">
                  <Icon mr="5px" SVG={Credits} width={[10, 13, 16]} heigh={[10, 13, 16]} />
                  <Typography variant="proximaNova-400" fontSize={[11, 12, 13, 14]} color={theme.color.gray[300]}>
                    {`${peakTimeCreditPrice} / hr`}
                  </Typography>
                </Flex>
                <Typography variant="proximaNova-400" fontSize={[11, 12, 13, 14]} color={theme.color.gray[300]}>
                  {peakTimeDays?.map(
                    (day, index) =>
                      `${weekDays[day % 7]}${
                        index === peakTimeDays.length - 1 ? '' : ', '
                      }`,
                  )}
                </Typography>
                <Typography variant="proximaNova-400" fontSize={[11, 12, 13, 14]} color={theme.color.gray[300]}>
                  {peakTimeIntervals?.map(
                    (interval, index) =>
                      `${locationTimeFormat === '12h' ? format24hTimeTo12h(interval.from) : interval.from}-${
                        locationTimeFormat === '12h' ? format24hTimeTo12h(interval.to) : interval.to}${
                        index === peakTimeIntervals.length - 1 ? '' : ', '
                      }`,
                  )}
                </Typography>
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

PricingDetails.propTypes = {
  locationTimeFormat: oneOf('12h', '24h'),
  children: node.isRequired,
  isClickable: bool.isRequired,
  baseCreditPrice: number,
  peakTimeCreditPrice: number,
  peakTimeDays: arrayOf(number),
  peakTimeIntervals: arrayOf(exact({
    from: string,
    to: string,
  })),
};

PricingDetails.defaultProps = {
  locationTimeFormat: '24h',
  baseCreditPrice: 1,
  peakTimeCreditPrice: 1.5,
  peakTimeDays: [1, 3, 5, 6],
  peakTimeIntervals: [{ from: '12:00', to: '13:00' }, { from: '14:00', to: '15:00' }],
};

export default PricingDetails;
