import React, { useRef, useMemo, useCallback } from 'react';
import ICalendarLink from 'react-icalendar-link';
import { object, string } from 'prop-types';
import { dayjs } from '@utils';

import { Icon } from '@atoms';
import { Typography, Flex, Button } from '@mixins';
import { useOutsideClick } from '@hooks';
import { trackEvent } from '@utils/mixpanel';
import { theme } from '@styles';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import { ReactComponent as Calendar } from '@assets/svgs/Calendar.svg';
import { ReactComponent as GoogleCalendar } from '@assets/svgs/GoogleCalendar.svg';
import { ReactComponent as Office365 } from '@assets/svgs/Office365.svg';
import { ReactComponent as Ical } from '@assets/svgs/Ical.svg';

import { DropdownItem } from '@organisms/Header/styles';
import { Container } from './styles';

const AddToCalendar = ({ startDate, endDate, title, address }) => {
  const buttonRef = useRef();
  const dropdownRef = useRef();

  const { isOpen, setIsOpen } = useOutsideClick(false, [buttonRef, dropdownRef]);

  const handleCalendarModalChangeState = useCallback(() => {
    setIsOpen(prevState => {
      if (!prevState) {
        trackEvent('"Add to Calendar" button clicked', {
          scope: 'Calendar',
          title,
          address,
          startDate,
          endDate,
        });
      }
      return !prevState;
    });
  }, []);

  const handleCalendarClick = link => () => {
    handleCalendarModalChangeState();
    window.open(link);
  };

  const startTime = dayjs(startDate).format('YYYYMMDDTHHmmss[000Z]');
  const endTime = dayjs(endDate).format('YYYYMMDDTHHmmss[000Z]');

  const calendars = [
    {
      name: 'Google',
      icon: GoogleCalendar,
      link: `https://calendar.google.com/calendar/u/0/r/eventedit?text=${title}&dates=${startTime}/${endTime}
      &location=${address}&sf=true&output=xml`,
    },
    {
      name: 'Office365',
      icon: Office365,
      link: `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/view/month&rru=addevent
      &startdt=${startDate.toISOString()}&enddt=${endDate.toISOString()}&subject=${title}
      &location=${address}`,
    },
    {
      name: 'iCal',
      icon: Ical,
    },
  ];

  const calendarsBlock = useMemo(() => calendars.map(({ icon, name, link }) => {
    const Item = (
      <DropdownItem
        width="100%"
        px={[10, 14]}
        py={['8px', 10]}
        alignItems="center"
        key={name}
        {...(name !== 'iCal' && { onClick: handleCalendarClick(link) })}
        pointer
      >
        <Icon width={18} mr={10} SVG={icon} height={18} />
        <Typography variant="proximaNova-400" fontSize={[10, 12, 14, 16]}>
          {name}
        </Typography>
      </DropdownItem>
    );

    return name === 'iCal'
      ? (
        <ICalendarLink
          key={name}
          event={{
            title,
            startTime: startDate,
            endTime: endDate,
            location: address,
          }}
        >
          {Item}
        </ICalendarLink>
      )
      : Item;
  }), [calendars]);

  return (
    <Button
      color={theme.color.darkGreen}
      ref={buttonRef}
      onClick={handleCalendarModalChangeState}
    >
      <Flex position="relative" justifyContent="center" alignItems="center">
        <Icon height={[12, 20]} color={theme.color.darkGreen} mr={['6px', 10]} SVG={Calendar} />
        <Typography
          mr="6px"
          color={theme.color.darkGreen}
          variant="proximaNova-400"
          fontSize={[10, 12, 14, 16]}
        >
          Add to Calendar
        </Typography>
        <Icon height={[12, 20]} color={theme.color.darkGreen} SVG={Dropdown} />
        {isOpen && (
          <Container
            ref={dropdownRef}
            backgroundColor={theme.color.white}
            position="absolute"
            top={30}
            p={15}
            flexDirection="column"
            alignItems="start"
            boxShadow="0px 4px 4px rgba(220, 220, 220, 0.25)"
            border={`1px solid ${theme.color.gray[100]}`}
            borderRadius="2px"
          >
            {calendarsBlock}
          </Container>
        )}
      </Flex>
    </Button>
  );
};

AddToCalendar.defaultProps = {
  address: '',
  title: '',
  endDate: {},
  startDate: {},
};

AddToCalendar.propTypes = {
  address: string,
  title: string,
  endDate: object,
  startDate: object,
};

export default AddToCalendar;
