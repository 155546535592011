import { apiEndpoints } from '@constants';
import { client } from '@utils';

const getCompanyById = id => (
  client.authorized.get(`${apiEndpoints.company}/${id}`)
);

const getAdminCompanyById = id => (
  client.authorized.get(`${apiEndpoints.adminCompany}/${id}`)
);

const getCompanyByAccessId = accessId => (
  client.authorized.get(`${apiEndpoints.adminCompany}/${accessId}`)
);

const getCompanyList = data => (
  client.authorized.get(apiEndpoints.company, {
    params: data,
  })
);

const getCompanyMembers = (id, data) => (
  client.authorized.get(`${apiEndpoints.company}/${id}/members`, {
    params: data,
  })
);

const getCompanyMembersByAccessId = (accessId, data) => (
  client.authorized.get(`${apiEndpoints.adminCompany}/${accessId}/members`, {
    params: data,
  })
);

const updateCompanyMember = (id, data, accessId) => (
  client.authorized.put(`${apiEndpoints.adminCompany}/${accessId}/members/${id}`, data)
);

const createCompanyMember = (data, accessId) => (
  client.authorized.post(`${apiEndpoints.adminCompany}/${accessId}/members`, data)
);

const getCreditsReport = data => (
  client.authorized.get(`${apiEndpoints.adminCompany}/my/report/credits`, {
    params: data,
  })
);

const getMyCompanyAvailableCredits = () => (
  client.authorized.get(apiEndpoints.availableCredits)
);

const getCompanyManagers = id => (
  client.authorized.get(`${apiEndpoints.company}/${id}/managers`)
);

const editCompany = (id, data) => (
  client.authorized.patch(`${apiEndpoints.adminCompany}/${id}`, data)
);

export {
  getCreditsReport,
  getAdminCompanyById,
  createCompanyMember,
  updateCompanyMember,
  getCompanyMembersByAccessId,
  getCompanyById,
  getCompanyMembers,
  editCompany,
  getCompanyManagers,
  getCompanyList,
  getMyCompanyAvailableCredits,
  getCompanyByAccessId,
};

