import { Auth } from 'aws-amplify';

import { storage } from '@utils';
import { routes } from '@constants';
import { refreshToken as refreshTokenAction } from '@store/user/duck';

import { store } from '../App';

export const getAmplifyConfig = webClientId => ({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_POOL_ID,
    userPoolWebClientId: webClientId,
  },
});

export const refreshToken = async () => {
  store.dispatch(refreshTokenAction());

  const authUser = storage.getAmplifyAuthUser();

  const webClientId = authUser?.includes('@')
    ? process.env.REACT_APP_AWS_EMAIL_CLIENT_ID
    : process.env.REACT_APP_AWS_PHONE_CLIENT_ID;

  Auth.configure(getAmplifyConfig(webClientId));

  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();

    const currentSession = cognitoUser.signInUserSession;

    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (err) {
        storage.clear();
        window.location.href = routes.login;
      } else {
        storage.setToken(session.accessToken.jwtToken);
        window.location.reload();
      }
    });
  } catch {
    storage.clear();
    window.location.href = routes.login;
  }
};
