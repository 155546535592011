import { apiEndpoints } from '@constants';
import { client } from '@utils';

const getBenefitById = id => (
  client.authorized.get(`${apiEndpoints.benefit}/${id}`)
);

const getBenefits = data => (
  client.authorized.get(apiEndpoints.benefit, {
    params: data,
  })
);

const getBenefitsCategories = () => (
  client.authorized.get(apiEndpoints.benefitCagerories)
);

export {
  getBenefitById,
  getBenefits,
  getBenefitsCategories,
};

