import React from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

import { Main } from '@templates';
import { Typography, Flex } from '@mixins';

import HouseRulesImage from '@assets/images/HouseRules.png';

const HouseRules = () => {
  const { currentLocation: { details } } = useSelector(store => (
    store.locationStore
  ));

  return (
    <Main
      isWhiteLabelingOpen={false}
    >
      <Flex
        flexGrow={1}
        width="100%"
        alignItems="start"
        flexDirection="column"
      >
        <Typography variant="garamond-500" fontSize={[30, 34]} mb={[10, 20]}>
          House Rules and Procedures
        </Typography>
        <Typography variant="proximaNova-400" fontSize={[12, 14, 16]} mb={[20, 30, 40]}>
          Remember, this is your home-office-away-from-home, so treat it nicely.
        </Typography>
        <Flex>
          <Flex
            mr={[20, 30, 50, 80]}
            minWidth={[150, 225]}
            height={[250, 380]}
            background={`url(${HouseRulesImage}) center no-repeat`}
            backgroundSize="cover"
          />
          <Flex
            display="block"
            maxWidth={650}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(details?.buildingProcedures) }}
          />
        </Flex>
      </Flex>
    </Main>
  );
};

export default HouseRules;
