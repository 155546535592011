import { apiEndpoints } from '@constants';
import { client } from '@utils';

const getCurrentUser = () => (
  client.authorized.get(apiEndpoints.currentUser)
);

const getUserById = id => (
  client.authorized.get(`${apiEndpoints.user}/${id}`)
);

const editUser = data => (
  client.authorized.patch(apiEndpoints.currentUser, data)
);

const sendSupportTicket = data => (
  client.authorized.post(apiEndpoints.supportTicket, data)
);

const sendFeedback = data => (
  client.authorized.post(apiEndpoints.supportFeedback, data)
);

const getUserSettings = () => (
  client.authorized.get(apiEndpoints.userSettings)
);

const updateUserSettings = data => (
  client.authorized.patch(apiEndpoints.userSettings, data)
);

export {
  getCurrentUser,
  editUser,
  getUserById,
  sendFeedback,
  sendSupportTicket,
  getUserSettings,
  updateUserSettings,
};

