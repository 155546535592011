import React, { useMemo } from 'react';
import { string, arrayOf, exact, number, func } from 'prop-types';

import { theme } from '@styles';
import { Typography, Flex } from '@mixins';

const Filters = ({ filters, selectedFilter, onFilterChange }) => {
  const filtersBlock = useMemo(() => filters.map(({ id, name }, index) => (
    <Flex
      borderLeft={`1px solid ${theme.color.gray[300]}`}
      pl={[13, 10, 13, 17]}
      pr={index === filters.length - 1 ? 0 : [13, 10, 13, 17]}
      key={id}
    >
      <Typography
        pointer
        variant="proximaNova-400"
        fontSize={['10px', '12px', '14px', '16px']}
        color={selectedFilter === id ? theme.color.black : '#656565'}
        onClick={onFilterChange(id)}
      >
        {name}
      </Typography>
    </Flex>
  )), [filters, selectedFilter]);

  return (
    <>
      <Typography
        variant="proximaNova-400"
        fontSize={['10px', '12px', '14px', '16px']}
        color={!selectedFilter ? theme.color.black : '#656565'}
        pr={[13, 10, 13, 17]}
        onClick={onFilterChange(0)}
        pointer
      >
        All
      </Typography>
      {filtersBlock}
    </>
  );
};

Filters.defaultProps = {
  filters: [],
  selectedFilter: 0,
};

Filters.propTypes = {
  filters: arrayOf(exact({
    id: number,
    name: string,
  })),
  onFilterChange: func.isRequired,
  selectedFilter: number,
};

export default Filters;
