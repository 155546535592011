import styled, { css } from 'styled-components';

import { Flex } from '@mixins';

export const DropdownItem = styled(Flex)`
  ${() => css`
    &:hover {
      background-color: #f7f5f3;
    }
  `}
`;

export const LogoImg = styled.img`
  object-fit: cover;
  width: auto;
  height: 20px;
  margin-left: 10px;

  @media screen and (min-width: 40em) {
    height: 30px;
    margin-left: 15px;
  }

  @media screen and (min-width: 52em) {
    height: 40px;
    margin-left: 20px;
  }
`;
