import React, { createRef } from 'react';
import {
  BrowserRouter, Redirect, Switch, useHistory,
} from 'react-router-dom';
import { routes } from '@constants';
import {
  Login,
  VerificationCode,
  Oops,
  Home,
  UserProfile,
  EditUserProfile,
  CompanyProfile,
  EditCompanyProfile,
  Benefits,
  BenefitDetails,
  Rooms,
  RoomDetails,
  BookedRoom,
  BookedRoomEdit,
  BookingHistory,
  KnowYourNeighbors,
  Support,
  BuildingAmenities,
  Events,
  EventDetails,
  ExploreLocation,
  CreditsPackages,
  PrintingDetails,
  HouseRules,
  PaymentSettings,
  Feedback,
  CompanyMembersApp,
} from '@pages';
import { ScrollToTop } from '@atoms';
import UnauthorizedRoute from '../UnauthorizedRoute';
import AuthorizedRoute from '../AuthorizedRoute';

export const historyRef = createRef();

const SetHistoryInstance = () => {
  historyRef.current = useHistory();
  return null;
};

const Router = () => (
  <BrowserRouter>
    <SetHistoryInstance />
    <ScrollToTop />
    <Switch>
      <UnauthorizedRoute exact path={routes.login}>
        <Login />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.verificationCode}>
        <VerificationCode />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.loginFailure}>
        <Oops />
      </UnauthorizedRoute>
      <AuthorizedRoute exact path={routes.home}>
        <Home />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.userProfileDetails}>
        <UserProfile />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.editUserProfileDetails}>
        <EditUserProfile />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.companyMembersApp}>
        <CompanyMembersApp />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.companyProfileDetails}>
        <CompanyProfile />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.editCompanyProfileDetails}>
        <EditCompanyProfile />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.benefits}>
        <Benefits />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.benefitDetails}>
        <BenefitDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.rooms}>
        <Rooms />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.roomDetails}>
        <RoomDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.bookedRoomDetails}>
        <BookedRoom />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.bookedRoomEdit}>
        <BookedRoomEdit />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.bookingHistory}>
        <BookingHistory />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.knowYourNeighbors}>
        <KnowYourNeighbors />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.support}>
        <Support />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.amenities}>
        <BuildingAmenities />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.events}>
        <Events />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.eventDetails}>
        <EventDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.exploreLocation}>
        <ExploreLocation />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.creditsPackages}>
        <CreditsPackages />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.printingDetails}>
        <PrintingDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.houseRules}>
        <HouseRules />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.paymentSettings}>
        <PaymentSettings />
      </AuthorizedRoute>
      <AuthorizedRoute exact path={routes.feedback}>
        <Feedback />
      </AuthorizedRoute>
      <Redirect to={routes.home} />
    </Switch>
  </BrowserRouter>
);

export default Router;
