import React, { useState } from 'react';

import { Typography, Flex } from '@mixins';
import { DataTable } from '@organisms';
import { theme } from '@styles';
import { StyledDropdown } from '@molecules/BookedRooms/styles';
import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import { useSelector } from 'react-redux';
import { columns } from './columns';

const CreditsReport = () => {
  const { creditPackages } = useSelector(store => (
    store.companyStore
  ));

  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpandButtonClick = () => {
    setIsExpanded(prevValue => !prevValue);
  };

  const renderTotalFooter = () => (
    <Flex borderTop="1px solid #000" py={16} width="100%">
      <Typography flex={1} px="8px" variant="proximaNova-600" fontSize={14}>
        Total
      </Typography>
      <Typography flex={1} px="8px" variant="proximaNova-600" fontSize={12}>
        -
      </Typography>
      <Typography flex={1} px="8px" variant="proximaNova-600" fontSize={12}>
        -
      </Typography>
      <Typography flex={1} px="8px" variant="proximaNova-400" fontSize={12}>
        {creditPackages.totals?.available ?? '-'}
      </Typography>
      <Typography flex={1} px="8px" variant="proximaNova-400" fontSize={12}>
        {creditPackages.totals?.used ?? '-'}
      </Typography>
      <Typography flex={1} px="8px" variant="proximaNova-400" fontSize={12}>
        {creditPackages.totals?.remaining ?? '-'}
      </Typography>
    </Flex>
  );

  return (
    <Flex mb="25px" width="100%" flexDirection="column">
      <Flex mb="15px" alignItems="center">
        <Flex
          onClick={handleExpandButtonClick}
          mr={10}
        >
          <StyledDropdown
            isExpanded={isExpanded}
            color={theme.color.black}
            SVG={Dropdown}
          />
        </Flex>
        <Typography
          color={theme.color.gray[500]}
          variant="garamond-500"
          fontSize={[22, 26, 30]}
        >
          Credit Summary
        </Typography>
      </Flex>
      {isExpanded && (
        <DataTable
          isClickable={false}
          slots={{ footer: renderTotalFooter }}
          rows={creditPackages.packages ?? []}
          columns={columns}
          paginationMode="server"
        />
      )}
    </Flex>
  );
};

export default CreditsReport;
