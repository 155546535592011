const VALIDATION = {
  ERROR_MESSAGE: {
    EMAIL: {
      INVALID: 'Email has invalid format',
      MAX_LENGTH_EXCEEDED: 'Email maximum length has exceeded',
      REQUIRED: 'Email is required',
    },
    FIRST_NAME: {
      REQUIRED: 'First name is required',
      MAX_LENGTH_EXCEEDED: 'First name maximum length has exceeded',
    },
    LAST_NAME: {
      REQUIRED: 'Last name is required',
      MAX_LENGTH_EXCEEDED: 'Last name maximum length has exceeded',
    },
    PHONE_NUMBER: {
      INVALID: 'Bad phone format',
    },
  },
  MAX_LENGTH: {
    EMAIL: 64,
    FIRST_NAME: 40,
    LAST_NAME: 80,
  },
  MAX_VALUE: {
    RANGE: 100,
  },
  REG_EXP: {
    EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  },
};

export default VALIDATION;
