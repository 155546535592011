import React from 'react';
import { func, string, bool, object } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Icon, Modal } from '@atoms';
import { Flex, Button, Typography } from '@mixins';
import { theme } from '@styles';
import { routes } from '@constants';

import { formatTime } from '@helpers/time';
import { getSuffixByNumericValue } from '@helpers/common';

import { ReactComponent as Sent } from '@assets/svgs/Sent.svg';

const formatDate = date => `${date.format('dddd')}, ${date.format('MMMM')} 
${getSuffixByNumericValue(date.$D)}`;

const InviteSentModal = ({
  isInvitedToTheRoom,
  date,
  time,
  location,
  isOpen,
  onClose,
  timeFormat,
}) => {
  const history = useHistory();

  const handleBookRoom = () => {
    onClose();

    history.push(routes.rooms);
  };

  return (
    <Modal onClose={onClose} topPosition={170} isOpen={isOpen} withCloseButton={false}>
      <Flex maxWidth={400} p={20} flexDirection="column" alignItems="center">
        <Icon mb={[15, 30]} SVG={Sent} />
        <Typography mb={15} variant="proximaNova-600" fontSize={[16, 18, 20]}>
          Invite sent
        </Typography>
        <Typography
          textAlign="center"
          mb={25}
          variant="proximaNova-400"
          color={theme.color.gray[300]}
          fontSize={[10, 12, 14]}
        >
          {`An email was sent to your guests inviting them to ${location} on 
        ${formatDate(date)}${time ? ` at ${formatTime(time, timeFormat)}` : ''}.`}
        </Typography>
        {!isInvitedToTheRoom && (
          <Typography
            mb={[20, 30]}
            variant="proximaNova-400"
            color={theme.color.gray[300]}
            fontSize={[10, 12, 14]}
          >
            Don&apos;t forget to book a room if you need one.
          </Typography>
        )}
        <Button variant="primary" onClick={onClose} mb={15} py={12} width={225}>
          Done
        </Button>
        {!isInvitedToTheRoom && (
          <Button onClick={handleBookRoom} py={12} width={225} color={theme.color.darkGreen}>
            Book a Room
          </Button>
        )}
      </Flex>
    </Modal>
  );
};

InviteSentModal.defaultProps = {
  time: '',
  location: '',
  timeFormat: '24h',
};

InviteSentModal.propTypes = {
  timeFormat: string,
  isInvitedToTheRoom: bool.isRequired,
  date: object.isRequired,
  time: string,
  location: string,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default InviteSentModal;
