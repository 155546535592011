export const permissions = [{
  id: 1,
  label: 'With Credit Card',
  values: {
    isAbleToInvoice: false,
    isAbleToPayByCard: true,
  },
}, {
  id: 2,
  label: 'With Credit Card or Invoice',
  values: {
    isAbleToInvoice: true,
    isAbleToPayByCard: true,
  },
}, {
  id: 3,
  label: 'Not Allowed',
  values: {
    isAbleToInvoice: false,
    isAbleToPayByCard: false,
  },
},
];
