import React, { useEffect } from 'react';
import { bool, node, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Redirect, Route,
} from 'react-router-dom';
import { routes } from '@constants';
import { SUCCESS } from '@constants/requestPhase';
import { getCurrentUser, resetPhases as resetUserPhases } from '@store/user/duck';
import { storage } from '@utils';
import { useMixpanel } from '@hooks';

const AuthorizedRoute = ({ exact, path, children }) => {
  const token = storage.getToken();

  const { currentLocation } = useSelector(store => store.locationStore);
  const { user, getCurrentUserPhase } = useSelector(store => (
    store.userStore
  ));

  const dispatch = useDispatch();
  const { mixpanel } = useMixpanel();

  useEffect(() => {
    if (Object.keys(user).length === 1) {
      dispatch(getCurrentUser());
    }
  }, []);

  useEffect(() => {
    if (currentLocation?.title) {
      mixpanel.people.set({
        'User Location': currentLocation.title,
      });
    }
  }, [currentLocation, mixpanel]);

  useEffect(() => {
    if (getCurrentUserPhase === SUCCESS) {
      dispatch(resetUserPhases());

      mixpanel.identify(`${user?.id}`);

      mixpanel.people.set_once({
        $email: user.email,
        $name: `${user.firstName} ${user.lastName}`,
      });
    }
  }, [getCurrentUserPhase, mixpanel]);

  if (!token) {
    return <Redirect to={`${routes.login}?redirect=${window.location.pathname}`} />;
  }

  return (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  );
};

AuthorizedRoute.defaultProps = {
  exact: false,
};

AuthorizedRoute.propTypes = {
  exact: bool,
  path: string.isRequired,
  children: node.isRequired,
};

export default AuthorizedRoute;
