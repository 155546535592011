import React, { useMemo } from 'react';
import { string, arrayOf, exact, func, bool, object } from 'prop-types';

import { Icon } from '@atoms';
import { TextInput } from '@atoms/Input';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';

const EditSocials = ({ socials, isCompany }) => {
  const socialsBlock = useMemo(() => socials.map(({ name, icon, handler, placeholder, value }, index) => (
    <Flex
      flexDirection="column"
      alignItems="start"
      key={name}
      mb={index === socials.length - 1 ? 0 : [10, 16, 20]}
      pt={[10, 16, 20]}
      width="100%"
    >
      <Flex mb={[10, 13, 16]} alignItems="center">
        <Icon
          color={isCompany ? theme.color.darkRed : theme.color.darkGreen}
          mr={['12px', '14px', '16px']}
          SVG={icon}
          width={[17, 20, 23]}
        />
        <Typography fontSize={['10px', '14px', '16px']} pointer onClick={handler} variant="proximaNova-400">
          {name}
        </Typography>
      </Flex>
      <TextInput placeholder={placeholder} value={value} onChange={handler} />
    </Flex>
  )), [socials, isCompany]);

  return socialsBlock;
};

EditSocials.defaultProps = {
  socials: [],
};

EditSocials.propTypes = {
  socials: arrayOf(
    exact({
      name: string,
      value: string,
      placeholder: string,
      handler: func,
      icon: object,
    }),
  ),
  isCompany: bool.isRequired,
};

export default EditSocials;
