import React from 'react';
import { useSelector } from 'react-redux';

import { Flex, Typography } from '@mixins';
import { dayjs } from '@utils';
import { formatTime } from '@helpers/time';
import { ONE_WEEK_MILLI } from 'src/constants/common';

const EventHeader = () => {
  const { eventById: { details: {
    upcomingDate, startTime, endTime, location, locationDescription, name, imageUrl, isRecurring,
  }, dates: { availableDates } } } = useSelector(store => store.eventStore);

  const { currentLocation } = useSelector(store => store.locationStore);

  const startDate = upcomingDate ? `${upcomingDate}T${startTime}.000Z` : 0;
  const endDate = upcomingDate ? `${upcomingDate}T${endTime}.000Z` : 0;

  const isWeekly = isRecurring
    ? new Date(availableDates[1]?.date ?? 0).getTime() - new Date(availableDates[0]?.date).getTime()
     === ONE_WEEK_MILLI
    : false;

  return (
    <Flex alignItems="center">
      <Flex
        mr={20}
        background={`url(${imageUrl}) center`}
        backgroundSize="cover"
        width={120}
        height={95}
      />
      <Flex flexDirection="column">
        <Typography lineHeight={1} variant="proximaNova-600" mb="8px">{name}</Typography>
        <Typography variant="proximaNova-400" fontSize={14}>
          {`${isWeekly ? `Every ${dayjs(startDate).format('ddd')},` : ''}
          ${isRecurring ? '' : `${dayjs(startDate).format('MMMM')} ${dayjs(startDate).$D},`} 
          ${formatTime(startDate, currentLocation.timeFormat)}-${formatTime(
      endDate, currentLocation.timeFormat,
    )}`}
        </Typography>
        <Typography variant="proximaNova-400" fontSize={14}>{location}</Typography>
        <Typography variant="proximaNova-400" fontSize={14}>{locationDescription}</Typography>
      </Flex>
    </Flex>
  );
};

export default EventHeader;
