import styled, { css } from 'styled-components';

import { Flex } from '@mixins';

export const Container = styled(Flex)`
  ${() => css`
    a {
      text-decoration: none;
      width: 100%;
    }
  `}
`;
