import React, { useEffect } from 'react';
import { string, bool, func } from 'prop-types';
import { Flex, Typography } from '@mixins';
import { theme } from '@styles';

const Hint = ({ title, isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  }, [isOpen]);

  return (
    isOpen && (
      <Flex right={0} left={0} alignItems="center" flexDirection="column" position="absolute" top={-65}>
        <Flex
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          flexDirection="column"
          py={10}
          px={15}
          backgroundColor={theme.color.gray[500]}
        >
          <Typography
            whiteSpace="pre"
            variant="proximaNova-400"
            fontSize={[12, 16]}
            color={theme.color.white}
          >
            {title}
          </Typography>
        </Flex>
        <Flex
          width={0}
          height={0}
          borderStyle="solid"
          borderWidth="7px 7px 0px 7px"
          borderColor={`${theme.color.gray[500]} transparent transparent transparent`}
        />
      </Flex>
    )
  );
};

Hint.propTypes = {
  title: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default Hint;
