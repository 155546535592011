import styled from 'styled-components';

import { Flex } from '@mixins';
import { theme } from '@styles';

export const StyledWrap = styled(Flex)`
  position: relative;
  &:before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 72px;
    background-color: ${theme.color.gray[500]};
    position: absolute;
    top: -25px;
    left: 0;
  }
`;

export const StyledLink = styled('a')`
  width: 100%;
  text-decoration: none;
  color: inherit;
`;
