import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import * as api from './api';

// < ----- ACTIONS ----- > //
const resetPhasesAction = 'RESET_ROOMS_PHASES';

const getMeetingRoomsRoutine = createRoutine(
  'GET_MEETING_ROOMS',
);

const getMeetingRoomByIdRoutine = createRoutine(
  'GET_MEETING_ROOM_BY_ID',
);

const getMeetingRoomsFiltersRoutine = createRoutine(
  'GET_MEETING_ROOMS_FILTERS',
);

const getMeetingRoomBookingsByIdRoutine = createRoutine(
  'GET_MEETING_ROOMS_BOOKINGS_BY_ID',
);
const getBookingCostRoutine = createRoutine(
  'GET_BOOKING_COST',
);

const resetStoreToInitialStateAction = 'RESET_STORE';

// < ----- ACTION CREATORS ----- > //
export const getMeetingRooms = data => async dispatch => {
  try {
    dispatch(getMeetingRoomsRoutine.request());

    const response = await api.getMeetingRooms(data);

    return dispatch(getMeetingRoomsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getMeetingRoomsRoutine.failure(error.response));
  }
};

export const getMeetingRoomsFilters = data => async dispatch => {
  try {
    dispatch(getMeetingRoomsFiltersRoutine.request());

    const response = await api.getMeetingRoomsFilters(data);

    return dispatch(getMeetingRoomsFiltersRoutine.success(response.data));
  } catch (error) {
    return dispatch(getMeetingRoomsFiltersRoutine.failure(error.response));
  }
};

export const getBookingCost = data => async dispatch => {
  try {
    dispatch(getBookingCostRoutine.request());

    const response = await api.getBookingCost(data);

    return dispatch(getBookingCostRoutine.success(response.data));
  } catch (error) {
    return dispatch(getBookingCostRoutine.failure(error.response));
  }
};

export const getMeetingRoomById = id => async dispatch => {
  try {
    dispatch(getMeetingRoomByIdRoutine.request());

    const response = await api.getMeetingRoomById(id);

    return dispatch(getMeetingRoomByIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getMeetingRoomByIdRoutine.failure(error.response));
  }
};

export const getMeetingRoomBookingsById = (id, date) => async dispatch => {
  try {
    dispatch(getMeetingRoomBookingsByIdRoutine.request());

    const response = await api.getMeetingRoomBookingsById(id, date);

    return dispatch(getMeetingRoomBookingsByIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getMeetingRoomBookingsByIdRoutine.failure(error.response));
  }
};

export const resetStore = () => ({
  type: resetStoreToInitialStateAction,
});

export const resetPhases = () => ({
  type: resetPhasesAction,
});

// < ----- STATE ----- > //
export const roomStorePersistWhitelist = [];

const initialState = {
  rooms: {
    availableOptions: {},
    meetingRooms: [],
  },

  roomById: {
    bookings: [],
    availableCredits: 0,
    cost: 0,
    creditsCost: 0,
    costBreakdown: [],
  },

  getMeetingRoomsPhase: INIT,
  getMeetingRoomsError: null,

  getMeetingRoomByIdPhase: INIT,
  getMeetingRoomByIdError: null,

  getMeetingRoomBookingsByIdPhase: INIT,
  getMeetingRoomBookingsByIdError: null,

  getMeetingRoomsFiltersPhase: INIT,
  getMeetingRoomsFiltersError: null,

  getBookingCostPhase: INIT,
  getBookingCostError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;
    // RESET PHASES
    case resetPhasesAction:
      return {
        ...state,

        getMeetingRoomsPhase: INIT,
        getMeetingRoomsError: null,

        getMeetingRoomByIdPhase: INIT,
        getMeetingRoomByIdError: null,

        getMeetingRoomBookingsByIdPhase: INIT,
        getMeetingRoomBookingsByIdError: null,

        getMeetingRoomsFiltersPhase: INIT,
        getMeetingRoomsFiltersError: null,

        getBookingCostPhase: INIT,
        getBookingCostError: null,
      };
    // GET MEETING ROOMS
    case getMeetingRoomsRoutine.REQUEST:
      return {
        ...state,
        getMeetingRoomsPhase: LOADING,
      };
    case getMeetingRoomsRoutine.SUCCESS:
      return {
        ...state,
        getMeetingRoomsPhase: SUCCESS,
        rooms: {
          ...state.rooms,
          meetingRooms: payload.data,
        },
      };
    case getMeetingRoomsRoutine.FAILURE:
      return {
        ...state,
        getMeetingRoomsPhase: FAILURE,
        getMeetingRoomsError: payload,
      };
    // GET MEETING ROOM BY ID
    case getMeetingRoomByIdRoutine.REQUEST:
      return {
        ...state,
        getMeetingRoomByIdPhase: LOADING,
      };
    case getMeetingRoomByIdRoutine.SUCCESS:
      return {
        ...state,
        getMeetingRoomByIdPhase: SUCCESS,
        roomById: {
          ...state.roomById,
          ...payload.data,
        },
      };
    case getMeetingRoomByIdRoutine.FAILURE:
      return {
        ...state,
        getMeetingRoomByIdPhase: FAILURE,
        getMeetingRoomByIdError: payload,
      };
    // GET MEETING ROOM BOOKINGS BY ID
    case getMeetingRoomBookingsByIdRoutine.REQUEST:
      return {
        ...state,
        getMeetingRoomBookingsByIdPhase: LOADING,
      };
    case getMeetingRoomBookingsByIdRoutine.SUCCESS:
      return {
        ...state,
        getMeetingRoomBookingsByIdPhase: SUCCESS,
        roomById: {
          ...state.roomById,
          bookings: payload.data,
        },
      };
    case getMeetingRoomBookingsByIdRoutine.FAILURE:
      return {
        ...state,
        getMeetingRoomBookingsByIdPhase: FAILURE,
        getMeetingRoomBookingsByIdError: payload,
      };
    // GET MEETING ROOMS FILTERS
    case getMeetingRoomsFiltersRoutine.REQUEST:
      return {
        ...state,
        getMeetingRoomsFiltersPhase: LOADING,
      };
    case getMeetingRoomsFiltersRoutine.SUCCESS: {
      const meetingRoomWorkstationCount = payload.data.workstationCount.map(seatsValue => seatsValue.toString());
      const eventSpaceWorkstationCount = payload.data.eventSpaceWorkstationCount.map(seatsValue => `${seatsValue}+`);
      return {
        ...state,
        getMeetingRoomsFiltersPhase: SUCCESS,
        rooms: {
          ...state.rooms,
          availableOptions: {
            ...payload.data,
            meetingRoomWorkstationCount,
            eventSpaceWorkstationCount,
            workstationCount: [
              ...meetingRoomWorkstationCount,
              ...eventSpaceWorkstationCount,
            ]
              .filter(
                (item, index, quantities) => quantities.indexOf(`${item}+`) === -1,
              )
              .sort(
                (seatsValue, nextSeatsValue) =>
                  Number(seatsValue.replace('+', '')) -
                  Number(nextSeatsValue.replace('+', '')),
              ),
          },
        },
      };
    }
    case getMeetingRoomsFiltersRoutine.FAILURE:
      return {
        ...state,
        getMeetingRoomsFiltersPhase: FAILURE,
        getMeetingRoomsFiltersError: payload,
      };
    // GET BOOKING COST
    case getBookingCostRoutine.REQUEST:
      return {
        ...state,
        getBookingCostPhase: LOADING,
      };
    case getBookingCostRoutine.SUCCESS:
      return {
        ...state,
        getBookingCostPhase: SUCCESS,
        roomById: {
          ...state.roomById,
          availableCredits: payload.data.availableCredits,
          cost: payload.data.cost,
          costBreakdown: payload.data.costBreakdown,
          creditsCost: payload.data.creditsCost,
        },
      };
    case getBookingCostRoutine.FAILURE:
      return {
        ...state,
        getBookingCostPhase: FAILURE,
        getBookingCostError: payload,
      };
    default:
      return state;
  }
};

export default store;
