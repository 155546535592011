import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Oops as View } from '@views';
import { routes } from '@constants';
import { trackEvent } from '@utils/mixpanel';

const Oops = () => {
  const location = useLocation();
  const history = useHistory();

  const handleTryAgain = () => {
    history.push({ pathname: routes.login, state: { redirect: location.state?.redirect } });
  };

  useEffect(() => {
    const pageTitle = 'Mindspace: Oops!';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Oops',
    });
  }, []);

  return (
    <View
      onTryAgain={handleTryAgain}
      codeAddress={location.state?.source || 'test@user.com'}
    />
  );
};

export default Oops;
