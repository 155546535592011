import React, { useState } from 'react';
import { node, string, bool } from 'prop-types';

import { Flex, Typography } from '@mixins';
import { theme } from '@styles';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { StyledDropdown } from './styles';

const LocationDropdown = ({
  title,
  children,
  showTitle,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onDropdownToggle = () => {
    setIsExpanded(prevState => !prevState);
  };

  return (
    <Flex mb={10} width="100%" alignItems="center" flexDirection="column">
      {showTitle && (
        <Flex
          width="100%"
          alignItems="center"
          onClick={onDropdownToggle}
          pointer
          justifyContent="space-between"
        >
          <Typography
            variant="garamond-500"
            fontSize={20}
            color={isExpanded ? theme.color.darkGreen : theme.color.gray[500]}
          >
            {title}
          </Typography>
          <StyledDropdown
            isExpanded={isExpanded}
            color={theme.color.black}
            SVG={Dropdown}
          />
        </Flex>
      )}
      {(isExpanded || !showTitle) && children}
    </Flex>
  );
};

LocationDropdown.defaultProps = {
  title: '',
};

LocationDropdown.propTypes = {
  showTitle: bool.isRequired,
  children: node.isRequired,
  title: string,
};

export default LocationDropdown;
