import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bool, exact, string, arrayOf, number } from 'prop-types';
import { Main } from '@templates';
import { Typography, Flex, Button } from '@mixins';
import { theme } from '@styles';
import { getCompanyMembersByAccessId, resetPhases } from '@store/company/duck';
import { TextInput } from '@atoms/Input';
import { useDebounce } from '@hooks';
import { Icon } from '@atoms';
import { DataTable, ManageCompanyMemberModal } from '@organisms';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import { ReactComponent as Search } from '@assets/svgs/Search.svg';
import { ManageCompanyMembersErrorModal } from '@molecules';
import { BackButton } from '../EditUserProfile/styles';

import { columns } from './columns';

const CompanyMembersApp = ({ companyMembers, pagination, companyName, companyStatus, activeMembers }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { updateCompanyMemberError } = useSelector(store => store.companyStore);
  const { user: { id: userId } } = useSelector(store => store.userStore);

  const [sortingModel, setSortingModel] = useState({
    direction: null,
    sortBy: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    dispatch(getCompanyMembersByAccessId('my', {
      page, search: debouncedSearch, pageSize: 8, ...sortingModel,
    }));
  }, [page, debouncedSearch, sortingModel]);

  const handleAddUser = () => {
    setIsModalOpen(true);
  };

  const handleResetState = () => {
    dispatch(resetPhases());
  };

  const handlePageChange = useCallback(value => {
    setPage(value.page + 1);
  }, []);

  const onBackClick = () => {
    history.goBack();
  };

  const handleUserClick = id => {
    setIsModalOpen(true);
    setSelectedUserId(id);
  };

  const handleSortModelChange = sorting => {
    setSortingModel({
      orderBy: sorting[0]?.field,
      direction: sorting[0]?.sort.toUpperCase(),
    });
  };

  const handleSearch = event => {
    setSearch(event.target.value);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  const selectedUser = useMemo(() => (
    companyMembers.find(user => user.id === selectedUserId)
  ), [companyMembers, selectedUserId]);

  return (
    <Main
      isWhiteLabelingOpen={false}
      isLoading={false}
    >
      <Flex alignItems="start" width="100%" flexDirection="column">
        <Flex
          width="fit-content"
          alignItems="center"
          mb={[20, 25, 30]}
          onClick={onBackClick}
          pointer
        >
          <BackButton mr={['9px', 11, 13]} SVG={Dropdown} color={theme.color.gray[500]} />
          <Typography variant="proximaNova-400">
            Back
          </Typography>
        </Flex>
        <Typography
          mb="30px"
          color={theme.color.black}
          variant="proximaNova-600"
          fontSize={['18px', '24px', '30px', '36px']}
        >
          Manage team members
        </Typography>
        <Flex
          flexDirection="column"
          width="100%"
          border="1px solid #D9D9D9"
          py={['20px', '20px', '30px', '60px']}
          px={['20px', '20px', '30px', '60px']}
        >
          <Flex mb="25px" justifyContent="space-between">
            <Typography variant="proximaNova-600">
              {activeMembers}
              {' '}
              Active Team Members at
              {' '}
              {companyName}
            </Typography>
            <Button onClick={handleAddUser} variant="primary">
              <Typography py="6px" px="12px" variant="proximaNova-400" fontSize="14px" color={theme.color.white}>
                + Add Team Member
              </Typography>
            </Button>
          </Flex>
          <Flex mb="20px" maxWidth="400px">
            <TextInput value={search} onChange={handleSearch} isRound placeholder="Search with name, email or phone...">
              <Icon mr={[10, 12]} SVG={Search} />
            </TextInput>
          </Flex>
          <DataTable
            columnVisibilityModel={{
              status: companyStatus === 'active',
            }}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            rows={companyMembers}
            columns={columns(userId)}
            onRowClick={handleUserClick}
            paginationMode="server"
            paginationModel={{
              pageSize: 8,
              page: page - 1,
            }}
            rowCount={pagination?.total}
            onPaginationModelChange={handlePageChange}
          />
        </Flex>
      </Flex>
      {isModalOpen && (
        <ManageCompanyMemberModal user={selectedUser} onModalClose={handleModalClose} isOpen={isModalOpen} />
      )}
      {!!updateCompanyMemberError && !isModalOpen && (
        <ManageCompanyMembersErrorModal
          error={{
            title: 'Cannot edit team member',
            status: updateCompanyMemberError?.data.error.status,
            message: updateCompanyMemberError?.data.error.message,
          }}
          onModalClose={handleResetState}
          isOpen={!!updateCompanyMemberError}
        />
      )}
    </Main>
  );
};

CompanyMembersApp.propTypes = {
  activeMembers: number,
  companyStatus: string.isRequired,
  companyName: string,
  companyMembers: arrayOf(exact({
    id: number,
    imageUrl: string,
    email: string,
    firstName: string,
    lastName: string,
    isCompanyAdmin: bool,
    phone: string,
    status: string,
    isAbleToBookMeetingRoom: bool,
    isAbleToInvoice: bool,
    isAbleToPayByCard: bool,
  })).isRequired,
  pagination: exact({
    pageSize: number,
    page: number,
    cursor: number,
    total: number,
  }).isRequired,
};

CompanyMembersApp.defaultProps = {
  activeMembers: 0,
  companyName: null,
};

export default CompanyMembersApp;
