import React, { useCallback, useMemo } from 'react';
import { bool, func, number, object, string, arrayOf, exact } from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RoomDetailsPanel } from '@molecules';
import { ManageBookingPanel, RoomBookingForm, PayByCard } from '@organisms';
import { Main } from '@templates';
import { Flex, Typography } from '@mixins';
import { getSuffixByNumericValue } from '@helpers/common';
import { withStripe } from '@hocs';
import { theme } from '@styles';
import { routes } from '@constants';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { BackButton } from '../EditUserProfile/styles';

const RoomDetails = ({
  creditPrices,
  maxBookingHours,
  externalCompany,
  onExternalCompanySelect,
  isEventSpace,
  bookings,
  isPayByCardOpen,
  onPayByCardChangeState,
  onBuyCreditsClick,
  onCompanySelect,
  selectedCompany,
  error,
  onEditBooking,
  areBookingsLoading,
  isEditing,
  onSubmit,
  roomPhotos,
  times,
  onDateChange,
  onEndTimeChange,
  onStartTimeChange,
  date,
  onTimelineChange,
  onTimelineClick,
  availableTimes,
  endTime,
  startTime,
  isLoading,
  features,
  roomNumber,
  floor,
  creditsPrice,
  currencyPrice,
  seats,
  title,
  onTitleChange,
  companyName,
  onBackClick,
}) => {
  const history = useHistory();

  const { roomById } = useSelector(store => (
    store.roomStore
  ));

  const { currentLocation } = useSelector(store => (
    store.locationStore
  ));

  const { bookingById } = useSelector(store => (
    store.bookingStore
  ));

  const handleProcessFinish = useCallback(() => {
    history.push(`${routes.bookedRooms}/${bookingById.id}`);
  }, [bookingById.id]);

  const booking = useMemo(() => ({
    fromDateTime: times[startTime]?.toISOString(),
    toDateTime: times[endTime] ? times[endTime]?.toISOString() : times[0].add(1, 'day')?.toISOString(),
    meetingRoomId: roomById.id,
    title,
    ...(selectedCompany?.id && { companyId: selectedCompany.id }),
    cost: roomById.cost,
  }), [startTime, endTime, times, roomById.id, roomById.cost, title, selectedCompany?.id, currencyPrice]);

  return (
    <Main
      error={error}
      isWhiteLabelingOpen={false}
      isLoading={isLoading}
    >
      <Flex
        width="100%"
      >
        <Flex
          mr={[20, 30, 40, 50]}
          width="100%"
          maxWidth={['55%', '55%', '55%', 'calc(100% - 530px)']}
          flexGrow={1}
          flexDirection="column"
        >
          <Flex width="fit-content" mb={[15, 20, 25]} onClick={onBackClick} pointer alignItems="center">
            <BackButton mr={['9px', 11, 13]} color={theme.color.gray[500]} SVG={Dropdown} />
            <Typography variant="proximaNova-400">Back</Typography>
          </Flex>
          <Typography mr={['8px', '8px', 10, 12]} variant="garamond-500" fontSize={[28, 32, 34]} mb={25}>
            {`${roomNumber} · ${getSuffixByNumericValue(floor)} floor`}
          </Typography>
          <Flex flexDirection="column" width="100%">
            <RoomDetailsPanel
              creditPrices={creditPrices}
              isActivate={currentLocation?.isWhiteLabeled}
              currencyPrice={currencyPrice}
              creditsPrice={creditsPrice}
              features={features}
              seats={seats}
            />
            <RoomBookingForm
              maxBookingHours={maxBookingHours}
              isEventSpace={isEventSpace}
              bookings={bookings}
              timeZone={isEditing
                ? bookingById.meetingRoom?.location.timeZone : roomById.location?.timeZone}
              timeFormat={isEditing
                ? bookingById.meetingRoom?.location.timeFormat : roomById.location?.timeFormat}
              areBookingsLoading={areBookingsLoading}
              times={times}
              onDateChange={onDateChange}
              onEndTimeChange={onEndTimeChange}
              onStartTimeChange={onStartTimeChange}
              date={date}
              onTimelineChange={onTimelineChange}
              onTimelineClick={onTimelineClick}
              availableTimes={availableTimes}
              endTime={endTime}
              startTime={startTime}
              title={title}
              onTitleChange={onTitleChange}
              isLoading={isLoading}
            />
          </Flex>
        </Flex>
        <ManageBookingPanel
          isEventSpace={isEventSpace}
          onBuyCreditsClick={onBuyCreditsClick}
          onPayNowClick={onPayByCardChangeState}
          endTime={endTime}
          startTime={startTime}
          onEditBooking={onEditBooking}
          isEditing={isEditing}
          companyName={companyName}
          onSubmit={onSubmit}
          roomPhotos={roomPhotos}
          onExternalCompanySelect={onExternalCompanySelect}
          onCompanySelect={onCompanySelect}
          externalCompany={externalCompany}
          selectedCompany={selectedCompany}
          availableTimes={availableTimes}
          times={times}
        />
      </Flex>
      {isPayByCardOpen && (
        <PayByCard
          onProcessFinish={handleProcessFinish}
          onClose={onPayByCardChangeState}
          isOpen={isPayByCardOpen}
          booking={booking}
          isEditing={isEditing}
        />
      )}
    </Main>
  );
};

RoomDetails.defaultProps = {
  creditPrices: {},
  maxBookingHours: 3,
  isEventSpace: false,
  bookings: [],
  roomPhotos: [],
  features: [],
  roomNumber: '',
  floor: 0,
  seats: 0,
  currencyPrice: 1,
  creditsPrice: 1,
  availableTimes: [],
  companyName: '',
  isEditing: false,
  onEditBooking: () => {},
  onCompanySelect: () => {},
  selectedCompany: {
    id: 0,
    name: '',
  },
  isPayByCardOpen: false,
  onPayByCardChangeState: () => {},
  error: {},
  externalCompany: '',
  onExternalCompanySelect: () => {},
};

RoomDetails.propTypes = {
  creditPrices: exact({
    base: exact({
      creditsPerHour: number,
    }),
    peakPrice: exact({
      creditsPerHour: number,
      timeFrames: exact({
        daysOfWeek: number,
        times: arrayOf(exact({
          from: string,
          to: string,
        })),
      }),
    }),
  }),
  maxBookingHours: number,
  externalCompany: string,
  onExternalCompanySelect: func,
  isEventSpace: bool,
  bookings: arrayOf(exact({
    fromDateTime: string,
    toDateTime: string,
    company: string,
  })),
  isPayByCardOpen: bool,
  onPayByCardChangeState: func,
  onBuyCreditsClick: func.isRequired,
  onCompanySelect: func,
  selectedCompany: exact({
    name: string,
    id: number,
  }),
  error: exact({
    status: number,
    message: string,
  }),
  onEditBooking: func,
  onBackClick: func.isRequired,
  areBookingsLoading: bool.isRequired,
  isEditing: bool,
  onSubmit: func.isRequired,
  title: string.isRequired,
  onTitleChange: func.isRequired,
  onTimelineChange: func.isRequired,
  onTimelineClick: func.isRequired,
  times: arrayOf(object).isRequired,
  availableTimes: arrayOf(object),
  roomNumber: string,
  floor: number,
  creditsPrice: number,
  currencyPrice: number,
  seats: number,
  isLoading: bool.isRequired,
  onDateChange: func.isRequired,
  onStartTimeChange: func.isRequired,
  onEndTimeChange: func.isRequired,
  startTime: number.isRequired,
  endTime: number.isRequired,
  date: object.isRequired,
  features: arrayOf(
    exact({
      id: number,
      key: string,
      displayName: string,
      iconUrl: string,
    }),
  ),
  roomPhotos: arrayOf(
    string,
  ),
  companyName: string,
};

export default withStripe(RoomDetails);
