import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { VerificationCode as View } from '@views';
import { routes } from '@constants';
import { login, setActivated } from '@store/user/duck';
import { storage } from '@utils';
import { getAmplifyConfig } from '@utils/amplify';
import { trackEvent } from '@utils/mixpanel';

const VerificationCode = () => {
  window.sessionStorage.setItem('isAnnouncementShown', true);

  const {
    user: { cognitoUser },
  } = useSelector(store => store.userStore);

  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState(
    Array.from({ length: 4 }).fill(),
  );
  const [error, setError] = useState('');

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handlePinPaste = e => {
    const value = e.clipboardData.getData('text');
    const pastedPin = [];

    if (!Number.isNaN(parseInt(value, 10))) {
      Array.from({ length: value.length }).forEach((_, index) => {
        pastedPin[index] = Number(value[index]);
      });

      setVerificationCode(pastedPin);

      if (value.length === 4) {
        handleCodeSubmit(pastedPin);
      }
    }
  };

  useEffect(() => {
    const pageTitle = 'Mindspace: Verification Code';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Verification Code',
    });

    if (!location?.state?.source || !cognitoUser.username) {
      history.push({
        pathname: routes.login,
        state: { redirect: location.state?.redirect },
      });
    }

    const webClientId = location?.state?.source.includes('@')
      ? process.env.REACT_APP_AWS_EMAIL_CLIENT_ID
      : process.env.REACT_APP_AWS_PHONE_CLIENT_ID;

    Auth.configure(getAmplifyConfig(webClientId));

    window.addEventListener('paste', handlePinPaste);

    return () => {
      window.removeEventListener('paste', () => {});
    };
  }, []);

  const handleVerificationCodeChange = (pin, index) => {
    const newCode = [...verificationCode];

    newCode[index] = pin;

    setVerificationCode(newCode);

    if (newCode.every(value => value || value === 0)) {
      handleCodeSubmit(newCode);
    }
  };

  const handleAmplityCodeVerify = async newCode => {
    const sendCustomChallengeAnswerResponse =
      await Auth.sendCustomChallengeAnswer(cognitoUser, newCode.join(''));

    return sendCustomChallengeAnswerResponse?.signInUserSession?.accessToken
      ?.jwtToken;
  };

  const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

  const handleCodeSubmit = async newCode => {
    await wait(250);
    setIsLoading(true);

    const token = await handleAmplityCodeVerify(newCode);

    setIsLoading(false);

    if (!token) {
      setError('*Wrong security code');
    } else {
      storage.setToken(token);
      storage.setAmplifyAuthUser(location?.state?.source);
      dispatch(setActivated(true));
      history.push({
        pathname: location.state?.redirect ?? routes.home,
        state: {
          from: 'login',
        },
      });
    }
  };

  const handleResendClick = async () => {
    dispatch(login({ cognitoUser: await Auth.signIn(location.state.source) }));
  };

  return (
    <View
      isLoading={isLoading}
      error={error}
      codeAddress={location?.state?.source}
      verificationCode={verificationCode}
      onVerificationCodeChange={handleVerificationCodeChange}
      onCodeSubmit={handleCodeSubmit}
      onResendClick={handleResendClick}
    />
  );
};

export default VerificationCode;
