import React from 'react';
import { bool, func, string, exact, number } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Modal, Icon } from '@atoms';
import { Flex, Typography, Button } from '@mixins';
import { routes } from '@constants';
import { theme } from '@styles';
import { ReactComponent as Warning } from '@assets/svgs/Warning.svg';
import { useSelector } from 'react-redux';

const ManageCompanyMembersErrorModal = ({
  isOpen,
  error,
  onModalClose,
}) => {
  const { navigationItems } = useSelector(store => (
    store.metadataStore
  ));

  const history = useHistory();
  const isClientError = Math.floor(error.status / 100) === 4;

  const handleSupportClick = () => {
    const navigationItemGetSupport = navigationItems.find(item => item.key === 'support');

    if (navigationItemGetSupport && navigationItemGetSupport.link) {
      window.open(navigationItemGetSupport.link);
    } else {
      history.push(routes.support);
    }
  };

  const handleModalClose = () => {
    onModalClose();
  };

  return (
    <Modal onClose={onModalClose} topPosition={170} isOpen={isOpen} withCloseButton>
      <Flex alignItems="center" width={640} flexDirection="column" px={[30, 45]} py={15}>
        <Icon width={42} mb="30px" height={42} SVG={Warning} />
        <Typography
          textAlign="center"
          mb={15}
          variant="proximaNova-600"
          fontSize={[16, 18, 20]}
        >
          {error.title ?? 'Something went wrong'}
        </Typography>
        <Typography
          mb={15}
          textAlign="center"
          variant="proximaNova-400"
          fontSize={[12, 14, 16]}
        >
          {isClientError ? error.message : 'Please try again later'}
        </Typography>
        <Flex>
          <Typography mr="5px" fontSize="16px" variant="proximaNova-400">
            Please
          </Typography>
          <Typography
            onClick={handleSupportClick}
            pointer
            textDecoration="underline"
            color={theme.color.darkGreen}
            fontSize="16px"
            variant="proximaNova-400"
          >
            Contact support
          </Typography>
        </Flex>
        <Button onClick={handleModalClose} mt={60} py={12} px={74} variant="primary">
          {error.buttonLabel || 'Try Again'}
        </Button>
      </Flex>
    </Modal>
  );
};

ManageCompanyMembersErrorModal.defaultProps = {
  error: {},
};

ManageCompanyMembersErrorModal.propTypes = {
  isOpen: bool.isRequired,
  onModalClose: func.isRequired,
  error: exact({
    message: string,
    title: string,
    status: number,
    buttonLabel: string,
  }),
};

export default ManageCompanyMembersErrorModal;
