import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { EditCompanyProfile as View } from '@views';
import { routes } from '@constants';
import { SUCCESS, LOADING, FAILURE } from '@constants/requestPhase';
import { COMPANY_ADMIN_ROLE } from '@constants/common';
import { getCompanyById, editCompany, resetPhases as resetCompanyPhases } from '@store/company/duck';
import { getMetadata } from '@store/metadata/duck';
import { useViewport } from '@hooks';

import { ReactComponent as Twitter } from '@assets/svgs/TwitterProfile.svg';
import { ReactComponent as Facebook } from '@assets/svgs/FacebookProfile.svg';
import { ReactComponent as LinkedIn } from '@assets/svgs/LinkedInProfile.svg';
import { ReactComponent as Instagram } from '@assets/svgs/InstagramProfile.svg';
import { trackEvent } from '@utils/mixpanel';

const EditCompanyProfile = () => {
  const { user, getCurrentUserPhase } = useSelector(store => (
    store.userStore
  ));

  const { companyById, editCompanyPhase, getCompanyByIdPhase, editCompanyError } = useSelector(store => (
    store.companyStore
  ));

  const { getMetadataPhase, metadata } = useSelector(store => (
    store.metadataStore
  ));

  const [error, setError] = useState({});
  const [aboutUs, setAboutUs] = useState(companyById.description ?? '');
  const [linkedinLink, setLinkedinLink] = useState(companyById.linkedin ?? '');
  const [instagramLink, setInstagramLink] = useState(companyById.instagram ?? '');
  const [facebookLink, setFacebookLink] = useState(companyById.facebook ?? '');
  const [twitterLink, setTwitterLink] = useState(companyById.twitter ?? '');
  const [industry, setIndustry] = useState(companyById.industry ?? '');
  const [website, setWebsite] = useState(companyById.website ?? '');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [blockedPath, setBlockedPath] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();
  const screenSize = useViewport();
  const { id } = useParams();
  const { state } = useLocation();

  const isAboutUsTheSame = aboutUs === (companyById.description ?? '');
  const isLinkedinTheSame = linkedinLink === (companyById.linkedin ?? '');
  const isInstagramTheSame = instagramLink === (companyById.instagram ?? '');
  const isFacebookTheSame = facebookLink === (companyById.facebook ?? '');
  const isTwitterTheSame = twitterLink === (companyById.twitter ?? '');
  const isIndustryTheSame = industry === (companyById.industry ?? '');
  const isWebsiteTheSame = website === (companyById.website ?? '');
  const isEditable = (user.role === COMPANY_ADMIN_ROLE && user.company?.id === parseInt(id, 10));

  const pageSize = screenSize.width < 1024 ? 4 : 6;

  const handleConfirmationModalChangeState = () => {
    setIsConfirmationModalOpen(prevValue => !prevValue);
  };

  const resetValues = () => {
    setAboutUs(companyById.description ?? '');
    setIndustry(companyById.industry ?? '');
    setLinkedinLink(companyById.linkedin ?? '');
    setInstagramLink(companyById.instagram ?? '');
    setTwitterLink(companyById.twitter ?? '');
    setFacebookLink(companyById.facebook ?? '');
    setWebsite(companyById.website ?? '');
  };

  const unblock = history.block(pathname => {
    if (hasChanges) {
      handleConfirmationModalChangeState();
      setBlockedPath(pathname);
      return false;
    }

    return true;
  });

  useEffect(() => {
    const pageTitle = 'Mindspace: Edit Company Profile';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Company Profile Editing',
    });

    if (!Object.keys(metadata).length) {
      dispatch(getMetadata());
    }

    return () => unblock();
  }, []);

  useEffect(() => {
    if (companyById.id !== parseInt(id, 10)) {
      dispatch(getCompanyById(id, pageSize));
    }
  }, [id]);

  useEffect(() => {
    if (getCompanyByIdPhase === SUCCESS) {
      resetValues();
    }
  }, [getCompanyByIdPhase]);

  useEffect(() => {
    if (editCompanyPhase === SUCCESS) {
      history.push({
        pathname: blockedPath || `${routes.companyProfile}/${id}`,
        ...(state?.from && {
          state: {
            from: state.from,
          },
        }),
      });
      dispatch(resetCompanyPhases());
    }
    if (getCurrentUserPhase === SUCCESS) {
      if (!isEditable) {
        history.goBack();
      }
    }
  }, [editCompanyPhase, getCurrentUserPhase]);

  useEffect(() => {
    if (editCompanyPhase === FAILURE) {
      setError({
        status: editCompanyError.data.error.status,
        message: editCompanyError.data.error.message,
      });
      dispatch(resetCompanyPhases());
    }
  }, [editCompanyPhase]);

  const handleAboutUsSave = text => {
    setAboutUs(text);
  };

  const handleIndustrySave = newIndustry => {
    setIndustry(prevIndustry => {
      if (prevIndustry === newIndustry) {
        return '';
      }
      return newIndustry;
    });
  };

  const handleWebsiteChange = e => {
    setWebsite(e.target.value);
  };

  const handleSave = () => {
    if (isConfirmationModalOpen) {
      handleConfirmationModalChangeState();
    }

    const companyData = {};

    if (!isAboutUsTheSame) {
      companyData.description = aboutUs;
    }
    if (!isLinkedinTheSame) {
      companyData.linkedin = linkedinLink;
    }
    if (!isInstagramTheSame) {
      companyData.instagram = instagramLink;
    }
    if (!isFacebookTheSame) {
      companyData.facebook = facebookLink;
    }
    if (!isTwitterTheSame) {
      companyData.twitter = twitterLink;
    }
    if (!isIndustryTheSame) {
      companyData.industry = industry;
    }
    if (!isWebsiteTheSame) {
      companyData.website = website;
    }

    dispatch(editCompany(id, companyData));
  };

  const handleDiscardChanges = () => {
    resetValues();

    history.block(() => {});

    history.push(blockedPath);
  };

  const handleBackClick = () => {
    history.push({
      pathname: `${routes.companyProfile}/${id}`,
      ...(state?.from && {
        state: {
          from: state.from,
        },
      }),
    });
  };

  const socials = [
    {
      name: 'LinkedIn',
      value: linkedinLink,
      handler: e => { setLinkedinLink(e.target.value); },
      placeholder: 'linkedin.com/username/',
      icon: LinkedIn,
    },
    {
      name: 'Instagram',
      value: instagramLink,
      handler: e => { setInstagramLink(e.target.value); },
      placeholder: 'instagram.com/username/',
      icon: Instagram,
    },
    {
      name: 'Twitter',
      value: twitterLink,
      handler: e => { setTwitterLink(e.target.value); },
      placeholder: 'twitter.com/username/',
      icon: Twitter,
    },
    {
      name: 'Facebook',
      value: facebookLink,
      handler: e => { setFacebookLink(e.target.value); },
      placeholder: 'facebook.com/username/',
      icon: Facebook,
    },
  ];

  const hasChanges = !isAboutUsTheSame || !isWebsiteTheSame || !isLinkedinTheSame
  || !isInstagramTheSame || !isFacebookTheSame || !isTwitterTheSame || !isIndustryTheSame;

  const isLoading = editCompanyPhase === LOADING || getCompanyByIdPhase === LOADING
  || getMetadataPhase === LOADING;

  return (
    <View
      error={error}
      onBackClick={handleBackClick}
      isConfirmationModalOpen={isConfirmationModalOpen}
      onDiscardChanges={handleDiscardChanges}
      hasChanges={hasChanges}
      onSave={handleSave}
      onAboutUsSave={handleAboutUsSave}
      onIndustrySave={handleIndustrySave}
      onWebsiteChange={handleWebsiteChange}
      avatar={user.imageUrl}
      isLoading={isLoading}
      companyName={companyById?.name}
      socials={socials}
      website={website}
      industryList={metadata.industries}
      userIndustry={industry}
      aboutUs={aboutUs}
    />
  );
};

export default EditCompanyProfile;
