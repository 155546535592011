import { ONE_MINUTE_MILLI, ONE_DAY_MILLI, TIMELINE_INTERVAL_MINUTES } from '@constants/common';
import { dayjs } from '@utils';

const formatTime = (time, timeFormat, type, timeZone) => {
  const hours = dayjs(time).tz(timeZone).$H;
  const dateMinutes = dayjs(time).tz(timeZone).$m;
  const minutes = dateMinutes > 9 ? dateMinutes : `0${dateMinutes}`;

  switch (true) {
    case timeFormat === '24h' && type === 'timeline':
      return `${hours > 9 ? hours : `0${hours}`}:${minutes}`;
    case timeFormat === '24h':
      return `${hours}:${minutes}`;
    case type === 'timeline':
      return `${hours % 12 || 12}${minutes === '00' ? '' : `:${minutes}`} ${
        hours >= 12 ? 'PM' : 'AM'
      }`;
    case type === 'title':
      return `${hours % 12 || 12}${minutes === '00' ? '' : `:${minutes}`}${
        hours >= 12 ? 'pm' : 'am'
      }`;
    default:
      return `${hours % 12 || 12}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
  }
};

const format24hTimeTo12h = time => {
  const [hours, minutes] = time.split(':');
  return `${hours % 12 || 12}${minutes === '00' ? '' : `:${minutes}`}${
    hours >= 12 ? 'pm' : 'am'
  }`;
};

const getDateLabel = (date, timeZone, useDateType = false) => {
  switch (true) {
    case !date:
      return 'Date';
    case date.tz(timeZone).$d.toString().substring(0, 15) ===
    dayjs().tz(timeZone).$d.toString().substring(0, 15) && !useDateType:
      return 'Today';
    default: {
      return `${date.tz(timeZone).format('ddd')}, ${date.tz(timeZone).format('MMM')} ${date.tz(timeZone).$D}`;
    }
  }
};

const getTimeList = (date, timeZone) => {
  const startOfDay = dayjs(`${dayjs(date).format('YYYY-MM-DD')}T00:00:00.000`).tz(timeZone);
  const halfOfDay = dayjs(`${dayjs(date).format('YYYY-MM-DD')}T12:00:00.000`).tz(timeZone);

  // This is used for days when the clock changes
  const offsetDifference = halfOfDay.$offset - startOfDay.$offset;

  const fromDateTime = halfOfDay.tz(timeZone).hour(0).minute(0).second(0)
    .add(offsetDifference, 'minute');

  const toDateTime = fromDateTime.tz(timeZone).hour(0).minute(0).second(0)
    .add(1, 'day');

  const length = (toDateTime - fromDateTime) / (TIMELINE_INTERVAL_MINUTES * ONE_MINUTE_MILLI);

  return Array.from({ length }, (_, timeSlotIndex) =>
    fromDateTime
      .add(TIMELINE_INTERVAL_MINUTES * timeSlotIndex, 'minutes')
      .add((dayjs().tz(timeZone).$u ? new Date().getTimezoneOffset() : 0), 'minute'));
};

const getEventsSlotsList = (fromDate, toDate, interval) => {
  const fromDateTime = new Date(fromDate).getTime();
  const toDateTime = (new Date(toDate).getTime() - new Date(fromDate).getTime()) > 0
    ? new Date(toDate).getTime() : new Date(toDate).getTime() + ONE_DAY_MILLI;
  const slotsNumber = (toDateTime - fromDateTime) / (interval * ONE_MINUTE_MILLI);

  return Array.from({ length: slotsNumber + 1 }, (_, timeSlotIndex) =>
    dayjs(fromDate).utc().add(interval * timeSlotIndex, 'minutes').toISOString());
};

const getAvailableTimelines = (timelinesList, bookings, timeZone) => timelinesList.filter((time, index, arr) =>
  bookings.every(booking => {
    const startBookingTime = dayjs(booking.fromDateTime).tz(timeZone);
    const endBookingTime = dayjs(booking.toDateTime).tz(timeZone);

    const startTimeline = time;
    const endTimeline = arr[index + 1] ? arr[index + 1] : arr[0].add(1, 'day');

    return (startBookingTime.$d < startTimeline.$d && endBookingTime.$d <= startTimeline.$d)
      || (endTimeline.$d <= startBookingTime.$d && endTimeline.$d < endBookingTime.$d);
  }));

const getClosestTime = () => {
  const now = dayjs().add(-1, 'hour');
  const currentMinutes = now.$m;
  const closestMinutes = Math.abs(currentMinutes - 30) < 15 ? '30' : '00';

  const closestHour =
    closestMinutes === '00' && currentMinutes - 30 > 0
      ? now.$H + 1
      : now.$H;

  return dayjs().hour(closestHour).minute(closestMinutes).toISOString();
};

const adjustEndTime = (endTime, startTime, times, availableTimes, isActivate) => {
  const startTimeIndex = times.indexOf(startTime);
  const isStartTimeLast = startTime === times[times.length - 1];

  return times[
    (startTimeIndex +
      ((!isStartTimeLast &&
      !endTime &&
      availableTimes.includes(times[(startTimeIndex + 2) % times.length])) || isActivate
        ? 2
        : 1)) %
      times.length
  ];
};

const shouldAdjustEndTime = (endTime, startTime, times, isActivate, isEventSpace) => {
  const startTimeIndex = times.indexOf(startTime);
  const endTimeIndex = times.indexOf(endTime);

  const isEndTimeBeforeStartTime =
    endTime?.$d && startTime?.$d && endTime !== times[0]
    && (startTimeIndex - endTimeIndex >= (isActivate || isEventSpace ? -1 : 0)
    || (isEventSpace && startTimeIndex - endTimeIndex < -6));

  return (!endTime?.$d && startTime?.$d) || isEndTimeBeforeStartTime;
};

export {
  getEventsSlotsList,
  getClosestTime,
  getTimeList,
  formatTime,
  shouldAdjustEndTime,
  adjustEndTime,
  getDateLabel,
  getAvailableTimelines,
  format24hTimeTo12h,
};
