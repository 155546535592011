import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bool, func, number, string, exact } from 'prop-types';

import { Modal, Icon, Loader } from '@atoms';
import { TextInput } from '@atoms/Input';
import { LOADING } from '@constants/requestPhase';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';
import { getCompanyList } from '@store/company/duck';
import { useDebounce } from '@hooks';

import { ReactComponent as Search } from '@assets/svgs/Search.svg';
import { ReactComponent as Checkmark } from '@assets/svgs/Checkmark.svg';
import { ReactComponent as NoResults } from '@assets/svgs/NoResults.svg';

import { ItemBlock, ScrollableContainer } from './styles';

const CompanyListModal = ({
  isOpen,
  selectedCompany,
  onModalClose,
  onCompanySelect,
}) => {
  const dispatch = useDispatch();

  const { companies: { data }, getCompanyListPhase } = useSelector(store => (
    store.companyStore
  ));

  const { currentLocation } = useSelector(store => (
    store.locationStore
  ));

  const [searchInput, setSearchInput] = useState('');
  const [company, setCompany] = useState(selectedCompany);

  const debouncedInput = useDebounce(searchInput, 400);

  const onSearchChange = useCallback(event => {
    setSearchInput(event.target.value);
  }, []);

  const handleCompanySelect = (id, name) => () => {
    setCompany({ id, name });
  };

  const handleDoneClick = () => {
    onCompanySelect(company);
    handleModalClose();
  };

  const handleModalClose = () => {
    setCompany(selectedCompany);
    setSearchInput('');
    onModalClose();
  };

  useEffect(() => {
    dispatch(getCompanyList({
      locationId: currentLocation.id,
      search: debouncedInput,
    }));
  }, [debouncedInput]);

  const itemsListBlock = useMemo(() => data.map(({ id, name }) => (
    <ItemBlock
      fontSize={[14, 16]}
      alignItems="center"
      justifyContent="space-between"
      onClick={handleCompanySelect(id, name)}
      key={id}
      width="100%"
      pointer
      my={12}
    >
      <Typography
        variant="proximaNova-400"
        fontWeight={company.id === id
          ? 600
          : 400}
      >
        {name}
      </Typography>
      {company.id === id && <Icon SVG={Checkmark} />}
    </ItemBlock>
  )), [data, company]);

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <Flex
        height={[500]}
        width={[540]}
        pt={['8px', 12, 16]}
        px={[12, 16, 20]}
        flexGrow={1}
        flexDirection="column"
        alignItems="center"
      >
        <Typography alignSelf="start" mb={[18, 24, 30]} variant="proximaNova-600" fontSize={[16, 18, 20]}>
          Book for Member Company
        </Typography>
        <TextInput
          placeholder="Search companies"
          onChange={onSearchChange}
          value={searchInput}
        >
          <Icon
            mr={9}
            color={theme.color.black}
            pointer
            SVG={Search}
            width={12}
            height={12}
          />
        </TextInput>
        {getCompanyListPhase === LOADING
          ? (
            <Flex alignSelf="center" flexGrow={1}>
              <Loader position="relative" background={theme.color.white} width={40} height={40} />
            </Flex>
          )
          : (
            <ScrollableContainer
              height="100%"
              my={16}
              width="100%"
            >
              {data.length
                ? itemsListBlock
                : (
                  <Flex flexDirection="column" height="100%" alignItems="center" justifyContent="center">
                    <Icon
                      SVG={NoResults}
                      width="45"
                      height="45"
                    />
                    <Typography variant="proximaNova-400">No results</Typography>
                  </Flex>
                )}
            </ScrollableContainer>
          )}
        <Flex minWidth="100%" height="1px" backgroundColor={theme.color.gray[100]} />
        <Button onClick={handleDoneClick} mt={15} variant="primary" px={92} py={12}>Done</Button>
      </Flex>
    </Modal>
  );
};

CompanyListModal.defaultProps = {
  selectedCompany: {},
};

CompanyListModal.propTypes = {
  selectedCompany: exact({
    description: string,
    id: number,
    industry: string,
    logoUrl: string,
    name: string,
  }),
  isOpen: bool.isRequired,
  onModalClose: func.isRequired,
  onCompanySelect: func.isRequired,
};

export default CompanyListModal;
