import { apiEndpoints } from '@constants';
import { client } from '@utils';

const getCreditsPackages = () => (
  client.authorized.get(apiEndpoints.paymentPackages)
);

const invoiceMe = data => (
  client.authorized.post(apiEndpoints.paymentInvoice, data)
);

const initPaymentMethod = () => (
  client.authorized.get(apiEndpoints.initPaymentMethod)
);

const addPaymentMethod = data => (
  client.authorized.post(apiEndpoints.paymentMethod, data)
);

const buyCredits = data => (
  client.authorized.post(apiEndpoints.initBuyCredits, data)
);

const buyCreditsConfirm = data => (
  client.authorized.post(apiEndpoints.buyCredits, data)
);

const editPaymentMethod = (id, data) => (
  client.authorized.patch(`${apiEndpoints.paymentMethod}/${id}`, data)
);

const removePaymentMethod = id => (
  client.authorized.delete(`${apiEndpoints.paymentMethod}/${id}`)
);

const getPaymentMethods = data => (
  client.authorized.get(apiEndpoints.paymentMethod, data)
);

const getPaymentMethodById = id => (
  client.authorized.get(`${apiEndpoints.paymentMethod}/${id}`)
);

export {
  buyCreditsConfirm,
  buyCredits,
  getCreditsPackages,
  invoiceMe,
  initPaymentMethod,
  addPaymentMethod,
  editPaymentMethod,
  getPaymentMethods,
  removePaymentMethod,
  getPaymentMethodById,
};

