import React from 'react';

import { Icon } from '@atoms';
import { Typography, Flex, Button } from '@mixins';

import { ReactComponent as ReferFriendIcon } from '@assets/svgs/ReferFriend.svg';
import { theme } from '@styles';

const ReferFriend = () => {
  const onReferFriend = () => {
    window.open('https://hs.mindspace.me/en/mindspace-refer-a-friend-2023#contact');
  };

  return (
    <Flex
      mb={[60, 100, 140]}
      border="1px solid #D9D9D9"
      py={[25, 35, 45]}
      px={[30, 60, 90]}
      alignItems="center"
      justifyContent="space-between"
      width={[360, 600, 800, 990]}
      height={[120, 185, 230, 280]}
    >
      <Flex flexDirection="column" alignItems="start">
        <Typography mb={['8px', 20]} variant="garamond-500" fontSize={[20, 32, 40]}>
          It pays to have friends!
        </Typography>
        <Typography mb={['8px', 20]} color={theme.color.gray[500]} variant="proximaNova-600" fontSize={[10, 16, 20]}>
          Earn from $500 to $3,500
        </Typography>
        <Button fontSize={[10, 13, 16]} onClick={onReferFriend} variant="primary" py={['6px', 12]} px={[15, 30]}>
          Refer a Friend
        </Button>
      </Flex>
      <Icon width={[90, 140, 190]} SVG={ReferFriendIcon} />
    </Flex>
  );
};

export default ReferFriend;
