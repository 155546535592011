import styled, { css } from 'styled-components';
import { Flex } from '@mixins';
import { theme } from '@styles';

export const ScrollableContainer = styled(Flex)`
${() => css`
    flex-direction: column;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 3px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: ${theme.color.gray[100]};
    }
  `}
`;

export const ItemBlock = styled(Flex)`
  ${() => css`
    p {
      &:hover {
        font-weight: 600;
      }
    }
  `}
`;
