import React from 'react';
import { Calendar } from 'react-calendar';
import { instanceOf, func, string } from 'prop-types';
import dayjs from 'dayjs';

import { dayjs as dayjsUtil } from '@utils';
import { theme } from '@styles';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import { CalendarContainer, PrevIcon, NextIcon } from './styles';

const DatePicker = ({ selectedDate, onDateSelect, type }) => (
  <CalendarContainer type={type}>
    <Calendar
      calendarType="US"
      showNeighboringMonth={false}
      formatMonthYear={(locale, calendarDate) => dayjs(calendarDate).format('MMM YYYY')}
      formatMonth={(locale, calendarDate) => dayjs(calendarDate).format('MMM')}
      value={selectedDate}
      onClickDay={onDateSelect}
      onClickMonth={onDateSelect}
      prevLabel={(
        <PrevIcon SVG={Dropdown} color={theme.color.black} height="12px" />
      )}
      nextLabel={(
        <NextIcon SVG={Dropdown} color={theme.color.black} height="12px" />
      )}
      {...(type === 'month' && { minDate: dayjsUtil().$d })}
      {...(type === 'year' && { maxDate: dayjsUtil().$d })}
      minDetail={type}
      maxDetail={type}
      defaultView={type}
      locale="en"
    />
  </CalendarContainer>
);

DatePicker.defaultProps = {
  selectedDate: dayjsUtil().$d,
};

DatePicker.propTypes = {
  selectedDate: instanceOf(Date),
  onDateSelect: func.isRequired,
  type: string.isRequired,
};

export default DatePicker;
