import React, { useMemo } from 'react';
import { arrayOf, exact, string, number } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Avatar } from '@atoms';
import { Flex, Typography } from '@mixins';
import { routes } from '@constants';

const LocationTeam = ({
  team,
  locationName,
}) => {
  const history = useHistory();

  const onTeamMemberClick = id => () => {
    history.push({ pathname: `${routes.userProfile}/${id}`, state: { from: routes.home } });
  };

  const teamBlock = useMemo(() => team.map(({ id, firstName, lastName, jobTitle, imageUrl }) => (
    <Flex
      pointer
      onClick={onTeamMemberClick(id)}
      width={150}
      flexDirection="column"
      alignItems="center"
      key={id}
      mx={[20, 25]}
      my={[20, 25]}
    >
      <Avatar
        firstName={firstName}
        lastName={lastName}
        width={[150]}
        height={[150]}
        imageUrl={imageUrl}
        fontSize={['60px']}
      />
      <Typography mt={18} fontSize="14px" variant="proximaNova-600">
        {`${firstName}  ${lastName}`}
      </Typography>
      <Typography mt="4px" fontSize="14px" variant="proximaNova-400" textAlign="center">
        {jobTitle}
      </Typography>
    </Flex>
  )), [team]);

  return (
    <Flex pb={40} px={10} flexDirection="column" alignItems="center">
      <Typography textAlign="center" variant="garamond-500" fontSize={['46px', '52px']} mb={15}>
        We’ve got your back
      </Typography>
      <Typography textAlign="center" variant="proximaNova-400" fontSize={['14px', '16px']} mb={25}>
        {`Community Team at ${locationName}`}
      </Typography>
      <Flex flexWrap="wrap" maxWidth={850} justifyContent="center">
        {teamBlock}
      </Flex>
    </Flex>
  );
};

LocationTeam.propTypes = {
  team: arrayOf(exact({
    id: number,
    firstName: string,
    lastName: string,
    jobTitle: string,
    role: string,
    imageUrl: string,
  })).isRequired,
  locationName: string.isRequired,
};

export default LocationTeam;
