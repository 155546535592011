import styled, { css } from 'styled-components';

import { Flex } from '@mixins';
import { theme } from '@styles';

export const SocialBlock = styled(Flex)`
  ${({ disabled, isCompany }) => css`
    p {
      color: ${disabled ? theme.color.gray[200] : theme.color.gray[500]};
    }

    ${!disabled
      && `&:hover {
        p {
          color: ${isCompany ? theme.color.darkRed : theme.color.darkGreen};
        }
      }`
}
  `}
`;
