import styled, { css } from 'styled-components';

import { Flex } from '@mixins';
import { theme } from '@styles';

export const EditButton = styled(Flex)`
  ${() => css`
    &:hover {
      border: 1px solid ${theme.color.gray[100]};
    }
  `}
`;
