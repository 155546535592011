import React, { useRef } from 'react';
import { string, bool, func } from 'prop-types';

import { Modal } from '@atoms';
import Input from '@atoms/Input/styles';
import { Flex, Typography, Button } from '@mixins';
import { theme } from '@styles';
import { getBackground } from '@helpers/common';

const PhotoModal = ({
  isOpen,
  onModalClose,
  image,
  isCompany,
  firstName,
  lastName,
  onImageUpload,
  isPhotoUploaded,
  onImageSave,
}) => {
  const fileRef = useRef(null);

  const onButtonClick = () => {
    fileRef.current.click();
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <Flex
        pt={10}
        pb={20}
        px={[80, 120, 150]}
        flexDirection="column"
        alignItems="center"
      >
        <Typography mb={[10, 15, 20]} variant="proximaNova-600" fontSize={[12, 16, 20]}>
          {`${image ? 'Change' : 'Add'} ${isCompany ? 'Company Logo' : 'Profile Picture'}`}
        </Typography>
        <Flex
          width={[100, 150, 180]}
          height={[100, 150, 180]}
          justifyContent="center"
          alignItems="center"
          position="relative"
          background={getBackground(isCompany, image)}
          backgroundSize="cover"
          mb={[15, 20, 25]}
        >
          {!image && !isCompany && (
            <Typography variant="garamond-500" fontSize={[30, 35, 42]} color={theme.color.darkRed}>
              {firstName && lastName ? `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}` : ''}
            </Typography>
          )}
        </Flex>
        <Input
          accept=".png, .jpg, .gif"
          display="none"
          ref={fileRef}
          type="file"
          onChange={onImageUpload}
        />
        {isPhotoUploaded
          ? (
            <Flex flexDirection="column" width={[150, 175, 215]}>
              <Button
                onClick={onImageSave}
                py={['8px', 10, 12]}
                variant="primary"
                fontSize={[12, 14, 16]}
                mb={18}
              >
                Done
              </Button>
              <Button
                border={0}
                backgroundColor="#EFECE6"
                onClick={onButtonClick}
                py={['8px', 10, 12]}
                fontSize={[12, 14, 16]}
              >
                Try another
                {isCompany ? ' image' : ' picture'}
              </Button>
            </Flex>
          )
          : (
            <>
              <Typography
                mb={[15, 20, 25]}
                textAlign="center"
                fontSize={[10, 12, 14]}
                color={theme.color.gray[200]}
                variant="proximaNova-400"
              >
                Upload a
                {isCompany ? ' square logo image ' : ' photo '}
                from your computer.  Use JPG, GIF or PNG. Max size of 800K
              </Typography>
              <Button
                onClick={onButtonClick}
                py={['8px', 10, 12]}
                px={[40, 50, 60]}
                variant="primary"
                fontSize={[12, 14, 16]}
              >
                Upload
                {isCompany ? ' image' : ' photo'}
              </Button>
            </>
          )}
      </Flex>
    </Modal>
  );
};

PhotoModal.defaultProps = {
  image: '',
  firstName: '',
  lastName: '',
  onImageSave: () => {},
};

PhotoModal.propTypes = {
  onImageSave: func,
  isPhotoUploaded: bool.isRequired,
  isOpen: bool.isRequired,
  onModalClose: func.isRequired,
  onImageUpload: func.isRequired,
  image: string,
  isCompany: bool.isRequired,
  firstName: string,
  lastName: string,
};

export default PhotoModal;
