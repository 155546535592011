import React from 'react';
import { string, bool, func, number, exact } from 'prop-types';

import { BenefitsFilter } from '@organisms';
import { Main } from '@templates';
import { Typography } from '@mixins';
import { theme } from '@styles';

const Benefits = ({
  isLoading,
  city,
  isActivated,
  searchText,
  onSearchTextChange,
  selectedCategory,
  onSelectedCategoryChange,
  error,
}) => (
  <Main
    isWhiteLabelingOpen={false}
    isLoading={isLoading}
    error={error}
  >
    <Typography alignSelf="start" variant="garamond-500" fontSize={[30, 34]} mb={[20, 30]}>
      Benefits
      {!isActivated && (
        <Typography
          ml={10}
          as="span"
          variant="proximaNova-400"
          fontSize={16}
          color={theme.color.gray[300]}
        >
          {`in ${city}`}
        </Typography>
      )}
    </Typography>
    <BenefitsFilter
      searchText={searchText}
      onSearchTextChange={onSearchTextChange}
      selectedCategory={selectedCategory}
      onSelectedCategoryChange={onSelectedCategoryChange}
    />
  </Main>
);

Benefits.defaultProps = {
  city: '',
  isActivated: false,
  error: {},
};

Benefits.propTypes = {
  error: exact({
    status: number,
    message: string,
  }),
  isLoading: bool.isRequired,
  searchText: string.isRequired,
  onSearchTextChange: func.isRequired,
  selectedCategory: number.isRequired,
  onSelectedCategoryChange: func.isRequired,
  city: string,
  isActivated: bool,
};

export default Benefits;
