import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Support as View } from '@views';
import { getMetadata } from '@store/metadata/duck';
import { LOADING } from '@constants/requestPhase';
import { trackEvent } from '@utils/mixpanel';

const Support = () => {
  const { sendSupportTicketPhase } = useSelector(store => (
    store.userStore
  ));

  const { getMetadataPhase, metadata } = useSelector(store => (
    store.metadataStore
  ));

  const dispatch = useDispatch();

  useEffect(() => {
    const pageTitle = 'Mindspace: Support';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Support',
    });

    if (!Object.keys(metadata).length) {
      dispatch(getMetadata());
    }
  }, []);

  const isLoading = getMetadataPhase === LOADING || sendSupportTicketPhase === LOADING;

  return (
    <View
      isLoading={isLoading}
      topics={metadata.supportTopics}
    />
  );
};

export default Support;
