import React, { useMemo, useCallback } from 'react';
import { arrayOf, string, exact, number } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Icon } from '@atoms';
import { Typography, Flex, Button } from '@mixins';
import { routes } from '@constants';
import { Main } from '@templates';
import { theme } from '@styles';

import AmenitiesImage from '@assets/images/Amenities.jpg';
import { ReactComponent as QuestionMark } from '@assets/svgs/QuestionMark.svg';
import { ReactComponent as Kitchen } from '@assets/svgs/Kitchen.svg';
import { useSelector } from 'react-redux';

const BuildingAmenities = ({
  amenities,
}) => {
  const history = useHistory();
  const { navigationItems } = useSelector(store => (
    store.metadataStore
  ));

  const handleSupportClick = useCallback(() => {
    const navigationItemGetSupport = navigationItems.find(item => item.key === 'support');

    if (navigationItemGetSupport && navigationItemGetSupport.link) {
      window.open(navigationItemGetSupport.link);
    } else {
      history.push(routes.support);
    }
  }, []);

  const amenitiesBlock = useMemo(() => amenities.map(({
    amenity: { id, displayName, iconUrl }, description,
  }) => (
    <Flex
      px={[15, 20, 25]}
      pb={[12, 16, 20]}
      mb={[10, 15, 20]}
      borderBottom="1px solid #EFECE6"
      width="49%"
      key={id}
      alignItems="start"
    >
      <svg width="20" height="20">
        <image
          xlinkHref={iconUrl || Kitchen}
          width="20"
          height="20"
        />
      </svg>
      <Flex ml={['7px', 11, 15]} flexDirection="column">
        <Typography mb="5px" variant="proximaNova-400" fontSize={[12, 14, 16]}>
          {displayName}
        </Typography>
        <Typography variant="proximaNova-400" fontSize={[10, 12, 14]} color={theme.color.gray[300]}>
          {description}
        </Typography>
      </Flex>
    </Flex>
  )), [amenities]);

  return (
    <Main
      isWhiteLabelingOpen={false}
    >
      <Flex
        width={['100%', '100%', '85%', '100%']}
        alignSelf={['start', 'start', 'start', 'center']}
        flexDirection="column"
        maxWidth="1320px"
      >
        <Typography mb={[20, 30]} variant="garamond-500" fontSize={34}>
          Building Amenities
        </Typography>
        <Flex width="100%" flexDirection={['column', 'column', 'column', 'row']}>
          {!!amenitiesBlock.length && (
            <Flex
              justifyContent="space-between"
              mr={[15, 20, 25]}
              height="fit-content"
              flexWrap="wrap"
              width={['100%', '100%', '100%', '55%']}
            >
              {amenitiesBlock}
            </Flex>
          )}
          <Flex
            height="fit-content"
            flexDirection="column"
            border="1px solid #D9D9D9"
            width={['100%', '100%', '100%', '43%']}
            p={[15, 17, 23, 25]}
          >
            <Typography mb={[15, 20, 30]} variant="garamond-500" fontSize={[20, 26, 32]}>
              We’re here for you
            </Typography>
            <Typography mb={[15, 20, 30]} variant="proximaNova-400" fontSize={[10, 12, 16]}>
              {`Need anything from the community team? Any issues with 
              something in your building? Don’t hesitate to reach out!`}
            </Typography>
            <Button
              width="fit-content"
              onClick={handleSupportClick}
              px={['35px', '50px', '75px']}
              py={['8px', '10px', '12px']}
              variant="primary"
              mb={[15, 20, 30]}
            >
              <Flex fontSize={[10, 14, 16]} alignItems="center">
                <Icon color={theme.color.white} mr={['6px', '8px', '10px']} SVG={QuestionMark} />
                Get Support
              </Flex>
            </Button>
            <Flex
              width="100%"
              height={['240px', '270px', '300px', '250']}
              background={`url(${AmenitiesImage}) no-repeat top`}
              backgroundSize="cover"
            />
          </Flex>
        </Flex>
      </Flex>
    </Main>
  );
};

BuildingAmenities.defaultProps = {
  amenities: [],
};

BuildingAmenities.propTypes = {
  amenities: arrayOf(
    exact({
      amenity: exact({
        id: number,
        key: string,
        displayName: string,
      }),
      description: string,
    }),
  ),

};

export default BuildingAmenities;
