import axios from 'axios';

import { common } from '@constants';
import { storage } from '@utils';
import { refreshToken } from '@utils/amplify';
import { reportError } from './sentry';

const options = {
  ...(process.env.REACT_APP_ENV === 'stage' && { withCredentials: true }),
  baseURL: common.BASE_URL,
};

const tokenHeaderInterceptor = config => {
  const token = storage.getToken();

  const configWithTokenHeader = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token || ''}`,
    },
  };

  return configWithTokenHeader;
};

const successInterceptor = response => response;

const errorInterceptor = error => {
  const { status } = error.response;

  const userId = storage.getUserId();
  const token = storage.getToken();

  if (Math.floor(status / 100) === 5) {
    reportError(
      `Request failed (${error.response.config.method}/ ${error.response.config.url})`,
      'userId',
      userId,
      error.response.config.data ? JSON.parse(error.response.config.data) : {},
    );
  }

  if (status === common.UNAUTHORIZED && token) {
    refreshToken();
  }

  return Promise.reject(error);
};

const authorized = axios.create(options);
authorized.interceptors.request.use(tokenHeaderInterceptor);
authorized.interceptors.response.use(successInterceptor, errorInterceptor);

export default {
  authorized,
};
