import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Icon, Loader } from '@atoms';
import { TextInput } from '@atoms/Input';
import { LocationDropdown } from '@molecules';
import { Flex, Typography } from '@mixins';
import { useOutsideClick, useDebounce } from '@hooks';
import { LOADING } from '@constants/requestPhase';
import { HEADER_ANNOUNCEMENT_KEY } from '@constants/common';
import { theme } from '@styles';
import { getLocations, getCurrentLocation } from '@store/location/duck';
import { trackEvent } from '@utils/mixpanel';

import { ReactComponent as Close } from '@assets/svgs/Close.svg';
import { ReactComponent as Search } from '@assets/svgs/Search.svg';
import { ReactComponent as Location } from '@assets/svgs/Location.svg';
import { ReactComponent as NoLocations } from '@assets/svgs/NoLocations.svg';
import { storage } from '@utils';

import { ItemTitle, ScrollableContainer } from './styles';

const LocationChanger = ({ currentLocation }) => {
  const locationsRef = useRef();
  const buttonRef = useRef();
  const textRef = useRef();

  const headerAnnouncement = storage.getFromLocalStorage(
    HEADER_ANNOUNCEMENT_KEY,
  );
  const parsedHeaderAnnouncement = JSON.parse(headerAnnouncement);

  const {
    currentLocation: location,
    locations,
    getLocationsPhase,
  } = useSelector(store => store.locationStore);

  const { isOpen, setIsOpen } = useOutsideClick(false, [
    locationsRef,
    buttonRef,
    textRef,
  ]);
  const [searchInput, setSearchInput] = useState('');

  const debouncedInput = useDebounce(searchInput, 300);
  const dispatch = useDispatch();

  const onSearchChange = useCallback(event => {
    setSearchInput(event.target.value);
  }, []);

  const handleStatusOpenChange = useCallback(() => {
    if (!location.isMindspace) return;
    setSearchInput('');
    setIsOpen(prevValue => !prevValue);
  }, [location]);

  const handleLocationClick = id => () => {
    setIsOpen(false);

    trackEvent('Location changed', {
      scope: 'Locations',
      locationId: id,
    });

    if (location.id !== id) {
      dispatch(getCurrentLocation(id));
      storage.setToLocalStorage(
        HEADER_ANNOUNCEMENT_KEY,
        JSON.stringify({
          id: parsedHeaderAnnouncement?.id,
          locationId: null,
        }),
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getLocations({ search: debouncedInput }));
    }
  }, [debouncedInput, isOpen]);

  const items = useMemo(
    () =>
      locations.map(country => (
        <LocationDropdown
          key={country.name}
          title={country.name}
          showTitle={!debouncedInput}
        >
          <Flex
            alignSelf="start"
            px={!debouncedInput ? 20 : 0}
            flexDirection="column"
          >
            {country.cities.map((city, index) => (
              <Flex
                key={city.name}
                flexDirection="column"
                mt={index || !debouncedInput ? 20 : 0}
              >
                <Typography
                  variant="proximaNova-600"
                  color={theme.color.gray[200]}
                >
                  {city.name}
                </Typography>
                <Flex pl={15} flexDirection="column">
                  {city.locations.map(({ id, name }) => (
                    <ItemTitle
                      key={id}
                      onClick={handleLocationClick(id)}
                      variant={
                        location.id === id
                          ? 'proximaNova-600'
                          : 'proximaNova-400'
                      }
                      mt={16}
                      pointer
                    >
                      {name}
                    </ItemTitle>
                  ))}
                </Flex>
              </Flex>
            ))}
          </Flex>
        </LocationDropdown>
      )),
    [locations, location],
  );

  return (
    <>
      <Flex alignItems="center">
        <Icon
          ref={buttonRef}
          onClick={handleStatusOpenChange}
          pointer={location.isMindspace}
          ml={['8px', 15, 24]}
          SVG={Location}
          width={[6, 10]}
          height={[8, 12]}
        />
        <Typography
          ref={textRef}
          onClick={handleStatusOpenChange}
          pointer={location.isMindspace}
          pl={['5px', '7px']}
          variant="proximaNova-400"
          fontSize={['8px', '12px', '16px']}
        >
          {currentLocation.includes('Mindspace ')
            ? currentLocation.split('Mindspace ')[1]
            : currentLocation}
        </Typography>
      </Flex>
      {isOpen && (
        <Flex
          ref={locationsRef}
          zIndex={9}
          flexDirection="column"
          position="absolute"
          top={[35, 47, 60]}
          left={[92, 196, 260]}
          width={[300, 390]}
          height={[330, 335, 340]}
          backgroundColor={theme.color.white}
          boxShadow="0px 2px 2px rgba(0, 0, 0, 0.25)"
          isExpanded={isOpen}
        >
          <Icon
            pointer
            top={[23, 23, 27, 31]}
            right={17}
            position="absolute"
            SVG={Close}
            width={[9, 10]}
            height={[9, 10]}
            onClick={handleStatusOpenChange}
          />
          <Flex
            borderRadius="4px 4px 0px 0px"
            p="16px 43px 16px 20px"
            backgroundColor="#EFECE6"
          >
            <TextInput
              placeholder="Search Locations..."
              onChange={onSearchChange}
              value={searchInput}
            >
              <Icon
                mr={9}
                color={theme.color.black}
                pointer
                SVG={Search}
                width={18}
                height={18}
              />
            </TextInput>
          </Flex>
          {getLocationsPhase === LOADING ? (
            <Loader
              background="none"
              width={50}
              height={50}
              position="absolute"
            />
          ) : (
            <ScrollableContainer m="6px" px={15} pt={15}>
              {items.length ? (
                items
              ) : (
                <Flex
                  pt={60}
                  flexDirection="column"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon
                    SVG={NoLocations}
                    width={35}
                    height={42}
                    color={theme.color.darkGreen}
                    mb={30}
                  />
                  <Typography variant="proximaNova-400">No results</Typography>
                </Flex>
              )}
            </ScrollableContainer>
          )}
        </Flex>
      )}
    </>
  );
};

LocationChanger.defaultProps = {
  currentLocation: '',
};

LocationChanger.propTypes = {
  currentLocation: string,
};

export default LocationChanger;
