import React from 'react';
import { node, string, bool, func } from 'prop-types';
import { Loader } from '@atoms';
import { Footer } from '@molecules';
import { Flex, Typography } from '@mixins';
import AuthBackground from '@assets/images/AuthBackground.jpg';
import { theme } from '@styles';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { PrevButton } from '@molecules/BenefitsCarousel/styles';

const Auth = ({
  children, title, text, isLoading, hasBackButton, onBackClick,
}) => {
  const onContactSupportClick = () => {
    const to = 'app@mindspace.me';
    const subject = 'Web App Support Request';

    window.location.href = `mailto:${to}?subject=${subject}`;
  };

  return (
    <>
      <Flex
        position="relative"
        flexDirection="column"
        pb={[185, 345, 295]}
        pt={[40, 60]}
        alignItems="center"
        minHeight="100vh"
        background={
          `linear-gradient(0.46deg, rgba(0, 0, 0, 0.8) 7.26%, rgba(0, 0, 0, 0) 99.61%), 
        url(${AuthBackground}) no-repeat center center fixed`
        }
        backgroundSize="cover"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          p={['30px', '35px']}
          mb={['20px', '40px']}
          backgroundColor={theme.color.white}
          width={['85%', '450px']}
          height={['480px']}
        >
          {hasBackButton && (
            <PrevButton
              alignSelf="start"
              color={theme.color.black}
              width={15}
              height={15}
              SVG={Dropdown}
              pointer
              onClick={onBackClick}
            />
          )}
          <Typography mb="20px" variant="garamond-500" fontSize={['46px', '60px']}>
            {title}
          </Typography>
          <Typography
            textAlign="center"
            color={theme.color.gray[300]}
            variant="proximaNova-400"
            fontSize="16px"
            mb="30px"
          >
            {text}
          </Typography>
          {children}
        </Flex>
        <Flex flexDirection="column">
          <Typography
            color={theme.color.white}
            fontSize={['12px', '14px']}
            variant="proximaNova-400"
            width="100%"
            mb="6px"
          >
            Trouble logging in?
            <Typography
              as="span"
              fontSize={['12px', '14px']}
              variant="proximaNova-600"
              onClick={onContactSupportClick}
              color={theme.color.white}
              pointer
            >
              {' '}
              Contact Support
            </Typography>
          </Typography>
        </Flex>
        <Footer />
      </Flex>
      {isLoading && <Loader position="fixed" background={theme.color.white} width={64} height={64} />}
    </>
  );
};

Auth.defaultProps = {
  hasBackButton: false,
  onBackClick: () => {},
};

Auth.propTypes = {
  hasBackButton: bool,
  onBackClick: func,
  children: node.isRequired,
  title: string.isRequired,
  text: string.isRequired,
  isLoading: bool.isRequired,
};

export default Auth;
