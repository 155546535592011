import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';
import * as api from './api';

// < ----- ACTIONS ----- > //
const resetPhasesAction = 'RESET_PAYMENT_PHASES';
const resetStoreToInitialStateAction = 'RESET_STORE';
const getCreditsPackagesRoutine = createRoutine(
  'GET_CREDITS_PACKAGES',
);

const invoiceMeRoutine = createRoutine(
  'INVOICE_ME',
);

const buyCreditsRoutine = createRoutine(
  'BUY_CREDITS',
);

const initPaymentMethodRoutine = createRoutine(
  'INIT_PAYMENT_METHOD',
);

const addPaymentMethodRoutine = createRoutine(
  'ADD_PAYMENT_METHOD',
);

const editPaymentMethodRoutine = createRoutine(
  'EDIT_PAYMENT_METHOD',
);

const removePaymentMethodRoutine = createRoutine(
  'REMOVE_PAYMENT_METHOD',
);

const getPaymentMethodsRoutine = createRoutine(
  'GET_PAYMENT_METHODS',
);

const getPaymentMethodByIdRoutine = createRoutine(
  'GET_PAYMENT_METHOD_BY_ID',
);

const buyCreditsConfirmRoutine = createRoutine(
  'BUT_CREDITS_CONFIRM',
);

const increasePurchasedCreditsAction = 'INCREASE_PURCHASED_CREDITS';

// < ----- ACTION CREATORS ----- > //
export const getCreditsPackages = () => async dispatch => {
  try {
    dispatch(getCreditsPackagesRoutine.request());

    const response = await api.getCreditsPackages();

    return dispatch(getCreditsPackagesRoutine.success(response.data));
  } catch (error) {
    return dispatch(getCreditsPackagesRoutine.failure(error.response));
  }
};

export const invoiceMe = data => async dispatch => {
  try {
    dispatch(invoiceMeRoutine.request());

    await api.invoiceMe(data);

    return dispatch(invoiceMeRoutine.success(data));
  } catch (error) {
    return dispatch(invoiceMeRoutine.failure(error.response));
  }
};

export const buyCredits = data => async dispatch => {
  try {
    dispatch(buyCreditsRoutine.request());

    const response = await api.buyCredits(data);

    return dispatch(buyCreditsRoutine.success(response.data));
  } catch (error) {
    return dispatch(buyCreditsRoutine.failure(error.response));
  }
};

export const initPaymentMethod = () => async dispatch => {
  try {
    dispatch(initPaymentMethodRoutine.request());

    const response = await api.initPaymentMethod();

    return dispatch(initPaymentMethodRoutine.success(response.data));
  } catch (error) {
    return dispatch(initPaymentMethodRoutine.failure(error.response));
  }
};

export const getPaymentMethods = () => async dispatch => {
  try {
    dispatch(getPaymentMethodsRoutine.request());

    const response = await api.getPaymentMethods();

    return dispatch(getPaymentMethodsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getPaymentMethodsRoutine.failure(error.response));
  }
};

export const getPaymentMethodById = id => async dispatch => {
  try {
    dispatch(getPaymentMethodByIdRoutine.request());

    const response = await api.getPaymentMethodById(id);

    return dispatch(getPaymentMethodByIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getPaymentMethodByIdRoutine.failure(error.response));
  }
};

export const editPaymentMethod = (id, data) => async dispatch => {
  try {
    dispatch(editPaymentMethodRoutine.request());

    await api.editPaymentMethod(id, data);

    return dispatch(editPaymentMethodRoutine.success());
  } catch (error) {
    return dispatch(editPaymentMethodRoutine.failure(error.response));
  }
};

export const addPaymentMethod = data => async dispatch => {
  try {
    dispatch(addPaymentMethodRoutine.request());

    const response = await api.addPaymentMethod(data);

    return dispatch(addPaymentMethodRoutine.success(response.data));
  } catch (error) {
    return dispatch(addPaymentMethodRoutine.failure(error.response));
  }
};

export const removePaymentMethod = id => async dispatch => {
  try {
    dispatch(removePaymentMethodRoutine.request());

    const response = await api.removePaymentMethod(id);

    return dispatch(removePaymentMethodRoutine.success(response.data));
  } catch (error) {
    return dispatch(removePaymentMethodRoutine.failure(error.response));
  }
};

export const buyCreditsConfirm = data => async dispatch => {
  try {
    dispatch(buyCreditsConfirmRoutine.request());

    await api.buyCreditsConfirm(data);

    return dispatch(buyCreditsConfirmRoutine.success());
  } catch (error) {
    return dispatch(buyCreditsConfirmRoutine.failure(error.response));
  }
};

export const resetStore = () => ({
  type: resetStoreToInitialStateAction,
});

export const resetPhases = () => ({
  type: resetPhasesAction,
});

export const increasePurchasedCredits = credits => ({
  type: increasePurchasedCreditsAction,
  payload: credits,
});

// < ----- STATE ----- > //
export const companyStorePersistWhitelist = [];

const initialState = {
  packages: [],
  paymentMethods: [],
  paymentMethodById: {},
  alreadyPurchasedCredits: 0,
  maxCreditsPerMonth: 0,
  vat: 0,
  stripeKeys: {},
  paymentInfo: {},

  getCreditsPackagesPhase: INIT,
  getCreditsPackagesError: null,

  invoiceMePhase: INIT,
  invoiceMeError: null,

  initPaymentMethodPhase: INIT,
  initPaymentMethodError: null,

  addPaymentMethodPhase: INIT,
  addPaymentMethodError: null,

  editPaymentMethodPhase: INIT,
  editPaymentMethodError: null,

  removePaymentMethodPhase: INIT,
  removePaymentMethodError: null,

  getPaymentMethodsPhase: INIT,
  getPaymentMethodsError: null,

  buyCreditsPhase: INIT,
  buyCreditsError: null,

  buyCreditsConfirmPhase: INIT,
  buyCreditsConfirmError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;
      // RESET PHASES
    case resetPhasesAction:
      return {
        ...state,

        getCreditsPackagesPhase: INIT,
        getCreditsPackagesError: null,

        invoiceMePhase: INIT,
        invoiceMeError: null,

        initPaymentMethodPhase: INIT,
        initPaymentMethodError: null,

        addPaymentMethodPhase: INIT,
        addPaymentMethodError: null,

        removePaymentMethodPhase: INIT,
        removePaymentMethodError: null,

        buyCreditsConfirmPhase: INIT,
        buyCreditsConfirmError: FAILURE,

        buyCreditsPhase: INIT,
        buyCreditsError: FAILURE,

        getPaymentMethodByIdPhase: INIT,
        getPaymentMethodByIdError: FAILURE,
      };
      // INCREASE PURCHASED CREDITS
    case increasePurchasedCreditsAction:
      return {
        ...state,
        alreadyPurchasedCredits: state.alreadyPurchasedCredits + payload,
      };
    // GET CREDITS PACKAGES
    case getCreditsPackagesRoutine.REQUEST:
      return {
        ...state,
        getCreditsPackagesPhase: LOADING,
      };
    case getCreditsPackagesRoutine.SUCCESS:
      return {
        ...state,
        packages: payload.data.packages,
        alreadyPurchasedCredits: payload.data.alreadyPurchasedCredits,
        maxCreditsPerMonth: payload.data.maxCreditsPerMonth,
        vat: payload.data.vat,
        getCreditsPackagesPhase: SUCCESS,
      };
    case getCreditsPackagesRoutine.FAILURE:
      return {
        ...state,
        getCreditsPackagesPhase: FAILURE,
        getCreditsPackagesError: payload,
      };
    // BUY CREDITS
    case buyCreditsRoutine.REQUEST:
      return {
        ...state,
        buyCreditsPhase: LOADING,
      };
    case buyCreditsRoutine.SUCCESS:
      return {
        ...state,
        paymentInfo: payload.data,
        buyCreditsPhase: SUCCESS,
      };
    case buyCreditsRoutine.FAILURE:
      return {
        ...state,
        buyCreditsPhase: FAILURE,
        buyCreditsError: payload,
      };
      // BUY CREDITS CONFIRM
    case buyCreditsConfirmRoutine.REQUEST:
      return {
        ...state,
        buyCreditsConfirmPhase: LOADING,
      };
    case buyCreditsConfirmRoutine.SUCCESS:
      return {
        ...state,
        buyCreditsConfirmPhase: SUCCESS,
      };
    case buyCreditsConfirmRoutine.FAILURE:
      return {
        ...state,
        buyCreditsConfirmPhase: FAILURE,
        buyCreditsConfirmError: payload,
      };
    // INIT PAYMENT METHOD
    case initPaymentMethodRoutine.REQUEST:
      return {
        ...state,
        initPaymentMethodPhase: LOADING,
      };
    case initPaymentMethodRoutine.SUCCESS:
      return {
        ...state,
        stripeKeys: payload.data,
        initPaymentMethodPhase: SUCCESS,
      };
    case initPaymentMethodRoutine.FAILURE:
      return {
        ...state,
        initPaymentMethodPhase: FAILURE,
        initPaymentMethodError: payload,
      };
    // ADD PAYMENT METHOD
    case addPaymentMethodRoutine.REQUEST:
      return {
        ...state,
        addPaymentMethodPhase: LOADING,
      };
    case addPaymentMethodRoutine.SUCCESS:
      return {
        ...state,
        addPaymentMethodPhase: SUCCESS,
      };
    case addPaymentMethodRoutine.FAILURE:
      return {
        ...state,
        addPaymentMethodPhase: FAILURE,
        addPaymentMethodError: payload,
      };
    // EDIT PAYMENT METHOD
    case editPaymentMethodRoutine.REQUEST:
      return {
        ...state,
        editPaymentMethodPhase: LOADING,
      };
    case editPaymentMethodRoutine.SUCCESS:
      return {
        ...state,
        editPaymentMethodPhase: SUCCESS,
      };
    case editPaymentMethodRoutine.FAILURE:
      return {
        ...state,
        editPaymentMethodPhase: FAILURE,
        editPaymentMethodError: payload,
      };
    // REMOVE PAYMENT METHOD
    case removePaymentMethodRoutine.REQUEST:
      return {
        ...state,
        removePaymentMethodPhase: LOADING,
      };
    case removePaymentMethodRoutine.SUCCESS:
      return {
        ...state,
        removePaymentMethodPhase: SUCCESS,
      };
    case removePaymentMethodRoutine.FAILURE:
      return {
        ...state,
        removePaymentMethodPhase: FAILURE,
        removePaymentMethodError: payload,
      };
      // GET PAYMENT METHODS
    case getPaymentMethodsRoutine.REQUEST:
      return {
        ...state,
        getPaymentMethodsPhase: LOADING,
      };
    case getPaymentMethodsRoutine.SUCCESS:
      return {
        ...state,
        paymentMethods: payload.data,
        getPaymentMethodsPhase: SUCCESS,
      };
    case getPaymentMethodsRoutine.FAILURE:
      return {
        ...state,
        getPaymentMethodsPhase: FAILURE,
        getPaymentMethodsError: payload,
      };
      // GET PAYMENT METHOD BY ID
    case getPaymentMethodByIdRoutine.REQUEST:
      return {
        ...state,
        getPaymentMethodByIdPhase: LOADING,
      };
    case getPaymentMethodByIdRoutine.SUCCESS:
      return {
        ...state,
        paymentMethodById: payload.data,
        getPaymentMethodByIdPhase: SUCCESS,
      };
    case getPaymentMethodByIdRoutine.FAILURE:
      return {
        ...state,
        getPaymentMethodByIdPhase: FAILURE,
        getPaymentMethodByIdError: payload,
      };
    // INVOICE ME
    case invoiceMeRoutine.REQUEST:
      return {
        ...state,
        invoiceMePhase: LOADING,
      };
    case invoiceMeRoutine.SUCCESS: {
      return {
        ...state,
        invoiceMePhase: SUCCESS,
      };
    }
    case invoiceMeRoutine.FAILURE:
      return {
        ...state,
        invoiceMePhase: FAILURE,
        invoiceMeError: payload,
      };
    default:
      return state;
  }
};

export default store;
