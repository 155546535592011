import React, { useMemo } from 'react';
import { func } from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon } from '@atoms';
import { routes } from '@constants';
import { Flex, Button } from '@mixins';
import { trackEvent } from '@utils/mixpanel';

import { ReactComponent as GetSupport } from '@assets/svgs/GetSupport.svg';
import { ReactComponent as BookRoom } from '@assets/svgs/BookRoom.svg';
import { ReactComponent as InviteGuests } from '@assets/svgs/InviteGuests.svg';

const ButtonsGrid = ({ onInviteGuests }) => {
  const history = useHistory();

  const { rooms: { meetingRooms } } = useSelector(store => store.roomStore);

  const { navigationItems } = useSelector(store => (
    store.metadataStore
  ));

  const handleInviteGuests = () => {
    trackEvent('"Invite Guests" button clicked', {
      scope: 'Home',
    });

    onInviteGuests();
  };

  const handleBookRoomClick = () => {
    trackEvent('"Book a Room" button clicked', {
      scope: 'Home',
    });

    history.push(routes.rooms);
  };

  const handleGetSupportClick = () => {
    trackEvent('"Get Support" button clicked', {
      scope: 'Home',
    });

    const navigationItemGetSupport = navigationItems.find(item => item.key === 'support');

    if (navigationItemGetSupport && navigationItemGetSupport.link) {
      window.open(navigationItemGetSupport.link);
    } else {
      history.push(routes.support);
    }
  };

  const buttonsArray = [
    {
      title: 'Book a Room',
      icon: BookRoom,
      handler: handleBookRoomClick,
      isShown: !!meetingRooms.length,
    },
    {
      title: 'Invite Guests',
      icon: InviteGuests,
      handler: handleInviteGuests,
      isShown: true,
    },
    {
      title: 'Get Support',
      icon: GetSupport,
      handler: handleGetSupportClick,
      isShown: true,
    },
  ];

  const buttons = useMemo(() => buttonsArray.reduce((acc, { title, icon, handler, isShown }, index) => {
    if (isShown) {
      acc.push(
        <Button
          onClick={handler}
          key={title}
          px={['16px', '26px', '52px']}
          py={['6px', '12px', '25px']}
          variant="secondary"
          mr={index !== 2 ? '10px' : 0}
        >
          <Flex fontSize={[10, 14, 16]} alignItems="center">
            <Icon mr={['6px', '10px', '13px']} SVG={icon} />
            {title}
          </Flex>
        </Button>,
      );
    }

    return acc;
  }, []), [buttonsArray]);

  return (
    <Flex>
      {buttons}
    </Flex>
  );
};

ButtonsGrid.propTypes = {
  onInviteGuests: func.isRequired,
};

export default ButtonsGrid;
