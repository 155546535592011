import React, { useState } from 'react';
import { string } from 'prop-types';
import DOMPurify from 'dompurify';

import { Icon, Hint } from '@atoms';
import { trackEvent } from '@utils/mixpanel';
import { Typography, Flex } from '@mixins';

import { ReactComponent as Copy } from '@assets/svgs/Copy.svg';

const Coupon = ({ coupon, link, couponDescription }) => {
  const [isCouponCopied, setIsCouponCopied] = useState(false);

  const onCopy = () => {
    navigator.clipboard.writeText(coupon);
  };

  const handleCouponCopy = () => {
    setIsCouponCopied(true);
    onCopy();
    trackEvent('Coupon copied', {
      scope: 'Benefits',
    });
  };

  const handleCouponHintClose = () => {
    setIsCouponCopied(false);
  };

  return (coupon || link) && (
    <Flex alignSelf="start" flexDirection="column" alignItems="start" width={[300, 500]}>
      <Typography mb={[15, 30]} lineHeight={1} variant="garamond-500" fontSize={[24, 30, 36]}>
        Get this benefit
      </Typography>
      {coupon && (
        <Typography mb={[15, 30]} variant="proximaNova-400" fontSize={[14, 16]}>
          {`Go to the website to get this benefit!
          Copy coupon code and use it in the website for the discount.`}
        </Typography>
      )}
      <Flex
        width={[200, 300, 400, 480]}
        border="1px solid #303E3C"
        p={[18, 25]}
        justifyContent="space-between"
        alignItems="center"
      >
        {coupon ? (
          <>
            <Typography variant="proximaNova-600" fontSize={[14, 16, 20]}>
              {coupon}
            </Typography>
            <Flex position="relative">
              <Hint title="Code copied" isOpen={isCouponCopied} onClose={handleCouponHintClose} />
              <Icon pointer onClick={handleCouponCopy} SVG={Copy} width={[10, 16]} height={[10, 16]} />
            </Flex>
          </>
        )
          : (
            <Flex
              display="block"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(couponDescription) }}
            />
          )}
      </Flex>
    </Flex>
  );
};

Coupon.defaultProps = {
  link: '',
  coupon: '',
  couponDescription: '',
};

Coupon.propTypes = {
  link: string,
  coupon: string,
  couponDescription: string,
};

export default Coupon;
