import { createRoutine } from 'redux-routines';
import {
  INIT, LOADING, SUCCESS, FAILURE,
} from '@constants/requestPhase';

import * as api from './api';

// < ----- ACTIONS ----- > //
const announcementsAction = 'ANNOUNCEMENTS';
const resetStoreToInitialStateAction = 'RESET_STORE';

const getAnnouncementsRoutine = createRoutine(
  'GET_ANNOUNCEMENTS',
);

// < ----- ACTION CREATORS ----- > //
export const getAnnouncementsList = data => ({
  type: announcementsAction,
  payload: data,
});

export const resetStore = () => ({
  type: resetStoreToInitialStateAction,
});

export const getAnnouncements = id => async dispatch => {
  try {
    dispatch(getAnnouncementsRoutine.request());

    const response = await api.getAnnouncements(id);

    return dispatch(getAnnouncementsRoutine.success(response.data));
  } catch (error) {
    return dispatch(getAnnouncementsRoutine.failure(error.response));
  }
};

// < ----- STATE ----- > //

const initialState = {
  announcements: {},
  announcementsPhase: INIT,
  announcementsError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state = initialState, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;
    // GET ANNOUNCEMENTS
    case getAnnouncementsRoutine.REQUEST:
      return {
        ...state,
        announcementsPhase: LOADING,
      };
    case getAnnouncementsRoutine.SUCCESS:
      return {
        ...state,
        announcements: payload.data,
        announcementsPhase: SUCCESS,
      };
    case getAnnouncementsRoutine.FAILURE:
      return {
        ...state,
        announcementsPhase: FAILURE,
        announcementsError: payload,
      };
    default:
      return state;
  }
};

export default store;
