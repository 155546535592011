import React from 'react';
import { string, func } from 'prop-types';

import { Auth } from '@templates';
import { theme } from '@styles';
import { Flex, Button } from '@mixins';
import Gif from '@assets/gifs/failure.gif';

const Oops = ({
  codeAddress,
  onTryAgain,
}) => {
  const text = `Looks like we can’t find\n${codeAddress}`;

  return (
    <Auth
      title="Oops!"
      text={text}
      isLoading={false}
    >
      <Flex mb={25} as="img" width={[140, 188]} height={[140, 188]} src={Gif} />
      <Button onClick={onTryAgain} fontSize="14px" color={theme.color.darkGreen}>Try again</Button>
    </Auth>
  );
};

Oops.propTypes = {
  codeAddress: string.isRequired,
  onTryAgain: func.isRequired,
};

export default Oops;
