import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { CompanyProfile as View } from '@views';
import { resetPhases as resetUserPhases } from '@store/user/duck';
import {
  getCompanyById,
  getCompanyMembers,
  resetPhases as resetCompanyPhases,
  editCompany,
} from '@store/company/duck';
import { useViewport } from '@hooks';
import { SUCCESS, LOADING, FAILURE } from '@constants/requestPhase';
import { COMPANY_ADMIN_ROLE } from '@constants/common';

import { ReactComponent as Twitter } from '@assets/svgs/TwitterProfile.svg';
import { ReactComponent as Facebook } from '@assets/svgs/FacebookProfile.svg';
import { ReactComponent as LinkedIn } from '@assets/svgs/LinkedInProfile.svg';
import { ReactComponent as Instagram } from '@assets/svgs/InstagramProfile.svg';
import { trackEvent } from '@utils/mixpanel';

const CompanyProfile = () => {
  const history = useHistory();

  const { user } = useSelector(store => (
    store.userStore
  ));

  const {
    companyById, getCompanyMembersPhase, editCompanyPhase, getCompanyByIdPhase, editCompanyError,
  } = useSelector(store => (
    store.companyStore
  ));

  const [error, setError] = useState();
  const [logo, setLogo] = useState(companyById.logoUrl);

  const dispatch = useDispatch();
  const screenSize = useViewport();
  const { id } = useParams();
  const { state } = useLocation();

  const pageSize = screenSize.width < 1024 ? 4 : 6;

  useEffect(() => {
    const pageTitle = 'Mindspace: Company Profile';
    document.title = pageTitle;

    window.gtag('event', 'page_view', {
      page_title: pageTitle,
    });

    trackEvent('Page Viewed', {
      Page: 'Company Profie',
    });
  }, []);

  useEffect(() => {
    if (companyById.id !== parseInt(id, 10) || Object.keys(companyById).length === 1) {
      dispatch(getCompanyById(id, pageSize));
    }
  }, [id]);

  useEffect(() => {
    if (getCompanyMembersPhase === SUCCESS || editCompanyPhase === SUCCESS) {
      setLogo(companyById.logoUrl);

      dispatch(resetUserPhases());
      dispatch(resetCompanyPhases());
    }
    if (editCompanyPhase === FAILURE) {
      setError({
        status: editCompanyError.data.error.status,
        message: editCompanyError.data.error.message,
      });
      dispatch(resetCompanyPhases());
    }
    if (getCompanyByIdPhase === FAILURE) {
      history.goBack();
    }
  }, [getCompanyMembersPhase, editCompanyPhase, getCompanyByIdPhase]);

  const handleSavePhoto = useCallback(file => {
    const companyData = new FormData();

    companyData.append('logo', file);

    dispatch(editCompany(id, companyData));
  }, [id]);

  const handleRemovePhoto = useCallback(() => {
    dispatch(editCompany(id, {
      logo: null,
    }));
  }, [id]);

  const handleTeamLoad = useCallback(() => {
    dispatch(getCompanyMembers(companyById.id, { pageSize, page: companyById.members.pagination.page + 1 }));
  }, [companyById, pageSize]);

  const socials = [
    {
      name: 'LinkedIn',
      link: companyById.linkedin,
      icon: LinkedIn,
    },
    {
      name: 'Instagram',
      link: companyById.instagram,
      icon: Instagram,
    },
    {
      name: 'Twitter',
      link: companyById.twitter,
      icon: Twitter,
    },
    {
      name: 'Facebook',
      link: companyById.facebook,
      icon: Facebook,
    },
  ];

  const isEditable = user.role === COMPANY_ADMIN_ROLE && user.company?.id === parseInt(id, 10);

  return (
    <View
      error={error}
      isPhotoLoading={editCompanyPhase === LOADING}
      from={state?.from}
      companyId={parseInt(id, 10)}
      onTeamLoad={handleTeamLoad}
      team={companyById.members?.data}
      teamAmount={companyById.members?.pagination?.total}
      onRemovePhoto={handleRemovePhoto}
      onSavePhoto={handleSavePhoto}
      logo={logo}
      isLoading={getCompanyByIdPhase === LOADING}
      companyName={companyById?.name}
      industry={companyById.industry}
      website={companyById.website}
      email={user.email}
      socials={socials}
      aboutUs={companyById.description}
      isEditable={isEditable}
    />
  );
};

export default CompanyProfile;
