import React, { useState, useCallback } from 'react';
import { bool, func } from 'prop-types';

import { Modal } from '@atoms';
import { TextInput } from '@atoms/Input';
import { Flex, Typography, Button } from '@mixins';

const ExternalCompanyModal = ({
  isOpen,
  onModalClose,
  onCompanySelect,
}) => {
  const [company, setCompany] = useState('');

  const onCompanyChange = useCallback(event => {
    setCompany(event.target.value);
  }, []);

  const handleDoneClick = () => {
    onCompanySelect(company);
    handleModalClose();
  };

  const handleModalClose = () => {
    setCompany('');
    onModalClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <Flex
        height={[300]}
        width={[540]}
        pt={['8px', 12, 16]}
        px={[12, 16, 20]}
        flexGrow={1}
        flexDirection="column"
        alignItems="center"
      >
        <Typography alignSelf="start" mb={[18, 24, 30]} variant="proximaNova-600" fontSize={[16, 18, 20]}>
          Book for External Company
        </Typography>
        <TextInput
          placeholder="Company name"
          onChange={onCompanyChange}
          value={company}
        />
        <Button onClick={handleDoneClick} mt={55} variant="primary" px={92} py={12}>Done</Button>
      </Flex>
    </Modal>
  );
};

ExternalCompanyModal.propTypes = {
  isOpen: bool.isRequired,
  onModalClose: func.isRequired,
  onCompanySelect: func.isRequired,
};

export default ExternalCompanyModal;
